* {
  box-sizing: border-box;
}
@font-face {
  font-family: "BrandonText-Light";
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-Light.otf);
  font-display: swap;
}
@font-face {
  font-family: "BrandonText-Bold";
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-Bold.otf);
  font-display: swap;
}
@font-face {
  font-family: "BrandonText-RegularItalic";
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-RegularItalic.otf);
  font-display: swap;
}
@font-face {
  font-family: "BrandonText-Regular";
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-Regular.otf);
  font-display: swap;
}
body {
  background-color: #ffffff;
  font-family: "BrandonText-Light", sans-serif;
  color: #000000;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
  line-height: normal;
  font-family: "BrandonText-Bold", sans-serif;
}
p {
  margin: 0px;
  padding: 0px;
  line-height: normal;
  font-family: "BrandonText-Light", sans-serif;
}
a {
  color: #000;
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}
.lg-none {
  display: none;
}
.sm-none {
  display: block;
}
.title-page h1 {
  color: #000;
  text-align: center;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  text-transform: uppercase;
}
.container-new {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
}
.info-text p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 30px;
  letter-spacing: 1.08px;
}
.title-page {
  padding: 2px 0px;
}
.image-list-one img {
  width: 100%;
  height: auto;
}
.image-list-one {
  padding: 35px 0px;
}
.sub-abt h2 {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 450;
  line-height: 245.99%;
  text-transform: uppercase;
  padding-bottom: 15px;
}
.sub-abt p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 30px;
  letter-spacing: 1.08px;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.gallery img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.gallery {
  flex-wrap: nowrap;
}
.left-gallery-one {
  width: 40%;
}
.left-gallery-one img {
  width: 95%;
}
.right-gallery-one {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.right-gallery-one img:nth-child(1) {
  padding-bottom: 5px;
}
.right-gallery-one img:nth-child(2) {
  padding-top: 5px;
}
.gallery .right img {
  flex: 0 0 48%;
  max-width: 48%;
}
.gallery-flex ul li {
  width: 50%;
}
.gallery-flex ul {
  display: flex;
}
.gallery-flex ul li img {
  width: 100%;
}
.gallery-flex ul li:first-child {
  padding-right: 10px;
}
.gallery-flex ul li:nth-child(2) {
  padding-left: 10px;
}
.sub-abt {
  padding-bottom: 30px;
}
.pg-top {
  padding-top: 30px;
}
.start-explor a {
  display: inline-block;
  border-radius: 9px;
  background: #000;
  color: #fff;
  text-align: center;
  font-family: "BrandonText-Light", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 450;
  line-height: 55px;
  text-transform: uppercase;
  width: 340px;
  height: 54px;
}
.start-explor {
  text-align: center;
  padding: 50px 0px;
}
/* ================Store page ===============*/
.visit-tittle h1 {
  color: #000;
  text-align: right;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  text-transform: uppercase;
}
.choose-row {
  display: flex;
}
.visit-tittle {
  width: 50%;
}
.choose-emirates {
  display: flex;
}
.choose-countries {
  display: flex;
  margin-left: 0px !important;
}
.visit-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.visit-row ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.visit-row ul li {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.viisit-img {
  width: 50%;
}
.viisit-img img {
  width: 100%;
}
.visit-text {
  width: 50%;
  height: 100%;
  background-color: #000;
  max-height: max-content;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 30px;
  position: relative;
}
.visit-pg {
  min-height: 145px;
  max-height: 145px;
  overflow: auto;
}
.choose-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.visit-text p {
  color: #fff;
  font-family: "BrandonText-Light", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 30px;
  text-transform: capitalize;
}
.visit-text a {
  margin-top: 25px;
  display: flex;
  width: 175px;
  height: 46px;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  gap: 38px;
  flex-shrink: 0;
  border-radius: 9px;
  background: #fff;
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
        bottom: 30px;
}
.visit-text h2 {
  color: #fff;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 450;
  line-height: 32px;
  text-transform: capitalize;
}
.visit-text h2::first-letter {
  text-transform: uppercase;
}
.visit-text p {
  color: #fff;
  font-family: "BrandonText-Light", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: normal;
  text-transform: capitalize;
}
section.visit-section {
  padding-bottom: 50px;
}
/* ================Store details page ===============*/
.row-details h2 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 245.99%;
  text-transform: uppercase;
}
.row-details h5 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 450;
  line-height: 25px;
  text-transform: lowercase;
}
.row-details p {
  color: #000;
  font-family: "BrandonText-Light", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 245.99%;
}
.row-details {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0px;
  gap: 0px 10%;
}
.store-address {
  width: 45%;
}
.store-hours {
  width: 45%;
}
.store-contact {
  width: 45%;
  padding-top: 25px;
}
.direction-btn a {
  display: flex;
  width: 340px;
  height: 54px;
  padding: 16px 102px;
  justify-content: center;
  align-items: center;
  gap: 38px;
  flex-shrink: 0;
  border-radius: 9px;
  background: #000;
  color: #fff;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 15px;
}
.direction-btn {
  padding-top: 30px;
}
.slider-owl-sect {
  position: relative;
}

.owl-carousel.owl-theme.owl-slider .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.owl-carousel.owl-theme.owl-slider button.owl-next {
  background-color: rgb(199, 193, 193) !important;
  padding: 10px;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
  color: #fff;
  width: 25px;
  height: 30px;
}
.owl-carousel.owl-theme.owl-slider button.owl-next {
  background-color: #ebece9 !important;
  padding: 10px;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
  color: #fff;
  width: 25px;
  height: 30px;
  position: absolute;
  right: -70px;
}
.owl-carousel.owl-theme.owl-slider button.owl-prev {
  background-color: #ebece9 !important;
  padding: 10px;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
  color: #fff;
  width: 25px;
  height: 30px;
  position: absolute;
  left: -74px;
}
.owl-carousel .owl-item img {
  width: 100%;
  height: 530px;
}
/* ================carrer page ===============*/
.carrer-listing {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.sales-list {
  width: 33.3%;
}
.marketing-list {
  width: 33.3%;
}
.finance-list {
  width: 33.3%;
}
.carrer-listing h3 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.carrer-sect {
  padding: 40px 0px;
}
.banner-careers img {
  width: 100%;
}
.carrer-listing ul li {
  color: #000;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 45px;
  text-decoration-line: underline;
  text-decoration-color: #a9a0a0;
}
.carrer-listing ul {
  padding-top: 20px;
}
.corporate-list {
  width: 33.3%;
  padding-top: 40px;
}
.career-review {
  display: flex;
}
.career-review-img {
  width: 100%;
}
.career-review-text {
  width: 50%;
  background-color: #efd8c5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.career-review-img img {
  width: 100%;
  min-height: 300px;
}
.wi-50 {
  width: 60%;
  text-align: center;
}
.career-review-text span {
  display: block;
}
.career-review-text h3 {
  color: #000;
  text-align: center;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.career-review-text p {
  color: #000;
  text-align: center;
  font-family: "BrandonText-RegularItalic", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 185%;
  padding-top: 20px;
}
section.career-detail-sect {
  padding-bottom: 40px;
}
/* ================detail page ===============*/
.retail-sales {
  padding: 30px 0px;
}
.retail-sales span {
  color: rgba(0, 0, 0, 0.5);
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 245.99%;
  text-transform: capitalize;
}
.retail-sales h2 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 450;
  line-height: 50px;
}
.retail-sales h3 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 245.99%;
  text-transform: capitalize;
}
.retail-sales p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 30px;
  letter-spacing: 1.08px;
}
.respons-list {
  padding: 20px 0px;
}
.respons-list ul li {
  list-style-type: disc;
  color: #000;
  font-family: "BrandonText-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 245.99%;
  letter-spacing: 1.08px;
  padding-left: 20px;
}

.respons-list ul {
  padding: 10px 20px;
}
.apply-form h3 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: 245.99%;
  text-align: center;
  text-transform: capitalize;
  margin: 0 auto;
  padding-bottom: 15px;
}
.apply-form form {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
}

.apply-form .form-group {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
}

.apply-form input[type="text"],
input[type="file"] {
  width: 50%;
  padding: 10px;
  margin: 5px;
  border: 1px solid #000;
  padding: 16px 20px;
  border-radius: 9px;
}
.apply-form .file-upload {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 5px 0px 5px;
}

.apply-form .file-upload input[type="file"] {
  display: none;
}

.apply-form .file-upload label {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 9px;
  border: 2px dashed rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  width: 100%;
  text-align: center;
  min-height: 200px;
}

.apply-form .file-upload img {
  max-width: 50px; /* Set the maximum width for the image */
  max-height: 50px; /* Set the maximum height for the image */
  margin-right: 10px;
}
.form-group.file-upload label {
  display: flex;
  flex-direction: column;
}
.upload-top {
  padding-top: 10px;
}
.upload-top p {
  color: #000;
  text-align: center;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 390;
  line-height: 23px;
}
.upload-top h4 {
  color: #000;
  text-align: center;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}
.apply-form .form-group-2 {
  width: 100%;
  margin: 35px 0px;
}
.form-group-2 input[type="text"] {
  width: 100%;
}
.form-group-2 label {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 245.99%;
  text-transform: capitalize;
}
.form-group input[type="submit"] {
  display: flex;
  width: 340px;
  height: 54px;
  padding: 16px 102px;
  justify-content: center;
  align-items: center;
  gap: 38px;
  border-radius: 9px;
  background-color: #000;
  color: #fff;
  text-align: center;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 auto;
}
input[type="text"]::placeholder {
  color: #000;
}
.form-group-2 input[type="text"]::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.form-group input#num::placeholder {
  color: #cbc9c9;
}

/* ================faq page ===============*/
#faq-links {
  padding: 10px 20px;
}

#faq-links div {
  color: #aeaeaf;
  cursor: pointer;
  font-weight: 700;
  text-transform: capitalize;
  margin: 18px 0;
}

.faq-group {
  padding: 0 20px 40px 20px;
  position: relative;
}

.faq-group div {
  font-weight: 700;
  text-align: center;
}

.faq-group hr {
  border: 0;
  border-top: 1px solid #606060;
  clear: both;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}

.faq-group:after {
  top: 33%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #393939;
  border-width: 15px;
  margin-left: -15px;
}

.faq-accordion > li {
  border-top: 1px solid rgba(198, 198, 198, 0.4);
  color: #666;
  padding: 1.125em 0;
}

.faq-accordion li a {
  color: #666;
  padding: 1.125em;
}

.faq-accordion > li:last-child {
  border-bottom: 1px solid rgba(198, 198, 198, 0.4);
}

.faq-accordion > li > a:after {
  content: "";
  float: right;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  line-height: 17px;
  margin-left: 10px;
  font-size: 13px;
  color: #fff;
  font-family: "BrandonText-Regular";
  font-weight: 300;
  margin-right: 1.25em;
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  overflow-y: hidden;
  margin-top: 10px;

  background-image: url("../images/Add.svg");
  background-size: cover; /* or 'contain' depending on your preference */
  background-repeat: no-repeat;
  background-position: center;
}

.faq-accordion > li > a.expanded:after {
  content: "";
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  color: #ffffff;
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  text-align: center;
  font-family: "BrandonText-Regular", sans-serif;
  line-height: 17px;
  margin-left: 10px;
  font-size: 13px;
  margin-top: 10px;

  background-image: url("../images/minus.svg");
  background-size: cover; /* or 'contain' depending on your preference */
  background-repeat: no-repeat;
  background-position: center;
}

.faq-accordion > li > a.expanded {
  font-weight: 700;
  opacity: 1 !important;
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 60px;
  text-transform: uppercase;
  /* padding: 10px; */
}

.faq-content {
  border-top: solid 1px #e8e8e8;
}
.faq-w {
  width: 80%;
  margin: 0 auto;
  padding: 40px 0px;
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 390;
  line-height: 245.99%;
  letter-spacing: 1.08px;
  text-transform: capitalize;
}
.faq-content p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 390;
  line-height: 245.99%;
  letter-spacing: 1.08px;
  text-transform: capitalize;
}
.faq-w h3 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 50px;
}

.faq-hide {
  display: none;
}

.faq-selected {
  color: #49494b !important;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.slide-left {
  -webkit-animation-name: slide-left;
  -moz-animation-name: slide-left;
  animation-name: slide-left;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
ul.faq-accordion li a {
  font-weight: 700;
  opacity: 1 !important;
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 40px;
  text-transform: uppercase;
}
div#faq-wrapper {
  padding-top: 30px;
}

/* ================blog page ===============*/
.bg-blog-banner {
  background-image: url(../images/blog-banner.webp);
  background-position: center bottom;
  min-height: 600px;
}
.blog-content a {
  display: flex;
  width: 193px;
  height: 49px;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  gap: 38px;
  flex-shrink: 0;
  border-radius: 9px;
  background: #fff;
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 30px;
}
.blog-content h2 {
  color: #fff;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 450;
  line-height: 144.49%;
  text-transform: uppercase;
}
.blog-content span {
  display: block;
}
.blog-content {
  padding: 60px;
}
.blog-sub-text {
  padding: 45px 0px;
}
.blog-rows {
  display: flex;
  padding: 22px 0px;
}
.blog-col-1 {
  width: 50%;
}
.blog-col-2 {
  width: 50%;
}

.blog-sub-text h2 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 32px;
  text-align: center;
  font-style: normal;
  font-weight: 450;
  line-height: 144.49%;
  text-transform: uppercase;
}
.blog-content-sub p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 25px;
  letter-spacing: 1.08px;
}
.blog-content-sub {
  padding-left: 50px;
}
.blog-content-sub h3 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 450;
  line-height: 144.49%;
  text-transform: uppercase;
  padding-bottom: 20px;
}
.blog-content-sub a {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 390;
  line-height: 143%;
  text-decoration-line: underline;
  text-transform: capitalize;
  margin-top: 40px;
  display: inline-block;
}
span.blog-date {
  color: #000;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 390;
  line-height: 143%;
  letter-spacing: 1.08px;
  display: block;
  padding-top: 55px;
}
.storage-care {
  display: flex;
  align-items: center;
  width: 50%;
  padding-right: 25px;
}
.storage-care-img {
  width: 40%;
}
.storage-care-txt {
  width: 60%;
}
.storage-care-img img {
  width: 100%;
}
.storage-rows {
  display: flex;
}
.storage-rows {
  padding: 30px 0px;
  border-top: solid 1px #000;
  border-bottom: solid 1px #000;
}
.storage-rows h2 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 144.49%;
  text-decoration-line: underline;
  text-transform: capitalize;
  line-height: 50px;
}
section.perfume-store {
  padding: 40px 0px;
}
.storage-rows p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 390;
  line-height: 200.99%;
  letter-spacing: 1px;
  text-transform: capitalize;
  max-height: 80px;
  overflow: hidden;
}
.storage-care-img img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.shelf-life {
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.storage-rows a {
  color: #000;
  text-align: justify;
  font-size: 15px;
  font-style: normal;
  font-weight: 390;
  line-height: 50px;
  font-family: "BrandonText-Regular", sans-serif;
  text-decoration-line: underline;
  text-transform: capitalize;
}

.blog-text-personality h3 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 32px;
  text-align: center;
  font-style: normal;
  font-weight: 450;
  line-height: 144.49%;
  text-transform: uppercase;
}
.blog-text-personality p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 25px;
  letter-spacing: 1.08px;
}
.blog-text-personality h3 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 450;
  line-height: 144.49%;
  text-transform: uppercase;
  padding-bottom: 20px;
  text-align: left;
}
.blog-text-personality a {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 390;
  line-height: 143%;
  text-decoration-line: underline;
  text-transform: capitalize;
  margin-top: 30px;
  display: inline-block;
}

.blog-img-personality {
  padding-left: 50px;
}
section.perfume-row {
  padding-bottom: 30px;
}
section.perfume-row-3 {
  padding: 40px;
}
.choose-emirates select {
  border: none;
  outline: none !important;
}
.choose-emirates select {
  color: #000;
  text-align: center;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 390;
  line-height: normal;
  text-transform: capitalize;
}

.blog-col-1 img {
  height: 355px;
}
.career-review-text.lg-none {
  display: none;
}

span#word-count {
  display: block;
  text-align: right;
  display: block;
  text-align: right;
  color: #c9c3c3;
  font-size: 13px;
  text-decoration: underline;
}
.custom-select {
  position: relative;
  font-family: "BrandonText-regular", sans-serif;
  font-size: 18px;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #030303;
  padding: 8px 16px;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  background-color: #fff;
  border-radius: 10px;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 16px !important;
  right: -5px;
  width: 16px;
  height: 17px;
  background-image: url(../images/arrow-btm.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: #80ba27;
  border-radius: 0px;
}
.select-items {
  position: absolute;
  right: 0;
  text-align: left;
  box-shadow: 0px 0px 11px 1px #d5cccc;
  border-radius: 10px;
  background: #fff;
}

/*==========blog-detail-page==========*/

.blog-detail-page img {
  width: 100%;
}
.blog-detail-page {
  padding-top: 70px;
}
.deatil-blog-content h1 {
  color: #000;
  text-align: center;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 450;
  line-height: 144.49%;
  text-transform: uppercase;
  padding-top: 40px;
    padding-bottom: 20px;
}
.deatil-blog-content {
  padding: 20px 0px;
  border-bottom: solid 1px #ccc5c5;
}
.deatil-blog-content p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 30px;
  letter-spacing: 1.08px;
  padding-top: 10px;
}
.details-full-img {
  width: 90%;
  margin: 0 auto;
}
.details-full-img img {
  width: 100%;
}
.date-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.date-text p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 245.99%;
  letter-spacing: 1.08px;
  text-transform: capitalize;
}
.deatil-blog-content h2 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 450;
  line-height: 144.49%;
  text-transform: uppercase;
  padding-top: 15px;
  ;
}
.deatil-blog-contents h3 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 450;
  line-height: 144.49%;
  text-transform: uppercase;
}
.deatil-blog-contents p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 30px;
  letter-spacing: 1.08px;
  /* text-transform: capitalize; */
}
.deatil-blog-contents {
  padding-top: 0px;
  padding-bottom: 40px;
}
.storage-rows h3 {
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 50px;
  text-decoration-line: underline;
  text-transform: capitalize;
}
ol.breadcrumb {
  margin-bottom: 2rem;
}
a.dropdown-toggle.text-dark.btn-filter {
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  color: #000;
}
.card-footer.border-0 h4.ellipsis-text {
  text-align: left !important;
  cursor: pointer;
}

.input-spinner .form-input {
  font-size: 13px;
  font-weight: bolder;
  color: #000 !important;
}
.product-desc-section h2 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 185%;
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  line-height: 36px;
}
.flash-sale-box h2 {
  color: #fff !important;
  text-transform: uppercase;
}
.flash-sale-box p {
  line-height: 17px;
}
.flash-sale-box span img {
  vertical-align: baseline;
}
.flash-sale-box span svg {
  vertical-align: baseline;
}
.fixed-top-margin.main-layout .conatiner.px-5 {
  padding-bottom: 0px;
}
.fixed-top-margin.main-layout.empty-top {
  margin-top: 10px;
}
.alert-box-bag-empty p {
  color: #0000;
  font-size: 12px;
  line-height: 22px;
  width: 30%;
  margin: 0 auto;
  padding-top: 10px;
  font-weight: 600;
}
.card.login-card .row.social-head {
  text-align: center;
}
.row.product-rating span svg {
  vertical-align: bottom;
  margin-right: 5px;
}
.py-6.p-md-6.p-lg-10 {
  padding-top: 0px !important;
}
ul.nav.flex-column.nav-pills.nav-pills-dark {
  padding-top: 24px;
}

button.btn.btn-dark.w-100.mt-2.add-to-bag-btn svg {
  vertical-align: middle;
}
.row.product-rating .col-md-3.mt-3 {
  text-align: center;
}
input.form-control.border-danger {
  border-color: black !important;
}

.change-btn {
  text-decoration-line: underline;
  padding-top: 10px;
  display: inline-block;
}
.row.brand-rating .col-md-3.mt-3 {
  text-align: center;
}

button.btn.btn-dark.btn-checkout.w-100 svg {
  vertical-align: bottom;
}

.info-box h2 span svg {
  vertical-align: middle;
}
svg.regular-sort-icon {
  vertical-align: middle;
}
svg.remove-icon {
  vertical-align: bottom;
}
.input-group-custom.input-spinner {
  justify-content: center;
  margin-left: 20px;
}
.checkout-left-card h5 {
  padding-left: 0px;
}
.secure-checkout-top {
  padding-top: 20px;
  border-top: solid 1px #e4e4e4;
}
p.delivery-info.mb-0 span svg {
  vertical-align: middle;
}
.row.g-4.m-2 {
  margin-left: calc(var(--fc-gutter-x) * -0.5) !important;
}
div#myTabContent button.btn.btn-dark.validate {
  margin-left: 5px !important;
  padding-left: 40px;
  padding-right: 40px;
}
div#myTabContent .card-body.p-6 {
  padding-left: 0px !important;
}
.col-md-3.text-center button.btn.btn-dark.w-100 {
  text-transform: uppercase;
}
.col-md-5 .p-d {
  padding-bottom: 40px;
}
li.list-group-item
  .row.align-items-center.d-flex.justify-content-space-between {
  align-items: normal !important;
  justify-content: space-between;
}
li.list-group-item
  .row.align-items-center.d-flex.justify-content-space-between
  h4 {
  padding-bottom: 10px;
}
ul.list-group.list-group-flush.px-6 {
  max-height: 240px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 3px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

:-webkit-scrollbar-thumb:hover {
  background-color: #797979; /* Color of the thumb on hover */
}
.row.p-ul .col-md-6.p ul li {
  list-style-type: disc;
}
.col-md-5.col-4.icon-end {
  text-align: end;
}
div#myTabContent .col-md-6.col-12.text-end a {
  cursor: pointer;
}

button.btn.btn-dark.p-32px.validate {
  padding-left: 50px;
  padding-right: 50px;
}
.address-popup-title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 29.6px;
  font-family: "BrandonText-Bold", sans-serif;
}
button.ModalBtn.offer-button {
  font-style: normal;
  font-weight: 450;
  line-height: 33px;
  letter-spacing: 4.6px;
  font-family: var(--fc-font-bold);
  border-radius: 10px 10px 0px 0px !important;
  padding: 4px 30px;
  border: none;
}
.row.align-items-center.justify-content-center.alert-box-bag-empty p {
  color: #000;
}
.row.brand-rating {
  width: 100%;
    display: flex;
    justify-content: end;
}
.row.brand-rating .col-md-9.col-10 {
  max-height: 385px;
  overflow-y: scroll;
}
.row.brand-rating .col-md-9.col-12 {
  max-height: 385px;
  overflow-y: scroll;
}
div#addressModal .modal-body {
  max-height: 300px;
  overflow-y: scroll;
}
p.delivery-info span svg {
  vertical-align: middle;
}
.rec-products-container .col-md-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.product-grid h4 {
  text-transform: capitalize;
  text-align: left;
}
.product-grid h4::first-letter {
  text-transform: uppercase;
}
.product-grid .card-footer {
  margin: 0px;
}
.row.d-flex.justify-content-center.dashboard-row {
  padding-bottom: 30px;
}
.row.d-flex.justify-content-center.dashboard-row .col-md-3.col-6.text-center {
  padding: 20px 0px;
}
/*.selling-price {
  padding-left: 5px;
}*/
.col.best-scroll {
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
}
.category-top {
  user-select: none;
  padding-top: 40px;
}
.icon-input .icon-input__icon {
  cursor: pointer;
}
img.img-fluid.img-base {
  margin-top: 10px;
}
.card-body.p-0.carts-pd {
  /* padding: 20px 30px; */
  margin: 10px 5px;
}
.fw-bold p.text.mb-0 {
  font-family: "BrandonText-bold", sans-serif;
}
.col-auto.search-tag {
  cursor: pointer;
}
.see-btn {
  cursor: pointer;
  color: #000;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  display: inline-block;
  padding-bottom: 20px;
}

.order-middle-row p {
  padding-top: 6px;
}
body.returns ul.nav.flex-column.nav-pills.nav-pills-dark {
  padding-top: 33px;
}
.order-bottom-row .react-multiple-carousel__arrow {
  background-color: #ebece9 !important;
  /* display: none; */
}
.order-img {
  width: 140px;
}
.return-reason {
  margin-top: 20px;
}
.col-md-2.text-end.p-0 span {
  cursor: pointer;
}
.row.search-container span {
  cursor: pointer;
}

a.col-12.link-dark {
  cursor: pointer;
}
.row.custom-row1.mb-5 {
  display: flex !important;
}
.input-icon-close {
  display: none;
}
.row.align-items-center.justify-content-center.alert-box-order-empty.mt-2 h1 {
  padding-top: 25px;
  padding-bottom: 10px;
  text-transform: capitalize;
  color: #ce1124;
  font-size: 25px;
  font-style: normal;
  font-weight: 450;
  line-height: 26px;
  text-transform: capitalize;
  font-family: var(--fc-font-bold);
}
.row.align-items-center.justify-content-center.alert-box-order-empty.mt-2 h1 p {
  padding-top: 10px;
}
.radio-button-label .options {
  font-family: "BrandonText-Light", sans-serif;
  font-size: 11px;
}
.cancel-btns p {
  font-family: "BrandonText-Light", sans-serif !important;
  padding-bottom: 20px !important;
  font-size: 12px !important;
}
.btn-ongoing {
  padding: 6px 40px 5px 30px;
  border-radius: 50px;
  height: 35px;
  margin-bottom: 30px;
  position: relative;
  z-index: 10;
}
.btn-complete {
  position: absolute;
  width: 156px;
  left: 173px;
  padding: 6px 0px 5px 0px;
  border-radius: 50px;
  height: 35px;
  margin-bottom: 30px;
}
button.order-btn-inactive {
  color: #7c756d;
}
.order-btn {
  padding-bottom: 2px;
  margin-bottom: 0px !important;
}
.row.align-items-center.justify-content-center.alert-box-order-empty.mt-2 a {
  background: linear-gradient(
    90deg,
    #397de4 5.71%,
    #25abcf 45.28%,
    #0fa 89.15%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.row.align-items-center.justify-content-center.alert-box-order-empty.mt-2 {
  padding: 60px 0px;
}
.row.align-items-center.justify-content-center.alert-box-order-empty.mt-2 svg {
  vertical-align: bottom;
}
.btn-cancelled {
  padding: 6px 40px 5px 30px;
  border-radius: 50px;
  height: 35px;
  margin-bottom: 30px;
  position: relative;
  z-index: 10;
}
.btn-returned {
  position: absolute;
  width: 156px;
  left: 120px;
  padding: 6px 0px 5px 0px;
  border-radius: 50px;
  height: 35px;
  margin-bottom: 30px;
}

.rc-slider-track {
  background-color: #000000 !important;
}
h2.product-author a {
  color: #80ba66;
  font-size: 18px;
  text-transform: uppercase;
}
.rc-slider-handle {
  border: solid 2px #000000 !important;
}
button.ModalBtn.offer-button img {
  transform: rotate(-90deg);
  padding: 0px 5px;
}
.row.order-middle-row.align-items-center a:hover {
  color: #000;
}
.deals-banner {
  width: 100%;
  border-radius: 10px;
}
sapn.text-break {
  display: inline-block;
  padding-left: 35px;
}
.product-active-image-wrapper span.badge.out-of-stock-badge {
  left: -30px;
}
.p-d-c.footer-text p,
a {
  color: #fff;
  font-size: 14px;
}
.p-d-c.footer-text p {
  line-height: 30px;
}
.p-d-c.footer-text h2 {
  padding: 10px 0px;
  color: #fff;
}
.orders-card a {
  color: #0000;
}
.text-size {
  font-size: 1.75rem !important;
  font-weight: 600!important;
  line-height: normal!important;
  font-family: "BrandonText-Bold", sans-serif!important;
}

.sub-option {
  background-color: transparent !important;
  border-radius: 9px;
  border: none;
  color: #000 !important;
  font-size: 15px !important;
  display: block;
  font-size: 0.875rem;
  padding-left: 5px;
  width: auto;
  text-decoration: underline;
  cursor: pointer;
}
.prod-selected-emirate {
  color: #000 !important;
  font-size: 16px;
  font-weight: 390;
  line-height: 36px;
}
.return-policy-div h1,
h6 {
  padding-bottom: 10px;
}
.form-group.has-search .product-search-icon-close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.tab-scrolls {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 20px;
}
.imgs-header {
  width: 19px;
}
.collect-banner {
  padding: 0px 0px !important;
}
.text-mid {
  text-align: center;
}
.footer-link-seperator {
  visibility: hidden;
  display: none;
}
body.orders .orders-card a {
  color: #000;
}
.offcanvas-body h6.product-name-cart.mb-3 {
  height: 37px;
  overflow: hidden;
  margin-bottom: 15px !important;
}
.checkout-left-card h4 {
  overflow: hidden;
  max-height: 50px;
}
.row.mob-payment-icons .col-md-3:first-child img {
  width: 69px;
}
.store-address p::first-letter {
  text-transform: uppercase;
}
.store-details-slider {
  padding-bottom: 40px;
}
.logo-m img.footer-logo-size {
  width: 66px;
  padding-top: 0px;
}
.footer-logo-size {
  width: 52px;
  padding-top: 5px;
}
.react-datepicker__close-icon::after {
  background-color: #000000;
}
.row.sortHeader {
  padding-bottom: 10px;
}
.sortHeader h6 {
  padding-bottom: 0px;
}
.card-body.fbt-product-box {
  background-color: #ffffff;
  border: none !important;
  border-radius: 3px;
  outline: none !important;
  padding: 0px;
  margin: 0 auto;
  border: 1px solid #a2a2a242!important;
    border-radius: 9px;
    padding: 7px 10px;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus img {
  width: 60%;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus
  .product-grid.slick-slider-alignment {
  width: 80%;
  margin: 0 auto;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus
  .react-multiple-carousel__arrow--left {
  left: calc(-2% + 1px);
  top: 120px;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus
  .react-multiple-carousel__arrow--right {
  right: calc(0% + 1px);
  top: 125px;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus
  .react-multiple-carousel__arrow {
  background: rgb(235 236 233);
  min-width: 35px;
  min-height: 35px;
}

h4.fbt-ellipsis-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 185%;
  font-family: var(--fc-font-bold);
  white-space: wrap;
  max-height: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
h5.fbt-selling-price {
  color: #000;
  font-size: 16px;
  font-family: var(--fc-font-bold);
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 1.44px;
  text-transform: uppercase;
}
.app-pay svg {
  cursor: pointer;
}
.dropdown-menu.lang-dropdown-menu svg {
  cursor: pointer;
}
.dropdown-menu {
  z-index: 1000;
}
.card.login-card .row.social-head svg {
  cursor: pointer;
}
.card-body.fbt-product-box .product-img {
  height: 200px;
  cursor: default;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 35px;
  min-height: 35px;
  opacity: 1;
  cursor: pointer;
}
body.cart p.promo-code-label {
  color: #80ba27;
}
.change-pop a {
  cursor: pointer;
}
body.checkout .change-pop a {
  cursor: pointer;
  font-size: 15px;
  font-family: var(--fc-font-bold);
}
.conatiner.pd-detail .col-md-5 .p-d {
  padding-bottom: 20px;
}
button.btn.btn-dark.w-100.mt-2.fbt-add-to-cart {
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
}
.card-body.fbt-product-box span input.form-check-input {
  cursor: pointer !important;
}
button.btn.btn-dark.mt-2.fbt-add-to-cart {
  width: 85% !important;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  background-color: #71b520;
  border-color: #75bb21;
  color: #000;
}
div#flush-collapseOne .promo-code-label {
  color: #0aad0a;
}
.return-policy-div a {
  cursor: pointer;
}
.get-img {
  cursor: pointer;
}
.container-new .title-page {
  padding-bottom: 20px;
}
body.checkout .checkout-left-card h6 {
  display: inline-block;
  padding-right: 10px;
}

body.checkout div#flush-collapseOne .promo-code-label {
  color: #0aad0a;
  padding-top: 4px !important;
  margin: 0px !important;
}
.error-border {
  border: 1px solid red;
}

body.address-book .mt-4 {
  margin-top: 0px !important;
}
body.contact .container.pt-10 {
  padding-top: 0px !important;
}
footer.footer p {
  color: #fff !important;
}
.side-scroller
{
  max-height: 423px;
  overflow-y: auto;
}
.row.product-rating {
  width: 100%;
  display: flex;
  justify-content: end;
}
a.footer-read-more-text {
  text-decoration: underline;
  color: #fff !important;
  text-align: center;
  display: block;
  line-height: normal;
  font-family: "BrandonText-Bold", sans-serif;
  font-size: 15px;
}
body.checkout div#rating-tab-pane svg.regular-sort-icon {
  /* font-size: 11px; */
  height: 17px !important;
}
body.checkout div#rating-tab-pane a.dropdown-toggle.text-dark.btn-filter {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  border: none;
}
body.checkout div#reviews-tab-pane a.dropdown-toggle.text-dark.btn-filter {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.6;
  border: none;
}
body.checkout div#reviews-tab-pane a svg.regular-sort-icon {
  /* font-size: 11px; */
  height: 17px !important;
}
img.footer-apple-logo {
  width: 100%;
  cursor: pointer;
}
img.footer-gplay-logo {
  width: 100%;
  cursor: pointer;
}
.check-form{
  display: flex;
  justify-content: space-between;
}
.dropdown.form-control.check-form .dropdown-menu {
  margin-top: 20px;
}
body.order-details .marquee-text {
  color: #fff !important;
}
footer p.bottom-nav-item--title{
  color: #000 !important;
}
.search-container h4 {
  cursor: pointer;
}
.dropdown.form-control.check-form a.dropdown-toggle.text-dark.btn-filter {
  display: inline-block;
  width: 100%;
}
body.checkout .form-check .form-check-input {
  border: solid 2px #000 !important;
  
}
body.address-book .form-check .form-check-input {
  border: solid 2px #000 !important;
}
body.orders .form-check .form-check-input {
  border: solid 2px #000 !important;
}

.order-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;}
  .deatil-blog-content a {
    color: #000 !important;
}
span.blog-date {
  padding-top: 25px;
  padding-bottom: 35px;
}
.blog-sub-text {
  padding: 0px;
}
.bg-blog-banner {
  margin-bottom: 80px;
}
.blog-detail-page {
  padding-top: 0px;
}
.deatil-blog-contents .faq a {
color: #000 !important;
}
.deatil-blog-content .faq li {
  line-height: 35px;
}
.deatil-blog-contents .faq li {
  line-height: 35px;
}
body.blog-details .date-text {
  padding: 15px 0px;
}
.dropdown.form-control.check-form svg.regular-sort-icon {
  cursor: pointer;
}
body.home .top-categories img {
  box-shadow: none;
}
.remove-cd a {
  cursor: pointer;
  padding-top: 10px;
  display: inline-block;

}
body.cart .promo-modal .modal-content {
  max-height: 440px;
  overflow-y: auto;
}
body.checkout .promo-modal .modal-content {
  max-height: 440px;
  overflow-y: auto;
}
.desktop_slider .product-grid.slick-slider-alignment h4 { 
  padding-top: 0px;
}
body.home .product-img {
  transition: transform 0.3s ease-in-out;
}
body.home .product-img:hover {
  transform: scale(1.1);
}
body.product-details .product-img {
  transition: transform 0.3s ease-in-out;
}
body.product-details .product-img:hover {
  transform: scale(1.1);
}
body.products .product-img {
  transition: transform 0.3s ease-in-out;
}
body.products .product-img:hover {
  transform: scale(1.1);
}
body.about .info-text p {
  text-transform: none;
}
body.about .info-text a {
  color: #000;
}
.sub-abt a {
  color: #000;
}
body.products .product-grid h4 {
  padding-top: 0px;}
body.product-details .product-grid h4 {
    padding-top: 0px;
  }
body.cart .my-bag-img {
    background: #fff !important;
  }

.bg-cutom-banner{
  background-color: #000000;
  color: white;
}
.custom-mega-menu ul li a {
  position: relative;
}

.custom-mega-menu ul li a.nav-link.dropdown-toggle:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background: #88b48d;
  content: "";
  transition: width 0.3s ease;
}

.custom-mega-menu ul li a.nav-link.dropdown-toggle:hover:before {
  width: 100%;
}

/*body.blog .faq {
  max-height: 180px;
  overflow: hidden;
}*/
body.product-details .product-desc-section h1 {
  padding-bottom: 15px;
}
body.stores-detail .store-contact {
  padding-top: 0px;
  width: 100%;
}
body.checkout p.promo-code-label.mb-0 small {
  color: #80ba27;
}
body.checkout p.promo-code-label.mb-0 {
  margin-top: 6px;
}
.add-heading h2 {
  color: var(--fc-black);
  font-family: var(--fc-font-regular);
  font-size: 23px;
  text-transform: uppercase;
  padding-left: 10px;
  padding-bottom: 10px;
}
.add-heading p {
  font-family: var(--fc-font-bold);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;
  width: 81%;
  padding-left: 10px;
}
.lists-code {
  display: flex;
  border: solid 1px #000;
  border-radius: 9px;
}

.lists-code button#rfs-btn {
  border: none;
    font-size: 13px !important;
    font-weight: 500;
    line-height: 1.6;
    padding:2px;
}
.lists-code input.form-control {
  border: none;
    font-size: 13px !important;
    font-weight: 500;
    line-height: 1.6;
    padding: 2px 6px;
}
body.product-details div#details-tab-pane .p-d-c {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 20px;
}
.add-heading a {
  font-family: var(--fc-font-bold);
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 81%;
  padding-left: 10px;
  display: block;
}
body.product-details .card.rec-products-container.mb-5 {
  padding-bottom: 0px;
  padding-top: 0px;
}
.card.flash-sale-container h1.mb-5.flash-sale {
  margin-bottom: 0.5rem !important;
}
body.checkout .ReactFlagsSelect-module_selectOptions__3LNBJ {
  position: absolute;
  z-index: 999;
  width: 100%;
  font-size: 12px !important;
}
.scroll-container2::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
}

.scroll-container2::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
body.product-details .desktop-thumbs .slick-slider .slick-next {
  position: absolute;
  left: 45%;
  top: calc(10% + -20px);
}
body.product-details img.topArrow {
  margin: 4px;
  margin-left: 10px;
}
body.product-details img.bottomArrow {
  margin: 7px;
  margin-left: 10px;
}
body.home .card-footer.price-footer button.btn.btn-dark.w-100.col-md-10 {
  background-color: #76bc21;
  border-color: #76bc21;
  color: #000;
}
body.address-book .mb-3 input.form-check-input.ml-2 {
  margin-left: 30px;
}
.card.address-card svg {
  cursor: pointer;
}
body.product-details .card-body.fbt-product-box .product-img {
  height: 170px;
  cursor: default;
}

body.product-details .plus-button {
  left: 36%;
}
.mb-lg-0.point-app input.form-check-input {
  cursor: pointer;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus {
  width: 90%;
  position: static;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus li {
  position: static !important;
}

body.product-details div#product-tab-pane .my-8 {
  margin-top: 15px !important;
}
body.product-details div#details-tab-pane  .my-8 {
  margin-top: 15px !important;
}
.brand-rating-date, .brand-rating-message {
  text-transform: none;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus .react-multi-carousel-item::after {
  content: '+';
  position: absolute;
  top: 104px;
  right: -3px;
  font-size: 26px;
  color: black;
  font-weight: 800;
}
.side-fixed {
  margin-top: -4%;
  position: fixed;
  width: 58.33333333%;
}
.store-hours table {
  border: none;
}
.store-address p br {
  display: none;
}
.row-details .store-contact {
  width: 100%;
  padding-top: 0;
}
.ft-icon-fixed {
  position: relative;
}
.ft-icon-fixed a {
  position: fixed;
  right: 2px;
  bottom: 45px;
  background-color: #0aad0a;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.st0 {
  fill: #ffffff;
}
.st1 {
  fill: #ffffff;
}
.icon svg {
  width: 30px;
}
.modal-content.discount-modal.p-4 .my-5 {
margin-left: 0px; 
}
section.banner-flashsale.container-lg-fluid .my-5 {
  margin-left: 10px; 
}
section.banner-flashsale.container-lg-fluid .ends-in-lg p{
  text-align: center;
}
div#desktop-menu .custom-mega-menu .my-5.d-none.d-lg-block {
  margin-left: 0px;
}
body.product .flash-sale-box {
  background: #e02327;
}

.visit-pg {
  max-height: max-content;
  min-height: 315px;
  overflow: auto;
}
.viisit-img img {
  width: 100%;
  height: 100%;
}
.visit-text .ms-9.me-9 {
  height: 100%;
}