.mian-pg p {
  color: #000;
  text-align: justify;
  font-family: "BrandonText-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 30px;
  letter-spacing: 1.08px;
  }
  .private-tittle h1 {
      color: #000;
      font-family: var(--fc-font-bold);
      font-size: 45px;
      font-style: normal;
      font-weight: 450;
      line-height: 144.49%;
      text-transform: uppercase;
  }
  .private-tittle {
      width: 75%;
  }
  .enquirys-btn {
      padding: 20px 0px;
  }
  
  .private-page .txt-end{
      text-align: end;
  }
  .work-it-img {
    width: 524px;
    height: 490px;
  }
  .it-work {
      padding: 40px 0px;
  }
  .develo-scent h2 {
      color: #000;
      font-family: var(--fc-font-bold);
      font-size: 39px;
      font-style: normal;
      font-weight: 450;
      line-height: 144.49%;
      text-transform: uppercase;
  }
  .accordion-scent .accordion-title {
    color: #000;
    font-family: var(--fc-font-bold);
    font-size: 22px;
    font-style: normal;
    font-weight: 450;
    line-height: 144.49%;
    text-transform: uppercase;
    padding: 10px 0px;
    padding: 1em 1.5em 1em 0;
  }
  .accordion-scent .accordion-item-scent {
    border-bottom: 1px solid #979292;
    padding: 15px 0px;
  }
    
    .accordion-scent button {
      position: relative;
      display: block;
      text-align: left;
      width: 100%;
      padding: 1em 0;
      color: var(--text);
      font-size: 1.15rem;
      font-weight: 400;
      border: none;
      background: none;
      outline: none;
    }
    
    .accordion-scent button:hover,
    .accordion-scent button:focus {
      cursor: pointer;
      color: var(--blue);
    }
    
    .accordion-scent button:hover::after,
    .accordion-scent button:focus::after {
      cursor: pointer;
      color: var(--blue);
      border: 1px solid var(--blue);
    }
    
   
    
    .accordion-scent .icon {
      display: inline-block;
      position: absolute;
      top: 18px;
      right: 0;
      border-radius: 22px;
      padding: 15px;
    }
    
    .accordion-scent .icon::before {
      display: block;
      position: absolute;
      content: '';
      top: 9px;
      left: 5px;
      width: 10px;
      height: 2px;
      background: currentColor;
    }
    
    .accordion-scent .icon::after {
      display: block;
      position: absolute;
      content: '';
      top: 5px;
      left: 9px;
      width: 2px;
      height: 10px;
      background: currentColor;
    }
    
    .accordion-scent button[aria-expanded='true'] {
      color: var(--blue);
    }
    
    .accordion-scent button[aria-expanded='true'] .icon::after {
      width: 0;
    }
    
    .accordion-scent button[aria-expanded='true'] + .accordion-content-scent {
      opacity: 1;
      max-height: 9em;
      transition: all 200ms linear;
      will-change: opacity, max-height;
    }
    
    .accordion-scent .accordion-content-scent {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: opacity 200ms linear, max-height 200ms linear;
      will-change: opacity, max-height;
    }
    
    .accordion-scent .accordion-content-scent p {
    
      margin: 2em 0;
    text-align: justify;
    font-family: "BrandonText-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 30px;
    letter-spacing: 1.08px;
    }
    
    video {
      object-fit: cover;
      height:auto;
      width: 100%;
    }
    .video-wrapper {
      height: auto;
      position: relative;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header-txt{
      position: relative;
      color: white;
      text-shadow: 1px 1px 8px rgba(0,0,0,0.6);
    }
  .btr {
    border-top: solid 1px #000;
    border-bottom: solid 1px #000;
  }
  .btr-right{
    border-right: solid 1px #000;
  }
  .benefit-tittle {
    padding: 40px 0px;
    text-align: center;
  }
  .quality-text {
    min-height: 330px;
    padding: 40px;
    text-align: center;
    padding-bottom: 0px;
}
  .quality-text img {
    width: 50px;
  }
  .quality-text img {
    width: 50px;
    padding-bottom: 35px;
  }
  .benefit-tittle h3 {
    color: #000;
    text-align: center;
    font-family: var(--fc-font-bold);
    font-size: 39px;
    font-style: normal;
    font-weight: 450;
    line-height: 144.49%;
    text-transform: uppercase;
  }
  .quality-text h4 {
    color: #000;
    padding-bottom: 10px;
    text-align: center;
    font-family: var(--fc-font-bold);
    font-size: 19px;
    font-style: normal;
    font-weight: 450;
    line-height: 144.49%;
    text-transform: uppercase;
  }
  .private-page .quality-text p {
    color: #000;
    text-align: center;
    font-family: "BrandonText-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 29px;
    letter-spacing: 1.08px;
  }
  .lets-tittle h3 {
    color: #000;
    font-family: var(--fc-font-bold);
    font-size: 39px;
    font-style: normal;
    font-weight: 450;
    line-height: 144.49%;
    text-transform: uppercase;
    padding-bottom: 20px;
  }
  .lest-img img {
    max-height: 370px;
    width: 80%;
  }
  .lest-img {
  text-align: end;
  }
  section.cont-ad {
    background-color: #EBECE9;
  }
  section.cont-ad {
    background-color: #EBECE9;
    margin-top: 40px;
    padding: 50px 0px;
  }
  .private_mails a {
    color: #000;
    font-family: var(--fc-font-bold);
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 144.49%;
    text-transform: lowercase;
    display: block;
  }
  .private_ph a {
    color: #000;
    font-family: var(--fc-font-bold);
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 144.49%;
    text-transform: capitalize;
    display: block;
    padding-left: 20px;
  }
  .private-cs p {
    font-family: var(--fc-font-bold);
    color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%; /* 24px */
  text-transform: capitalize;
  }
  .private_ph {
    display: flex;
  }
  .private_mails {
    display: flex;
  }
  .enquirys-btn.lg-none {
    display: none;
  }
  .lg-nones{
    display: none;
  }
  .enquirys-btn a {
    color: #FFF;
    text-align: center;
    font-family: var(--fc-font-bold); 
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .private-cs p  {
    width: 68%;
    padding-left: 20px;
  }
  .private_mails div {
    padding-left: 20px;
  }
  .pro-dot{
    background-color: #000;
    color: #fff;
    position: relative;
  }
  .pro-dot-title h2 {
    color: #fff;
    text-align: right;
    position: relative;
    padding-right: 35px;
    text-align: right;
    font-family: var(--fc-font-bold);
    font-size: 28px;
    font-style: normal;
    font-weight: 450;
    line-height: 144.49%;
    text-transform: uppercase;
  }
  
  .pro-dot-title h2::after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background-color: #fafafa;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -16px;
    transform: translate(50%, -50%);
    z-index: 10;
}
  
  .pro-dot-content {
    padding-left: 20px; 
  }
  
  .pro-dot-content p {
    padding: 0px 30px;
    width: 85%;
    color: #FFF;
    font-family: "BrandonText-Regular", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 29px;
    letter-spacing: 1.08px;
}
.pro-dot-title::after {
  content: "";
  display: block;
  width: 2px;
  height: 80%; 
  background-color: #eae7e7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: lineAnimation 1s ease;
}
@keyframes lineAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 80%;
  }
}
.pro-dot-content {
  padding-left: 20px; 
  
}
section.pro-dot ul li {
  min-height: 200px;
}


  @media screen and (max-width:767px) {
   .order-1 {
      order: 1;
   }
   .order-2 {
    order: 1;
  }
  .col-md-6.txt-end.order-1 {
    order: 0;
  }
  .private-tittle {
    width: 100%;
    padding: 20px 0px;
  }
  .private-tittle h1 {
    font-size: 20px;
    text-align: center;
  }
  img.private-imgs {
    width: 100%;
  }
  .enquirys-btn {
    text-align: center;
  }
  .it-work {
    padding-top: 10px;
  }
  .enquirys-btn {
    padding: 5px 0px;
  }
  .private-page .pb-10 {
    padding-bottom: 15px !important;
  }
  .develo-scent h2 {
    font-size: 20px;
  }
  .accordion-scent .accordion-title {
    font-size: 17px;
    padding: 8px 0px;
  }
  .accordion-scent .accordion-content-scent p {
    font-size: 16px;
    font-weight: 300;
    margin: 0em 0;
  }
  .enquirys-btn.lg-none {
    display:block;
  }
  .work-it-img {
    width: 100%;
    height: auto;
  }
  
  .lg-nones{
    display: block;
  }
  .quality-text {
   padding:0px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 40px;
    display: flex;
    min-height: 230px;
  }
  .private-page .quality-text p {
    color: #000;
    text-align: left;
  }
  .quality-imgs {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.quality-text h4 {
  text-align: left;
}
  .quality-text p {
    font-size: 11px;
  }
  .quality-text h4 {
    font-size: 13px;}
    .lest-img {
      text-align: center;
      padding-top: 30px;
  }
  .private-page .quality-text p {
    font-size: 16px;
  }
  .lest-img img {
    width: 100%;
  }
  .private-cs p {
    font-size: 15px;
    padding-bottom: 20px;
  }
  .private-cs svg {
    width: 10%;
  }
  .private_ph svg {
    width: 6%;
  }
  .btr-right {
    border-right: none;
  }
  .private_ph {
    display: flex;
    padding-bottom: 20px;
  }
  .bdr-sm {
    border-bottom: solid 1px #000;
  }
  body.prvt-label .btr {
    border-top: none;
    border-bottom: none;
}
  .bdr-top{
    border-top: solid 1px #000;
  }
  .private-cs p  {
    width: auto;
  }
  .pro-dot-title h2 {
    color: #fff;
    position: relative;
    padding-right: 18px;
    text-align: left;
    font-family: var(--fc-font-bold);
    font-size: 16px;}
    .pro-dot-content p {
      padding: 0px 5px;
      width: 100%;
      padding-top: 15px;
  }
  section.pro-dot ul li {
    min-height: 200px;
}
.pro-dot-title::after {
  content: "";
  display: block;
  width: 2px;
  height: 89%;
  left: 5%;
  top: 52%;
}
.pro-dot-title h2::after {
  position: absolute;
  top: 50%;
  left: -36px;}
.private-cs svg {
    width: 21%;
}
section.cont-ad .row.mt-5.df svg {
  width: 21% !important;
}
  }