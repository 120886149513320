@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
.text-inherit {
  color: var(--fc-gray-800);
}

@font-face {
  font-family: brandonTextLight;
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-Light.otf);
}
@font-face {
  font-family: brandonTextThin;
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-Thin.otf);
}
@font-face {
  font-family: brandonTextMedium;
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-Medium.otf);
}
@font-face {
  font-family: brandonTextRegularI;
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-RegularItalic.otf);
}
@font-face {
  font-family: brandonTextRegular;
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-Regular.otf);
}
@font-face {
  font-family: brandonTextBold;
  src: url(../fonts/HVD\ Fonts\ -\ BrandonText-Bold.otf);
}
@font-face {
  font-family: "DS-Digital Bold";
  font-style: normal;
  font-weight: normal;
  src: local("DS-Digital Bold"), url("../fonts//DS-DIGIB.woff") format("woff");
}
@font-face {
  font-family: NexaRegular;
  src: url(../fonts/NexaRegular.otf);
}
input,
textarea {
  touch-action: manipulation !important; /* Helps prevent double-tap zoom. */
}
.text-reset:hover {
  color: var(--fc-primary) !important;
}
.text-inverse {
  filter: brightness(0) invert(1);
}
.icon-xxs {
  height: 1rem;
  line-height: 1rem;
  width: 1rem;
}
.icon-xs {
  height: 1.5rem;
  line-height: 1.5rem;
  width: 1.5rem;
}
.icon-sm {
  height: 2rem;
  line-height: 2rem;
  width: 2rem;
}
.icon-md {
  height: 2.5rem;
  line-height: 2.5rem;
  width: 2.5rem;
}
.icon-lg {
  height: 3rem;
  line-height: 3rem;
  width: 3rem;
}
.icon-xl {
  height: 3.5rem;
  line-height: 3.5rem;
  width: 3.5rem;
}
.icon-xxl {
  height: 4rem;
  line-height: 4rem;
  width: 4rem;
}
.icon-xxxl {
  height: 7rem;
  line-height: 7rem;
  width: 7rem;
}
.icon-shape {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}
.border-dashed {
  border: 1px dashed var(--fc-gray-300) !important;
}
.img-zoom {
  border-radius: 0.5rem;
  overflow: hidden;
}
.img-zoom img {
  transition: transform 0.5s ease;
}
.img-zoom img:hover {
  transform: scale(1.1);
}
div.zoom {
  background-position: 50% 50%;
  border-radius: 0.5rem;
  cursor: zoom-in;
  overflow: hidden;
  position: relative;
  width: 100%;
}
div.zoom img:hover {
  opacity: 0;
}
div.zoom img {
  display: block;
  transition: opacity 0.5s;
  width: 100%;
}
.simplebar-scrollbar:before {
  background: var(--fc-gray-400);
}

/*!
 * Bootstrap  v5.3.0-alpha3 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme="light"] {
  --fc-blue: #0aad0a;
  --fc-yellow: #ffc107;
  --fc-green: #198754;
  --fc-cyan: #016bf8;
  --fc-black: #000;
  --fc-gray: #373835;
  --fc-gray-dark: #21313c;
  --fc-gray-100: #f0f3f2;
  --fc-gray-200: #ecf0ef;
  --fc-gray-300: #dfe2e1;
  --fc-gray-500: #889397;
  --fc-gray-600: #5c6c75;
  --fc-gray-700: #3d4f58;
  --fc-gray-800: #21313c;
  --fc-gray-900: #001e2b;
  --fc-primary: #0aad0a;
  --fc-secondary: #889397;
  --fc-success: #198754;
  --fc-info: #016bf8;
  --fc-warning: #ffc107;
  --fc-danger: #db3030;
  --fc-light: #f0f3f2;
  --fc-dark: #001e2b;
  --fc-gray-400: #c1c7c6;
  --fc-white: #fff;
  --fc-light-primary: #ceefce;
  --fc-light-secondary: #889397;
  --fc-light-danger: #f8d6d6;
  --fc-light-info: #cce1fe;
  --fc-light-warning: #fff3cd;
  --fc-light-success: #d1e7dd;
  --fc-dark-primary: #044504;
  --fc-dark-warning: #664d03;
  --fc-dark-info: #002b63;
  --fc-dark-danger: #581313;
  --fc-dark-success: #0a3622;
  --fc-dark-secondary: #ecf0ef;
  --fc-indigo: #6610f2;
  --fc-red: #db3030;
  --fc-pink: #d63384;
  --fc-orange: #fd7e14;
  --fc-teal: #20c997;
  --fc-purple: #6f42c1;
  --fc-primary-rgb: 10, 173, 10;
  --fc-secondary-rgb: 136, 147, 151;
  --fc-success-rgb: 25, 135, 84;
  --fc-info-rgb: 1, 107, 248;
  --fc-warning-rgb: 255, 193, 7;
  --fc-danger-rgb: 219, 48, 48;
  --fc-light-rgb: 240, 243, 242;
  --fc-dark-rgb: 0, 30, 43;
  --fc-gray-400-rgb: 193, 199, 198;
  --fc-light-primary-rgb: 206, 239, 206;
  --fc-light-secondary-rgb: 136, 147, 151;
  --fc-light-danger-rgb: 248, 214, 214;
  --fc-light-info-rgb: 204, 225, 254;
  --fc-light-warning-rgb: 255, 243, 205;
  --fc-light-success-rgb: 209, 231, 221;
  --fc-dark-primary-rgb: 4, 69, 4;
  --fc-dark-warning-rgb: 102, 77, 3;
  --fc-dark-info-rgb: 0, 43, 99;
  --fc-dark-danger-rgb: 88, 19, 19;
  --fc-dark-success-rgb: 10, 54, 34;
  --fc-dark-secondary-rgb: 236, 240, 239;
  --fc-indigo-rgb: 102, 16, 242;
  --fc-red-rgb: 219, 48, 48;
  --fc-pink-rgb: 214, 51, 132;
  --fc-orange-rgb: 253, 126, 20;
  --fc-teal-rgb: 32, 201, 151;
  --fc-purple-rgb: 111, 66, 193;
  --fc-primary-text-emphasis: #044504;
  --fc-secondary-text-emphasis: #363b3c;
  --fc-success-text-emphasis: #0a3622;
  --fc-info-text-emphasis: #002b63;
  --fc-warning-text-emphasis: #664d03;
  --fc-danger-text-emphasis: #581313;
  --fc-light-text-emphasis: #3d4f58;
  --fc-dark-text-emphasis: #3d4f58;
  --fc-primary-bg-subtle: #ceefce;
  --fc-secondary-bg-subtle: #e7e9ea;
  --fc-success-bg-subtle: #d1e7dd;
  --fc-info-bg-subtle: #cce1fe;
  --fc-warning-bg-subtle: #fff3cd;
  --fc-danger-bg-subtle: #f8d6d6;
  --fc-light-bg-subtle: #f8f9f9;
  --fc-dark-bg-subtle: #c1c7c6;
  --fc-primary-border-subtle: #9dde9d;
  --fc-secondary-border-subtle: #cfd4d5;
  --fc-success-border-subtle: #a3cfbb;
  --fc-info-border-subtle: #99c4fc;
  --fc-warning-border-subtle: #ffe69c;
  --fc-danger-border-subtle: #f1acac;
  --fc-light-border-subtle: #ecf0ef;
  --fc-dark-border-subtle: #889397;
  --fc-white-rgb: 255, 255, 255;
  --fc-black-rgb: 0, 0, 0;
  --fc-font-regular: "brandonTextRegular", sans-serif;
  --fc-font-medium: "brandonTextMedium", sans-serif;
  --fc-font-bold: "brandonTextBold", sans-serif;
  --fc-font-thin: "brandonTextThin", sans-serif;
  --fc-font-light: "brandonTextLight", sans-serif;

  --fc-heading-font: "Poppins", sans-serif;
  --fc-font-bold: "brandonTextBold", sans-serif;
  --fc-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --fc-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  --fc-body-font-family: var(--fc-font-regular);
  --fc-body-font-size: 0.875rem;
  --fc-body-font-weight: 500;
  --fc-body-line-height: 1.6;
  --fc-body-color: #5c6c75;
  --fc-body-color-rgb: 92, 108, 117;
  --fc-body-bg: #fff;
  --fc-body-bg-rgb: 255, 255, 255;
  --fc-emphasis-color: #000;
  --fc-emphasis-color-rgb: 0, 0, 0;
  --fc-secondary-color: rgba(92, 108, 117, 0.75);
  --fc-secondary-color-rgb: 92, 108, 117;
  --fc-secondary-bg: #ecf0ef;
  --fc-secondary-bg-rgb: 236, 240, 239;
  --fc-tertiary-color: rgba(92, 108, 117, 0.5);
  --fc-tertiary-color-rgb: 92, 108, 117;
  --fc-tertiary-bg: #f0f3f2;
  --fc-tertiary-bg-rgb: 240, 243, 242;
  --fc-heading-color: #000;
  --fc-link-color: #0aad0a;
  --fc-link-color-rgb: 10, 173, 10;
  --fc-link-decoration: underline;
  --fc-link-hover-color: #088a08;
  --fc-link-hover-color-rgb: 8, 138, 8;
  --fc-code-color: #d63384;
  --fc-highlight-bg: #fff3cd;
  --fc-border-width: 1px;
  --fc-border-style: solid;
  --fc-border-color: var(--fc-gray-300);
  --fc-border-color-translucent: var(--fc-gray-300);
  --fc-border-radius: 0.5rem;
  --fc-border-radius-sm: 0.25rem;
  --fc-border-radius-lg: 0.75rem;
  --fc-border-radius-xl: 1rem;
  --fc-border-radius-xxl: 2rem;
  --fc-border-radius-2xl: var(--fc-border-radius-xxl);
  --fc-border-radius-pill: 50rem;
  --fc-box-shadow: rgba(145, 158, 171, 0.2) 0px 2px 4px -1px,
    rgba(145, 158, 171, 0.14) 0px 4px 5px 0px,
    rgba(145, 158, 171, 0.12) 0px 1px 10px 0px;
  --fc-box-shadow-sm: rgba(145, 158, 171, 0.2) 0px 3px 1px -2px,
    rgba(145, 158, 171, 0.14) 0px 2px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 1px 5px 0px;
  --fc-box-shadow-lg: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  --fc-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --fc-focus-ring-width: 0.25rem;
  --fc-focus-ring-opacity: 0.25;
  --fc-focus-ring-color: rgba(10, 173, 10, 0.25);
  --fc-form-valid-color: #198754;
  --fc-form-valid-border-color: #198754;
  --fc-form-invalid-color: #db3030;
  --fc-form-invalid-border-color: #db3030;
}
[data-bs-theme="dark"] {
  --fc-body-color: #889397;
  --fc-body-color-rgb: 136, 147, 151;
  --fc-body-bg: #001e2b;
  --fc-body-bg-rgb: 0, 30, 43;
  --fc-emphasis-color: #fff;
  --fc-emphasis-color-rgb: 255, 255, 255;
  --fc-secondary-color: hsla(196, 7%, 56%, 0.75);
  --fc-secondary-color-rgb: 136, 147, 151;
  --fc-secondary-bg: #21313c;
  --fc-secondary-bg-rgb: 33, 49, 60;
  --fc-tertiary-color: hsla(196, 7%, 56%, 0.5);
  --fc-tertiary-color-rgb: 136, 147, 151;
  --fc-tertiary-bg: #112834;
  --fc-tertiary-bg-rgb: 17, 40, 52;
  --fc-primary-text-emphasis: #6cce6c;
  --fc-secondary-text-emphasis: #b8bec1;
  --fc-success-text-emphasis: #75b798;
  --fc-info-text-emphasis: #67a6fb;
  --fc-warning-text-emphasis: #ffda6a;
  --fc-danger-text-emphasis: #e98383;
  --fc-light-text-emphasis: #f0f3f2;
  --fc-dark-text-emphasis: #dfe2e1;
  --fc-primary-bg-subtle: #022302;
  --fc-secondary-bg-subtle: #1b1d1e;
  --fc-success-bg-subtle: #051b11;
  --fc-info-bg-subtle: #001532;
  --fc-warning-bg-subtle: #332701;
  --fc-danger-bg-subtle: #2c0a0a;
  --fc-light-bg-subtle: #21313c;
  --fc-dark-bg-subtle: #11191e;
  --fc-primary-border-subtle: #066806;
  --fc-secondary-border-subtle: #52585b;
  --fc-success-border-subtle: #0f5132;
  --fc-info-border-subtle: #014095;
  --fc-warning-border-subtle: #997404;
  --fc-danger-border-subtle: #831d1d;
  --fc-light-border-subtle: #3d4f58;
  --fc-dark-border-subtle: #21313c;
  --fc-link-color: #6cce6c;
  --fc-link-hover-color: #89d889;
  --fc-link-color-rgb: 108, 206, 108;
  --fc-link-hover-color-rgb: 137, 216, 137;
  --fc-code-color: #e685b5;
  --fc-border-color: #3d4f58;
  --fc-border-color-translucent: hsla(0, 0%, 100%, 0.15);
  --fc-form-valid-color: #75b798;
  --fc-form-valid-border-color: #75b798;
  --fc-form-invalid-color: #e98383;
  --fc-form-invalid-border-color: #e98383;
  color-scheme: dark;
}
*,
:after,
:before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--fc-body-bg);
  color: var(--fc-body-color);
  font-family: var(--fc-body-font-family);
  font-size: var(--fc-body-font-size);
  font-weight: var(--fc-body-font-weight);
  line-height: var(--fc-body-line-height);
  margin: 0;
  text-align: var(--fc-body-text-align);
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
hr {
  border: 0;
  border-top: 1px solid #dfe2e1;
  color: #dfe2e1;
  margin: 1rem 0;
  opacity: 1;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--fc-heading-color, inherit);
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 0;
}
.h1,
h1 {
  font-size: calc(1.34375rem + 1.125vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.1875rem;
  }
}
.h2,
h2 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 1.75rem;
  }
}
.h3,
h3 {
  font-size: calc(1.27813rem + 0.3375vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.53125rem;
  }
}
.h4,
h4 {
  font-size: calc(1.25625rem + 0.075vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.3125rem;
  }
}
.h5,
h5 {
  font-size: 1.09375rem;
}
.h6,
h6 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1rem;
  margin-top: 0;
}
abbr[title] {
  cursor: help;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-bottom: 1rem;
  margin-top: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
.small,
small {
  font-size: 0.875em;
}
.mark,
mark {
  background-color: var(--fc-highlight-bg);
  padding: 0.1875em;
}
sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: rgba(var(--fc-link-color-rgb), var(--fc-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --fc-link-color-rgb: var(--fc-link-hover-color-rgb);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: var(--fc-font-monospace);
  font-size: 1em;
}
pre {
  display: block;
  font-size: 0.875em;
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto;
}
pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}
code {
  word-wrap: break-word;
  color: var(--fc-code-color);
  font-size: 0.875em;
}
a > code {
  color: inherit;
}
kbd {
  background-color: var(--fc-body-color);
  border-radius: 0.25rem;
  color: var(--fc-body-bg);
  font-size: 0.875em;
  padding: 0.1875rem 0.375rem;
}
kbd kbd {
  font-size: 1em;
  padding: 0;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  border-collapse: collapse;
  caption-side: bottom;
}
caption {
  color: #889397;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  text-align: left;
}
th {
  font-weight: 600;
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
  padding: 8px !important;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not(
    [type="week"]
  ):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
textarea {
  resize: vertical;
}
fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}
legend {
  float: left;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  margin-bottom: 0.5rem;
  padding: 0;
  width: 100%;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  -webkit-appearance: button;
  font: inherit;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  cursor: pointer;
  display: list-item;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.09375rem;
  font-weight: 500;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 600;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
  /* .list-inline,
.list-unstyled {
  list-style: none;
 display: flex;
 justify-content: end;
 align-items: center;
 align-content: center;
} */
}
.list-inline,
.list-unstyled {
  list-style: none;
  padding-left: 0;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  font-size: 1.09375rem;
  margin-bottom: 1rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  color: #889397;
  font-size: 0.875em;
  margin-bottom: 1rem;
  margin-top: -1rem;
}
.blockquote-footer:before {
  content: "— ";
}
.img-fluid,
.img-thumbnail {
  height: auto;
  max-width: 100%;
}
.img-thumbnail {
  background-color: var(--fc-body-bg);
  border: var(--fc-border-width) solid var(--fc-border-color);
  border-radius: var(--fc-border-radius);
  padding: 0.25rem;
}
.figure {
  display: inline-block;
}
.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem;
}
.figure-caption {
  color: var(--fc-secondary-color);
  font-size: 0.875em;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --fc-gutter-x: 4rem;
  --fc-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--fc-gutter-x) * 0.5);
  padding-right: calc(var(--fc-gutter-x) * 0.5);
  width: 100%;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
:root {
  --fc-breakpoint-xs: 0;
  --fc-breakpoint-sm: 576px;
  --fc-breakpoint-md: 768px;
  --fc-breakpoint-lg: 992px;
  --fc-breakpoint-xl: 1200px;
  --fc-breakpoint-xxl: 1400px;
}
.row {
  --fc-gutter-x: 2rem;
  --fc-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--fc-gutter-x) * -0.5);
  margin-right: calc(var(--fc-gutter-x) * -0.5);
  margin-top: calc(var(--fc-gutter-y) * -1);
}
.row > * {
  flex-shrink: 0;
  margin-top: var(--fc-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--fc-gutter-x) * 0.5);
  padding-right: calc(var(--fc-gutter-x) * 0.5);
  width: 100%;
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --fc-gutter-x: 0;
}
.g-0,
.gy-0 {
  --fc-gutter-y: 0;
}
.g-1,
.gx-1 {
  --fc-gutter-x: 0.25rem;
}
.g-1,
.gy-1 {
  --fc-gutter-y: 0.25rem;
}
.g-2,
.gx-2 {
  --fc-gutter-x: 0.5rem;
}
.g-2,
.gy-2 {
  --fc-gutter-y: 0.5rem;
}
.g-3,
.gx-3 {
  --fc-gutter-x: 0.75rem;
}
.g-3,
.gy-3 {
  --fc-gutter-y: 0.75rem;
}
.g-4,
.gx-4 {
  --fc-gutter-x: 1rem;
}
.g-4,
.gy-4 {
  --fc-gutter-y: 1rem;
}
.g-5,
.gx-5 {
  --fc-gutter-x: 1.25rem;
}
.g-5,
.gy-5 {
  --fc-gutter-y: 1.25rem;
}
.g-6,
.gx-6 {
  --fc-gutter-x: 1.5rem;
}
.g-6,
.gy-6 {
  --fc-gutter-y: 1.5rem;
}
.g-7,
.gx-7 {
  --fc-gutter-x: 1.75rem;
}
.g-7,
.gy-7 {
  --fc-gutter-y: 1.75rem;
}
.g-8,
.gx-8 {
  --fc-gutter-x: 2rem;
}
.g-8,
.gy-8 {
  --fc-gutter-y: 2rem;
}
.g-9,
.gx-9 {
  --fc-gutter-x: 2.5rem;
}
.g-9,
.gy-9 {
  --fc-gutter-y: 2.5rem;
}
.g-10,
.gx-10 {
  --fc-gutter-x: 3rem;
}
.g-10,
.gy-10 {
  --fc-gutter-y: 3rem;
}
.g-11,
.gx-11 {
  --fc-gutter-x: 3.5rem;
}
.g-11,
.gy-11 {
  --fc-gutter-y: 3.5rem;
}
.g-12,
.gx-12 {
  --fc-gutter-x: 4rem;
}
.g-12,
.gy-12 {
  --fc-gutter-y: 4rem;
}
.g-13,
.gx-13 {
  --fc-gutter-x: 4.5rem;
}
.g-13,
.gy-13 {
  --fc-gutter-y: 4.5rem;
}
.g-14,
.gx-14 {
  --fc-gutter-x: 5rem;
}
.g-14,
.gy-14 {
  --fc-gutter-y: 5rem;
}
.g-15,
.gx-15 {
  --fc-gutter-x: 6rem;
}
.g-15,
.gy-15 {
  --fc-gutter-y: 6rem;
}
.g-16,
.gx-16 {
  --fc-gutter-x: 8rem;
}
.g-16,
.gy-16 {
  --fc-gutter-y: 8rem;
}
.g-17,
.gx-17 {
  --fc-gutter-x: 10rem;
}
.g-17,
.gy-17 {
  --fc-gutter-y: 10rem;
}
.g-18,
.gx-18 {
  --fc-gutter-x: 12rem;
}
.g-18,
.gy-18 {
  --fc-gutter-y: 12rem;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --fc-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --fc-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --fc-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --fc-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --fc-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --fc-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --fc-gutter-x: 0.75rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --fc-gutter-y: 0.75rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --fc-gutter-x: 1rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --fc-gutter-y: 1rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --fc-gutter-x: 1.25rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --fc-gutter-y: 1.25rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --fc-gutter-x: 1.5rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --fc-gutter-y: 1.5rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --fc-gutter-x: 1.75rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --fc-gutter-y: 1.75rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --fc-gutter-x: 2rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --fc-gutter-y: 2rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --fc-gutter-x: 2.5rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --fc-gutter-y: 2.5rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --fc-gutter-x: 3rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --fc-gutter-y: 3rem;
  }
  .g-sm-11,
  .gx-sm-11 {
    --fc-gutter-x: 3.5rem;
  }
  .g-sm-11,
  .gy-sm-11 {
    --fc-gutter-y: 3.5rem;
  }
  .g-sm-12,
  .gx-sm-12 {
    --fc-gutter-x: 4rem;
  }
  .g-sm-12,
  .gy-sm-12 {
    --fc-gutter-y: 4rem;
  }
  .g-sm-13,
  .gx-sm-13 {
    --fc-gutter-x: 4.5rem;
  }
  .g-sm-13,
  .gy-sm-13 {
    --fc-gutter-y: 4.5rem;
  }
  .g-sm-14,
  .gx-sm-14 {
    --fc-gutter-x: 5rem;
  }
  .g-sm-14,
  .gy-sm-14 {
    --fc-gutter-y: 5rem;
  }
  .g-sm-15,
  .gx-sm-15 {
    --fc-gutter-x: 6rem;
  }
  .g-sm-15,
  .gy-sm-15 {
    --fc-gutter-y: 6rem;
  }
  .g-sm-16,
  .gx-sm-16 {
    --fc-gutter-x: 8rem;
  }
  .g-sm-16,
  .gy-sm-16 {
    --fc-gutter-y: 8rem;
  }
  .g-sm-17,
  .gx-sm-17 {
    --fc-gutter-x: 10rem;
  }
  .g-sm-17,
  .gy-sm-17 {
    --fc-gutter-y: 10rem;
  }
  .g-sm-18,
  .gx-sm-18 {
    --fc-gutter-x: 12rem;
  }
  .g-sm-18,
  .gy-sm-18 {
    --fc-gutter-y: 12rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --fc-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --fc-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --fc-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --fc-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --fc-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --fc-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --fc-gutter-x: 0.75rem;
  }
  .g-md-3,
  .gy-md-3 {
    --fc-gutter-y: 0.75rem;
  }
  .g-md-4,
  .gx-md-4 {
    --fc-gutter-x: 1rem;
  }
  .g-md-4,
  .gy-md-4 {
    --fc-gutter-y: 1rem;
  }
  .g-md-5,
  .gx-md-5 {
    --fc-gutter-x: 1.25rem;
  }
  .g-md-5,
  .gy-md-5 {
    --fc-gutter-y: 1.25rem;
  }
  .g-md-6,
  .gx-md-6 {
    --fc-gutter-x: 1.5rem;
  }
  .g-md-6,
  .gy-md-6 {
    --fc-gutter-y: 1.5rem;
  }
  .g-md-7,
  .gx-md-7 {
    --fc-gutter-x: 1.75rem;
  }
  .g-md-7,
  .gy-md-7 {
    --fc-gutter-y: 1.75rem;
  }
  .g-md-8,
  .gx-md-8 {
    --fc-gutter-x: 2rem;
  }
  .g-md-8,
  .gy-md-8 {
    --fc-gutter-y: 2rem;
  }
  .g-md-9,
  .gx-md-9 {
    --fc-gutter-x: 2.5rem;
  }
  .g-md-9,
  .gy-md-9 {
    --fc-gutter-y: 2.5rem;
  }
  .g-md-10,
  .gx-md-10 {
    --fc-gutter-x: 3rem;
  }
  .g-md-10,
  .gy-md-10 {
    --fc-gutter-y: 3rem;
  }
  .g-md-11,
  .gx-md-11 {
    --fc-gutter-x: 3.5rem;
  }
  .g-md-11,
  .gy-md-11 {
    --fc-gutter-y: 3.5rem;
  }
  .g-md-12,
  .gx-md-12 {
    --fc-gutter-x: 4rem;
  }
  .g-md-12,
  .gy-md-12 {
    --fc-gutter-y: 4rem;
  }
  .g-md-13,
  .gx-md-13 {
    --fc-gutter-x: 4.5rem;
  }
  .g-md-13,
  .gy-md-13 {
    --fc-gutter-y: 4.5rem;
  }
  .g-md-14,
  .gx-md-14 {
    --fc-gutter-x: 5rem;
  }
  .g-md-14,
  .gy-md-14 {
    --fc-gutter-y: 5rem;
  }
  .g-md-15,
  .gx-md-15 {
    --fc-gutter-x: 6rem;
  }
  .g-md-15,
  .gy-md-15 {
    --fc-gutter-y: 6rem;
  }
  .g-md-16,
  .gx-md-16 {
    --fc-gutter-x: 8rem;
  }
  .g-md-16,
  .gy-md-16 {
    --fc-gutter-y: 8rem;
  }
  .g-md-17,
  .gx-md-17 {
    --fc-gutter-x: 10rem;
  }
  .g-md-17,
  .gy-md-17 {
    --fc-gutter-y: 10rem;
  }
  .g-md-18,
  .gx-md-18 {
    --fc-gutter-x: 12rem;
  }
  .g-md-18,
  .gy-md-18 {
    --fc-gutter-y: 12rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --fc-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --fc-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --fc-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --fc-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --fc-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --fc-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --fc-gutter-x: 0.75rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --fc-gutter-y: 0.75rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --fc-gutter-x: 1rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --fc-gutter-y: 1rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --fc-gutter-x: 1.25rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --fc-gutter-y: 1.25rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --fc-gutter-x: 1.5rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --fc-gutter-y: 1.5rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --fc-gutter-x: 1.75rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --fc-gutter-y: 1.75rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --fc-gutter-x: 2rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --fc-gutter-y: 2rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --fc-gutter-x: 2.5rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --fc-gutter-y: 2.5rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --fc-gutter-x: 3rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --fc-gutter-y: 3rem;
  }
  .g-lg-11,
  .gx-lg-11 {
    --fc-gutter-x: 3.5rem;
  }
  .g-lg-11,
  .gy-lg-11 {
    --fc-gutter-y: 3.5rem;
  }
  .g-lg-12,
  .gx-lg-12 {
    --fc-gutter-x: 4rem;
  }
  .g-lg-12,
  .gy-lg-12 {
    --fc-gutter-y: 4rem;
  }
  .g-lg-13,
  .gx-lg-13 {
    --fc-gutter-x: 4.5rem;
  }
  .g-lg-13,
  .gy-lg-13 {
    --fc-gutter-y: 4.5rem;
  }
  .g-lg-14,
  .gx-lg-14 {
    --fc-gutter-x: 5rem;
  }
  .g-lg-14,
  .gy-lg-14 {
    --fc-gutter-y: 5rem;
  }
  .g-lg-15,
  .gx-lg-15 {
    --fc-gutter-x: 6rem;
  }
  .g-lg-15,
  .gy-lg-15 {
    --fc-gutter-y: 6rem;
  }
  .g-lg-16,
  .gx-lg-16 {
    --fc-gutter-x: 8rem;
  }
  .g-lg-16,
  .gy-lg-16 {
    --fc-gutter-y: 8rem;
  }
  .g-lg-17,
  .gx-lg-17 {
    --fc-gutter-x: 10rem;
  }
  .g-lg-17,
  .gy-lg-17 {
    --fc-gutter-y: 10rem;
  }
  .g-lg-18,
  .gx-lg-18 {
    --fc-gutter-x: 12rem;
  }
  .g-lg-18,
  .gy-lg-18 {
    --fc-gutter-y: 12rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --fc-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --fc-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --fc-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --fc-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --fc-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --fc-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --fc-gutter-x: 0.75rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --fc-gutter-y: 0.75rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --fc-gutter-x: 1rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --fc-gutter-y: 1rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --fc-gutter-x: 1.25rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --fc-gutter-y: 1.25rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --fc-gutter-x: 1.5rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --fc-gutter-y: 1.5rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --fc-gutter-x: 1.75rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --fc-gutter-y: 1.75rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --fc-gutter-x: 2rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --fc-gutter-y: 2rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --fc-gutter-x: 2.5rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --fc-gutter-y: 2.5rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --fc-gutter-x: 3rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --fc-gutter-y: 3rem;
  }
  .g-xl-11,
  .gx-xl-11 {
    --fc-gutter-x: 3.5rem;
  }
  .g-xl-11,
  .gy-xl-11 {
    --fc-gutter-y: 3.5rem;
  }
  .g-xl-12,
  .gx-xl-12 {
    --fc-gutter-x: 4rem;
  }
  .g-xl-12,
  .gy-xl-12 {
    --fc-gutter-y: 4rem;
  }
  .g-xl-13,
  .gx-xl-13 {
    --fc-gutter-x: 4.5rem;
  }
  .g-xl-13,
  .gy-xl-13 {
    --fc-gutter-y: 4.5rem;
  }
  .g-xl-14,
  .gx-xl-14 {
    --fc-gutter-x: 5rem;
  }
  .g-xl-14,
  .gy-xl-14 {
    --fc-gutter-y: 5rem;
  }
  .g-xl-15,
  .gx-xl-15 {
    --fc-gutter-x: 6rem;
  }
  .g-xl-15,
  .gy-xl-15 {
    --fc-gutter-y: 6rem;
  }
  .g-xl-16,
  .gx-xl-16 {
    --fc-gutter-x: 8rem;
  }
  .g-xl-16,
  .gy-xl-16 {
    --fc-gutter-y: 8rem;
  }
  .g-xl-17,
  .gx-xl-17 {
    --fc-gutter-x: 10rem;
  }
  .g-xl-17,
  .gy-xl-17 {
    --fc-gutter-y: 10rem;
  }
  .g-xl-18,
  .gx-xl-18 {
    --fc-gutter-x: 12rem;
  }
  .g-xl-18,
  .gy-xl-18 {
    --fc-gutter-y: 12rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --fc-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --fc-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --fc-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --fc-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --fc-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --fc-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --fc-gutter-x: 0.75rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --fc-gutter-y: 0.75rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --fc-gutter-x: 1rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --fc-gutter-y: 1rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --fc-gutter-x: 1.25rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --fc-gutter-y: 1.25rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --fc-gutter-x: 1.5rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --fc-gutter-y: 1.5rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --fc-gutter-x: 1.75rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --fc-gutter-y: 1.75rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --fc-gutter-x: 2rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --fc-gutter-y: 2rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --fc-gutter-x: 2.5rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --fc-gutter-y: 2.5rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --fc-gutter-x: 3rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --fc-gutter-y: 3rem;
  }
  .g-xxl-11,
  .gx-xxl-11 {
    --fc-gutter-x: 3.5rem;
  }
  .g-xxl-11,
  .gy-xxl-11 {
    --fc-gutter-y: 3.5rem;
  }
  .g-xxl-12,
  .gx-xxl-12 {
    --fc-gutter-x: 4rem;
  }
  .g-xxl-12,
  .gy-xxl-12 {
    --fc-gutter-y: 4rem;
  }
  .g-xxl-13,
  .gx-xxl-13 {
    --fc-gutter-x: 4.5rem;
  }
  .g-xxl-13,
  .gy-xxl-13 {
    --fc-gutter-y: 4.5rem;
  }
  .g-xxl-14,
  .gx-xxl-14 {
    --fc-gutter-x: 5rem;
  }
  .g-xxl-14,
  .gy-xxl-14 {
    --fc-gutter-y: 5rem;
  }
  .g-xxl-15,
  .gx-xxl-15 {
    --fc-gutter-x: 6rem;
  }
  .g-xxl-15,
  .gy-xxl-15 {
    --fc-gutter-y: 6rem;
  }
  .g-xxl-16,
  .gx-xxl-16 {
    --fc-gutter-x: 8rem;
  }
  .g-xxl-16,
  .gy-xxl-16 {
    --fc-gutter-y: 8rem;
  }
  .g-xxl-17,
  .gx-xxl-17 {
    --fc-gutter-x: 10rem;
  }
  .g-xxl-17,
  .gy-xxl-17 {
    --fc-gutter-y: 10rem;
  }
  .g-xxl-18,
  .gx-xxl-18 {
    --fc-gutter-x: 12rem;
  }
  .g-xxl-18,
  .gy-xxl-18 {
    --fc-gutter-y: 12rem;
  }
}
.table {
  --fc-table-color: var(--fc-body-color);
  --fc-table-bg: transparent;
  --fc-table-border-color: var(--fc-border-color);
  --fc-table-accent-bg: transparent;
  --fc-table-striped-color: var(--fc-body-color);
  --fc-table-striped-bg: var(--fc-gray-100);
  --fc-table-active-color: var(--fc-body-color);
  --fc-table-active-bg: var(--fc-gray-100);
  --fc-table-hover-color: var(--fc-body-color);
  --fc-table-hover-bg: var(--fc-gray-100);
  border-color: var(--fc-table-border-color);
  color: var(--fc-table-color);
  margin-bottom: 1rem;
  vertical-align: top;
  width: 100%;
}
.table > :not(caption) > * > * {
  background-color: var(--fc-table-bg);
  border-bottom-width: var(--fc-border-width);
  box-shadow: inset 0 0 0 9999px var(--fc-table-accent-bg);
  padding: 0.75rem 1.5rem;
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table-group-divider {
  border-top: calc(var(--fc-border-width) * 2) solid #ecf0ef;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 0.3rem 0.6rem;
}
.table-bordered > :not(caption) > * {
  border-width: var(--fc-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--fc-border-width);
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.table-striped-columns > :not(caption) > tr > :nth-child(2n),
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --fc-table-accent-bg: var(--fc-table-striped-bg);
  color: var(--fc-table-striped-color);
}
.table-active {
  --fc-table-accent-bg: var(--fc-table-active-bg);
  color: var(--fc-table-active-color);
}
.table-hover > tbody > tr:hover > * {
  --fc-table-accent-bg: var(--fc-table-hover-bg);
  color: var(--fc-table-hover-color);
}
.table-primary {
  --fc-table-color: #000;
  --fc-table-bg: #ceefce;
  --fc-table-border-color: #b9d7b9;
  --fc-table-striped-bg: #000;
  --fc-table-striped-color: #fff;
  --fc-table-active-bg: #000;
  --fc-table-active-color: #fff;
  --fc-table-hover-bg: #000;
  --fc-table-hover-color: #fff;
}
.table-primary,
.table-secondary {
  border-color: var(--fc-table-border-color);
  color: var(--fc-table-color);
}
.table-secondary {
  --fc-table-color: #000;
  --fc-table-bg: #e7e9ea;
  --fc-table-border-color: #d0d2d3;
  --fc-table-striped-bg: #000;
  --fc-table-striped-color: #fff;
  --fc-table-active-bg: #000;
  --fc-table-active-color: #fff;
  --fc-table-hover-bg: #000;
  --fc-table-hover-color: #fff;
}
.table-success {
  --fc-table-color: #000;
  --fc-table-bg: #d1e7dd;
  --fc-table-border-color: #bcd0c7;
  --fc-table-striped-bg: #000;
  --fc-table-striped-color: #fff;
  --fc-table-active-bg: #000;
  --fc-table-active-color: #fff;
  --fc-table-hover-bg: #000;
  --fc-table-hover-color: #fff;
}
.table-info,
.table-success {
  border-color: var(--fc-table-border-color);
  color: var(--fc-table-color);
}
.table-info {
  --fc-table-color: #000;
  --fc-table-bg: #cce1fe;
  --fc-table-border-color: #b8cbe5;
  --fc-table-striped-bg: #000;
  --fc-table-striped-color: #fff;
  --fc-table-active-bg: #000;
  --fc-table-active-color: #fff;
  --fc-table-hover-bg: #000;
  --fc-table-hover-color: #fff;
}
.table-warning {
  --fc-table-color: #000;
  --fc-table-bg: #fff3cd;
  --fc-table-border-color: #e6dbb9;
  --fc-table-striped-bg: #000;
  --fc-table-striped-color: #fff;
  --fc-table-active-bg: #000;
  --fc-table-active-color: #fff;
  --fc-table-hover-bg: #000;
  --fc-table-hover-color: #fff;
}
.table-danger,
.table-warning {
  border-color: var(--fc-table-border-color);
  color: var(--fc-table-color);
}
.table-danger {
  --fc-table-color: #000;
  --fc-table-bg: #f8d6d6;
  --fc-table-border-color: #dfc1c1;
  --fc-table-striped-bg: #000;
  --fc-table-striped-color: #fff;
  --fc-table-active-bg: #000;
  --fc-table-active-color: #fff;
  --fc-table-hover-bg: #000;
  --fc-table-hover-color: #fff;
}
.table-light {
  --fc-table-color: #000;
  --fc-table-bg: #f0f3f2;
  --fc-table-border-color: #d8dbda;
  --fc-table-striped-bg: #000;
  --fc-table-striped-color: #fff;
  --fc-table-active-bg: #000;
  --fc-table-active-color: #fff;
  --fc-table-hover-bg: #000;
  --fc-table-hover-color: #fff;
}
.table-dark,
.table-light {
  border-color: var(--fc-table-border-color);
  color: var(--fc-table-color);
}
.table-dark {
  --fc-table-color: #fff;
  --fc-table-bg: #001e2b;
  --fc-table-border-color: #1a3540;
  --fc-table-striped-bg: #fff;
  --fc-table-striped-color: #000;
  --fc-table-active-bg: #fff;
  --fc-table-active-color: #000;
  --fc-table-hover-bg: #fff;
  --fc-table-hover-color: #000;
}
.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}
.form-label {
  color: #3d4f58;
  margin-bottom: 0.5rem;
}
.col-form-label {
  color: #3d4f58;
  font-size: inherit;
  line-height: 1.6;
  margin-bottom: 0;
  padding-bottom: calc(0.55rem + 1px);
  padding-top: calc(0.55rem + 1px);
}
.col-form-label-lg {
  font-size: 1.09375rem;
  padding-bottom: calc(0.5rem + 1px);
  padding-top: calc(0.5rem + 1px);
}
.col-form-label-sm {
  font-size: 0.765625rem;
  padding-bottom: calc(0.25rem + 1px);
  padding-top: calc(0.25rem + 1px);
}
.form-text {
  color: #000;
  font-size: 0.875em;
  margin-top: 0.25rem;
}
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 9px;
  border: 1px solid #000;
  color: #000 !important;
  font-size: 12px !important;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.6;
  padding: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  /* margin-bottom: 30px; */
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  background-color: #fff;
  border-color: #000;
  box-shadow: none;
  color: #21313c;
  outline: 0;
}
.form-control::-webkit-date-and-time-value {
  height: 1.6em;
  margin: 0;
  min-width: 85px;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::-moz-placeholder {
  color: #000;
  opacity: 1;
}
.form-control::placeholder {
  color: #000;
  opacity: 1;
}
.form-control:disabled {
  background-color: #ecf0ef;
  opacity: 1;
}
.form-control::file-selector-button {
  -webkit-margin-end: 1rem;
  background-color: #ecf0ef;
  border: 0 solid;
  border-color: inherit;
  border-inline-end-width: 1px;
  border-radius: 0;
  color: #21313c;
  margin: -0.55rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.55rem 1rem;
  pointer-events: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e0e4e3;
}
.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  color: #5c6c75;
  display: block;
  line-height: 1.6;
  margin-bottom: 0;
  padding: 0.55rem 0;
  width: 100%;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}
.form-control-sm {
  border-radius: 0.25rem;
  font-size: 0.765625rem;
  min-height: calc(1.6em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
}
.form-control-sm::file-selector-button {
  -webkit-margin-end: 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
  padding: 0.25rem 0.5rem;
}
.form-control-lg {
  border-radius: 0.75rem;
  font-size: 1.09375rem;
  min-height: calc(1.6em + 1rem + 2px);
  padding: 0.5rem 1rem;
}
.form-control-lg::file-selector-button {
  -webkit-margin-end: 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
  padding: 0.5rem 1rem;
}
textarea.form-control {
  min-height: calc(1.6em + 1.1rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
}
.form-control-color {
  height: calc(1.6em + 1.1rem + 2px);
  padding: 0.55rem;
  width: 3rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 0.5rem;
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 0.5rem;
}
.form-control-color.form-control-sm {
  height: calc(1.6em + 0.5rem + 2px);
}
.form-control-color.form-control-lg {
  height: calc(1.6em + 1rem + 2px);
}
.form-select {
  --fc-form-select-bg-img: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%2321313c' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: var(--fc-form-select-bg-img),
    var(--fc-form-select-bg-icon, none);
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #dfe2e1;
  border-radius: 0.5rem;
  color: #21313c;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.6;
  padding: 0.55rem 3rem 0.55rem 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #85d685;
  box-shadow: 0 0 0 0.25rem rgba(10, 173, 10, 0.25);
  outline: 0;
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}
.form-select:disabled {
  background-color: #ecf0ef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #21313c;
}
.form-select-sm {
  border-radius: 0.25rem;
  font-size: 0.765625rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
}
.form-select-lg {
  border-radius: 0.75rem;
  font-size: 1.09375rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
}
[data-bs-theme="dark"] .form-select {
  --fc-form-select-bg-img: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23889397' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
}
.form-check {
  display: block;
  margin-bottom: 0.125rem;
  min-height: 1.4rem;
  padding-left: 1.5em;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
  background-color: #ebece9;
  border: unset !important;
  cursor: pointer;
}
.form-check-reverse {
  padding-left: 0;
  padding-right: 1.5em;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}
.form-check-input {
  --fc-form-check-bg: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--fc-form-check-bg);
  background-image: var(--fc-form-check-bg-image);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #000;
  border-radius: none;
  height: 16px;
  margin-top: 0.3em;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  vertical-align: top;
  width: 16px;
}
.form-check-input[type="checkbox"] {
  border-radius: none;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  /* border-color: #85d685;
  box-shadow: 0 0 0 0.25rem rgba(10, 173, 10, 0.25); */
  outline: 0;
}
.form-check-input:checked {
  background-color: var(--fc-black);
  border-color: var(--fc-black);
}
.form-check-input:checked[type="checkbox"] {
  --fc-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
}
.form-check-input:checked[type="radio"] {
  --fc-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E");
}
.form-check-input[type="checkbox"]:indeterminate {
  --fc-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E");
  background-color: #0aad0a;
  border-color: #0aad0a;
}
.form-check-input:disabled {
  filter: none;
  opacity: 0.5;
  pointer-events: none;
}
.form-check-label {
  padding-left: 2rem;
  color: var(--fc-black);
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
}
.form-check-label span {
  color: #bdbebb;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --fc-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(33, 49, 60, 0.25)'/%3E%3C/svg%3E");
  background-image: var(--fc-form-switch-bg);
  background-position: 0;
  border-radius: 2em;
  margin-left: -2.5em;
  transition: background-position 0.15s ease-in-out;
  width: 2em;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --fc-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%2385d685'/%3E%3C/svg%3E");
}
.form-switch .form-check-input:checked {
  --fc-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  background-position: 100%;
}
.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}
.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}
.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  filter: none;
  opacity: 0.65;
  pointer-events: none;
}
[data-bs-theme="dark"]
  .form-switch
  .form-check-input:not(:checked):not(:focus) {
  --fc-form-switch-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='rgba(255, 255, 255, 0.25)'/%3E%3C/svg%3E");
}
.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  height: 1.5rem;
  padding: 0;
  width: 100%;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(10, 173, 10, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(10, 173, 10, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #0aad0a;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6e6b6;
}
.form-range::-webkit-slider-runnable-track {
  background-color: #ecf0ef;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.form-range::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  background-color: #0aad0a;
  border: 0;
  border-radius: 1rem;
  height: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6e6b6;
}
.form-range::-moz-range-track {
  background-color: #ecf0ef;
  border-color: transparent;
  border-radius: 1rem;
  color: transparent;
  cursor: pointer;
  height: 0.5rem;
  width: 100%;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #889397;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #889397;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  border: 1px solid transparent;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  text-align: start;
  text-overflow: ellipsis;
  top: 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  white-space: nowrap;
  z-index: 2;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem;
}
.form-floating > .form-control-plaintext::-moz-placeholder,
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control-plaintext::placeholder,
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control-plaintext:not(:-moz-placeholder-shown),
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control-plaintext:-webkit-autofill,
.form-floating > .form-control:-webkit-autofill {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-select {
  padding-bottom: 0.625rem;
  padding-top: 1.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: rgba(var(--fc-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--fc-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label:after {
  background-color: #fff;
  border-radius: 0.5rem;
  content: "";
  height: 1.5em;
  inset: 1rem 0.5rem;
  position: absolute;
  z-index: -1;
}
.form-floating > .form-control-plaintext ~ label:after,
.form-floating > .form-control:focus ~ label:after,
.form-floating > .form-control:not(:placeholder-shown) ~ label:after,
.form-floating > .form-select ~ label:after {
  background-color: #fff;
  border-radius: 0.5rem;
  content: "";
  height: 1.5em;
  inset: 1rem 0.5rem;
  position: absolute;
  z-index: -1;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--fc-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}
.form-floating > :disabled ~ label {
  color: #5c6c75;
}
.form-floating > :disabled ~ label:after {
  background-color: #ecf0ef;
}
.input-group {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-floating,
.input-group > .form-select {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
  width: 1%;
}
.input-group > .form-control:focus,
.input-group > .form-floating:focus-within,
.input-group > .form-select:focus {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}
.input-group-text {
  align-items: center;
  background-color: #ecf0ef;
  border: 1px solid #dfe2e1;
  border-radius: 0.5rem;
  color: #21313c;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.6;
  padding: 0.55rem 1rem;
  text-align: center;
  white-space: nowrap;
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  border-radius: 0.75rem;
  font-size: 1.09375rem;
  padding: 0.5rem 1rem;
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  border-radius: 0.25rem;
  font-size: 0.765625rem;
  padding: 0.25rem 0.5rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select,
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  /* margin-left: -1px; */
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.valid-feedback {
  color: var(--fc-form-valid-color);
  display: none;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}
.valid-tooltip {
  background-color: var(--fc-success);
  border-radius: 0.5rem;
  color: #fff;
  display: none;
  font-size: 0.765625rem;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right calc(0.4em + 0.275rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.8em + 0.55rem) calc(0.8em + 0.55rem);
  border-color: var(--fc-form-valid-border-color);
  padding-right: calc(1.6em + 1.1rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: var(--fc-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--fc-success-rgb), 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  background-position: top calc(0.4em + 0.275rem) right calc(0.4em + 0.275rem);
  padding-right: calc(1.6em + 1.1rem);
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: var(--fc-form-valid-border-color);
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  --fc-form-select-bg-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.8em + 0.55rem) calc(0.8em + 0.55rem);
  padding-right: 5.5rem;
}
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: var(--fc-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--fc-success-rgb), 0.25);
}
.form-control-color.is-valid,
.was-validated .form-control-color:valid {
  width: calc(4.1rem + 1.6em);
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: var(--fc-form-valid-border-color);
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: var(--fc-form-valid-color);
}
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--fc-success-rgb), 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: var(--fc-form-valid-color);
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.input-group > .form-control:not(:focus).is-valid,
.input-group > .form-floating:not(:focus-within).is-valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-control:not(:focus):valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.was-validated .input-group > .form-select:not(:focus):valid {
  z-index: 3;
}
.invalid-feedback {
  color: var(--fc-form-invalid-color);
  display: none;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}
.invalid-tooltip {
  background-color: var(--fc-danger);
  border-radius: 0.5rem;
  color: #fff;
  display: none;
  font-size: 0.765625rem;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db3030'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23db3030' stroke='none'/%3E%3C/svg%3E");
  background-position: right calc(0.4em + 0.275rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.8em + 0.55rem) calc(0.8em + 0.55rem);
  border-color: var(--fc-form-invalid-border-color);
  padding-right: calc(1.6em + 1.1rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: var(--fc-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--fc-danger-rgb), 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  background-position: top calc(0.4em + 0.275rem) right calc(0.4em + 0.275rem);
  padding-right: calc(1.6em + 1.1rem);
}
.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: var(--fc-form-invalid-border-color);
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  --fc-form-select-bg-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23db3030'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23db3030' stroke='none'/%3E%3C/svg%3E");
  background-position: right 1rem center, center right 3rem;
  background-size: 16px 12px, calc(0.8em + 0.55rem) calc(0.8em + 0.55rem);
  padding-right: 5.5rem;
}
.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: var(--fc-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--fc-danger-rgb), 0.25);
}
.form-control-color.is-invalid,
.was-validated .form-control-color:invalid {
  width: calc(4.1rem + 1.6em);
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: var(--fc-form-invalid-border-color);
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: var(--fc-form-invalid-color);
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--fc-danger-rgb), 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: var(--fc-form-invalid-color);
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.input-group > .form-control:not(:focus).is-invalid,
.input-group > .form-floating:not(:focus-within).is-invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-control:not(:focus):invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.was-validated .input-group > .form-select:not(:focus):invalid {
  z-index: 4;
}
.btn {
  --fc-btn-padding-x: 1rem;
  --fc-btn-padding-y: 0.55rem;
  --fc-btn-font-family: ;
  --fc-btn-font-size: 0.875rem;
  --fc-btn-font-weight: 600;
  --fc-btn-line-height: 1.6;
  --fc-btn-color: var(--fc-body-color);
  --fc-btn-bg: transparent;
  --fc-btn-border-width: 1px;
  --fc-btn-border-color: transparent;
  --fc-btn-border-radius: 0.5rem;
  --fc-btn-hover-border-color: transparent;
  --fc-btn-box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --fc-btn-disabled-opacity: 0.65;
  --fc-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--fc-btn-focus-shadow-rgb), 0.5);
  background-color: var(--fc-btn-bg);
  border: var(--fc-btn-border-width) solid var(--fc-btn-border-color);
  border-radius: var(--fc-btn-border-radius);
  color: var(--fc-btn-color);
  cursor: pointer;
  display: inline-block;
  font-family: var(--fc-btn-font-family);
  font-size: var(--fc-btn-font-size);
  font-weight: var(--fc-btn-font-weight);
  line-height: var(--fc-btn-line-height);
  padding: var(--fc-btn-padding-y) var(--fc-btn-padding-x);
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  background-color: var(--fc-btn-hover-bg);
  border-color: var(--fc-btn-hover-border-color);
  color: var(--fc-btn-hover-color);
}
.btn-check + .btn:hover {
  background-color: var(--fc-btn-bg);
  border-color: var(--fc-btn-border-color);
  color: var(--fc-btn-color);
}
.btn:focus-visible {
  background-color: var(--fc-btn-hover-bg);
  border-color: var(--fc-btn-hover-border-color);
  box-shadow: var(--fc-btn-focus-box-shadow);
  color: var(--fc-btn-hover-color);
  outline: 0;
}
.btn-check:focus-visible + .btn {
  border-color: var(--fc-btn-hover-border-color);
  box-shadow: var(--fc-btn-focus-box-shadow);
  outline: 0;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--fc-btn-active-bg);
  /* border-color: var(--fc-btn-active-border-color); */
  color: var(--fc-btn-active-color);
}
.btn-check:checked + .btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check) + .btn:active:focus-visible {
  box-shadow: var(--fc-btn-focus-box-shadow);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--fc-btn-disabled-bg);
  border-color: var(--fc-btn-disabled-border-color);
  color: var(--fc-btn-disabled-color);
  opacity: var(--fc-btn-disabled-opacity);
  pointer-events: none;
}
.btn-primary {
  --fc-btn-color: #fff;
  --fc-btn-bg: #0aad0a;
  --fc-btn-border-color: #0aad0a;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #099309;
  --fc-btn-hover-border-color: #088a08;
  --fc-btn-focus-shadow-rgb: 47, 185, 47;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #088a08;
  --fc-btn-active-border-color: #088208;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #0aad0a;
  --fc-btn-disabled-border-color: #0aad0a;
}
.btn-secondary {
  --fc-btn-color: #fff;
  --fc-btn-bg: #889397;
  --fc-btn-border-color: #889397;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #747d80;
  --fc-btn-hover-border-color: #6d7679;
  --fc-btn-focus-shadow-rgb: 154, 163, 167;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #6d7679;
  --fc-btn-active-border-color: #666e71;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #889397;
  --fc-btn-disabled-border-color: #889397;
}
.btn-success {
  --fc-btn-color: #fff;
  --fc-btn-bg: #198754;
  --fc-btn-border-color: #198754;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #157347;
  --fc-btn-hover-border-color: #146c43;
  --fc-btn-focus-shadow-rgb: 60, 153, 110;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #146c43;
  --fc-btn-active-border-color: #13653f;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #198754;
  --fc-btn-disabled-border-color: #198754;
}
.btn-info {
  --fc-btn-color: #fff;
  --fc-btn-bg: #016bf8;
  --fc-btn-border-color: #016bf8;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #015bd3;
  --fc-btn-hover-border-color: #0156c6;
  --fc-btn-focus-shadow-rgb: 39, 129, 249;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #0156c6;
  --fc-btn-active-border-color: #0150ba;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #016bf8;
  --fc-btn-disabled-border-color: #016bf8;
}
.btn-warning {
  --fc-btn-color: #000;
  --fc-btn-bg: #ffc107;
  --fc-btn-border-color: #ffc107;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #ffca2c;
  --fc-btn-hover-border-color: #ffc720;
  --fc-btn-focus-shadow-rgb: 217, 164, 6;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #ffcd39;
  --fc-btn-active-border-color: #ffc720;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #ffc107;
  --fc-btn-disabled-border-color: #ffc107;
}
.btn-danger {
  --fc-btn-color: #fff;
  --fc-btn-bg: #db3030;
  --fc-btn-border-color: #db3030;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #ba2929;
  --fc-btn-hover-border-color: #af2626;
  --fc-btn-focus-shadow-rgb: 224, 79, 79;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #af2626;
  --fc-btn-active-border-color: #a42424;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #db3030;
  --fc-btn-disabled-border-color: #db3030;
}
.btn-light {
  --fc-btn-color: #000;
  --fc-btn-bg: #f0f3f2;
  --fc-btn-border-color: #f0f3f2;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #cccfce;
  --fc-btn-hover-border-color: #c0c2c2;
  --fc-btn-focus-shadow-rgb: 204, 207, 206;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #c0c2c2;
  --fc-btn-active-border-color: #b4b6b6;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #f0f3f2;
  --fc-btn-disabled-border-color: #f0f3f2;
}
.btn-dark {
  --fc-btn-color: #ffffff;
  --fc-btn-bg: #000;
  --fc-btn-border-color: #000;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #000;
  --fc-btn-hover-border-color: #000;
  --fc-btn-focus-shadow-rgb: 38, 64, 75;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #334b55;
  --fc-btn-active-border-color: #1a3540;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #001e2b;
  --fc-btn-disabled-border-color: #001e2b;
}
.btn-gray-400 {
  --fc-btn-color: #000;
  --fc-btn-bg: #c1c7c6;
  --fc-btn-border-color: #c1c7c6;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #cacfcf;
  --fc-btn-hover-border-color: #c7cdcc;
  --fc-btn-focus-shadow-rgb: 164, 169, 168;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #cdd2d1;
  --fc-btn-active-border-color: #c7cdcc;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #c1c7c6;
  --fc-btn-disabled-border-color: #c1c7c6;
}
.btn-white {
  --fc-btn-color: #000;
  --fc-btn-bg: #fff;
  --fc-btn-border-color: #fff;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #fff;
  --fc-btn-hover-border-color: #fff;
  --fc-btn-focus-shadow-rgb: 217, 217, 217;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #fff;
  --fc-btn-active-border-color: #fff;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #fff;
  --fc-btn-disabled-border-color: #fff;
}
.btn-light-primary {
  --fc-btn-color: #000;
  --fc-btn-bg: #ceefce;
  --fc-btn-border-color: #ceefce;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #d5f1d5;
  --fc-btn-hover-border-color: #d3f1d3;
  --fc-btn-focus-shadow-rgb: 175, 203, 175;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #d8f2d8;
  --fc-btn-active-border-color: #d3f1d3;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #ceefce;
  --fc-btn-disabled-border-color: #ceefce;
}
.btn-light-secondary {
  --fc-btn-color: #fff;
  --fc-btn-bg: #889397;
  --fc-btn-border-color: #889397;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #747d80;
  --fc-btn-hover-border-color: #6d7679;
  --fc-btn-focus-shadow-rgb: 154, 163, 167;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #6d7679;
  --fc-btn-active-border-color: #666e71;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #889397;
  --fc-btn-disabled-border-color: #889397;
}
.btn-light-danger {
  --fc-btn-color: #000;
  --fc-btn-bg: #f8d6d6;
  --fc-btn-border-color: #f8d6d6;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #f9dcdc;
  --fc-btn-hover-border-color: #f9dada;
  --fc-btn-focus-shadow-rgb: 211, 182, 182;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #f9dede;
  --fc-btn-active-border-color: #f9dada;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #f8d6d6;
  --fc-btn-disabled-border-color: #f8d6d6;
}
.btn-light-info {
  --fc-btn-color: #000;
  --fc-btn-bg: #cce1fe;
  --fc-btn-border-color: #cce1fe;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #d4e6fe;
  --fc-btn-hover-border-color: #d1e4fe;
  --fc-btn-focus-shadow-rgb: 173, 191, 216;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #d6e7fe;
  --fc-btn-active-border-color: #d1e4fe;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #cce1fe;
  --fc-btn-disabled-border-color: #cce1fe;
}
.btn-light-warning {
  --fc-btn-color: #000;
  --fc-btn-bg: #fff3cd;
  --fc-btn-border-color: #fff3cd;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #fff5d5;
  --fc-btn-hover-border-color: #fff4d2;
  --fc-btn-focus-shadow-rgb: 217, 207, 174;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #fff5d7;
  --fc-btn-active-border-color: #fff4d2;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #fff3cd;
  --fc-btn-disabled-border-color: #fff3cd;
}
.btn-light-success {
  --fc-btn-color: #000;
  --fc-btn-bg: #d1e7dd;
  --fc-btn-border-color: #d1e7dd;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #d8ebe2;
  --fc-btn-hover-border-color: #d6e9e0;
  --fc-btn-focus-shadow-rgb: 178, 196, 188;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #daece4;
  --fc-btn-active-border-color: #d6e9e0;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #d1e7dd;
  --fc-btn-disabled-border-color: #d1e7dd;
}
.btn-dark-primary {
  --fc-btn-color: #fff;
  --fc-btn-bg: #044504;
  --fc-btn-border-color: #044504;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #033b03;
  --fc-btn-hover-border-color: #033703;
  --fc-btn-focus-shadow-rgb: 42, 97, 42;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #033703;
  --fc-btn-active-border-color: #033403;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #044504;
  --fc-btn-disabled-border-color: #044504;
}
.btn-dark-warning {
  --fc-btn-color: #fff;
  --fc-btn-bg: #664d03;
  --fc-btn-border-color: #664d03;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #574103;
  --fc-btn-hover-border-color: #523e02;
  --fc-btn-focus-shadow-rgb: 125, 104, 41;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #523e02;
  --fc-btn-active-border-color: #4d3a02;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #664d03;
  --fc-btn-disabled-border-color: #664d03;
}
.btn-dark-info {
  --fc-btn-color: #fff;
  --fc-btn-bg: #002b63;
  --fc-btn-border-color: #002b63;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #002554;
  --fc-btn-hover-border-color: #00224f;
  --fc-btn-focus-shadow-rgb: 38, 75, 122;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #00224f;
  --fc-btn-active-border-color: #00204a;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #002b63;
  --fc-btn-disabled-border-color: #002b63;
}
.btn-dark-danger {
  --fc-btn-color: #fff;
  --fc-btn-bg: #581313;
  --fc-btn-border-color: #581313;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #4b1010;
  --fc-btn-hover-border-color: #460f0f;
  --fc-btn-focus-shadow-rgb: 113, 54, 54;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #460f0f;
  --fc-btn-active-border-color: #420e0e;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #581313;
  --fc-btn-disabled-border-color: #581313;
}
.btn-dark-success {
  --fc-btn-color: #fff;
  --fc-btn-bg: #0a3622;
  --fc-btn-border-color: #0a3622;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #092e1d;
  --fc-btn-hover-border-color: #082b1b;
  --fc-btn-focus-shadow-rgb: 47, 84, 67;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #082b1b;
  --fc-btn-active-border-color: #08291a;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #0a3622;
  --fc-btn-disabled-border-color: #0a3622;
}
.btn-dark-secondary {
  --fc-btn-color: #000;
  --fc-btn-bg: #ecf0ef;
  --fc-btn-border-color: #ecf0ef;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #eff2f1;
  --fc-btn-hover-border-color: #eef2f1;
  --fc-btn-focus-shadow-rgb: 201, 204, 203;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #f0f3f2;
  --fc-btn-active-border-color: #eef2f1;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #ecf0ef;
  --fc-btn-disabled-border-color: #ecf0ef;
}
.btn-indigo {
  --fc-btn-color: #fff;
  --fc-btn-bg: #6610f2;
  --fc-btn-border-color: #6610f2;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #570ece;
  --fc-btn-hover-border-color: #520dc2;
  --fc-btn-focus-shadow-rgb: 125, 52, 244;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #520dc2;
  --fc-btn-active-border-color: #4d0cb6;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #6610f2;
  --fc-btn-disabled-border-color: #6610f2;
}
.btn-red {
  --fc-btn-color: #fff;
  --fc-btn-bg: #db3030;
  --fc-btn-border-color: #db3030;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #ba2929;
  --fc-btn-hover-border-color: #af2626;
  --fc-btn-focus-shadow-rgb: 224, 79, 79;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #af2626;
  --fc-btn-active-border-color: #a42424;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #db3030;
  --fc-btn-disabled-border-color: #db3030;
}
.btn-pink {
  --fc-btn-color: #fff;
  --fc-btn-bg: #d63384;
  --fc-btn-border-color: #d63384;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #b62b70;
  --fc-btn-hover-border-color: #ab296a;
  --fc-btn-focus-shadow-rgb: 220, 82, 150;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #ab296a;
  --fc-btn-active-border-color: #a12663;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #d63384;
  --fc-btn-disabled-border-color: #d63384;
}
.btn-orange {
  --fc-btn-color: #000;
  --fc-btn-bg: #fd7e14;
  --fc-btn-border-color: #fd7e14;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #fd9137;
  --fc-btn-hover-border-color: #fd8b2c;
  --fc-btn-focus-shadow-rgb: 215, 107, 17;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #fd9843;
  --fc-btn-active-border-color: #fd8b2c;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #fd7e14;
  --fc-btn-disabled-border-color: #fd7e14;
}
.btn-teal {
  --fc-btn-color: #000;
  --fc-btn-bg: #20c997;
  --fc-btn-border-color: #20c997;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #41d1a7;
  --fc-btn-hover-border-color: #36cea1;
  --fc-btn-focus-shadow-rgb: 27, 171, 128;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #4dd4ac;
  --fc-btn-active-border-color: #36cea1;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #000;
  --fc-btn-disabled-bg: #20c997;
  --fc-btn-disabled-border-color: #20c997;
}
.btn-purple {
  --fc-btn-color: #fff;
  --fc-btn-bg: #6f42c1;
  --fc-btn-border-color: #6f42c1;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #5e38a4;
  --fc-btn-hover-border-color: #59359a;
  --fc-btn-focus-shadow-rgb: 133, 94, 202;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #59359a;
  --fc-btn-active-border-color: #533291;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: #6f42c1;
  --fc-btn-disabled-border-color: #6f42c1;
}
.btn-outline-primary {
  --fc-btn-color: #0aad0a;
  --fc-btn-border-color: #0aad0a;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #0aad0a;
  --fc-btn-hover-border-color: #0aad0a;
  --fc-btn-focus-shadow-rgb: 10, 173, 10;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #0aad0a;
  --fc-btn-active-border-color: #0aad0a;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #0aad0a;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #0aad0a;
  --fc-gradient: none;
}
.btn-outline-secondary {
  --fc-btn-color: #889397;
  --fc-btn-border-color: #889397;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #889397;
  --fc-btn-hover-border-color: #889397;
  --fc-btn-focus-shadow-rgb: 136, 147, 151;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #889397;
  --fc-btn-active-border-color: #889397;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #889397;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #889397;
  --fc-gradient: none;
}
.btn-outline-success {
  --fc-btn-color: #198754;
  --fc-btn-border-color: #198754;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #198754;
  --fc-btn-hover-border-color: #198754;
  --fc-btn-focus-shadow-rgb: 25, 135, 84;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #198754;
  --fc-btn-active-border-color: #198754;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #198754;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #198754;
  --fc-gradient: none;
}
.btn-outline-info {
  --fc-btn-color: #016bf8;
  --fc-btn-border-color: #016bf8;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #016bf8;
  --fc-btn-hover-border-color: #016bf8;
  --fc-btn-focus-shadow-rgb: 1, 107, 248;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #016bf8;
  --fc-btn-active-border-color: #016bf8;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #016bf8;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #016bf8;
  --fc-gradient: none;
}
.btn-outline-warning {
  --fc-btn-color: #ffc107;
  --fc-btn-border-color: #ffc107;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #ffc107;
  --fc-btn-hover-border-color: #ffc107;
  --fc-btn-focus-shadow-rgb: 255, 193, 7;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #ffc107;
  --fc-btn-active-border-color: #ffc107;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #ffc107;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #ffc107;
  --fc-gradient: none;
}
.btn-outline-danger {
  --fc-btn-color: #db3030;
  --fc-btn-border-color: #db3030;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #db3030;
  --fc-btn-hover-border-color: #db3030;
  --fc-btn-focus-shadow-rgb: 219, 48, 48;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #db3030;
  --fc-btn-active-border-color: #db3030;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #db3030;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #db3030;
  --fc-gradient: none;
}
.btn-outline-light {
  --fc-btn-color: #f0f3f2;
  --fc-btn-border-color: #f0f3f2;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #f0f3f2;
  --fc-btn-hover-border-color: #f0f3f2;
  --fc-btn-focus-shadow-rgb: 240, 243, 242;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #f0f3f2;
  --fc-btn-active-border-color: #f0f3f2;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #f0f3f2;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #f0f3f2;
  --fc-gradient: none;
}
.btn-outline-dark {
  --fc-btn-color: #001e2b;
  --fc-btn-border-color: #001e2b;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #001e2b;
  --fc-btn-hover-border-color: #001e2b;
  --fc-btn-focus-shadow-rgb: 0, 30, 43;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #001e2b;
  --fc-btn-active-border-color: #001e2b;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #001e2b;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #001e2b;
  --fc-gradient: none;
}
.btn-outline-gray-400 {
  --fc-btn-color: #c1c7c6;
  --fc-btn-border-color: #c1c7c6;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #c1c7c6;
  --fc-btn-hover-border-color: #c1c7c6;
  --fc-btn-focus-shadow-rgb: 193, 199, 198;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #c1c7c6;
  --fc-btn-active-border-color: #c1c7c6;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #c1c7c6;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #c1c7c6;
  --fc-gradient: none;
}
.btn-outline-white {
  --fc-btn-color: #fff;
  --fc-btn-border-color: #fff;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #fff;
  --fc-btn-hover-border-color: #fff;
  --fc-btn-focus-shadow-rgb: 255, 255, 255;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #fff;
  --fc-btn-active-border-color: #fff;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #fff;
  --fc-gradient: none;
}
.btn-outline-light-primary {
  --fc-btn-color: #ceefce;
  --fc-btn-border-color: #ceefce;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #ceefce;
  --fc-btn-hover-border-color: #ceefce;
  --fc-btn-focus-shadow-rgb: 206, 239, 206;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #ceefce;
  --fc-btn-active-border-color: #ceefce;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #ceefce;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #ceefce;
  --fc-gradient: none;
}
.btn-outline-light-secondary {
  --fc-btn-color: #889397;
  --fc-btn-border-color: #889397;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #889397;
  --fc-btn-hover-border-color: #889397;
  --fc-btn-focus-shadow-rgb: 136, 147, 151;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #889397;
  --fc-btn-active-border-color: #889397;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #889397;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #889397;
  --fc-gradient: none;
}
.btn-outline-light-danger {
  --fc-btn-color: #f8d6d6;
  --fc-btn-border-color: #f8d6d6;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #f8d6d6;
  --fc-btn-hover-border-color: #f8d6d6;
  --fc-btn-focus-shadow-rgb: 248, 214, 214;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #f8d6d6;
  --fc-btn-active-border-color: #f8d6d6;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #f8d6d6;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #f8d6d6;
  --fc-gradient: none;
}
.btn-outline-light-info {
  --fc-btn-color: #cce1fe;
  --fc-btn-border-color: #cce1fe;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #cce1fe;
  --fc-btn-hover-border-color: #cce1fe;
  --fc-btn-focus-shadow-rgb: 204, 225, 254;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #cce1fe;
  --fc-btn-active-border-color: #cce1fe;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #cce1fe;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #cce1fe;
  --fc-gradient: none;
}
.btn-outline-light-warning {
  --fc-btn-color: #fff3cd;
  --fc-btn-border-color: #fff3cd;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #fff3cd;
  --fc-btn-hover-border-color: #fff3cd;
  --fc-btn-focus-shadow-rgb: 255, 243, 205;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #fff3cd;
  --fc-btn-active-border-color: #fff3cd;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fff3cd;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #fff3cd;
  --fc-gradient: none;
}
.btn-outline-light-success {
  --fc-btn-color: #d1e7dd;
  --fc-btn-border-color: #d1e7dd;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #d1e7dd;
  --fc-btn-hover-border-color: #d1e7dd;
  --fc-btn-focus-shadow-rgb: 209, 231, 221;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #d1e7dd;
  --fc-btn-active-border-color: #d1e7dd;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #d1e7dd;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #d1e7dd;
  --fc-gradient: none;
}
.btn-outline-dark-primary {
  --fc-btn-color: #044504;
  --fc-btn-border-color: #044504;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #044504;
  --fc-btn-hover-border-color: #044504;
  --fc-btn-focus-shadow-rgb: 4, 69, 4;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #044504;
  --fc-btn-active-border-color: #044504;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #044504;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #044504;
  --fc-gradient: none;
}
.btn-outline-dark-warning {
  --fc-btn-color: #664d03;
  --fc-btn-border-color: #664d03;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #664d03;
  --fc-btn-hover-border-color: #664d03;
  --fc-btn-focus-shadow-rgb: 102, 77, 3;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #664d03;
  --fc-btn-active-border-color: #664d03;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #664d03;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #664d03;
  --fc-gradient: none;
}
.btn-outline-dark-info {
  --fc-btn-color: #002b63;
  --fc-btn-border-color: #002b63;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #002b63;
  --fc-btn-hover-border-color: #002b63;
  --fc-btn-focus-shadow-rgb: 0, 43, 99;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #002b63;
  --fc-btn-active-border-color: #002b63;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #002b63;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #002b63;
  --fc-gradient: none;
}
.btn-outline-dark-danger {
  --fc-btn-color: #581313;
  --fc-btn-border-color: #581313;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #581313;
  --fc-btn-hover-border-color: #581313;
  --fc-btn-focus-shadow-rgb: 88, 19, 19;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #581313;
  --fc-btn-active-border-color: #581313;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #581313;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #581313;
  --fc-gradient: none;
}
.btn-outline-dark-success {
  --fc-btn-color: #0a3622;
  --fc-btn-border-color: #0a3622;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #0a3622;
  --fc-btn-hover-border-color: #0a3622;
  --fc-btn-focus-shadow-rgb: 10, 54, 34;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #0a3622;
  --fc-btn-active-border-color: #0a3622;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #0a3622;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #0a3622;
  --fc-gradient: none;
}
.btn-outline-dark-secondary {
  --fc-btn-color: #ecf0ef;
  --fc-btn-border-color: #ecf0ef;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #ecf0ef;
  --fc-btn-hover-border-color: #ecf0ef;
  --fc-btn-focus-shadow-rgb: 236, 240, 239;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #ecf0ef;
  --fc-btn-active-border-color: #ecf0ef;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #ecf0ef;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #ecf0ef;
  --fc-gradient: none;
}
.btn-outline-indigo {
  --fc-btn-color: #6610f2;
  --fc-btn-border-color: #6610f2;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #6610f2;
  --fc-btn-hover-border-color: #6610f2;
  --fc-btn-focus-shadow-rgb: 102, 16, 242;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #6610f2;
  --fc-btn-active-border-color: #6610f2;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #6610f2;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #6610f2;
  --fc-gradient: none;
}
.btn-outline-red {
  --fc-btn-color: #db3030;
  --fc-btn-border-color: #db3030;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #db3030;
  --fc-btn-hover-border-color: #db3030;
  --fc-btn-focus-shadow-rgb: 219, 48, 48;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #db3030;
  --fc-btn-active-border-color: #db3030;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #db3030;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #db3030;
  --fc-gradient: none;
}
.btn-outline-pink {
  --fc-btn-color: #d63384;
  --fc-btn-border-color: #d63384;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #d63384;
  --fc-btn-hover-border-color: #d63384;
  --fc-btn-focus-shadow-rgb: 214, 51, 132;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #d63384;
  --fc-btn-active-border-color: #d63384;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #d63384;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #d63384;
  --fc-gradient: none;
}
.btn-outline-orange {
  --fc-btn-color: #fd7e14;
  --fc-btn-border-color: #fd7e14;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #fd7e14;
  --fc-btn-hover-border-color: #fd7e14;
  --fc-btn-focus-shadow-rgb: 253, 126, 20;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #fd7e14;
  --fc-btn-active-border-color: #fd7e14;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #fd7e14;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #fd7e14;
  --fc-gradient: none;
}
.btn-outline-teal {
  --fc-btn-color: #20c997;
  --fc-btn-border-color: #20c997;
  --fc-btn-hover-color: #000;
  --fc-btn-hover-bg: #20c997;
  --fc-btn-hover-border-color: #20c997;
  --fc-btn-focus-shadow-rgb: 32, 201, 151;
  --fc-btn-active-color: #000;
  --fc-btn-active-bg: #20c997;
  --fc-btn-active-border-color: #20c997;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #20c997;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #20c997;
  --fc-gradient: none;
}
.btn-outline-purple {
  --fc-btn-color: #6f42c1;
  --fc-btn-border-color: #6f42c1;
  --fc-btn-hover-color: #fff;
  --fc-btn-hover-bg: #6f42c1;
  --fc-btn-hover-border-color: #6f42c1;
  --fc-btn-focus-shadow-rgb: 111, 66, 193;
  --fc-btn-active-color: #fff;
  --fc-btn-active-bg: #6f42c1;
  --fc-btn-active-border-color: #6f42c1;
  --fc-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --fc-btn-disabled-color: #6f42c1;
  --fc-btn-disabled-bg: transparent;
  --fc-btn-disabled-border-color: #6f42c1;
  --fc-gradient: none;
}
.btn-link {
  --fc-btn-font-weight: 400;
  --fc-btn-color: var(--fc-link-color);
  --fc-btn-bg: transparent;
  --fc-btn-border-color: transparent;
  --fc-btn-hover-color: var(--fc-link-hover-color);
  --fc-btn-hover-border-color: transparent;
  --fc-btn-active-color: var(--fc-link-hover-color);
  --fc-btn-active-border-color: transparent;
  --fc-btn-disabled-color: #5c6c75;
  --fc-btn-disabled-border-color: transparent;
  --fc-btn-box-shadow: 0 0 0 #000;
  --fc-btn-focus-shadow-rgb: 47, 185, 47;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--fc-btn-color);
}
.btn-link:hover {
  color: var(--fc-btn-hover-color);
}
.btn-group-lg > .btn,
.btn-lg {
  --fc-btn-padding-y: 0.5rem;
  --fc-btn-padding-x: 1rem;
  --fc-btn-font-size: 1.09375rem;
  --fc-btn-border-radius: 0.75rem;
}
.btn-group-sm > .btn,
.btn-sm {
  --fc-btn-padding-y: 0.25rem;
  --fc-btn-padding-x: 0.5rem;
  --fc-btn-font-size: 0.765625rem;
  --fc-btn-border-radius: 0.25rem;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
/* .fade:not(.show) {
  opacity: 0;
} */
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  height: auto;
  transition: width 0.35s ease;
  width: 0;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}
.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle:after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: "";
  display: none;
}
.dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropdown-menu {
  --fc-dropdown-z-index: 1000;
  --fc-dropdown-min-width: 10rem;
  --fc-dropdown-padding-x: 0.5rem;
  --fc-dropdown-padding-y: 0.5rem;
  --fc-dropdown-spacer: 0.125rem;
  --fc-dropdown-font-size: 0.875rem;
  --fc-dropdown-color: var(--fc-gray-600);
  --fc-dropdown-bg: #fff;
  --fc-dropdown-border-color: transparent;
  --fc-dropdown-border-radius: 0.5rem;
  --fc-dropdown-border-width: 1px;
  --fc-dropdown-inner-border-radius: calc(0.5rem - 1px);
  --fc-dropdown-divider-bg: #ecf0ef;
  --fc-dropdown-divider-margin-y: 0.5rem;
  --fc-dropdown-box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  --fc-dropdown-link-color: #5c6c75;
  --fc-dropdown-link-hover-color: #3d4f58;
  --fc-dropdown-link-hover-bg: #f0f3f2;
  --fc-dropdown-link-active-color: #fff;
  --fc-dropdown-link-active-bg: #0aad0a;
  --fc-dropdown-link-disabled-color: #889397;
  --fc-dropdown-item-padding-x: 0.75rem;
  --fc-dropdown-item-padding-y: 0.25rem;
  --fc-dropdown-header-color: #5c6c75;
  --fc-dropdown-header-padding-x: 0.75rem;
  --fc-dropdown-header-padding-y: 0.5rem;
  background-clip: padding-box;
  background-color: var(--fc-dropdown-bg);
  border: var(--fc-dropdown-border-width) solid var(--fc-dropdown-border-color);
  border-radius: var(--fc-dropdown-border-radius);
  color: var(--fc-dropdown-color);
  display: none;
  font-size: var(--fc-dropdown-font-size);
  list-style: none;
  margin: 0;
  min-width: var(--fc-dropdown-min-width);
  padding: var(--fc-dropdown-padding-y) var(--fc-dropdown-padding-x);
  position: absolute;
  text-align: left;
  z-index: var(--fc-dropdown-z-index);
}
.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: var(--fc-dropdown-spacer);
  top: 100%;
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  bottom: 100%;
  margin-bottom: var(--fc-dropdown-spacer);
  margin-top: 0;
  top: auto;
}
.dropup .dropdown-toggle:after {
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0;
  content: "";
}
.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  left: 100%;
  margin-left: var(--fc-dropdown-spacer);
  margin-top: 0;
  right: auto;
  top: 0;
}
.dropend .dropdown-toggle:after {
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  border-right: 0;
  border-top: 0.3em solid transparent;
  content: "";
}
.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropend .dropdown-toggle:after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  left: auto;
  margin-right: var(--fc-dropdown-spacer);
  margin-top: 0;
  right: 100%;
  top: 0;
}
.dropstart .dropdown-toggle:after {
  content: "";
  display: inline-block;
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.dropstart .dropdown-toggle:before {
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-top: 0.3em solid transparent;
  content: "";
  vertical-align: 0.255em;
}
.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}
.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}
.dropdown-divider {
  border-top: 1px solid var(--fc-dropdown-divider-bg);
  height: 0;
  margin: var(--fc-dropdown-divider-margin-y) 0;
  opacity: 1;
  overflow: hidden;
}
.dropdown-item {
  border: 0;
  border-radius: var(--fc-dropdown-item-border-radius, 0);
  clear: both;
  color: var(--fc-dropdown-link-color);
  display: block;
  font-weight: 400;
  padding: var(--fc-dropdown-item-padding-y) var(--fc-dropdown-item-padding-x);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: var(--fc-dropdown-link-hover-bg);
  color: var(--fc-dropdown-link-hover-color);
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--fc-dropdown-link-active-bg);
  color: var(--fc-dropdown-link-active-color);
  text-decoration: none;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  background-color: transparent;
  color: var(--fc-dropdown-link-disabled-color);
  pointer-events: none;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  color: var(--fc-dropdown-header-color);
  display: block;
  font-size: 0.765625rem;
  margin-bottom: 0;
  padding: var(--fc-dropdown-header-padding-y)
    var(--fc-dropdown-header-padding-x);
  white-space: nowrap;
}
.dropdown-item-text {
  color: var(--fc-dropdown-link-color);
  display: block;
  padding: var(--fc-dropdown-item-padding-y) var(--fc-dropdown-item-padding-x);
}
.dropdown-menu-dark {
  --fc-dropdown-color: #dfe2e1;
  --fc-dropdown-bg: #21313c;
  --fc-dropdown-border-color: transparent;
  --fc-dropdown-box-shadow: ;
  --fc-dropdown-link-color: #dfe2e1;
  --fc-dropdown-link-hover-color: #fff;
  --fc-dropdown-divider-bg: #ecf0ef;
  --fc-dropdown-link-hover-bg: hsla(0, 0%, 100%, 0.15);
  --fc-dropdown-link-active-color: #fff;
  --fc-dropdown-link-active-bg: #0aad0a;
  --fc-dropdown-link-disabled-color: #889397;
  --fc-dropdown-header-color: #889397;
}
.btn-group,
.btn-group-vertical {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  flex: 1 1 auto;
  position: relative;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group {
  border-radius: 0.5rem;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > :not(.btn-check:first-child) + .btn {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.dropdown-toggle-split:after,
.dropend .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.btn-group-vertical {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  --fc-nav-link-padding-x: 1rem;
  --fc-nav-link-padding-y: 0.5rem;
  --fc-nav-link-font-size: 0.875rem;
  --fc-nav-link-font-weight: 400;
  --fc-nav-link-color: var(--fc-white);
  --fc-nav-link-hover-color: var(--fc-link-hover-color);
  --fc-nav-link-disabled-color: #c1c7c6;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.nav-link {
  background: none;
  border: 0;
  /* color: var(--fc-white); */
  color: #000;
  display: block;
  font-size: var(--fc-nav-link-font-size);
  font-weight: var(--fc-nav-link-font-weight);
  padding: var(--fc-nav-link-padding-y) var(--fc-nav-link-padding-x);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:focus,
.nav-link:hover {
  /* color: var(--fc-nav-link-hover-color); */
  color: #000;
}
.nav-link:focus-visible {
  box-shadow: 0 0 0 0.25rem rgba(10, 173, 10, 0.25);
  outline: 0;
}
.nav-link.disabled {
  color: var(--fc-nav-link-disabled-color);
  cursor: default;
  pointer-events: none;
}
.nav-tabs {
  --fc-nav-tabs-border-width: 1px;
  --fc-nav-tabs-border-color: #dfe2e1;
  --fc-nav-tabs-border-radius: 0.5rem;
  --fc-nav-tabs-link-hover-border-color: #ecf0ef #ecf0ef #dfe2e1;
  --fc-nav-tabs-link-active-color: #3d4f58;
  --fc-nav-tabs-link-active-bg: #fff;
  --fc-nav-tabs-link-active-border-color: #dfe2e1 #dfe2e1 #fff;
  border-bottom: var(--fc-nav-tabs-border-width) solid
    var(--fc-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  border: var(--fc-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--fc-nav-tabs-border-radius);
  border-top-right-radius: var(--fc-nav-tabs-border-radius);
  margin-bottom: calc(var(--fc-nav-tabs-border-width) * -1);
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: var(--fc-nav-tabs-link-hover-border-color);
  isolation: isolate;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--fc-nav-link-disabled-color);
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: var(--fc-nav-tabs-link-active-bg);
  border-color: var(--fc-nav-tabs-link-active-border-color);
  color: var(--fc-nav-tabs-link-active-color);
}
.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: calc(var(--fc-nav-tabs-border-width) * -1);
}
.nav-pills {
  --fc-nav-pills-border-radius: 0.5rem;
  --fc-nav-pills-link-active-color: #fff;
  --fc-nav-pills-link-active-bg: #0aad0a;
}
.nav-pills .nav-link {
  border-radius: var(--fc-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  background-color: transparent;
  border-color: transparent;
  color: var(--fc-nav-link-disabled-color);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--fc-nav-pills-link-active-bg);
  color: var(--fc-nav-pills-link-active-color);
}
.nav-underline {
  --fc-nav-underline-gap: 1rem;
  --fc-nav-underline-border-width: 0.125rem;
  --fc-nav-underline-link-active-color: var(--fc-emphasis-color);
  gap: var(--fc-nav-underline-gap);
}
.nav-underline .nav-link {
  border-bottom: var(--fc-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}
.nav-underline .nav-link:focus,
.nav-underline .nav-link:hover {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  border-bottom-color: currentcolor;
  color: var(--fc-nav-underline-link-active-color);
  font-weight: 700;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  --fc-navbar-padding-x: 0;
  --fc-navbar-padding-y: 0.5rem;
  --fc-navbar-color: #21313c;
  --fc-navbar-hover-color: #0aad0a;
  --fc-navbar-disabled-color: rgba(0, 0, 0, 0.3);
  --fc-navbar-active-color: rgba(0, 0, 0, 0.9);
  --fc-navbar-brand-padding-y: 0.325rem;
  --fc-navbar-brand-margin-end: 1rem;
  --fc-navbar-brand-font-size: 1.09375rem;
  --fc-navbar-brand-color: rgba(0, 0, 0, 0.9);
  --fc-navbar-brand-hover-color: rgba(0, 0, 0, 0.9);
  --fc-navbar-nav-link-padding-x: 1.5rem;
  --fc-navbar-toggler-padding-y: 0.25rem;
  --fc-navbar-toggler-padding-x: 0.75rem;
  --fc-navbar-toggler-font-size: 1.09375rem;
  --fc-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%2321313c' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --fc-navbar-toggler-border-color: rgba(0, 0, 0, 0.1);
  --fc-navbar-toggler-border-radius: 0.5rem;
  --fc-navbar-toggler-focus-width: 0.25rem;
  --fc-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--fc-navbar-padding-y) var(--fc-navbar-padding-x);
  position: relative;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
}
.navbar-brand {
  color: var(--fc-navbar-brand-color);
  font-size: var(--fc-navbar-brand-font-size);
  margin-right: var(--fc-navbar-brand-margin-end);
  padding-bottom: var(--fc-navbar-brand-padding-y);
  padding-top: var(--fc-navbar-brand-padding-y);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  color: var(--fc-navbar-brand-hover-color);
}
.navbar-nav {
  --fc-nav-link-padding-x: 0;
  --fc-nav-link-padding-y: 0.5rem;
  --fc-nav-link-font-size: 0.875rem;
  --fc-nav-link-font-weight: 500;
  --fc-nav-link-color: var(--fc-navbar-color);
  --fc-nav-link-hover-color: var(--fc-navbar-hover-color);
  --fc-nav-link-disabled-color: var(--fc-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  /* color: var(--fc-navbar-active-color); */
  color: #000;
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  color: var(--fc-navbar-color);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.navbar-text a,
.navbar-text a:focus,
.navbar-text a:hover {
  color: var(--fc-navbar-active-color);
}
.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}
.navbar-toggler {
  background-color: transparent;
  border: var(--fc-border-width) solid var(--fc-navbar-toggler-border-color);
  border-radius: var(--fc-navbar-toggler-border-radius);
  color: var(--fc-navbar-color);
  font-size: var(--fc-navbar-toggler-font-size);
  line-height: 1;
  padding: var(--fc-navbar-toggler-padding-y) var(--fc-navbar-toggler-padding-x);
  transition: var(--fc-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--fc-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}
.navbar-toggler-icon {
  background-image: var(--fc-navbar-toggler-icon-bg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
}
.navbar-nav-scroll {
  max-height: var(--fc-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: var(--fc-navbar-nav-link-padding-x);
    padding-right: var(--fc-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: var(--fc-navbar-nav-link-padding-x);
    padding-right: var(--fc-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: var(--fc-navbar-nav-link-padding-x);
    padding-right: var(--fc-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: var(--fc-navbar-nav-link-padding-x);
    padding-right: var(--fc-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: var(--fc-navbar-nav-link-padding-x);
    padding-right: var(--fc-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    background-color: transparent !important;
    border: 0 !important;
    flex-grow: 1;
    height: auto !important;
    position: static;
    transform: none !important;
    transition: none;
    visibility: visible !important;
    width: auto !important;
    z-index: auto;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-left: var(--fc-navbar-nav-link-padding-x);
  padding-right: var(--fc-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  background-color: transparent !important;
  border: 0 !important;
  flex-grow: 1;
  height: auto !important;
  position: static;
  transform: none !important;
  transition: none;
  visibility: visible !important;
  width: auto !important;
  z-index: auto;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  overflow-y: visible;
  padding: 0;
}
.navbar-dark {
  --fc-navbar-color: hsla(0, 0%, 100%, 0.85);
  --fc-navbar-hover-color: #fff;
  --fc-navbar-disabled-color: hsla(0, 0%, 100%, 0.25);
  --fc-navbar-active-color: #fff;
  --fc-navbar-brand-color: #fff;
  --fc-navbar-brand-hover-color: #fff;
  --fc-navbar-toggler-border-color: hsla(0, 0%, 100%, 0.1);
}
.navbar-dark,
[data-bs-theme="dark"] .navbar-toggler-icon {
  --fc-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.85)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.card {
  --fc-card-spacer-y: 1rem;
  --fc-card-spacer-x: 1rem;
  --fc-card-title-spacer-y: 0.5rem;
  --fc-card-title-color: ;
  --fc-card-subtitle-color: ;
  --fc-card-border-width: 1px;
  --fc-card-border-color: var(--fc-border-color-translucent);
  --fc-card-border-radius: 0.5rem;
  --fc-card-box-shadow: ;
  --fc-card-inner-border-radius: calc(0.5rem - 1px);
  --fc-card-cap-padding-y: 0.5rem;
  --fc-card-cap-padding-x: 1rem;
  --fc-card-cap-bg: var(--fc-card-bg);
  --fc-card-cap-color: ;
  --fc-card-height: ;
  --fc-card-color: ;
  --fc-card-bg: #fff;
  --fc-card-img-overlay-padding: 1rem;
  --fc-card-group-margin: 1rem;
  word-wrap: break-word;
  background-clip: border-box;
  background-color: var(--fc-card-bg);
  border: var(--fc-card-border-width) solid var(--fc-card-border-color);
  border-radius: var(--fc-card-border-radius);
  color: var(--fc-body-color);
  display: flex;
  flex-direction: column;
  height: var(--fc-card-height);
  min-width: 0;
  position: relative;
}
.card > hr {
  margin-left: 0;
  margin-right: 0;
}
.card > .list-group {
  border-bottom: inherit;
  border-top: inherit;
}
.card > .list-group:first-child {
  border-top-left-radius: var(--fc-card-inner-border-radius);
  border-top-right-radius: var(--fc-card-inner-border-radius);
  border-top-width: 0;
}
.card > .list-group:last-child {
  border-bottom-left-radius: var(--fc-card-inner-border-radius);
  border-bottom-right-radius: var(--fc-card-inner-border-radius);
  border-bottom-width: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  color: var(--fc-card-color);
  flex: 1 1 auto;
  padding: var(--fc-card-spacer-y) var(--fc-card-spacer-x);
}
.card-title {
  color: var(--fc-card-title-color);
  margin-bottom: var(--fc-card-title-spacer-y);
}
.card-subtitle {
  color: var(--fc-card-subtitle-color);
  margin-top: calc(var(--fc-card-title-spacer-y) * -0.5);
}
.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: var(--fc-card-spacer-x);
}
.card-header {
  background-color: var(--fc-card-cap-bg);
  border-bottom: var(--fc-card-border-width) solid var(--fc-card-border-color);
  color: var(--fc-card-cap-color);
  margin-bottom: 0;
  padding: var(--fc-card-cap-padding-y) var(--fc-card-cap-padding-x);
}
.card-header:first-child {
  border-radius: var(--fc-card-inner-border-radius)
    var(--fc-card-inner-border-radius) 0 0;
}
.card-footer {
  background-color: var(--fc-card-cap-bg);
  /* border-top: var(--fc-card-border-width) solid var(--fc-card-border-color); */
  color: var(--fc-card-cap-color);
  /* padding: var(--fc-card-cap-padding-y) var(--fc-card-cap-padding-x); */
}
/* .card-footer:last-child {
  border-radius: 0 0 var(--fc-card-inner-border-radius)
    var(--fc-card-inner-border-radius);
} */
.card-header-tabs {
  border-bottom: 0;
  margin-bottom: calc(var(--fc-card-cap-padding-y) * -1);
  margin-left: calc(var(--fc-card-cap-padding-x) * -0.5);
  margin-right: calc(var(--fc-card-cap-padding-x) * -0.5);
}
.card-header-tabs .nav-link.active {
  background-color: var(--fc-card-bg);
  border-bottom-color: var(--fc-card-bg);
}
.card-header-pills {
  margin-left: calc(var(--fc-card-cap-padding-x) * -0.5);
  margin-right: calc(var(--fc-card-cap-padding-x) * -0.5);
}
.card-img-overlay {
  border-radius: var(--fc-card-inner-border-radius);
  bottom: 0;
  left: 0;
  padding: var(--fc-card-img-overlay-padding);
  position: absolute;
  right: 0;
  top: 0;
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: var(--fc-card-inner-border-radius);
  border-top-right-radius: var(--fc-card-inner-border-radius);
}
.card-img,
.card-img-bottom {
  border-bottom-left-radius: var(--fc-card-inner-border-radius);
  border-bottom-right-radius: var(--fc-card-inner-border-radius);
}
.card-group > .card {
  margin-bottom: var(--fc-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion {
  --fc-accordion-color: var(--fc-gray-600);
  --fc-accordion-bg: #fff;
  --fc-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --fc-accordion-border-color: var(--fc-border-color);
  --fc-accordion-border-width: 1px;
  --fc-accordion-border-radius: 0rem;
  --fc-accordion-inner-border-radius: calc(0.5rem - 1px);
  --fc-accordion-btn-padding-x: 1.25rem;
  --fc-accordion-btn-padding-y: 30px;
  --fc-accordion-btn-color: var(--fc-gray-600);
  --fc-accordion-btn-bg: var(--fc-accordion-bg);
  --fc-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='var(--fc-gray-600)' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --fc-accordion-btn-icon-width: 1.25rem;
  --fc-accordion-btn-icon-transform: rotate(-180deg);
  --fc-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --fc-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23099c09' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --fc-accordion-btn-focus-border-color: #85d685;
  --fc-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(10, 173, 10, 0.25);
  --fc-accordion-body-padding-x: 1.25rem;
  --fc-accordion-body-padding-y: 1rem;
  --fc-accordion-active-color: #099c09;
  --fc-accordion-active-bg: #e7f7e7;
}
.accordion-button {
  align-items: center;
  background-color: var(--fc-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  color: var(--fc-black);
  display: flex;
  font-size: 1rem;
  font-family: var(--fc-font-bold);
  text-transform: uppercase;
  overflow-anchor: none;
  padding: var(--fc-accordion-btn-padding-y) var(--fc-accordion-btn-padding-x);
  position: relative;
  text-align: left;
  transition: var(--fc-accordion-transition);
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  background-color: var(--fc-accordion-active-bg);
  box-shadow: inset 0 calc(var(--fc-accordion-border-width) * -1) 0
    var(--fc-accordion-border-color);
  color: var(--fc-accordion-active-color);
}
.accordion-button:not(.collapsed):after {
  background-image: var(--fc-accordion-btn-active-icon);
  transform: var(--fc-accordion-btn-icon-transform);
}
.accordion-button:after {
  background-image: var(--fc-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--fc-accordion-btn-icon-width);
  content: "";
  flex-shrink: 0;
  height: var(--fc-accordion-btn-icon-width);
  margin-left: auto;
  transition: var(--fc-accordion-btn-icon-transition);
  width: var(--fc-accordion-btn-icon-width);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  border-color: var(--fc-accordion-btn-focus-border-color);
  box-shadow: var(--fc-accordion-btn-focus-box-shadow);
  outline: 0;
  z-index: 3;
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  background-color: var(--fc-accordion-bg);
  border: var(--fc-accordion-border-width) solid
    var(--fc-accordion-border-color);
  color: var(--fc-accordion-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--fc-accordion-border-radius);
  border-top-right-radius: var(--fc-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--fc-accordion-inner-border-radius);
  border-top-right-radius: var(--fc-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-left-radius: var(--fc-accordion-border-radius);
  border-bottom-right-radius: var(--fc-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: var(--fc-accordion-inner-border-radius);
  border-bottom-right-radius: var(--fc-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: var(--fc-accordion-border-radius);
  border-bottom-right-radius: var(--fc-accordion-border-radius);
}
.accordion-body {
  padding: var(--fc-accordion-body-padding-y) var(--fc-accordion-body-padding-x);
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}
[data-bs-theme="dark"] .accordion-button:after {
  --fc-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236cce6c' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  --fc-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236cce6c' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.breadcrumb {
  --fc-breadcrumb-padding-x: 0;
  --fc-breadcrumb-padding-y: 0;
  --fc-breadcrumb-margin-bottom: 6%;
  --fc-breadcrumb-bg: ;
  --fc-breadcrumb-border-radius: ;
  --fc-breadcrumb-divider-color: #dfe2e1;
  --fc-breadcrumb-item-padding-x: 1rem;
  --fc-breadcrumb-item-active-color: var(--fc-gray-500);
  background-color: var(--fc-breadcrumb-bg);
  border-radius: var(--fc-breadcrumb-border-radius);
  display: flex;
  flex-wrap: wrap;
  font-size: var(--fc-breadcrumb-font-size);
  list-style: none;
  margin-bottom: var(--fc-breadcrumb-margin-bottom);
  margin-top: var(--fc-breadcrumb-margin-bottom);
  padding: var(--fc-breadcrumb-padding-y) var(--fc-breadcrumb-padding-x);
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--fc-breadcrumb-item-padding-x);
  color: var(--fc-black);
}
.breadcrumb-item + .breadcrumb-item:before {
  color: var(--fc-black);
  content: var(--fc-breadcrumb-divider, "/");
  float: left;
  padding-right: var(--fc-breadcrumb-item-padding-x);
}
.breadcrumb-item.active {
  color: var(--fc-breadcrumb-item-active-color);
}
.breadcrumb-item a {
  color: var(--fc-black);
}
.pagination {
  --fc-pagination-padding-x: 0.925rem;
  --fc-pagination-padding-y: 0.5rem;
  --fc-pagination-font-size: 0.875rem;
  --fc-pagination-color: var(--fc-gray-500);
  --fc-pagination-bg: var(--fc-card-bg);
  --fc-pagination-border-width: 1px;
  --fc-pagination-border-color: var(--fc-border-color);
  --fc-pagination-border-radius: 0.5rem;
  --fc-pagination-hover-color: var(--fc-white);
  --fc-pagination-hover-bg: #0aad0a;
  --fc-pagination-hover-border-color: #0aad0a;
  --fc-pagination-focus-color: var(--fc-white);
  --fc-pagination-focus-bg: #0aad0a;
  --fc-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(10, 173, 10, 0.25);
  --fc-pagination-active-color: #fff;
  --fc-pagination-active-bg: #0aad0a;
  --fc-pagination-active-border-color: #0aad0a;
  --fc-pagination-disabled-color: var(--fc-gray-600);
  --fc-pagination-disabled-bg: var(--fc-gray-200);
  --fc-pagination-disabled-border-color: var(--fc-gray-200);
  display: flex;
  list-style: none;
  padding-left: 0;
}
.page-link {
  background-color: var(--fc-pagination-bg);
  border: var(--fc-pagination-border-width) solid
    var(--fc-pagination-border-color);
  color: var(--fc-pagination-color);
  display: block;
  font-size: var(--fc-pagination-font-size);
  padding: var(--fc-pagination-padding-y) var(--fc-pagination-padding-x);
  position: relative;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  background-color: var(--fc-pagination-hover-bg);
  border-color: var(--fc-pagination-hover-border-color);
  color: var(--fc-pagination-hover-color);
  z-index: 2;
}
.page-link:focus {
  background-color: var(--fc-pagination-focus-bg);
  box-shadow: var(--fc-pagination-focus-box-shadow);
  color: var(--fc-pagination-focus-color);
  outline: 0;
  z-index: 3;
}
.active > .page-link,
.page-link.active {
  background-color: var(--fc-pagination-active-bg);
  border-color: var(--fc-pagination-active-border-color);
  color: var(--fc-pagination-active-color);
  z-index: 3;
}
.disabled > .page-link,
.page-link.disabled {
  background-color: var(--fc-pagination-disabled-bg);
  border-color: var(--fc-pagination-disabled-border-color);
  color: var(--fc-pagination-disabled-color);
  pointer-events: none;
}
.pagination-lg {
  --fc-pagination-padding-x: 1.5rem;
  --fc-pagination-padding-y: 0.75rem;
  --fc-pagination-font-size: 1.09375rem;
  --fc-pagination-border-radius: 0.75rem;
}
.pagination-sm {
  --fc-pagination-padding-x: 0.5rem;
  --fc-pagination-padding-y: 0.25rem;
  --fc-pagination-font-size: 0.765625rem;
  --fc-pagination-border-radius: 0.25rem;
}
.badge {
  --fc-badge-padding-x: 0.65em;
  --fc-badge-padding-y: 0.35em;
  --fc-badge-font-size: 0.75em;
  --fc-badge-font-weight: 700;
  --fc-badge-color: #fff;
  --fc-badge-border-radius: 0.25rem;
  border-radius: var(--fc-badge-border-radius);
  color: var(--fc-badge-color);
  display: inline-block;
  font-size: var(--fc-badge-font-size);
  font-weight: var(--fc-badge-font-weight);
  line-height: 1;
  padding: var(--fc-badge-padding-y) var(--fc-badge-padding-x);
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.alert {
  --fc-alert-bg: transparent;
  --fc-alert-padding-x: 1rem;
  --fc-alert-padding-y: 1rem;
  --fc-alert-margin-bottom: 1rem;
  --fc-alert-color: inherit;
  --fc-alert-border-color: transparent;
  --fc-alert-border: 1px solid var(--fc-alert-border-color);
  --fc-alert-border-radius: 0.5rem;
  --fc-alert-link-color: inherit;
  background-color: var(--fc-alert-bg);
  border: var(--fc-alert-border);
  border-radius: var(--fc-alert-border-radius);
  color: var(--fc-alert-color);
  margin-bottom: var(--fc-alert-margin-bottom);
  padding: var(--fc-alert-padding-y) var(--fc-alert-padding-x);
  position: relative;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  color: var(--fc-alert-link-color);
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  padding: 1.25rem 1rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.alert-primary {
  --fc-alert-color: var(--fc-primary-text-emphasis);
  --fc-alert-bg: var(--fc-primary-bg-subtle);
  --fc-alert-border-color: var(--fc-primary-border-subtle);
  --fc-alert-link-color: var(--fc-primary-text-emphasis);
}
.alert-secondary {
  --fc-alert-color: var(--fc-secondary-text-emphasis);
  --fc-alert-bg: var(--fc-secondary-bg-subtle);
  --fc-alert-border-color: var(--fc-secondary-border-subtle);
  --fc-alert-link-color: var(--fc-secondary-text-emphasis);
}
.alert-success {
  --fc-alert-color: var(--fc-success-text-emphasis);
  --fc-alert-bg: var(--fc-success-bg-subtle);
  --fc-alert-border-color: var(--fc-success-border-subtle);
  --fc-alert-link-color: var(--fc-success-text-emphasis);
}
.alert-info {
  --fc-alert-color: var(--fc-info-text-emphasis);
  --fc-alert-bg: var(--fc-info-bg-subtle);
  --fc-alert-border-color: var(--fc-info-border-subtle);
  --fc-alert-link-color: var(--fc-info-text-emphasis);
}
.alert-warning {
  --fc-alert-color: var(--fc-warning-text-emphasis);
  --fc-alert-bg: var(--fc-warning-bg-subtle);
  --fc-alert-border-color: var(--fc-warning-border-subtle);
  --fc-alert-link-color: var(--fc-warning-text-emphasis);
}
.alert-danger {
  --fc-alert-color: var(--fc-danger-text-emphasis);
  --fc-alert-bg: var(--fc-danger-bg-subtle);
  --fc-alert-border-color: var(--fc-danger-border-subtle);
  --fc-alert-link-color: var(--fc-danger-text-emphasis);
}
.alert-light {
  --fc-alert-color: var(--fc-light-text-emphasis);
  --fc-alert-bg: var(--fc-light-bg-subtle);
  --fc-alert-border-color: var(--fc-light-border-subtle);
  --fc-alert-link-color: var(--fc-light-text-emphasis);
}
.alert-dark {
  --fc-alert-color: var(--fc-dark-text-emphasis);
  --fc-alert-bg: var(--fc-dark-bg-subtle);
  --fc-alert-border-color: var(--fc-dark-border-subtle);
  --fc-alert-link-color: var(--fc-dark-text-emphasis);
}
.alert-gray-400 {
  --fc-alert-color: var(--fc-gray-400-text-emphasis);
  --fc-alert-bg: var(--fc-gray-400-bg-subtle);
  --fc-alert-border-color: var(--fc-gray-400-border-subtle);
  --fc-alert-link-color: var(--fc-gray-400-text-emphasis);
}
.alert-white {
  --fc-alert-color: var(--fc-white-text-emphasis);
  --fc-alert-bg: var(--fc-white-bg-subtle);
  --fc-alert-border-color: var(--fc-white-border-subtle);
  --fc-alert-link-color: var(--fc-white-text-emphasis);
}
.alert-light-primary {
  --fc-alert-color: var(--fc-light-primary-text-emphasis);
  --fc-alert-bg: var(--fc-light-primary-bg-subtle);
  --fc-alert-border-color: var(--fc-light-primary-border-subtle);
  --fc-alert-link-color: var(--fc-light-primary-text-emphasis);
}
.alert-light-secondary {
  --fc-alert-color: var(--fc-light-secondary-text-emphasis);
  --fc-alert-bg: var(--fc-light-secondary-bg-subtle);
  --fc-alert-border-color: var(--fc-light-secondary-border-subtle);
  --fc-alert-link-color: var(--fc-light-secondary-text-emphasis);
}
.alert-light-danger {
  --fc-alert-color: var(--fc-light-danger-text-emphasis);
  --fc-alert-bg: var(--fc-light-danger-bg-subtle);
  --fc-alert-border-color: var(--fc-light-danger-border-subtle);
  --fc-alert-link-color: var(--fc-light-danger-text-emphasis);
}
.alert-light-info {
  --fc-alert-color: var(--fc-light-info-text-emphasis);
  --fc-alert-bg: var(--fc-light-info-bg-subtle);
  --fc-alert-border-color: var(--fc-light-info-border-subtle);
  --fc-alert-link-color: var(--fc-light-info-text-emphasis);
}
.alert-light-warning {
  --fc-alert-color: var(--fc-light-warning-text-emphasis);
  --fc-alert-bg: var(--fc-light-warning-bg-subtle);
  --fc-alert-border-color: var(--fc-light-warning-border-subtle);
  --fc-alert-link-color: var(--fc-light-warning-text-emphasis);
}
.alert-light-success {
  --fc-alert-color: var(--fc-light-success-text-emphasis);
  --fc-alert-bg: var(--fc-light-success-bg-subtle);
  --fc-alert-border-color: var(--fc-light-success-border-subtle);
  --fc-alert-link-color: var(--fc-light-success-text-emphasis);
}
.alert-dark-primary {
  --fc-alert-color: var(--fc-dark-primary-text-emphasis);
  --fc-alert-bg: var(--fc-dark-primary-bg-subtle);
  --fc-alert-border-color: var(--fc-dark-primary-border-subtle);
  --fc-alert-link-color: var(--fc-dark-primary-text-emphasis);
}
.alert-dark-warning {
  --fc-alert-color: var(--fc-dark-warning-text-emphasis);
  --fc-alert-bg: var(--fc-dark-warning-bg-subtle);
  --fc-alert-border-color: var(--fc-dark-warning-border-subtle);
  --fc-alert-link-color: var(--fc-dark-warning-text-emphasis);
}
.alert-dark-info {
  --fc-alert-color: var(--fc-dark-info-text-emphasis);
  --fc-alert-bg: var(--fc-dark-info-bg-subtle);
  --fc-alert-border-color: var(--fc-dark-info-border-subtle);
  --fc-alert-link-color: var(--fc-dark-info-text-emphasis);
}
.alert-dark-danger {
  --fc-alert-color: var(--fc-dark-danger-text-emphasis);
  --fc-alert-bg: var(--fc-dark-danger-bg-subtle);
  --fc-alert-border-color: var(--fc-dark-danger-border-subtle);
  --fc-alert-link-color: var(--fc-dark-danger-text-emphasis);
}
.alert-dark-success {
  --fc-alert-color: var(--fc-dark-success-text-emphasis);
  --fc-alert-bg: var(--fc-dark-success-bg-subtle);
  --fc-alert-border-color: var(--fc-dark-success-border-subtle);
  --fc-alert-link-color: var(--fc-dark-success-text-emphasis);
}
.alert-dark-secondary {
  --fc-alert-color: var(--fc-dark-secondary-text-emphasis);
  --fc-alert-bg: var(--fc-dark-secondary-bg-subtle);
  --fc-alert-border-color: var(--fc-dark-secondary-border-subtle);
  --fc-alert-link-color: var(--fc-dark-secondary-text-emphasis);
}
.alert-indigo {
  --fc-alert-color: var(--fc-indigo-text-emphasis);
  --fc-alert-bg: var(--fc-indigo-bg-subtle);
  --fc-alert-border-color: var(--fc-indigo-border-subtle);
  --fc-alert-link-color: var(--fc-indigo-text-emphasis);
}
.alert-red {
  --fc-alert-color: var(--fc-red-text-emphasis);
  --fc-alert-bg: var(--fc-red-bg-subtle);
  --fc-alert-border-color: var(--fc-red-border-subtle);
  --fc-alert-link-color: var(--fc-red-text-emphasis);
}
.alert-pink {
  --fc-alert-color: var(--fc-pink-text-emphasis);
  --fc-alert-bg: var(--fc-pink-bg-subtle);
  --fc-alert-border-color: var(--fc-pink-border-subtle);
  --fc-alert-link-color: var(--fc-pink-text-emphasis);
}
.alert-orange {
  --fc-alert-color: var(--fc-orange-text-emphasis);
  --fc-alert-bg: var(--fc-orange-bg-subtle);
  --fc-alert-border-color: var(--fc-orange-border-subtle);
  --fc-alert-link-color: var(--fc-orange-text-emphasis);
}
.alert-teal {
  --fc-alert-color: var(--fc-teal-text-emphasis);
  --fc-alert-bg: var(--fc-teal-bg-subtle);
  --fc-alert-border-color: var(--fc-teal-border-subtle);
  --fc-alert-link-color: var(--fc-teal-text-emphasis);
}
.alert-purple {
  --fc-alert-color: var(--fc-purple-text-emphasis);
  --fc-alert-bg: var(--fc-purple-bg-subtle);
  --fc-alert-border-color: var(--fc-purple-border-subtle);
  --fc-alert-link-color: var(--fc-purple-text-emphasis);
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --fc-progress-height: 11px;
  --fc-progress-font-size: 0.65625rem;
  --fc-progress-bg: #ecf0ef;
  --fc-progress-border-radius: 0.5rem;
  --fc-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --fc-progress-bar-color: #fff;
  --fc-progress-bar-bg: rgba(2, 2, 2, 1);
  --fc-progress-bar-transition: width 0.6s ease;
  background-color: var(--fc-progress-bg);
  border-radius: var(--fc-progress-border-radius);
  display: flex;
  font-size: var(--fc-progress-font-size);
  height: var(--fc-progress-height);
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
}
.progress-bar {
  background-color: var(--fc-progress-bar-bg);
  color: var(--fc-progress-bar-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  transition: var(--fc-progress-bar-transition);
  white-space: nowrap;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-size: var(--fc-progress-height) var(--fc-progress-height);
}
.progress-stacked > .progress {
  overflow: visible;
}
.progress-stacked > .progress > .progress-bar {
  width: 100%;
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}
.list-group {
  --fc-list-group-color: #001e2b;
  --fc-list-group-bg: #fff;
  --fc-list-group-border-color: var(--fc-gray-300);
  --fc-list-group-border-width: 1px;
  --fc-list-group-border-radius: 0.5rem;
  --fc-list-group-item-padding-x: 1rem;
  --fc-list-group-item-padding-y: 0.5rem;
  --fc-list-group-action-color: #3d4f58;
  --fc-list-group-action-hover-color: #3d4f58;
  --fc-list-group-action-hover-bg: var(--fc-gray-100);
  --fc-list-group-action-active-color: var(--fc-dark);
  --fc-list-group-action-active-bg: var(--fc-gray-200);
  --fc-list-group-disabled-color: #5c6c75;
  --fc-list-group-disabled-bg: #fff;
  --fc-list-group-active-color: var(--fc-dark);
  --fc-list-group-active-bg: var(--fc-gray-200);
  --fc-list-group-active-border-color: var(--fc-gray-200);
  border-radius: var(--fc-list-group-border-radius);
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}
.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}
.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  color: var(--fc-list-group-action-color);
  text-align: inherit;
  width: 100%;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  background-color: var(--fc-list-group-action-hover-bg);
  color: var(--fc-list-group-action-hover-color);
  text-decoration: none;
  z-index: 1;
}
.list-group-item-action:active {
  background-color: var(--fc-list-group-action-active-bg);
  color: var(--fc-list-group-action-active-color);
}
.list-group-item {
  background-color: var(--fc-list-group-bg);
  border: var(--fc-list-group-border-width) solid
    var(--fc-list-group-border-color);
  color: var(--fc-list-group-color);
  display: block;
  padding: var(--fc-list-group-item-padding-y)
    var(--fc-list-group-item-padding-x);
  position: relative;
  text-decoration: none;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  background-color: var(--fc-list-group-disabled-bg);
  color: var(--fc-list-group-disabled-color);
  pointer-events: none;
}
.list-group-item.active {
  background-color: var(--fc-list-group-active-bg);
  border-color: var(--fc-list-group-active-border-color);
  color: var(--fc-list-group-active-color);
  z-index: 2;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  border-top-width: var(--fc-list-group-border-width);
  margin-top: calc(var(--fc-list-group-border-width) * -1);
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--fc-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--fc-list-group-border-radius);
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-left-width: 0;
  border-top-width: var(--fc-list-group-border-width);
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: var(--fc-list-group-border-width);
  margin-left: calc(var(--fc-list-group-border-width) * -1);
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--fc-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--fc-list-group-border-radius);
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--fc-list-group-border-width);
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: var(--fc-list-group-border-width);
    margin-left: calc(var(--fc-list-group-border-width) * -1);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--fc-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--fc-list-group-border-radius);
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--fc-list-group-border-width);
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: var(--fc-list-group-border-width);
    margin-left: calc(var(--fc-list-group-border-width) * -1);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--fc-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--fc-list-group-border-radius);
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--fc-list-group-border-width);
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: var(--fc-list-group-border-width);
    margin-left: calc(var(--fc-list-group-border-width) * -1);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--fc-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--fc-list-group-border-radius);
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--fc-list-group-border-width);
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: var(--fc-list-group-border-width);
    margin-left: calc(var(--fc-list-group-border-width) * -1);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--fc-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--fc-list-group-border-radius);
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-left-width: 0;
    border-top-width: var(--fc-list-group-border-width);
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    border-left-width: var(--fc-list-group-border-width);
    margin-left: calc(var(--fc-list-group-border-width) * -1);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--fc-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  --fc-list-group-color: var(--fc-primary-text-emphasis);
  --fc-list-group-bg: var(--fc-primary-bg-subtle);
  --fc-list-group-border-color: var(--fc-primary-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-primary-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-primary-border-subtle);
  --fc-list-group-active-color: var(--fc-primary-bg-subtle);
  --fc-list-group-active-bg: var(--fc-primary-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-primary-text-emphasis);
}
.list-group-item-secondary {
  --fc-list-group-color: var(--fc-secondary-text-emphasis);
  --fc-list-group-bg: var(--fc-secondary-bg-subtle);
  --fc-list-group-border-color: var(--fc-secondary-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-secondary-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-secondary-border-subtle);
  --fc-list-group-active-color: var(--fc-secondary-bg-subtle);
  --fc-list-group-active-bg: var(--fc-secondary-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-secondary-text-emphasis);
}
.list-group-item-success {
  --fc-list-group-color: var(--fc-success-text-emphasis);
  --fc-list-group-bg: var(--fc-success-bg-subtle);
  --fc-list-group-border-color: var(--fc-success-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-success-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-success-border-subtle);
  --fc-list-group-active-color: var(--fc-success-bg-subtle);
  --fc-list-group-active-bg: var(--fc-success-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-success-text-emphasis);
}
.list-group-item-info {
  --fc-list-group-color: var(--fc-info-text-emphasis);
  --fc-list-group-bg: var(--fc-info-bg-subtle);
  --fc-list-group-border-color: var(--fc-info-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-info-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-info-border-subtle);
  --fc-list-group-active-color: var(--fc-info-bg-subtle);
  --fc-list-group-active-bg: var(--fc-info-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-info-text-emphasis);
}
.list-group-item-warning {
  --fc-list-group-color: var(--fc-warning-text-emphasis);
  --fc-list-group-bg: var(--fc-warning-bg-subtle);
  --fc-list-group-border-color: var(--fc-warning-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-warning-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-warning-border-subtle);
  --fc-list-group-active-color: var(--fc-warning-bg-subtle);
  --fc-list-group-active-bg: var(--fc-warning-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-warning-text-emphasis);
}
.list-group-item-danger {
  --fc-list-group-color: var(--fc-danger-text-emphasis);
  --fc-list-group-bg: var(--fc-danger-bg-subtle);
  --fc-list-group-border-color: var(--fc-danger-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-danger-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-danger-border-subtle);
  --fc-list-group-active-color: var(--fc-danger-bg-subtle);
  --fc-list-group-active-bg: var(--fc-danger-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-danger-text-emphasis);
}
.list-group-item-light {
  --fc-list-group-color: var(--fc-light-text-emphasis);
  --fc-list-group-bg: var(--fc-light-bg-subtle);
  --fc-list-group-border-color: var(--fc-light-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-light-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-light-border-subtle);
  --fc-list-group-active-color: var(--fc-light-bg-subtle);
  --fc-list-group-active-bg: var(--fc-light-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-light-text-emphasis);
}
.list-group-item-dark {
  --fc-list-group-color: var(--fc-dark-text-emphasis);
  --fc-list-group-bg: var(--fc-dark-bg-subtle);
  --fc-list-group-border-color: var(--fc-dark-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-dark-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-dark-border-subtle);
  --fc-list-group-active-color: var(--fc-dark-bg-subtle);
  --fc-list-group-active-bg: var(--fc-dark-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-dark-text-emphasis);
}
.list-group-item-gray-400 {
  --fc-list-group-color: var(--fc-gray-400-text-emphasis);
  --fc-list-group-bg: var(--fc-gray-400-bg-subtle);
  --fc-list-group-border-color: var(--fc-gray-400-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-gray-400-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-gray-400-border-subtle);
  --fc-list-group-active-color: var(--fc-gray-400-bg-subtle);
  --fc-list-group-active-bg: var(--fc-gray-400-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-gray-400-text-emphasis);
}
.list-group-item-white {
  --fc-list-group-color: var(--fc-white-text-emphasis);
  --fc-list-group-bg: var(--fc-white-bg-subtle);
  --fc-list-group-border-color: var(--fc-white-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-white-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-white-border-subtle);
  --fc-list-group-active-color: var(--fc-white-bg-subtle);
  --fc-list-group-active-bg: var(--fc-white-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-white-text-emphasis);
}
.list-group-item-light-primary {
  --fc-list-group-color: var(--fc-light-primary-text-emphasis);
  --fc-list-group-bg: var(--fc-light-primary-bg-subtle);
  --fc-list-group-border-color: var(--fc-light-primary-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-light-primary-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-light-primary-border-subtle);
  --fc-list-group-active-color: var(--fc-light-primary-bg-subtle);
  --fc-list-group-active-bg: var(--fc-light-primary-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-light-primary-text-emphasis);
}
.list-group-item-light-secondary {
  --fc-list-group-color: var(--fc-light-secondary-text-emphasis);
  --fc-list-group-bg: var(--fc-light-secondary-bg-subtle);
  --fc-list-group-border-color: var(--fc-light-secondary-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-light-secondary-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-light-secondary-border-subtle);
  --fc-list-group-active-color: var(--fc-light-secondary-bg-subtle);
  --fc-list-group-active-bg: var(--fc-light-secondary-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-light-secondary-text-emphasis);
}
.list-group-item-light-danger {
  --fc-list-group-color: var(--fc-light-danger-text-emphasis);
  --fc-list-group-bg: var(--fc-light-danger-bg-subtle);
  --fc-list-group-border-color: var(--fc-light-danger-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-light-danger-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-light-danger-border-subtle);
  --fc-list-group-active-color: var(--fc-light-danger-bg-subtle);
  --fc-list-group-active-bg: var(--fc-light-danger-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-light-danger-text-emphasis);
}
.list-group-item-light-info {
  --fc-list-group-color: var(--fc-light-info-text-emphasis);
  --fc-list-group-bg: var(--fc-light-info-bg-subtle);
  --fc-list-group-border-color: var(--fc-light-info-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-light-info-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-light-info-border-subtle);
  --fc-list-group-active-color: var(--fc-light-info-bg-subtle);
  --fc-list-group-active-bg: var(--fc-light-info-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-light-info-text-emphasis);
}
.list-group-item-light-warning {
  --fc-list-group-color: var(--fc-light-warning-text-emphasis);
  --fc-list-group-bg: var(--fc-light-warning-bg-subtle);
  --fc-list-group-border-color: var(--fc-light-warning-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-light-warning-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-light-warning-border-subtle);
  --fc-list-group-active-color: var(--fc-light-warning-bg-subtle);
  --fc-list-group-active-bg: var(--fc-light-warning-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-light-warning-text-emphasis);
}
.list-group-item-light-success {
  --fc-list-group-color: var(--fc-light-success-text-emphasis);
  --fc-list-group-bg: var(--fc-light-success-bg-subtle);
  --fc-list-group-border-color: var(--fc-light-success-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-light-success-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-light-success-border-subtle);
  --fc-list-group-active-color: var(--fc-light-success-bg-subtle);
  --fc-list-group-active-bg: var(--fc-light-success-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-light-success-text-emphasis);
}
.list-group-item-dark-primary {
  --fc-list-group-color: var(--fc-dark-primary-text-emphasis);
  --fc-list-group-bg: var(--fc-dark-primary-bg-subtle);
  --fc-list-group-border-color: var(--fc-dark-primary-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-dark-primary-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-dark-primary-border-subtle);
  --fc-list-group-active-color: var(--fc-dark-primary-bg-subtle);
  --fc-list-group-active-bg: var(--fc-dark-primary-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-dark-primary-text-emphasis);
}
.list-group-item-dark-warning {
  --fc-list-group-color: var(--fc-dark-warning-text-emphasis);
  --fc-list-group-bg: var(--fc-dark-warning-bg-subtle);
  --fc-list-group-border-color: var(--fc-dark-warning-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-dark-warning-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-dark-warning-border-subtle);
  --fc-list-group-active-color: var(--fc-dark-warning-bg-subtle);
  --fc-list-group-active-bg: var(--fc-dark-warning-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-dark-warning-text-emphasis);
}
.list-group-item-dark-info {
  --fc-list-group-color: var(--fc-dark-info-text-emphasis);
  --fc-list-group-bg: var(--fc-dark-info-bg-subtle);
  --fc-list-group-border-color: var(--fc-dark-info-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-dark-info-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-dark-info-border-subtle);
  --fc-list-group-active-color: var(--fc-dark-info-bg-subtle);
  --fc-list-group-active-bg: var(--fc-dark-info-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-dark-info-text-emphasis);
}
.list-group-item-dark-danger {
  --fc-list-group-color: var(--fc-dark-danger-text-emphasis);
  --fc-list-group-bg: var(--fc-dark-danger-bg-subtle);
  --fc-list-group-border-color: var(--fc-dark-danger-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-dark-danger-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-dark-danger-border-subtle);
  --fc-list-group-active-color: var(--fc-dark-danger-bg-subtle);
  --fc-list-group-active-bg: var(--fc-dark-danger-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-dark-danger-text-emphasis);
}
.list-group-item-dark-success {
  --fc-list-group-color: var(--fc-dark-success-text-emphasis);
  --fc-list-group-bg: var(--fc-dark-success-bg-subtle);
  --fc-list-group-border-color: var(--fc-dark-success-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-dark-success-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-dark-success-border-subtle);
  --fc-list-group-active-color: var(--fc-dark-success-bg-subtle);
  --fc-list-group-active-bg: var(--fc-dark-success-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-dark-success-text-emphasis);
}
.list-group-item-dark-secondary {
  --fc-list-group-color: var(--fc-dark-secondary-text-emphasis);
  --fc-list-group-bg: var(--fc-dark-secondary-bg-subtle);
  --fc-list-group-border-color: var(--fc-dark-secondary-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-dark-secondary-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-dark-secondary-border-subtle);
  --fc-list-group-active-color: var(--fc-dark-secondary-bg-subtle);
  --fc-list-group-active-bg: var(--fc-dark-secondary-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-dark-secondary-text-emphasis);
}
.list-group-item-indigo {
  --fc-list-group-color: var(--fc-indigo-text-emphasis);
  --fc-list-group-bg: var(--fc-indigo-bg-subtle);
  --fc-list-group-border-color: var(--fc-indigo-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-indigo-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-indigo-border-subtle);
  --fc-list-group-active-color: var(--fc-indigo-bg-subtle);
  --fc-list-group-active-bg: var(--fc-indigo-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-indigo-text-emphasis);
}
.list-group-item-red {
  --fc-list-group-color: var(--fc-red-text-emphasis);
  --fc-list-group-bg: var(--fc-red-bg-subtle);
  --fc-list-group-border-color: var(--fc-red-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-red-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-red-border-subtle);
  --fc-list-group-active-color: var(--fc-red-bg-subtle);
  --fc-list-group-active-bg: var(--fc-red-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-red-text-emphasis);
}
.list-group-item-pink {
  --fc-list-group-color: var(--fc-pink-text-emphasis);
  --fc-list-group-bg: var(--fc-pink-bg-subtle);
  --fc-list-group-border-color: var(--fc-pink-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-pink-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-pink-border-subtle);
  --fc-list-group-active-color: var(--fc-pink-bg-subtle);
  --fc-list-group-active-bg: var(--fc-pink-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-pink-text-emphasis);
}
.list-group-item-orange {
  --fc-list-group-color: var(--fc-orange-text-emphasis);
  --fc-list-group-bg: var(--fc-orange-bg-subtle);
  --fc-list-group-border-color: var(--fc-orange-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-orange-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-orange-border-subtle);
  --fc-list-group-active-color: var(--fc-orange-bg-subtle);
  --fc-list-group-active-bg: var(--fc-orange-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-orange-text-emphasis);
}
.list-group-item-teal {
  --fc-list-group-color: var(--fc-teal-text-emphasis);
  --fc-list-group-bg: var(--fc-teal-bg-subtle);
  --fc-list-group-border-color: var(--fc-teal-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-teal-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-teal-border-subtle);
  --fc-list-group-active-color: var(--fc-teal-bg-subtle);
  --fc-list-group-active-bg: var(--fc-teal-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-teal-text-emphasis);
}
.list-group-item-purple {
  --fc-list-group-color: var(--fc-purple-text-emphasis);
  --fc-list-group-bg: var(--fc-purple-bg-subtle);
  --fc-list-group-border-color: var(--fc-purple-border-subtle);
  --fc-list-group-action-hover-color: var(--fc-emphasis-color);
  --fc-list-group-action-hover-bg: var(--fc-purple-border-subtle);
  --fc-list-group-action-active-color: var(--fc-emphasis-color);
  --fc-list-group-action-active-bg: var(--fc-purple-border-subtle);
  --fc-list-group-active-color: var(--fc-purple-bg-subtle);
  --fc-list-group-active-bg: var(--fc-purple-text-emphasis);
  --fc-list-group-active-border-color: var(--fc-purple-text-emphasis);
}
.btn-close {
  --fc-btn-close-color: #000;
  --fc-btn-close-bg: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  --fc-btn-close-opacity: 1;
  --fc-btn-close-hover-opacity: 0.75;
  --fc-btn-close-focus-shadow: 0 0 0 0.25rem rgba(10, 173, 10, 0.25);
  --fc-btn-close-focus-opacity: 1;
  --fc-btn-close-disabled-opacity: 0.25;
  --fc-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  background: transparent var(--fc-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  box-sizing: content-box;
  height: 1em;
  opacity: var(--fc-btn-close-opacity);
  padding: 0.25em;
  width: 1em;
}
.btn-close,
.btn-close:hover {
  color: var(--fc-btn-close-color);
}
.btn-close:hover {
  opacity: var(--fc-btn-close-hover-opacity);
  text-decoration: none;
}
.btn-close:focus {
  box-shadow: var(--fc-btn-close-focus-shadow);
  opacity: var(--fc-btn-close-focus-opacity);
  outline: 0;
}
.btn-close.disabled,
.btn-close:disabled {
  opacity: var(--fc-btn-close-disabled-opacity);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.btn-close-white,
[data-bs-theme="dark"] .btn-close {
  filter: var(--fc-btn-close-white-filter);
}
.toast {
  --fc-toast-z-index: 1090;
  --fc-toast-padding-x: 0.75rem;
  --fc-toast-padding-y: 0.5rem;
  --fc-toast-spacing: 2rem;
  --fc-toast-max-width: 350px;
  --fc-toast-font-size: 0.875rem;
  --fc-toast-color: ;
  --fc-toast-bg: #fff;
  --fc-toast-border-width: 1px;
  --fc-toast-border-color: var(--fc-border-color-translucent);
  --fc-toast-border-radius: 0.75rem;
  --fc-toast-box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
    0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  --fc-toast-header-color: #21313c;
  --fc-toast-header-bg: hsla(0, 0%, 100%, 0.85);
  --fc-toast-header-border-color: rgba(0, 30, 43, 0.05);
  background-clip: padding-box;
  background-color: var(--fc-toast-bg);
  border: var(--fc-toast-border-width) solid var(--fc-toast-border-color);
  border-radius: var(--fc-toast-border-radius);
  box-shadow: var(--fc-toast-box-shadow);
  color: var(--fc-toast-color);
  font-size: var(--fc-toast-font-size);
  max-width: 100%;
  pointer-events: auto;
  width: var(--fc-toast-max-width);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  --fc-toast-z-index: 1090;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  z-index: var(--fc-toast-z-index);
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--fc-toast-spacing);
}
.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: var(--fc-toast-header-bg);
  border-bottom: var(--fc-toast-border-width) solid
    var(--fc-toast-header-border-color);
  border-top-left-radius: calc(
    var(--fc-toast-border-radius) - var(--fc-toast-border-width)
  );
  border-top-right-radius: calc(
    var(--fc-toast-border-radius) - var(--fc-toast-border-width)
  );
  color: var(--fc-toast-header-color);
  display: flex;
  padding: var(--fc-toast-padding-y) var(--fc-toast-padding-x);
}
.toast-header .btn-close {
  margin-left: var(--fc-toast-padding-x);
  margin-right: calc(var(--fc-toast-padding-x) * -0.5);
}
.toast-body {
  word-wrap: break-word;
  padding: var(--fc-toast-padding-x);
}
.modal {
  --fc-modal-z-index: 1055;
  --fc-modal-width: 500px;
  --fc-modal-padding: 1rem;
  --fc-modal-margin: 0.5rem;
  --fc-modal-color: ;
  --fc-modal-bg: #fff;
  --fc-modal-border-color: var(--fc-border-transparent);
  --fc-modal-border-width: 1px;
  --fc-modal-border-radius: 0.75rem;
  --fc-modal-box-shadow: rgba(145, 158, 171, 0.2) 0px 3px 1px -2px,
    rgba(145, 158, 171, 0.14) 0px 2px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 1px 5px 0px;
  --fc-modal-inner-border-radius: calc(0.75rem - 1px);
  --fc-modal-header-padding-x: 1rem;
  --fc-modal-header-padding-y: 1rem;
  --fc-modal-header-padding: 1rem 1rem;
  --fc-modal-header-border-color: var(--fc-border-color);
  --fc-modal-header-border-width: 1px;
  --fc-modal-title-line-height: 1.6;
  --fc-modal-footer-gap: 0.5rem;
  --fc-modal-footer-bg: ;
  --fc-modal-footer-border-color: var(--fc-border-color);
  --fc-modal-footer-border-width: 1px;
  display: none;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--fc-modal-z-index);
}
.modal-dialog {
  margin: var(--fc-modal-margin);
  pointer-events: none;
  position: relative;
  width: auto;
}
.modal.fade .modal-dialog {
  transform: translateY(-50px);
  transition: transform 0.3s ease-out;
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - var(--fc-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - var(--fc-modal-margin) * 2);
}
.modal-content {
  background-clip: padding-box;
  background-color: var(--fc-modal-bg);
  border: var(--fc-modal-border-width) solid var(--fc-modal-border-color);
  border-radius: var(--fc-modal-border-radius);
  color: var(--fc-modal-color);
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}
.modal-backdrop {
  --fc-backdrop-z-index: 1050;
  --fc-backdrop-bg: #000;
  --fc-backdrop-opacity: 0.5;
  background-color: var(--fc-backdrop-bg);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--fc-backdrop-z-index);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--fc-backdrop-opacity);
}
.modal-header {
  align-items: center;
  border-bottom: var(--fc-modal-header-border-width) solid
    var(--fc-modal-header-border-color);
  border-top-left-radius: var(--fc-modal-inner-border-radius);
  border-top-right-radius: var(--fc-modal-inner-border-radius);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: var(--fc-modal-header-padding);
}
.modal-header .btn-close {
  margin: calc(var(--fc-modal-header-padding-y) * -0.5)
    calc(var(--fc-modal-header-padding-x) * -0.5)
    calc(var(--fc-modal-header-padding-y) * -0.5) auto;
  padding: calc(var(--fc-modal-header-padding-y) * 0.5)
    calc(var(--fc-modal-header-padding-x) * 0.5);
}
.modal-title {
  line-height: var(--fc-modal-title-line-height);
  margin-bottom: 0;
}
.modal-body {
  flex: 1 1 auto;
  padding: var(--fc-modal-padding);
  position: relative;
}
.modal-footer {
  align-items: center;
  background-color: var(--fc-modal-footer-bg);
  border-bottom-left-radius: var(--fc-modal-inner-border-radius);
  border-bottom-right-radius: var(--fc-modal-inner-border-radius);
  border-top: var(--fc-modal-footer-border-width) solid
    var(--fc-modal-footer-border-color);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: calc(var(--fc-modal-padding) - var(--fc-modal-footer-gap) * 0.5);
}
.modal-footer > * {
  margin: calc(var(--fc-modal-footer-gap) * 0.5);
}
@media (min-width: 576px) {
  .modal {
    --fc-modal-margin: 1.75rem;
    --fc-modal-box-shadow: rgba(145, 158, 171, 0.2) 0px 2px 4px -1px,
      rgba(145, 158, 171, 0.14) 0px 4px 5px 0px,
      rgba(145, 158, 171, 0.12) 0px 1px 10px 0px;
  }
  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--fc-modal-width);
  }
  .modal-sm {
    --fc-modal-width: 420px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --fc-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --fc-modal-width: 1140px;
  }
}
.modal-fullscreen {
  height: 100%;
  margin: 0;
  max-width: none;
  width: 100vw;
}
.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}
.modal-fullscreen .modal-footer,
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-sm-down .modal-footer,
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-md-down .modal-footer,
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-lg-down .modal-footer,
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xl-down .modal-footer,
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100vw;
  }
  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }
  .modal-fullscreen-xxl-down .modal-footer,
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --fc-tooltip-z-index: 1080;
  --fc-tooltip-max-width: 200px;
  --fc-tooltip-padding-x: 0.5rem;
  --fc-tooltip-padding-y: 0.25rem;
  --fc-tooltip-margin: ;
  --fc-tooltip-font-size: 0.765625rem;
  --fc-tooltip-color: #fff;
  --fc-tooltip-bg: #001e2b;
  --fc-tooltip-border-radius: 0.5rem;
  --fc-tooltip-opacity: 0.9;
  --fc-tooltip-arrow-width: 0.8rem;
  --fc-tooltip-arrow-height: 0.4rem;
  word-wrap: break-word;
  display: block;
  font-family: var(--fc-font-sans-serif);
  font-size: var(--fc-tooltip-font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.6;
  margin: var(--fc-tooltip-margin);
  opacity: 0;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: var(--fc-tooltip-z-index);
}
.tooltip.show {
  opacity: var(--fc-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  height: var(--fc-tooltip-arrow-height);
  width: var(--fc-tooltip-arrow-width);
}
.tooltip .tooltip-arrow:before {
  border-color: transparent;
  border-style: solid;
  content: "";
  position: absolute;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: calc(var(--fc-tooltip-arrow-height) * -1);
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before,
.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: var(--fc-tooltip-bg);
  border-width: var(--fc-tooltip-arrow-height)
    calc(var(--fc-tooltip-arrow-width) * 0.5) 0;
  top: -1px;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  height: var(--fc-tooltip-arrow-width);
  left: calc(var(--fc-tooltip-arrow-height) * -1);
  width: var(--fc-tooltip-arrow-height);
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before,
.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: var(--fc-tooltip-bg);
  border-width: calc(var(--fc-tooltip-arrow-width) * 0.5)
    var(--fc-tooltip-arrow-height) calc(var(--fc-tooltip-arrow-width) * 0.5) 0;
  right: -1px;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: calc(var(--fc-tooltip-arrow-height) * -1);
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before,
.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: var(--fc-tooltip-bg);
  border-width: 0 calc(var(--fc-tooltip-arrow-width) * 0.5)
    var(--fc-tooltip-arrow-height);
  bottom: -1px;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  height: var(--fc-tooltip-arrow-width);
  right: calc(var(--fc-tooltip-arrow-height) * -1);
  width: var(--fc-tooltip-arrow-height);
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before,
.bs-tooltip-start .tooltip-arrow:before {
  border-left-color: var(--fc-tooltip-bg);
  border-width: calc(var(--fc-tooltip-arrow-width) * 0.5) 0
    calc(var(--fc-tooltip-arrow-width) * 0.5) var(--fc-tooltip-arrow-height);
  left: -1px;
}
.tooltip-inner {
  background-color: var(--fc-tooltip-bg);
  border-radius: var(--fc-tooltip-border-radius);
  color: var(--fc-tooltip-color);
  max-width: var(--fc-tooltip-max-width);
  padding: var(--fc-tooltip-padding-y) var(--fc-tooltip-padding-x);
  text-align: center;
}
.popover {
  --fc-popover-z-index: 1070;
  --fc-popover-max-width: 276px;
  --fc-popover-font-size: 0.875rem;
  --fc-popover-bg: #fff;
  --fc-popover-border-width: 1px;
  --fc-popover-border-color: var(--fc-border-color-translucent);
  --fc-popover-border-radius: 0.75rem;
  --fc-popover-inner-border-radius: calc(0.75rem - 1px);
  --fc-popover-box-shadow: rgba(145, 158, 171, 0.2) 0px 2px 4px -1px,
    rgba(145, 158, 171, 0.14) 0px 4px 5px 0px,
    rgba(145, 158, 171, 0.12) 0px 1px 10px 0px;
  --fc-popover-header-padding-x: 1rem;
  --fc-popover-header-padding-y: 0.5rem;
  --fc-popover-header-font-size: 0.875rem;
  --fc-popover-header-color: #21313c;
  --fc-popover-header-bg: #f0f0f0;
  --fc-popover-body-padding-x: 1rem;
  --fc-popover-body-padding-y: 1rem;
  --fc-popover-body-color: #5c6c75;
  --fc-popover-arrow-width: 1rem;
  --fc-popover-arrow-height: 0.5rem;
  --fc-popover-arrow-border: var(--fc-popover-border-color);
  word-wrap: break-word;
  background-clip: padding-box;
  background-color: var(--fc-popover-bg);
  border: var(--fc-popover-border-width) solid var(--fc-popover-border-color);
  border-radius: var(--fc-popover-border-radius);
  display: block;
  font-family: var(--fc-font-sans-serif);
  font-size: var(--fc-popover-font-size);
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.6;
  max-width: var(--fc-popover-max-width);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: var(--fc-popover-z-index);
}
.popover .popover-arrow {
  display: block;
  height: var(--fc-popover-arrow-height);
  width: var(--fc-popover-arrow-width);
}
.popover .popover-arrow:after,
.popover .popover-arrow:before {
  border: 0 solid transparent;
  content: "";
  display: block;
  position: absolute;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(
    (var(--fc-popover-arrow-height)) * -1 - var(--fc-popover-border-width)
  );
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:after,
.bs-popover-top > .popover-arrow:before {
  border-width: var(--fc-popover-arrow-height)
    calc(var(--fc-popover-arrow-width) * 0.5) 0;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before,
.bs-popover-top > .popover-arrow:before {
  border-top-color: var(--fc-popover-arrow-border);
  bottom: 0;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after,
.bs-popover-top > .popover-arrow:after {
  border-top-color: var(--fc-popover-bg);
  bottom: var(--fc-popover-border-width);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  height: var(--fc-popover-arrow-width);
  left: calc(
    (var(--fc-popover-arrow-height)) * -1 - var(--fc-popover-border-width)
  );
  width: var(--fc-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:after,
.bs-popover-end > .popover-arrow:before {
  border-width: calc(var(--fc-popover-arrow-width) * 0.5)
    var(--fc-popover-arrow-height) calc(var(--fc-popover-arrow-width) * 0.5) 0;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before,
.bs-popover-end > .popover-arrow:before {
  border-right-color: var(--fc-popover-arrow-border);
  left: 0;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after,
.bs-popover-end > .popover-arrow:after {
  border-right-color: var(--fc-popover-bg);
  left: var(--fc-popover-border-width);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(
    (var(--fc-popover-arrow-height)) * -1 - var(--fc-popover-border-width)
  );
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:before {
  border-width: 0 calc(var(--fc-popover-arrow-width) * 0.5)
    var(--fc-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before,
.bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: var(--fc-popover-arrow-border);
  top: 0;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after,
.bs-popover-bottom > .popover-arrow:after {
  border-bottom-color: var(--fc-popover-bg);
  top: var(--fc-popover-border-width);
}
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  border-bottom: var(--fc-popover-border-width) solid
    var(--fc-popover-header-bg);
  content: "";
  display: block;
  left: 50%;
  margin-left: calc(var(--fc-popover-arrow-width) * -0.5);
  position: absolute;
  top: 0;
  width: var(--fc-popover-arrow-width);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  height: var(--fc-popover-arrow-width);
  right: calc(
    (var(--fc-popover-arrow-height)) * -1 - var(--fc-popover-border-width)
  );
  width: var(--fc-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:after,
.bs-popover-start > .popover-arrow:before {
  border-width: calc(var(--fc-popover-arrow-width) * 0.5) 0
    calc(var(--fc-popover-arrow-width) * 0.5) var(--fc-popover-arrow-height);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before,
.bs-popover-start > .popover-arrow:before {
  border-left-color: var(--fc-popover-arrow-border);
  right: 0;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after,
.bs-popover-start > .popover-arrow:after {
  border-left-color: var(--fc-popover-bg);
  right: var(--fc-popover-border-width);
}
.popover-header {
  background-color: var(--fc-popover-header-bg);
  border-bottom: var(--fc-popover-border-width) solid
    var(--fc-popover-border-color);
  border-top-left-radius: var(--fc-popover-inner-border-radius);
  border-top-right-radius: var(--fc-popover-inner-border-radius);
  color: var(--fc-popover-header-color);
  font-size: var(--fc-popover-header-font-size);
  margin-bottom: 0;
  padding: var(--fc-popover-header-padding-y) var(--fc-popover-header-padding-x);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  color: var(--fc-popover-body-color);
  padding: var(--fc-popover-body-padding-y) var(--fc-popover-body-padding-x);
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}
.carousel-item {
  backface-visibility: hidden;
  display: none;
  float: left;
  margin-right: -100%;
  position: relative;
  transition: transform 0.6s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}
.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transform: none;
  transition-property: opacity;
}
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  opacity: 1;
  z-index: 1;
}
.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  opacity: 0;
  transition: opacity 0s 0.6s;
  z-index: 0;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  align-items: center;
  background: none;
  border: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transition: opacity 0.15s ease;
  width: 15%;
  z-index: 1;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  opacity: 0.9;
  outline: 0;
  text-decoration: none;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.carousel-indicators {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}
.carousel-indicators [data-bs-target] {
  background-clip: padding-box;
  background-color: #fff;
  border: 0;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  box-sizing: content-box;
  cursor: pointer;
  flex: 0 1 auto;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  opacity: 0.5;
  padding: 0;
  text-indent: -999px;
  transition: opacity 0.6s ease;
  width: 30px;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  bottom: 1.25rem;
  color: #fff;
  left: 15%;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  position: absolute;
  right: 15%;
  text-align: center;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
[data-bs-theme="dark"] .carousel .carousel-control-next-icon,
[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon,
[data-bs-theme="dark"].carousel .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme="dark"] .carousel .carousel-caption,
[data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}
.spinner-border,
.spinner-grow {
  animation: var(--fc-spinner-animation-speed) linear infinite
    var(--fc-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
  height: var(--fc-spinner-height);
  vertical-align: var(--fc-spinner-vertical-align);
  width: var(--fc-spinner-width);
}
@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}
.spinner-border {
  --fc-spinner-width: 2rem;
  --fc-spinner-height: 2rem;
  --fc-spinner-vertical-align: -0.125em;
  --fc-spinner-border-width: 0.25em;
  --fc-spinner-animation-speed: 0.75s;
  --fc-spinner-animation-name: spinner-border;
  border-right-color: currentcolor;
  border: var(--fc-spinner-border-width) solid;
  border-right: var(--fc-spinner-border-width) solid transparent;
}
.spinner-border-sm {
  --fc-spinner-width: 1rem;
  --fc-spinner-height: 1rem;
  --fc-spinner-border-width: 0.2em;
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --fc-spinner-width: 2rem;
  --fc-spinner-height: 2rem;
  --fc-spinner-vertical-align: -0.125em;
  --fc-spinner-animation-speed: 0.75s;
  --fc-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}
.spinner-grow-sm {
  --fc-spinner-width: 1rem;
  --fc-spinner-height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --fc-spinner-animation-speed: 1.5s;
  }
}
.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --fc-offcanvas-z-index: 1045;
  --fc-offcanvas-width: 420px;
  --fc-offcanvas-height: 30vh;
  --fc-offcanvas-padding-x: 1rem;
  --fc-offcanvas-padding-y: 1rem;
  --fc-offcanvas-color: var(--fc-body-color);
  --fc-offcanvas-bg: #fff;
  --fc-offcanvas-border-width: 1px;
  --fc-offcanvas-border-color: var(--fc-border-transparent);
  --fc-offcanvas-box-shadow: rgba(145, 158, 171, 0.2) 0px 3px 1px -2px,
    rgba(145, 158, 171, 0.14) 0px 2px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 1px 5px 0px;
  --fc-offcanvas-transition: transform 0.3s ease-in-out;
  --fc-offcanvas-title-line-height: 1.6;
}
@media (max-width: 575.98px) {
  .offcanvas-sm {
    background-clip: padding-box;
    background-color: var(--fc-offcanvas-bg);
    bottom: 0;
    color: var(--fc-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: var(--fc-offcanvas-transition);
    visibility: hidden;
    z-index: var(--fc-offcanvas-z-index);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    border-right: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-sm.offcanvas-end {
    border-left: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-sm.offcanvas-top {
    border-bottom: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom,
  .offcanvas-sm.offcanvas-top {
    height: var(--fc-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-sm.offcanvas-bottom {
    border-top: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.show:not(.hiding),
  .offcanvas-sm.showing {
    transform: none;
  }
  .offcanvas-sm.hiding,
  .offcanvas-sm.show,
  .offcanvas-sm.showing {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --fc-offcanvas-height: auto;
    --fc-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md {
    background-clip: padding-box;
    background-color: var(--fc-offcanvas-bg);
    bottom: 0;
    color: var(--fc-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: var(--fc-offcanvas-transition);
    visibility: hidden;
    z-index: var(--fc-offcanvas-z-index);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    border-right: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-md.offcanvas-end {
    border-left: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-md.offcanvas-top {
    border-bottom: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom,
  .offcanvas-md.offcanvas-top {
    height: var(--fc-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-md.offcanvas-bottom {
    border-top: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.show:not(.hiding),
  .offcanvas-md.showing {
    transform: none;
  }
  .offcanvas-md.hiding,
  .offcanvas-md.show,
  .offcanvas-md.showing {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --fc-offcanvas-height: auto;
    --fc-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg {
    background-clip: padding-box;
    background-color: var(--fc-offcanvas-bg);
    bottom: 0;
    color: var(--fc-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: var(--fc-offcanvas-transition);
    visibility: hidden;
    z-index: var(--fc-offcanvas-z-index);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    border-right: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-lg.offcanvas-end {
    border-left: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-lg.offcanvas-top {
    border-bottom: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom,
  .offcanvas-lg.offcanvas-top {
    height: var(--fc-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-lg.offcanvas-bottom {
    border-top: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.show:not(.hiding),
  .offcanvas-lg.showing {
    transform: none;
  }
  .offcanvas-lg.hiding,
  .offcanvas-lg.show,
  .offcanvas-lg.showing {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --fc-offcanvas-height: auto;
    --fc-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl {
    background-clip: padding-box;
    background-color: var(--fc-offcanvas-bg);
    bottom: 0;
    color: var(--fc-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: var(--fc-offcanvas-transition);
    visibility: hidden;
    z-index: var(--fc-offcanvas-z-index);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    border-right: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-xl.offcanvas-end {
    border-left: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-xl.offcanvas-top {
    border-bottom: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom,
  .offcanvas-xl.offcanvas-top {
    height: var(--fc-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-xl.offcanvas-bottom {
    border-top: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.show:not(.hiding),
  .offcanvas-xl.showing {
    transform: none;
  }
  .offcanvas-xl.hiding,
  .offcanvas-xl.show,
  .offcanvas-xl.showing {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --fc-offcanvas-height: auto;
    --fc-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    background-clip: padding-box;
    background-color: var(--fc-offcanvas-bg);
    bottom: 0;
    color: var(--fc-offcanvas-color);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    transition: var(--fc-offcanvas-transition);
    visibility: hidden;
    z-index: var(--fc-offcanvas-z-index);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    border-right: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-xxl.offcanvas-end {
    border-left: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: var(--fc-offcanvas-width);
  }
  .offcanvas-xxl.offcanvas-top {
    border-bottom: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    top: 0;
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom,
  .offcanvas-xxl.offcanvas-top {
    height: var(--fc-offcanvas-height);
    left: 0;
    max-height: 100%;
    right: 0;
  }
  .offcanvas-xxl.offcanvas-bottom {
    border-top: var(--fc-offcanvas-border-width) solid
      var(--fc-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.show:not(.hiding),
  .offcanvas-xxl.showing {
    transform: none;
  }
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show,
  .offcanvas-xxl.showing {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --fc-offcanvas-height: auto;
    --fc-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    background-color: transparent !important;
    display: flex;
    flex-grow: 0;
    overflow-y: visible;
    padding: 0;
  }
}
.offcanvas {
  background-clip: padding-box;
  background-color: var(--fc-offcanvas-bg);
  bottom: 0;
  color: var(--fc-offcanvas-color);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  outline: 0;
  position: fixed;
  transition: var(--fc-offcanvas-transition);
  visibility: hidden;
  z-index: 100 !important;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  border-right: var(--fc-offcanvas-border-width) solid
    var(--fc-offcanvas-border-color);
  left: 0;
  top: 0;
  transform: translateX(-100%);
  width: var(--fc-offcanvas-width);
}
.offcanvas.offcanvas-end {
  right: 0;
  top: 150px;
  transform: translateX(100%);
  width: var(--fc-offcanvas-width);
  height: max-content;
  border-radius: 9px 0px 0px 9px;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  overflow-y: hidden !important;
}
.offcanvas.offcanvas-top {
  border-bottom: var(--fc-offcanvas-border-width) solid
    var(--fc-offcanvas-border-color);
  top: 0;
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom,
.offcanvas.offcanvas-top {
  height: var(--fc-offcanvas-height);
  left: 0;
  max-height: 100%;
  right: 0;
}
.offcanvas.offcanvas-bottom {
  border-top: var(--fc-offcanvas-border-width) solid
    var(--fc-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none;
}
.offcanvas.hiding,
.offcanvas.show,
.offcanvas.showing {
  visibility: visible;
}
.offcanvas-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  /* position: fixed; */
  top: 0;
  width: 100vw;
  z-index: 1040;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0;
}
.offcanvas-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--fc-offcanvas-padding-y) var(--fc-offcanvas-padding-x);
  border-radius: 9px 0px 0px 0px;
  background: #000;
  color: var(--fc-white);
}
.offcanvas-header .btn-close {
  margin-bottom: calc(var(--fc-offcanvas-padding-y) * -0.5);
  margin-right: calc(var(--fc-offcanvas-padding-x) * -0.5);
  margin-top: calc(var(--fc-offcanvas-padding-y) * -0.5);
  padding: calc(var(--fc-offcanvas-padding-y) * 0.5)
    calc(var(--fc-offcanvas-padding-x) * 0.5);
}
.offcanvas-title {
  line-height: var(--fc-offcanvas-title-line-height);
  margin-bottom: 0;
  color: #fff;
}
.btn-close svg {
  transform: scale(2);
}
.filter-side {
  margin-top: -20px;
}
.filter-side .offcanvas-header {
  border-radius: 0 !important;
}
.offcanvas-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: var(--fc-offcanvas-padding-y) var(--fc-offcanvas-padding-x);
}
.placeholder {
  background-color: currentcolor;
  cursor: wait;
  display: inline-block;
  min-height: 1em;
  opacity: 0.5;
  vertical-align: middle;
}
.placeholder.btn:before {
  content: "";
  display: inline-block;
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  animation: placeholder-wave 2s linear infinite;
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}
@keyframes placeholder-wave {
  to {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
.clearfix:after {
  clear: both;
  content: "";
  display: block;
}
.text-bg-primary {
  background-color: RGBA(10, 173, 10, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-secondary {
  background-color: RGBA(136, 147, 151, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-success {
  background-color: RGBA(25, 135, 84, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-info {
  background-color: RGBA(1, 107, 248, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-warning {
  background-color: RGBA(255, 193, 7, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-danger {
  background-color: RGBA(219, 48, 48, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-light {
  background-color: RGBA(240, 243, 242, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-dark {
  background-color: RGBA(0, 30, 43, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-gray-400 {
  background-color: RGBA(193, 199, 198, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-white {
  background-color: RGBA(255, 255, 255, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-primary {
  background-color: RGBA(206, 239, 206, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-secondary {
  background-color: RGBA(136, 147, 151, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-light-danger {
  background-color: RGBA(248, 214, 214, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-info {
  background-color: RGBA(204, 225, 254, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-warning {
  background-color: RGBA(255, 243, 205, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-light-success {
  background-color: RGBA(209, 231, 221, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-dark-primary {
  background-color: RGBA(4, 69, 4, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-dark-warning {
  background-color: RGBA(102, 77, 3, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-dark-info {
  background-color: RGBA(0, 43, 99, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-dark-danger {
  background-color: RGBA(88, 19, 19, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-dark-success {
  background-color: RGBA(10, 54, 34, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-dark-secondary {
  background-color: RGBA(236, 240, 239, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-indigo {
  background-color: RGBA(102, 16, 242, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-red {
  background-color: RGBA(219, 48, 48, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-pink {
  background-color: RGBA(214, 51, 132, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.text-bg-orange {
  background-color: RGBA(253, 126, 20, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-teal {
  background-color: RGBA(32, 201, 151, var(--fc-bg-opacity, 1)) !important;
  color: #000 !important;
}
.text-bg-purple {
  background-color: RGBA(111, 66, 193, var(--fc-bg-opacity, 1)) !important;
  color: #fff !important;
}
.link-primary {
  color: RGBA(var(--fc-primary-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-primary-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-primary:focus,
.link-primary:hover {
  color: RGBA(8, 138, 8, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(8, 138, 8, var(--fc-link-underline-opacity, 1));
}
.link-secondary {
  color: RGBA(var(--fc-secondary-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-secondary-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-secondary:focus,
.link-secondary:hover {
  color: RGBA(109, 118, 121, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    109,
    118,
    121,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-success {
  color: RGBA(var(--fc-success-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-success-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-success:focus,
.link-success:hover {
  color: RGBA(20, 108, 67, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(20, 108, 67, var(--fc-link-underline-opacity, 1));
}
.link-info {
  color: RGBA(var(--fc-info-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-info-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-info:focus,
.link-info:hover {
  color: RGBA(1, 86, 198, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(1, 86, 198, var(--fc-link-underline-opacity, 1));
}
.link-warning {
  color: RGBA(var(--fc-warning-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-warning-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-warning:focus,
.link-warning:hover {
  color: RGBA(255, 205, 57, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    255,
    205,
    57,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-danger {
  color: RGBA(var(--fc-danger-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-danger-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-danger:focus,
.link-danger:hover {
  color: RGBA(175, 38, 38, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(175, 38, 38, var(--fc-link-underline-opacity, 1));
}
.link-light {
  color: RGBA(var(--fc-light-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-light-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light:focus,
.link-light:hover {
  color: RGBA(243, 245, 245, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    243,
    245,
    245,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-dark {
  color: RGBA(var(--fc-dark-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-dark-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-dark:focus,
.link-dark:hover {
  color: RGBA(0, 24, 34, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(0, 24, 34, var(--fc-link-underline-opacity, 1));
}
.link-gray-400 {
  color: RGBA(var(--fc-gray-400-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-gray-400-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-gray-400:focus,
.link-gray-400:hover {
  color: RGBA(205, 210, 209, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    205,
    210,
    209,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-white {
  color: RGBA(var(--fc-white-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-white-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-white:focus,
.link-white:hover {
  color: RGBA(255, 255, 255, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    255,
    255,
    255,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-primary {
  color: RGBA(var(--fc-light-primary-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-light-primary-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-primary:focus,
.link-light-primary:hover {
  color: RGBA(216, 242, 216, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    216,
    242,
    216,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-secondary {
  color: RGBA(var(--fc-light-secondary-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-light-secondary-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-secondary:focus,
.link-light-secondary:hover {
  color: RGBA(109, 118, 121, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    109,
    118,
    121,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-danger {
  color: RGBA(var(--fc-light-danger-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-light-danger-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-danger:focus,
.link-light-danger:hover {
  color: RGBA(249, 222, 222, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    249,
    222,
    222,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-info {
  color: RGBA(var(--fc-light-info-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-light-info-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-info:focus,
.link-light-info:hover {
  color: RGBA(214, 231, 254, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    214,
    231,
    254,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-warning {
  color: RGBA(var(--fc-light-warning-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-light-warning-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-warning:focus,
.link-light-warning:hover {
  color: RGBA(255, 245, 215, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    255,
    245,
    215,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-success {
  color: RGBA(var(--fc-light-success-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-light-success-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-light-success:focus,
.link-light-success:hover {
  color: RGBA(218, 236, 228, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    218,
    236,
    228,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-dark-primary {
  color: RGBA(var(--fc-dark-primary-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-dark-primary-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-dark-primary:focus,
.link-dark-primary:hover {
  color: RGBA(3, 55, 3, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(3, 55, 3, var(--fc-link-underline-opacity, 1));
}
.link-dark-warning {
  color: RGBA(var(--fc-dark-warning-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-dark-warning-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-dark-warning:focus,
.link-dark-warning:hover {
  color: RGBA(82, 62, 2, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(82, 62, 2, var(--fc-link-underline-opacity, 1));
}
.link-dark-info {
  color: RGBA(var(--fc-dark-info-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-dark-info-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-dark-info:focus,
.link-dark-info:hover {
  color: RGBA(0, 34, 79, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(0, 34, 79, var(--fc-link-underline-opacity, 1));
}
.link-dark-danger {
  color: RGBA(var(--fc-dark-danger-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-dark-danger-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-dark-danger:focus,
.link-dark-danger:hover {
  color: RGBA(70, 15, 15, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(70, 15, 15, var(--fc-link-underline-opacity, 1));
}
.link-dark-success {
  color: RGBA(var(--fc-dark-success-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-dark-success-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-dark-success:focus,
.link-dark-success:hover {
  color: RGBA(8, 43, 27, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(8, 43, 27, var(--fc-link-underline-opacity, 1));
}
.link-dark-secondary {
  color: RGBA(var(--fc-dark-secondary-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-dark-secondary-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-dark-secondary:focus,
.link-dark-secondary:hover {
  color: RGBA(240, 243, 242, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    240,
    243,
    242,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-indigo {
  color: RGBA(var(--fc-indigo-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-indigo-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-indigo:focus,
.link-indigo:hover {
  color: RGBA(82, 13, 194, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(82, 13, 194, var(--fc-link-underline-opacity, 1));
}
.link-red {
  color: RGBA(var(--fc-red-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-red-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-red:focus,
.link-red:hover {
  color: RGBA(175, 38, 38, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(175, 38, 38, var(--fc-link-underline-opacity, 1));
}
.link-pink {
  color: RGBA(var(--fc-pink-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-pink-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-pink:focus,
.link-pink:hover {
  color: RGBA(171, 41, 106, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    171,
    41,
    106,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-orange {
  color: RGBA(var(--fc-orange-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-orange-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-orange:focus,
.link-orange:hover {
  color: RGBA(253, 152, 67, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    253,
    152,
    67,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-teal {
  color: RGBA(var(--fc-teal-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-teal-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-teal:focus,
.link-teal:hover {
  color: RGBA(77, 212, 172, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    77,
    212,
    172,
    var(--fc-link-underline-opacity, 1)
  );
}
.link-purple {
  color: RGBA(var(--fc-purple-rgb, var(--fc-link-opacity, 1)));
  text-decoration-color: RGBA(
    var(--fc-purple-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-purple:focus,
.link-purple:hover {
  color: RGBA(89, 53, 154, var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(89, 53, 154, var(--fc-link-underline-opacity, 1));
}
.link-body-emphasis {
  color: RGBA(var(--fc-emphasis-color-rgb), var(--fc-link-opacity, 1));
  text-decoration-color: RGBA(
    var(--fc-emphasis-color-rgb),
    var(--fc-link-underline-opacity, 1)
  );
}
.link-body-emphasis:focus,
.link-body-emphasis:hover {
  color: RGBA(var(--fc-emphasis-color-rgb), var(--fc-link-opacity, 0.75));
  text-decoration-color: RGBA(
    var(--fc-emphasis-color-rgb),
    var(--fc-link-underline-opacity, 0.75)
  );
}
.focus-ring:focus {
  box-shadow: var(--fc-focus-ring-x, 0) var(--fc-focus-ring-y, 0)
    var(--fc-focus-ring-blur, 0) var(--fc-focus-ring-width)
    var(--fc-focus-ring-color);
  outline: 0;
}
.icon-link {
  align-items: center;
  backface-visibility: hidden;
  display: inline-flex;
  gap: 0.375rem;
  text-decoration-color: rgba(
    var(--fc-link-color-rgb),
    var(--fc-link-opacity, 0.5)
  );
  text-underline-offset: 0.25em;
}
.icon-link > .bi {
  fill: currentcolor;
  flex-shrink: 0;
  height: 1em;
  transition: transform 0.2s ease-in-out;
  width: 1em;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}
.icon-link-hover:focus-visible > .bi,
.icon-link-hover:hover > .bi {
  transform: var(--fc-icon-link-transform, translate3d(0.25em, 0, 0));
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio:before {
  content: "";
  display: block;
  padding-top: var(--fc-aspect-ratio);
}
.ratio > * {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.ratio-1x1 {
  --fc-aspect-ratio: 100%;
}
.ratio-4x3 {
  --fc-aspect-ratio: 75%;
}
.ratio-16x9 {
  --fc-aspect-ratio: 56.25%;
}
.ratio-21x9 {
  --fc-aspect-ratio: 42.8571428571%;
}
.fixed-top {
  top: 0;
  border-bottom: solid 1px #e6e6e6;
}
.fixed-bottom,
.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1030;
}
.fixed-bottom {
  bottom: 0;
}
.sticky-top {
  top: 0;
}
.sticky-bottom,
.sticky-top {
  position: sticky;
  z-index: 1020;
}
.sticky-bottom {
  bottom: 0;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    bottom: 0;
    position: sticky;
    z-index: 1020;
  }
}
.hstack {
  align-items: center;
  flex-direction: row;
}
.hstack,
.vstack {
  align-self: stretch;
  display: flex;
}
.vstack {
  flex: 1 1 auto;
  flex-direction: column;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  white-space: nowrap !important;
  width: 1px !important;
}
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption),
.visually-hidden:not(caption) {
  position: absolute !important;
}
.stretched-link:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vr {
  align-self: stretch;
  background-color: currentcolor;
  display: inline-block;
  min-height: 1em;
  opacity: 1;
  width: 1px;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.object-fit-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}
.object-fit-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}
.object-fit-fill {
  -o-object-fit: fill !important;
  object-fit: fill !important;
}
.object-fit-scale {
  -o-object-fit: scale-down !important;
  object-fit: scale-down !important;
}
.object-fit-none {
  -o-object-fit: none !important;
  object-fit: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-x-visible {
  overflow-x: visible !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.overflow-y-visible {
  overflow-y: visible !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-inline-grid {
  display: inline-grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  box-shadow: 0 2px 4px -1px rgba(145, 158, 171, 0.2),
    0 4px 5px 0 rgba(145, 158, 171, 0.14),
    0 1px 10px 0 rgba(145, 158, 171, 0.12) !important;
}
.shadow-sm {
  box-shadow: 0 3px 1px -2px rgba(145, 158, 171, 0.2),
    0 2px 2px 0 rgba(145, 158, 171, 0.14), 0 1px 5px 0 rgba(145, 158, 171, 0.12) !important;
}
.shadow-lg {
  box-shadow: 0 0 2px rgba(145, 158, 171, 0.2),
    0 12px 24px -4px rgba(145, 158, 171, 0.12) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.focus-ring-primary {
  --fc-focus-ring-color: rgba(
    var(--fc-primary-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-secondary {
  --fc-focus-ring-color: rgba(
    var(--fc-secondary-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-success {
  --fc-focus-ring-color: rgba(
    var(--fc-success-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-info {
  --fc-focus-ring-color: rgba(var(--fc-info-rgb), var(--fc-focus-ring-opacity));
}
.focus-ring-warning {
  --fc-focus-ring-color: rgba(
    var(--fc-warning-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-danger {
  --fc-focus-ring-color: rgba(
    var(--fc-danger-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-light {
  --fc-focus-ring-color: rgba(
    var(--fc-light-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-dark {
  --fc-focus-ring-color: rgba(var(--fc-dark-rgb), var(--fc-focus-ring-opacity));
}
.focus-ring-gray-400 {
  --fc-focus-ring-color: rgba(
    var(--fc-gray-400-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-white {
  --fc-focus-ring-color: rgba(
    var(--fc-white-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-light-primary {
  --fc-focus-ring-color: rgba(
    var(--fc-light-primary-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-light-secondary {
  --fc-focus-ring-color: rgba(
    var(--fc-light-secondary-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-light-danger {
  --fc-focus-ring-color: rgba(
    var(--fc-light-danger-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-light-info {
  --fc-focus-ring-color: rgba(
    var(--fc-light-info-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-light-warning {
  --fc-focus-ring-color: rgba(
    var(--fc-light-warning-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-light-success {
  --fc-focus-ring-color: rgba(
    var(--fc-light-success-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-dark-primary {
  --fc-focus-ring-color: rgba(
    var(--fc-dark-primary-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-dark-warning {
  --fc-focus-ring-color: rgba(
    var(--fc-dark-warning-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-dark-info {
  --fc-focus-ring-color: rgba(
    var(--fc-dark-info-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-dark-danger {
  --fc-focus-ring-color: rgba(
    var(--fc-dark-danger-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-dark-success {
  --fc-focus-ring-color: rgba(
    var(--fc-dark-success-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-dark-secondary {
  --fc-focus-ring-color: rgba(
    var(--fc-dark-secondary-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-indigo {
  --fc-focus-ring-color: rgba(
    var(--fc-indigo-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-red {
  --fc-focus-ring-color: rgba(var(--fc-red-rgb), var(--fc-focus-ring-opacity));
}
.focus-ring-pink {
  --fc-focus-ring-color: rgba(var(--fc-pink-rgb), var(--fc-focus-ring-opacity));
}
.focus-ring-orange {
  --fc-focus-ring-color: rgba(
    var(--fc-orange-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.focus-ring-teal {
  --fc-focus-ring-color: rgba(var(--fc-teal-rgb), var(--fc-focus-ring-opacity));
}
.focus-ring-purple {
  --fc-focus-ring-color: rgba(
    var(--fc-purple-rgb),
    var(--fc-focus-ring-opacity)
  );
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.top-auto {
  top: auto !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.bottom-auto {
  bottom: auto !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.start-auto {
  left: auto !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.end-auto {
  right: auto !important;
}
.translate-middle {
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  transform: translateY(-50%) !important;
}
.border {
  border: var(--fc-border-width) var(--fc-border-style) var(--fc-border-color) !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: var(--fc-border-width) var(--fc-border-style)
    var(--fc-border-color) !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: var(--fc-border-width) var(--fc-border-style)
    var(--fc-border-color) !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: var(--fc-border-width) var(--fc-border-style)
    var(--fc-border-color) !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: var(--fc-border-width) var(--fc-border-style)
    var(--fc-border-color) !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-primary-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-secondary {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-secondary-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-success {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-success-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-info {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-info-rgb), var(--fc-border-opacity)) !important;
}
.border-warning {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-warning-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-danger {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-danger-rgb), var(--fc-border-opacity)) !important;
}
.border-light {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-light-rgb), var(--fc-border-opacity)) !important;
}
.border-dark {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-dark-rgb), var(--fc-border-opacity)) !important;
}
.border-gray-400 {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-gray-400-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-white {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-white-rgb), var(--fc-border-opacity)) !important;
}
.border-light-primary {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-light-primary-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-light-secondary {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-light-secondary-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-light-danger {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-light-danger-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-light-info {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-light-info-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-light-warning {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-light-warning-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-light-success {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-light-success-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-dark-primary {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-dark-primary-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-dark-warning {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-dark-warning-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-dark-info {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-dark-info-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-dark-danger {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-dark-danger-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-dark-success {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-dark-success-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-dark-secondary {
  --fc-border-opacity: 1;
  border-color: rgba(
    var(--fc-dark-secondary-rgb),
    var(--fc-border-opacity)
  ) !important;
}
.border-indigo {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-indigo-rgb), var(--fc-border-opacity)) !important;
}
.border-red {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-red-rgb), var(--fc-border-opacity)) !important;
}
.border-pink {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-pink-rgb), var(--fc-border-opacity)) !important;
}
.border-orange {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-orange-rgb), var(--fc-border-opacity)) !important;
}
.border-teal {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-teal-rgb), var(--fc-border-opacity)) !important;
}
.border-purple {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-purple-rgb), var(--fc-border-opacity)) !important;
}
.border-black {
  --fc-border-opacity: 1;
  border-color: rgba(var(--fc-black-rgb), var(--fc-border-opacity)) !important;
}
.border-primary-subtle {
  border-color: var(--fc-primary-border-subtle) !important;
}
.border-secondary-subtle {
  border-color: var(--fc-secondary-border-subtle) !important;
}
.border-success-subtle {
  border-color: var(--fc-success-border-subtle) !important;
}
.border-info-subtle {
  border-color: var(--fc-info-border-subtle) !important;
}
.border-warning-subtle {
  border-color: var(--fc-warning-border-subtle) !important;
}
.border-danger-subtle {
  border-color: var(--fc-danger-border-subtle) !important;
}
.border-light-subtle {
  border-color: var(--fc-light-border-subtle) !important;
}
.border-dark-subtle {
  border-color: var(--fc-dark-border-subtle) !important;
}
.border-1 {
  border-width: 1px !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.border-opacity-10 {
  --fc-border-opacity: 0.1;
}
.border-opacity-25 {
  --fc-border-opacity: 0.25;
}
.border-opacity-50 {
  --fc-border-opacity: 0.5;
}
.border-opacity-75 {
  --fc-border-opacity: 0.75;
}
.border-opacity-100 {
  --fc-border-opacity: 1;
}
.w-0 {
  width: 0 !important;
}
.w-5 {
  width: 5% !important;
}
.w-10 {
  width: 10% !important;
}
.w-15 {
  width: 15% !important;
}
.w-20 {
  width: 20% !important;
}
.w-25 {
  width: 25% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-50 {
  width: 50% !important;
}
.w-65 {
  width: 65% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.order-first {
  order: -1 !important;
}
.order-0 {
  order: 0 !important;
}
/* .order-1 {
  order: 1 !important;
}
.order-2 {
  order: 2 !important;
} */
.order-3 {
  order: 3 !important;
}
.order-4 {
  order: 4 !important;
}
.order-5 {
  order: 5 !important;
}
.order-last {
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.m-3 {
  margin: 0.75rem !important;
}
.m-4 {
  margin: 1rem !important;
}
.m-5 {
  margin: 1.25rem !important;
}
.m-6 {
  margin: 1.5rem !important;
}
.m-7 {
  margin: 1.75rem !important;
}
.m-8 {
  margin: 2rem !important;
}
.m-9 {
  margin: 2.5rem !important;
}
.m-10 {
  margin: 3rem !important;
}
.m-11 {
  margin: 3.5rem !important;
}
.m-12 {
  margin: 4rem !important;
}
.m-13 {
  margin: 4.5rem !important;
}
.m-14 {
  margin: 5rem !important;
}
.m-15 {
  margin: 6rem !important;
}
.m-16 {
  margin: 8rem !important;
}
.m-17 {
  margin: 10rem !important;
}
.m-18 {
  margin: 12rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-7 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}
.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.mx-9 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
.mx-10 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.mx-11 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}
.mx-12 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
.mx-13 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}
.mx-14 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}
.mx-15 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}
.mx-16 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}
.mx-17 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}
.mx-18 {
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.my-1 {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}
.my-2 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.my-3 {
  margin-bottom: 0.75rem !important;
  margin-top: 0.75rem !important;
}
.my-4 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
.my-5 {
  margin-bottom: 1.25rem !important;
  margin-top: 1.25rem !important;
  margin-left: 160px;
}
.my-6 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}
.my-7 {
  margin-bottom: 1.75rem !important;
  margin-top: 1.75rem !important;
}
.my-8 {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
}
.my-9 {
  margin-bottom: 2.5rem !important;
  margin-top: 2.5rem !important;
}
.my-10 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}
.my-11 {
  margin-bottom: 3.5rem !important;
  margin-top: 3.5rem !important;
}
.my-12 {
  margin-bottom: 4rem !important;
  margin-top: 4rem !important;
}
.my-13 {
  margin-bottom: 4.5rem !important;
  margin-top: 4.5rem !important;
}
.my-14 {
  margin-bottom: 5rem !important;
  margin-top: 5rem !important;
}
.my-15 {
  margin-bottom: 6rem !important;
  margin-top: 6rem !important;
}
.my-16 {
  margin-bottom: 8rem !important;
  margin-top: 8rem !important;
}
.my-17 {
  margin-bottom: 10rem !important;
  margin-top: 10rem !important;
}
.my-18 {
  margin-bottom: 12rem !important;
  margin-top: 12rem !important;
}
.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.mt-5 {
  margin-top: 1.25rem !important;
}
.mt-6 {
  margin-top: 1.5rem !important;
}
.mt-7 {
  margin-top: 1.75rem !important;
}
.mt-8 {
  margin-top: 2rem !important;
}
.mt-9 {
  margin-top: 2.5rem !important;
}
.mt-10 {
  margin-top: 3rem !important;
}
.mt-11 {
  margin-top: 3.5rem !important;
}
.mt-12 {
  margin-top: 4rem !important;
}
.mt-13 {
  margin-top: 4.5rem !important;
}
.mt-14 {
  margin-top: 5rem !important;
}
.mt-15 {
  margin-top: 6rem !important;
}
.mt-16 {
  margin-top: 8rem !important;
}
.mt-17 {
  margin-top: 10rem !important;
}
.mt-18 {
  margin-top: 12rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.25rem !important;
}
.me-2 {
  margin-right: 0.5rem !important;
}
.me-3 {
  margin-right: 0.75rem !important;
}
.me-4 {
  margin-right: 1rem !important;
}
.me-5 {
  margin-right: 0.25rem !important;
}
.me-6 {
  margin-right: 1.5rem !important;
}
.me-7 {
  margin-right: 1.75rem !important;
}
.me-8 {
  margin-right: 2rem !important;
}
.me-9 {
  margin-right: 2.5rem !important;
}
.me-10 {
  margin-right: 3rem !important;
}
.me-11 {
  margin-right: 3.5rem !important;
}
.me-12 {
  margin-right: 4rem !important;
}
.me-13 {
  margin-right: 4.5rem !important;
}
.me-14 {
  margin-right: 5rem !important;
}
.me-15 {
  margin-right: 6rem !important;
}
.me-16 {
  margin-right: 8rem !important;
}
.me-17 {
  margin-right: 10rem !important;
}
.me-18 {
  margin-right: 12rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mb-5 {
  margin-bottom: 1.25rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.mb-7 {
  margin-bottom: 1.75rem !important;
}
.mb-8 {
  margin-bottom: 2rem !important;
}
.mb-9 {
  margin-bottom: 2.5rem !important;
}
.mb-10 {
  margin-bottom: 3rem !important;
}
.mb-11 {
  margin-bottom: 3.5rem !important;
}
.mb-12 {
  margin-bottom: 4rem !important;
}
.mb-13 {
  margin-bottom: 4.5rem !important;
}
.mb-14 {
  margin-bottom: 5rem !important;
}
.mb-15 {
  margin-bottom: 6rem !important;
}
.mb-16 {
  margin-bottom: 8rem !important;
}
.mb-17 {
  margin-bottom: 10rem !important;
}
.mb-18 {
  margin-bottom: 12rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.25rem !important;
}
.ms-2 {
  margin-left: 0.5rem !important;
}
.ms-3 {
  margin-left: 0.75rem !important;
}
.ms-4 {
  margin-left: 1rem !important;
}
.ms-5 {
  margin-left: 1.25rem !important;
}
.ms-6 {
  margin-left: 1.5rem !important;
}
.ms-7 {
  margin-left: 1.75rem !important;
}
.ms-8 {
  margin-left: 2rem !important;
}
.ms-9 {
  margin-left: 2.5rem !important;
}
.ms-10 {
  margin-left: 3rem !important;
}
.ms-11 {
  margin-left: 3.5rem !important;
}
.ms-12 {
  margin-left: 4rem !important;
}
.ms-13 {
  margin-left: 4.5rem !important;
}
.ms-14 {
  margin-left: 5rem !important;
}
.ms-15 {
  margin-left: 6rem !important;
}
.ms-16 {
  margin-left: 8rem !important;
}
.ms-17 {
  margin-left: 10rem !important;
}
.ms-18 {
  margin-left: 12rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.m-n3 {
  margin: -0.75rem !important;
}
.m-n4 {
  margin: -1rem !important;
}
.m-n5 {
  margin: -1.25rem !important;
}
.m-n6 {
  margin: -1.5rem !important;
}
.m-n7 {
  margin: -1.75rem !important;
}
.m-n8 {
  margin: -2rem !important;
}
.m-n9 {
  margin: -2.5rem !important;
}
.m-n10 {
  margin: -3rem !important;
}
.m-n11 {
  margin: -3.5rem !important;
}
.m-n12 {
  margin: -4rem !important;
}
.m-n13 {
  margin: -4.5rem !important;
}
.m-n14 {
  margin: -5rem !important;
}
.m-n15 {
  margin: -6rem !important;
}
.m-n16 {
  margin: -8rem !important;
}
.m-n17 {
  margin: -10rem !important;
}
.m-n18 {
  margin: -12rem !important;
}
.mx-n1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}
.mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.mx-n3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}
.mx-n4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
.mx-n5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}
.mx-n6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.mx-n7 {
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}
.mx-n8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}
.mx-n9 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}
.mx-n10 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}
.mx-n11 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}
.mx-n12 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}
.mx-n13 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}
.mx-n14 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}
.mx-n15 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}
.mx-n16 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}
.mx-n17 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}
.mx-n18 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}
.my-n1 {
  margin-bottom: -0.25rem !important;
  margin-top: -0.25rem !important;
}
.my-n2 {
  margin-bottom: -0.5rem !important;
  margin-top: -0.5rem !important;
}
.my-n3 {
  margin-bottom: -0.75rem !important;
  margin-top: -0.75rem !important;
}
.my-n4 {
  margin-bottom: -1rem !important;
  margin-top: -1rem !important;
}
.my-n5 {
  margin-bottom: -1.25rem !important;
  margin-top: -1.25rem !important;
}
.my-n6 {
  margin-bottom: -1.5rem !important;
  margin-top: -1.5rem !important;
}
.my-n7 {
  margin-bottom: -1.75rem !important;
  margin-top: -1.75rem !important;
}
.my-n8 {
  margin-bottom: -2rem !important;
  margin-top: -2rem !important;
}
.my-n9 {
  margin-bottom: -2.5rem !important;
  margin-top: -2.5rem !important;
}
.my-n10 {
  margin-bottom: -3rem !important;
  margin-top: -3rem !important;
}
.my-n11 {
  margin-bottom: -3.5rem !important;
  margin-top: -3.5rem !important;
}
.my-n12 {
  margin-bottom: -4rem !important;
  margin-top: -4rem !important;
}
.my-n13 {
  margin-bottom: -4.5rem !important;
  margin-top: -4.5rem !important;
}
.my-n14 {
  margin-bottom: -5rem !important;
  margin-top: -5rem !important;
}
.my-n15 {
  margin-bottom: -6rem !important;
  margin-top: -6rem !important;
}
.my-n16 {
  margin-bottom: -8rem !important;
  margin-top: -8rem !important;
}
.my-n17 {
  margin-bottom: -10rem !important;
  margin-top: -10rem !important;
}
.my-n18 {
  margin-bottom: -12rem !important;
  margin-top: -12rem !important;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}
.mt-n2 {
  margin-top: -0.5rem !important;
}
.mt-n3 {
  margin-top: -0.75rem !important;
}
.mt-n4 {
  margin-top: -1rem !important;
}
.mt-n5 {
  margin-top: -1.25rem !important;
}
.mt-n6 {
  margin-top: -1.5rem !important;
}
.mt-n7 {
  margin-top: -1.75rem !important;
}
.mt-n8 {
  margin-top: -2rem !important;
}
.mt-n9 {
  margin-top: -2.5rem !important;
}
.mt-n10 {
  margin-top: -3rem !important;
}
.mt-n11 {
  margin-top: -3.5rem !important;
}
.mt-n12 {
  margin-top: -4rem !important;
}
.mt-n13 {
  margin-top: -4.5rem !important;
}
.mt-n14 {
  margin-top: -5rem !important;
}
.mt-n15 {
  margin-top: -6rem !important;
}
.mt-n16 {
  margin-top: -8rem !important;
}
.mt-n17 {
  margin-top: -10rem !important;
}
.mt-n18 {
  margin-top: -12rem !important;
}
.me-n1 {
  margin-right: -0.25rem !important;
}
.me-n2 {
  margin-right: -0.5rem !important;
}
.me-n3 {
  margin-right: -0.75rem !important;
}
.me-n4 {
  margin-right: -1rem !important;
}
.me-n5 {
  margin-right: -1.25rem !important;
}
.me-n6 {
  margin-right: -1.5rem !important;
}
.me-n7 {
  margin-right: -1.75rem !important;
}
.me-n8 {
  margin-right: -2rem !important;
}
.me-n9 {
  margin-right: -2.5rem !important;
}
.me-n10 {
  margin-right: -3rem !important;
}
.me-n11 {
  margin-right: -3.5rem !important;
}
.me-n12 {
  margin-right: -4rem !important;
}
.me-n13 {
  margin-right: -4.5rem !important;
}
.me-n14 {
  margin-right: -5rem !important;
}
.me-n15 {
  margin-right: -6rem !important;
}
.me-n16 {
  margin-right: -8rem !important;
}
.me-n17 {
  margin-right: -10rem !important;
}
.me-n18 {
  margin-right: -12rem !important;
}
.mb-n1 {
  margin-bottom: -0.25rem !important;
}
.mb-n2 {
  margin-bottom: -0.5rem !important;
}
.mb-n3 {
  margin-bottom: -0.75rem !important;
}
.mb-n4 {
  margin-bottom: -1rem !important;
}
.mb-n5 {
  margin-bottom: -1.25rem !important;
}
.mb-n6 {
  margin-bottom: -1.5rem !important;
}
.mb-n7 {
  margin-bottom: -1.75rem !important;
}
.mb-n8 {
  margin-bottom: -2rem !important;
}
.mb-n9 {
  margin-bottom: -2.5rem !important;
}
.mb-n10 {
  margin-bottom: -3rem !important;
}
.mb-n11 {
  margin-bottom: -3.5rem !important;
}
.mb-n12 {
  margin-bottom: -4rem !important;
}
.mb-n13 {
  margin-bottom: -4.5rem !important;
}
.mb-n14 {
  margin-bottom: -5rem !important;
}
.mb-n15 {
  margin-bottom: -6rem !important;
}
.mb-n16 {
  margin-bottom: -8rem !important;
}
.mb-n17 {
  margin-bottom: -10rem !important;
}
.mb-n18 {
  margin-bottom: -12rem !important;
}
.ms-n1 {
  margin-left: -0.25rem !important;
}
.ms-n2 {
  margin-left: -0.5rem !important;
}
.ms-n3 {
  margin-left: -0.75rem !important;
}
.ms-n4 {
  margin-left: -1rem !important;
}
.ms-n5 {
  margin-left: -1.25rem !important;
}
.ms-n6 {
  margin-left: -1.5rem !important;
}
.ms-n7 {
  margin-left: -1.75rem !important;
}
.ms-n8 {
  margin-left: -2rem !important;
}
.ms-n9 {
  margin-left: -2.5rem !important;
}
.ms-n10 {
  margin-left: -3rem !important;
}
.ms-n11 {
  margin-left: -3.5rem !important;
}
.ms-n12 {
  margin-left: -4rem !important;
}
.ms-n13 {
  margin-left: -4.5rem !important;
}
.ms-n14 {
  margin-left: -5rem !important;
}
.ms-n15 {
  margin-left: -6rem !important;
}
.ms-n16 {
  margin-left: -8rem !important;
}
.ms-n17 {
  margin-left: -10rem !important;
}
.ms-n18 {
  margin-left: -12rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.p-7 {
  padding: 1.75rem !important;
}
.p-8 {
  padding: 2rem !important;
}
.p-9 {
  padding: 2.5rem !important;
}
.p-10 {
  padding: 3rem !important;
}
.p-11 {
  padding: 3.5rem !important;
}
.p-12 {
  padding: 4rem !important;
}
.p-13 {
  padding: 4.5rem !important;
}
.p-14 {
  padding: 5rem !important;
}
.p-15 {
  padding: 6rem !important;
}
.p-16 {
  padding: 8rem !important;
}
.p-17 {
  padding: 10rem !important;
}
.p-18 {
  padding: 12rem !important;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}
.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.px-9 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.px-10 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.px-11 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}
.px-12 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
.px-13 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}
.px-14 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
.px-15 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}
.px-16 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}
.px-17 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}
.px-18 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}
.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.py-1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}
.py-2 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}
.py-3 {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
}
.py-4 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.py-5 {
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
}
.py-6 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}
.py-7 {
  padding-bottom: 1.75rem !important;
  padding-top: 1.75rem !important;
}
.py-8 {
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
}
.py-9 {
  padding-bottom: 2.5rem !important;
  padding-top: 2.5rem !important;
}
.py-10 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}
.py-11 {
  padding-bottom: 3.5rem !important;
  padding-top: 3.5rem !important;
}
.py-12 {
  padding-bottom: 4rem !important;
  padding-top: 4rem !important;
}
.py-13 {
  padding-bottom: 4.5rem !important;
  padding-top: 4.5rem !important;
}
.py-14 {
  padding-bottom: 5rem !important;
  padding-top: 5rem !important;
}
.py-15 {
  padding-bottom: 6rem !important;
  padding-top: 6rem !important;
}
.py-16 {
  padding-bottom: 8rem !important;
  padding-top: 8rem !important;
}
.py-17 {
  padding-bottom: 10rem !important;
  padding-top: 10rem !important;
}
.py-18 {
  padding-bottom: 12rem !important;
  padding-top: 12rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 0.75rem !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pt-5 {
  padding-top: 1.25rem !important;
}
.pt-6 {
  padding-top: 1.5rem !important;
}
.pt-7 {
  padding-top: 1.75rem !important;
}
.pt-8 {
  padding-top: 2rem !important;
}
.pt-9 {
  padding-top: 2.5rem !important;
}
.pt-10 {
  padding-top: 3rem !important;
}
.pt-11 {
  padding-top: 3.5rem !important;
}
.pt-12 {
  padding-top: 4rem !important;
}
.pt-13 {
  padding-top: 4.5rem !important;
}
.pt-14 {
  padding-top: 5rem !important;
}
.pt-15 {
  padding-top: 6rem !important;
}
.pt-16 {
  padding-top: 8rem !important;
}
.pt-17 {
  padding-top: 10rem !important;
}
.pt-18 {
  padding-top: 12rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.25rem !important;
}
.pe-2 {
  padding-right: 0.5rem !important;
}
.pe-3 {
  padding-right: 0.75rem !important;
}
.pe-4 {
  padding-right: 1rem !important;
}
.pe-5 {
  padding-right: 1.25rem !important;
}
.pe-6 {
  padding-right: 1.5rem !important;
}
.pe-7 {
  padding-right: 1.75rem !important;
}
.pe-8 {
  padding-right: 2rem !important;
}
.pe-9 {
  padding-right: 2.5rem !important;
}
.pe-10 {
  padding-right: 3rem !important;
}
.pe-11 {
  padding-right: 3.5rem !important;
}
.pe-12 {
  padding-right: 4rem !important;
}
.pe-13 {
  padding-right: 4.5rem !important;
}
.pe-14 {
  padding-right: 5rem !important;
}
.pe-15 {
  padding-right: 6rem !important;
}
.pe-16 {
  padding-right: 8rem !important;
}
.pe-17 {
  padding-right: 10rem !important;
}
.pe-18 {
  padding-right: 12rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 0.75rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.pb-5 {
  padding-bottom: 1.25rem !important;
}
.pb-6 {
  padding-bottom: 1.5rem !important;
}
.pb-7 {
  padding-bottom: 1.75rem !important;
}
.pb-8 {
  padding-bottom: 2rem !important;
}
.pb-9 {
  padding-bottom: 2.5rem !important;
}
.pb-10 {
  padding-bottom: 3rem !important;
}
.pb-11 {
  padding-bottom: 3.5rem !important;
}
.pb-12 {
  padding-bottom: 4rem !important;
}
.pb-13 {
  padding-bottom: 4.5rem !important;
}
.pb-14 {
  padding-bottom: 5rem !important;
}
.pb-15 {
  padding-bottom: 6rem !important;
}
.pb-16 {
  padding-bottom: 8rem !important;
}
.pb-17 {
  padding-bottom: 10rem !important;
}
.pb-18 {
  padding-bottom: 12rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.25rem !important;
}
.ps-2 {
  padding-left: 0.5rem !important;
}
.ps-3 {
  padding-left: 0.75rem !important;
}
.ps-4 {
  padding-left: 1rem !important;
}
.ps-5 {
  padding-left: 1.25rem !important;
}
.ps-6 {
  padding-left: 1.5rem !important;
}
.ps-7 {
  padding-left: 1.75rem !important;
}
.ps-8 {
  padding-left: 2rem !important;
}
.ps-9 {
  padding-left: 2.5rem !important;
}
.ps-10 {
  padding-left: 3rem !important;
}
.ps-11 {
  padding-left: 3.5rem !important;
}
.ps-12 {
  padding-left: 4rem !important;
}
.ps-13 {
  padding-left: 4.5rem !important;
}
.ps-14 {
  padding-left: 5rem !important;
}
.ps-15 {
  padding-left: 6rem !important;
}
.ps-16 {
  padding-left: 8rem !important;
}
.ps-17 {
  padding-left: 10rem !important;
}
.ps-18 {
  padding-left: 12rem !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.25rem !important;
}
.gap-2 {
  gap: 0.5rem !important;
}
.gap-3 {
  gap: 0.75rem !important;
}
.gap-4 {
  gap: 1rem !important;
}
.gap-5 {
  gap: 1.25rem !important;
}
.gap-6 {
  gap: 1.5rem !important;
}
.gap-7 {
  gap: 1.75rem !important;
}
.gap-8 {
  gap: 2rem !important;
}
.gap-9 {
  gap: 2.5rem !important;
}
.gap-10 {
  gap: 3rem !important;
}
.gap-11 {
  gap: 3.5rem !important;
}
.gap-12 {
  gap: 4rem !important;
}
.gap-13 {
  gap: 4.5rem !important;
}
.gap-14 {
  gap: 5rem !important;
}
.gap-15 {
  gap: 6rem !important;
}
.gap-16 {
  gap: 8rem !important;
}
.gap-17 {
  gap: 10rem !important;
}
.gap-18 {
  gap: 12rem !important;
}
.row-gap-0 {
  row-gap: 0 !important;
}
.row-gap-1 {
  row-gap: 0.25rem !important;
}
.row-gap-2 {
  row-gap: 0.5rem !important;
}
.row-gap-3 {
  row-gap: 0.75rem !important;
}
.row-gap-4 {
  row-gap: 1rem !important;
}
.row-gap-5 {
  row-gap: 1.25rem !important;
}
.row-gap-6 {
  row-gap: 1.5rem !important;
}
.row-gap-7 {
  row-gap: 1.75rem !important;
}
.row-gap-8 {
  row-gap: 2rem !important;
}
.row-gap-9 {
  row-gap: 2.5rem !important;
}
.row-gap-10 {
  row-gap: 3rem !important;
}
.row-gap-11 {
  row-gap: 3.5rem !important;
}
.row-gap-12 {
  row-gap: 4rem !important;
}
.row-gap-13 {
  row-gap: 4.5rem !important;
}
.row-gap-14 {
  row-gap: 5rem !important;
}
.row-gap-15 {
  row-gap: 6rem !important;
}
.row-gap-16 {
  row-gap: 8rem !important;
}
.row-gap-17 {
  row-gap: 10rem !important;
}
.row-gap-18 {
  row-gap: 12rem !important;
}
.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}
.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
  column-gap: 0.25rem !important;
}
.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
  column-gap: 0.5rem !important;
}
.column-gap-3 {
  -moz-column-gap: 0.75rem !important;
  column-gap: 0.75rem !important;
}
.column-gap-4 {
  -moz-column-gap: 1rem !important;
  column-gap: 1rem !important;
}
.column-gap-5 {
  -moz-column-gap: 1.25rem !important;
  column-gap: 1.25rem !important;
}
.column-gap-6 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}
.column-gap-7 {
  -moz-column-gap: 1.75rem !important;
  column-gap: 1.75rem !important;
}
.column-gap-8 {
  -moz-column-gap: 2rem !important;
  column-gap: 2rem !important;
}
.column-gap-9 {
  -moz-column-gap: 2.5rem !important;
  column-gap: 2.5rem !important;
}
.column-gap-10 {
  -moz-column-gap: 3rem !important;
  column-gap: 3rem !important;
}
.column-gap-11 {
  -moz-column-gap: 3.5rem !important;
  column-gap: 3.5rem !important;
}
.column-gap-12 {
  -moz-column-gap: 4rem !important;
  column-gap: 4rem !important;
}
.column-gap-13 {
  -moz-column-gap: 4.5rem !important;
  column-gap: 4.5rem !important;
}
.column-gap-14 {
  -moz-column-gap: 5rem !important;
  column-gap: 5rem !important;
}
.column-gap-15 {
  -moz-column-gap: 6rem !important;
  column-gap: 6rem !important;
}
.column-gap-16 {
  -moz-column-gap: 8rem !important;
  column-gap: 8rem !important;
}
.column-gap-17 {
  -moz-column-gap: 10rem !important;
  column-gap: 10rem !important;
}
.column-gap-18 {
  -moz-column-gap: 12rem !important;
  column-gap: 12rem !important;
}
.font-monospace {
  font-family: var(--fc-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important;
}
.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
.fs-3 {
  font-size: calc(1.27813rem + 0.3375vw) !important;
}
.fs-4 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}
.fs-5 {
  font-size: 1.09375rem !important;
}
.fs-6 {
  font-size: 0.875rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.6 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-primary-rgb), var(--fc-text-opacity)) !important;
}
.text-secondary {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-secondary-rgb), var(--fc-text-opacity)) !important;
}
.text-success {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-success-rgb), var(--fc-text-opacity)) !important;
}
.text-info {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-info-rgb), var(--fc-text-opacity)) !important;
}
.text-warning {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-warning-rgb), var(--fc-text-opacity)) !important;
}
.text-danger {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-danger-rgb), var(--fc-text-opacity)) !important;
}
.text-light {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-light-rgb), var(--fc-text-opacity)) !important;
}
.text-dark {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-dark-rgb), var(--fc-text-opacity)) !important;
}
.text-gray-400 {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-gray-400-rgb), var(--fc-text-opacity)) !important;
}
.text-white {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-white-rgb), var(--fc-text-opacity)) !important;
}
.text-light-primary {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-light-primary-rgb), var(--fc-text-opacity)) !important;
}
.text-light-secondary {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-light-secondary-rgb), var(--fc-text-opacity)) !important;
}
.text-light-danger {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-light-danger-rgb), var(--fc-text-opacity)) !important;
}
.text-light-info {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-light-info-rgb), var(--fc-text-opacity)) !important;
}
.text-light-warning {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-light-warning-rgb), var(--fc-text-opacity)) !important;
}
.text-light-success {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-light-success-rgb), var(--fc-text-opacity)) !important;
}
.text-dark-primary {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-dark-primary-rgb), var(--fc-text-opacity)) !important;
}
.text-dark-warning {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-dark-warning-rgb), var(--fc-text-opacity)) !important;
}
.text-dark-info {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-dark-info-rgb), var(--fc-text-opacity)) !important;
}
.text-dark-danger {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-dark-danger-rgb), var(--fc-text-opacity)) !important;
}
.text-dark-success {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-dark-success-rgb), var(--fc-text-opacity)) !important;
}
.text-dark-secondary {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-dark-secondary-rgb), var(--fc-text-opacity)) !important;
}
.text-indigo {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-indigo-rgb), var(--fc-text-opacity)) !important;
}
.text-red {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-red-rgb), var(--fc-text-opacity)) !important;
}
.text-pink {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-pink-rgb), var(--fc-text-opacity)) !important;
}
.text-orange {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-orange-rgb), var(--fc-text-opacity)) !important;
}
.text-teal {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-teal-rgb), var(--fc-text-opacity)) !important;
}
.text-purple {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-purple-rgb), var(--fc-text-opacity)) !important;
}
.text-black {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-black-rgb), var(--fc-text-opacity)) !important;
}
.text-body {
  --fc-text-opacity: 1;
  color: rgba(var(--fc-body-color-rgb), var(--fc-text-opacity)) !important;
}
.text-muted {
  --fc-text-opacity: 1;
  color: var(--fc-secondary-color) !important;
}
.text-black-50 {
  --fc-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --fc-text-opacity: 1;
  color: hsla(0, 0%, 100%, 0.5) !important;
}
.text-body-secondary {
  --fc-text-opacity: 1;
  color: var(--fc-secondary-color) !important;
}
.text-body-tertiary {
  --fc-text-opacity: 1;
  color: var(--fc-tertiary-color) !important;
}
.text-body-emphasis {
  --fc-text-opacity: 1;
  color: var(--fc-emphasis-color) !important;
}
.text-reset {
  --fc-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --fc-text-opacity: 0.25;
}
.text-opacity-50 {
  --fc-text-opacity: 0.5;
}
.text-opacity-75 {
  --fc-text-opacity: 0.75;
}
.text-opacity-100 {
  --fc-text-opacity: 1;
}
.text-primary-emphasis {
  color: var(--fc-primary-text-emphasis) !important;
}
.text-secondary-emphasis {
  color: var(--fc-secondary-text-emphasis) !important;
}
.text-success-emphasis {
  color: var(--fc-success-text-emphasis) !important;
}
.text-info-emphasis {
  color: var(--fc-info-text-emphasis) !important;
}
.text-warning-emphasis {
  color: var(--fc-warning-text-emphasis) !important;
}
.text-danger-emphasis {
  color: var(--fc-danger-text-emphasis) !important;
}
.text-light-emphasis {
  color: var(--fc-light-text-emphasis) !important;
}
.text-dark-emphasis {
  color: var(--fc-dark-text-emphasis) !important;
}
.link-opacity-10,
.link-opacity-10-hover:hover {
  --fc-link-opacity: 0.1;
}
.link-opacity-25,
.link-opacity-25-hover:hover {
  --fc-link-opacity: 0.25;
}
.link-opacity-50,
.link-opacity-50-hover:hover {
  --fc-link-opacity: 0.5;
}
.link-opacity-75,
.link-opacity-75-hover:hover {
  --fc-link-opacity: 0.75;
}
.link-opacity-100,
.link-opacity-100-hover:hover {
  --fc-link-opacity: 1;
}
.link-offset-1,
.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}
.link-offset-2,
.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}
.link-offset-3,
.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}
.link-underline-primary {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-primary-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-secondary {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-secondary-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-success {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-success-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-info {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-info-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-warning {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-warning-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-danger {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-danger-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-light {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-light-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-dark {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-dark-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-gray-400 {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-gray-400-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-white {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-white-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-light-primary {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-light-primary-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-light-secondary {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-light-secondary-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-light-danger {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-light-danger-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-light-info {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-light-info-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-light-warning {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-light-warning-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-light-success {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-light-success-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-dark-primary {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-dark-primary-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-dark-warning {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-dark-warning-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-dark-info {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-dark-info-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-dark-danger {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-dark-danger-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-dark-success {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-dark-success-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-dark-secondary {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-dark-secondary-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-indigo {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-indigo-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-red {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-red-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-pink {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-pink-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-orange {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-orange-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-teal {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-teal-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline-purple {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-purple-rgb),
    var(--fc-link-underline-opacity)
  ) !important;
}
.link-underline {
  --fc-link-underline-opacity: 1;
  text-decoration-color: rgba(
    var(--fc-link-color-rgb),
    var(--fc-link-underline-opacity, 1)
  ) !important;
}
.link-underline-opacity-0,
.link-underline-opacity-0-hover:hover {
  --fc-link-underline-opacity: 0;
}
.link-underline-opacity-10,
.link-underline-opacity-10-hover:hover {
  --fc-link-underline-opacity: 0.1;
}
.link-underline-opacity-25,
.link-underline-opacity-25-hover:hover {
  --fc-link-underline-opacity: 0.25;
}
.link-underline-opacity-50,
.link-underline-opacity-50-hover:hover {
  --fc-link-underline-opacity: 0.5;
}
.link-underline-opacity-75,
.link-underline-opacity-75-hover:hover {
  --fc-link-underline-opacity: 0.75;
}
.link-underline-opacity-100,
.link-underline-opacity-100-hover:hover {
  --fc-link-underline-opacity: 1;
}
.bg-primary {
  --fc-bg-opacity: 1;
  background-color: #0aad0a !important;
}
.bg-secondary {
  --fc-bg-opacity: 1;
  background-color: #889397 !important;
}
.bg-success {
  --fc-bg-opacity: 1;
  background-color: #198754 !important;
}
.bg-info {
  --fc-bg-opacity: 1;
  background-color: #016bf8 !important;
}
.bg-warning {
  --fc-bg-opacity: 1;
  background-color: #ffc107 !important;
}
.bg-danger {
  --fc-bg-opacity: 1;
  background-color: #db3030 !important;
}
.bg-light {
  --fc-bg-opacity: 1;
  background-color: #f0f3f2 !important;
}
.bg-dark {
  --fc-bg-opacity: 1;
  background-color: #000 !important;
}
.bg-gray-400 {
  --fc-bg-opacity: 1;
  background-color: #c1c7c6 !important;
}
.bg-white {
  --fc-bg-opacity: 1;
  background-color: #fff !important;
}
.bg-light-primary {
  --fc-bg-opacity: 1;
  background-color: #ceefce !important;
}
.bg-light-secondary {
  --fc-bg-opacity: 1;
  background-color: #889397 !important;
}
.bg-light-danger {
  --fc-bg-opacity: 1;
  background-color: #f8d6d6 !important;
}
.bg-light-info {
  --fc-bg-opacity: 1;
  background-color: #cce1fe !important;
}
.bg-light-warning {
  --fc-bg-opacity: 1;
  background-color: #fff3cd !important;
}
.bg-light-success {
  --fc-bg-opacity: 1;
  background-color: #d1e7dd !important;
}
.bg-dark-primary {
  --fc-bg-opacity: 1;
  background-color: #044504 !important;
}
.bg-dark-warning {
  --fc-bg-opacity: 1;
  background-color: #664d03 !important;
}
.bg-dark-info {
  --fc-bg-opacity: 1;
  background-color: #002b63 !important;
}
.bg-dark-danger {
  --fc-bg-opacity: 1;
  background-color: #581313 !important;
}
.bg-dark-success {
  --fc-bg-opacity: 1;
  background-color: #0a3622 !important;
}
.bg-dark-secondary {
  --fc-bg-opacity: 1;
  background-color: #ecf0ef !important;
}
.bg-indigo {
  --fc-bg-opacity: 1;
  background-color: #6610f2 !important;
}
.bg-red {
  background-color: #db3030 !important;
}
.bg-pink,
.bg-red {
  --fc-bg-opacity: 1;
}
.bg-pink {
  background-color: #d63384 !important;
}
.bg-orange {
  --fc-bg-opacity: 1;
  background-color: #fd7e14 !important;
}
.bg-teal {
  --fc-bg-opacity: 1;
  background-color: #20c997 !important;
}
.bg-purple {
  --fc-bg-opacity: 1;
  background-color: #6f42c1 !important;
}
.bg-transparent {
  --fc-bg-opacity: 1;
  background-color: transparent !important;
}
.bg-body-secondary {
  --fc-bg-opacity: 1;
  background-color: rgba(
    var(--fc-secondary-bg-rgb),
    var(--fc-bg-opacity)
  ) !important;
}
.bg-body-tertiary {
  --fc-bg-opacity: 1;
  background-color: rgba(
    var(--fc-tertiary-bg-rgb),
    var(--fc-bg-opacity)
  ) !important;
}
.bg-body-emphasis {
  --fc-bg-opacity: 1;
  background-color: rgba(
    var(--fc-emphasis-bg-rgb),
    var(--fc-bg-opacity)
  ) !important;
}
.bg-gray-100 {
  --fc-bg-opacity: 1;
  background-color: #f0f3f2 !important;
}
.bg-gray-200 {
  --fc-bg-opacity: 1;
  background-color: #ecf0ef !important;
}
.bg-gray-300 {
  --fc-bg-opacity: 1;
  background-color: #dfe2e1 !important;
}
.bg-gray-500 {
  --fc-bg-opacity: 1;
  background-color: #889397 !important;
}
.bg-gray-600 {
  --fc-bg-opacity: 1;
  background-color: #5c6c75 !important;
}
.bg-gray-700 {
  --fc-bg-opacity: 1;
  background-color: #3d4f58 !important;
}
.bg-gray-800 {
  --fc-bg-opacity: 1;
  background-color: #21313c !important;
}
.bg-gray-900 {
  --fc-bg-opacity: 1;
  background-color: #001e2b !important;
}
.bg-opacity-10 {
  --fc-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --fc-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --fc-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --fc-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --fc-bg-opacity: 1;
}
.bg-primary-subtle {
  background-color: var(--fc-primary-bg-subtle) !important;
}
.bg-secondary-subtle {
  background-color: var(--fc-secondary-bg-subtle) !important;
}
.bg-success-subtle {
  background-color: var(--fc-success-bg-subtle) !important;
}
.bg-info-subtle {
  background-color: var(--fc-info-bg-subtle) !important;
}
.bg-warning-subtle {
  background-color: var(--fc-warning-bg-subtle) !important;
}
.bg-danger-subtle {
  background-color: var(--fc-danger-bg-subtle) !important;
}
.bg-light-subtle {
  background-color: var(--fc-light-bg-subtle) !important;
}
.bg-dark-subtle {
  background-color: var(--fc-dark-bg-subtle) !important;
}
.bg-gradient {
  background-image: var(--fc-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: var(--fc-border-radius) !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: var(--fc-border-radius-sm) !important;
}
.rounded-2 {
  border-radius: var(--fc-border-radius) !important;
}
.rounded-3 {
  border-radius: var(--fc-border-radius-lg) !important;
}
.rounded-4 {
  border-radius: var(--fc-border-radius-xl) !important;
}
.rounded-5 {
  border-radius: var(--fc-border-radius-xxl) !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: var(--fc-border-radius-pill) !important;
}
.rounded-top {
  border-top-left-radius: var(--fc-border-radius) !important;
  border-top-right-radius: var(--fc-border-radius) !important;
}
.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.rounded-top-1 {
  border-top-left-radius: var(--fc-border-radius-sm) !important;
  border-top-right-radius: var(--fc-border-radius-sm) !important;
}
.rounded-top-2 {
  border-top-left-radius: var(--fc-border-radius) !important;
  border-top-right-radius: var(--fc-border-radius) !important;
}
.rounded-top-3 {
  border-top-left-radius: var(--fc-border-radius-lg) !important;
  border-top-right-radius: var(--fc-border-radius-lg) !important;
}
.rounded-top-4 {
  border-top-left-radius: var(--fc-border-radius-xl) !important;
  border-top-right-radius: var(--fc-border-radius-xl) !important;
}
.rounded-top-5 {
  border-top-left-radius: var(--fc-border-radius-xxl) !important;
  border-top-right-radius: var(--fc-border-radius-xxl) !important;
}
.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
.rounded-top-pill {
  border-top-left-radius: var(--fc-border-radius-pill) !important;
  border-top-right-radius: var(--fc-border-radius-pill) !important;
}
.rounded-end {
  border-bottom-right-radius: var(--fc-border-radius) !important;
  border-top-right-radius: var(--fc-border-radius) !important;
}
.rounded-end-0 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.rounded-end-1 {
  border-bottom-right-radius: var(--fc-border-radius-sm) !important;
  border-top-right-radius: var(--fc-border-radius-sm) !important;
}
.rounded-end-2 {
  border-bottom-right-radius: var(--fc-border-radius) !important;
  border-top-right-radius: var(--fc-border-radius) !important;
}
.rounded-end-3 {
  border-bottom-right-radius: var(--fc-border-radius-lg) !important;
  border-top-right-radius: var(--fc-border-radius-lg) !important;
}
.rounded-end-4 {
  border-bottom-right-radius: var(--fc-border-radius-xl) !important;
  border-top-right-radius: var(--fc-border-radius-xl) !important;
}
.rounded-end-5 {
  border-bottom-right-radius: var(--fc-border-radius-xxl) !important;
  border-top-right-radius: var(--fc-border-radius-xxl) !important;
}
.rounded-end-circle {
  border-bottom-right-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
.rounded-end-pill {
  border-bottom-right-radius: var(--fc-border-radius-pill) !important;
  border-top-right-radius: var(--fc-border-radius-pill) !important;
}
.rounded-bottom {
  border-bottom-left-radius: var(--fc-border-radius) !important;
  border-bottom-right-radius: var(--fc-border-radius) !important;
}
.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-bottom-1 {
  border-bottom-left-radius: var(--fc-border-radius-sm) !important;
  border-bottom-right-radius: var(--fc-border-radius-sm) !important;
}
.rounded-bottom-2 {
  border-bottom-left-radius: var(--fc-border-radius) !important;
  border-bottom-right-radius: var(--fc-border-radius) !important;
}
.rounded-bottom-3 {
  border-bottom-left-radius: var(--fc-border-radius-lg) !important;
  border-bottom-right-radius: var(--fc-border-radius-lg) !important;
}
.rounded-bottom-4 {
  border-bottom-left-radius: var(--fc-border-radius-xl) !important;
  border-bottom-right-radius: var(--fc-border-radius-xl) !important;
}
.rounded-bottom-5 {
  border-bottom-left-radius: var(--fc-border-radius-xxl) !important;
  border-bottom-right-radius: var(--fc-border-radius-xxl) !important;
}
.rounded-bottom-circle {
  border-bottom-left-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.rounded-bottom-pill {
  border-bottom-left-radius: var(--fc-border-radius-pill) !important;
  border-bottom-right-radius: var(--fc-border-radius-pill) !important;
}
.rounded-start {
  border-bottom-left-radius: var(--fc-border-radius) !important;
  border-top-left-radius: var(--fc-border-radius) !important;
}
.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.rounded-start-1 {
  border-bottom-left-radius: var(--fc-border-radius-sm) !important;
  border-top-left-radius: var(--fc-border-radius-sm) !important;
}
.rounded-start-2 {
  border-bottom-left-radius: var(--fc-border-radius) !important;
  border-top-left-radius: var(--fc-border-radius) !important;
}
.rounded-start-3 {
  border-bottom-left-radius: var(--fc-border-radius-lg) !important;
  border-top-left-radius: var(--fc-border-radius-lg) !important;
}
.rounded-start-4 {
  border-bottom-left-radius: var(--fc-border-radius-xl) !important;
  border-top-left-radius: var(--fc-border-radius-xl) !important;
}
.rounded-start-5 {
  border-bottom-left-radius: var(--fc-border-radius-xxl) !important;
  border-top-left-radius: var(--fc-border-radius-xxl) !important;
}
.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}
.rounded-start-pill {
  border-bottom-left-radius: var(--fc-border-radius-pill) !important;
  border-top-left-radius: var(--fc-border-radius-pill) !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
.z-n1 {
  z-index: -1 !important;
}
.z-0 {
  z-index: 0 !important;
}
.z-1 {
  z-index: 1 !important;
}
.z-2 {
  z-index: 2 !important;
}
.z-3 {
  z-index: 3 !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .border-sm {
    border: var(--fc-border-width) var(--fc-border-style) var(--fc-border-color) !important;
  }
  .border-sm-0 {
    border: 0 !important;
  }
  .border-top-sm {
    border-top: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-top-sm-0 {
    border-top: 0 !important;
  }
  .border-end-sm {
    border-right: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-end-sm-0 {
    border-right: 0 !important;
  }
  .border-bottom-sm {
    border-bottom: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }
  .border-start-sm {
    border-left: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-start-sm-0 {
    border-left: 0 !important;
  }
  .w-sm-0 {
    width: 0 !important;
  }
  .w-sm-5 {
    width: 5% !important;
  }
  .w-sm-10 {
    width: 10% !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-45 {
    width: 45% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.25rem !important;
  }
  .m-sm-6 {
    margin: 1.5rem !important;
  }
  .m-sm-7 {
    margin: 1.75rem !important;
  }
  .m-sm-8 {
    margin: 2rem !important;
  }
  .m-sm-9 {
    margin: 2.5rem !important;
  }
  .m-sm-10 {
    margin: 3rem !important;
  }
  .m-sm-11 {
    margin: 3.5rem !important;
  }
  .m-sm-12 {
    margin: 4rem !important;
  }
  .m-sm-13 {
    margin: 4.5rem !important;
  }
  .m-sm-14 {
    margin: 5rem !important;
  }
  .m-sm-15 {
    margin: 6rem !important;
  }
  .m-sm-16 {
    margin: 8rem !important;
  }
  .m-sm-17 {
    margin: 10rem !important;
  }
  .m-sm-18 {
    margin: 12rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-sm-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-sm-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-sm-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-sm-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-sm-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-sm-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-sm-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-sm-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-sm-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-sm-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-sm-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-sm-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-sm-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-sm-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-sm-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-sm-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-sm-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-sm-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-sm-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-sm-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-sm-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-sm-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-sm-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-sm-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-sm-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-sm-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-sm-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-sm-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-sm-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-sm-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-sm-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-sm-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-4 {
    margin-top: 1rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 1.75rem !important;
  }
  .mt-sm-8 {
    margin-top: 2rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-10 {
    margin-top: 3rem !important;
  }
  .mt-sm-11 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-12 {
    margin-top: 4rem !important;
  }
  .mt-sm-13 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-14 {
    margin-top: 5rem !important;
  }
  .mt-sm-15 {
    margin-top: 6rem !important;
  }
  .mt-sm-16 {
    margin-top: 8rem !important;
  }
  .mt-sm-17 {
    margin-top: 10rem !important;
  }
  .mt-sm-18 {
    margin-top: 12rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 0.75rem !important;
  }
  .me-sm-4 {
    margin-right: 1rem !important;
  }
  .me-sm-5 {
    margin-right: 1.25rem !important;
  }
  .me-sm-6 {
    margin-right: 1.5rem !important;
  }
  .me-sm-7 {
    margin-right: 1.75rem !important;
  }
  .me-sm-8 {
    margin-right: 2rem !important;
  }
  .me-sm-9 {
    margin-right: 2.5rem !important;
  }
  .me-sm-10 {
    margin-right: 3rem !important;
  }
  .me-sm-11 {
    margin-right: 3.5rem !important;
  }
  .me-sm-12 {
    margin-right: 4rem !important;
  }
  .me-sm-13 {
    margin-right: 4.5rem !important;
  }
  .me-sm-14 {
    margin-right: 5rem !important;
  }
  .me-sm-15 {
    margin-right: 6rem !important;
  }
  .me-sm-16 {
    margin-right: 8rem !important;
  }
  .me-sm-17 {
    margin-right: 10rem !important;
  }
  .me-sm-18 {
    margin-right: 12rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-12 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-14 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-16 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-17 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-18 {
    margin-bottom: 12rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-4 {
    margin-left: 1rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 1.75rem !important;
  }
  .ms-sm-8 {
    margin-left: 2rem !important;
  }
  .ms-sm-9 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-10 {
    margin-left: 3rem !important;
  }
  .ms-sm-11 {
    margin-left: 3.5rem !important;
  }
  .ms-sm-12 {
    margin-left: 4rem !important;
  }
  .ms-sm-13 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-14 {
    margin-left: 5rem !important;
  }
  .ms-sm-15 {
    margin-left: 6rem !important;
  }
  .ms-sm-16 {
    margin-left: 8rem !important;
  }
  .ms-sm-17 {
    margin-left: 10rem !important;
  }
  .ms-sm-18 {
    margin-left: 12rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .m-sm-n4 {
    margin: -1rem !important;
  }
  .m-sm-n5 {
    margin: -1.25rem !important;
  }
  .m-sm-n6 {
    margin: -1.5rem !important;
  }
  .m-sm-n7 {
    margin: -1.75rem !important;
  }
  .m-sm-n8 {
    margin: -2rem !important;
  }
  .m-sm-n9 {
    margin: -2.5rem !important;
  }
  .m-sm-n10 {
    margin: -3rem !important;
  }
  .m-sm-n11 {
    margin: -3.5rem !important;
  }
  .m-sm-n12 {
    margin: -4rem !important;
  }
  .m-sm-n13 {
    margin: -4.5rem !important;
  }
  .m-sm-n14 {
    margin: -5rem !important;
  }
  .m-sm-n15 {
    margin: -6rem !important;
  }
  .m-sm-n16 {
    margin: -8rem !important;
  }
  .m-sm-n17 {
    margin: -10rem !important;
  }
  .m-sm-n18 {
    margin: -12rem !important;
  }
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-sm-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-sm-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-sm-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-sm-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-sm-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-sm-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-sm-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-sm-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-sm-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-sm-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-sm-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-sm-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-sm-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-sm-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-sm-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-sm-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-sm-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-sm-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-sm-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-sm-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-sm-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-sm-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-sm-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-sm-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-sm-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-sm-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-sm-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-sm-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-sm-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-sm-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n10 {
    margin-top: -3rem !important;
  }
  .mt-sm-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-sm-n12 {
    margin-top: -4rem !important;
  }
  .mt-sm-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n14 {
    margin-top: -5rem !important;
  }
  .mt-sm-n15 {
    margin-top: -6rem !important;
  }
  .mt-sm-n16 {
    margin-top: -8rem !important;
  }
  .mt-sm-n17 {
    margin-top: -10rem !important;
  }
  .mt-sm-n18 {
    margin-top: -12rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n4 {
    margin-right: -1rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n6 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -1.75rem !important;
  }
  .me-sm-n8 {
    margin-right: -2rem !important;
  }
  .me-sm-n9 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n10 {
    margin-right: -3rem !important;
  }
  .me-sm-n11 {
    margin-right: -3.5rem !important;
  }
  .me-sm-n12 {
    margin-right: -4rem !important;
  }
  .me-sm-n13 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n14 {
    margin-right: -5rem !important;
  }
  .me-sm-n15 {
    margin-right: -6rem !important;
  }
  .me-sm-n16 {
    margin-right: -8rem !important;
  }
  .me-sm-n17 {
    margin-right: -10rem !important;
  }
  .me-sm-n18 {
    margin-right: -12rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-sm-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-sm-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-sm-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-sm-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-sm-n8 {
    margin-left: -2rem !important;
  }
  .ms-sm-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n10 {
    margin-left: -3rem !important;
  }
  .ms-sm-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-sm-n12 {
    margin-left: -4rem !important;
  }
  .ms-sm-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n14 {
    margin-left: -5rem !important;
  }
  .ms-sm-n15 {
    margin-left: -6rem !important;
  }
  .ms-sm-n16 {
    margin-left: -8rem !important;
  }
  .ms-sm-n17 {
    margin-left: -10rem !important;
  }
  .ms-sm-n18 {
    margin-left: -12rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.25rem !important;
  }
  .p-sm-6 {
    padding: 1.5rem !important;
  }
  .p-sm-7 {
    padding: 1.75rem !important;
  }
  .p-sm-8 {
    padding: 2rem !important;
  }
  .p-sm-9 {
    padding: 2.5rem !important;
  }
  .p-sm-10 {
    padding: 3rem !important;
  }
  .p-sm-11 {
    padding: 3.5rem !important;
  }
  .p-sm-12 {
    padding: 4rem !important;
  }
  .p-sm-13 {
    padding: 4.5rem !important;
  }
  .p-sm-14 {
    padding: 5rem !important;
  }
  .p-sm-15 {
    padding: 6rem !important;
  }
  .p-sm-16 {
    padding: 8rem !important;
  }
  .p-sm-17 {
    padding: 10rem !important;
  }
  .p-sm-18 {
    padding: 12rem !important;
  }
  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-sm-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-sm-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-sm-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-sm-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-sm-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-sm-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-sm-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-sm-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-sm-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-sm-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-sm-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-sm-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-sm-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-sm-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-sm-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-sm-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-sm-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-sm-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-sm-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-sm-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-sm-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-sm-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-sm-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-sm-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-sm-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-sm-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-sm-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-sm-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-sm-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-sm-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-sm-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-sm-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-sm-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-4 {
    padding-top: 1rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 1.75rem !important;
  }
  .pt-sm-8 {
    padding-top: 2rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-10 {
    padding-top: 3rem !important;
  }
  .pt-sm-11 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-12 {
    padding-top: 4rem !important;
  }
  .pt-sm-13 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-14 {
    padding-top: 5rem !important;
  }
  .pt-sm-15 {
    padding-top: 6rem !important;
  }
  .pt-sm-16 {
    padding-top: 8rem !important;
  }
  .pt-sm-17 {
    padding-top: 10rem !important;
  }
  .pt-sm-18 {
    padding-top: 12rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-4 {
    padding-right: 1rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 1.75rem !important;
  }
  .pe-sm-8 {
    padding-right: 2rem !important;
  }
  .pe-sm-9 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-10 {
    padding-right: 3rem !important;
  }
  .pe-sm-11 {
    padding-right: 3.5rem !important;
  }
  .pe-sm-12 {
    padding-right: 4rem !important;
  }
  .pe-sm-13 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-14 {
    padding-right: 5rem !important;
  }
  .pe-sm-15 {
    padding-right: 6rem !important;
  }
  .pe-sm-16 {
    padding-right: 8rem !important;
  }
  .pe-sm-17 {
    padding-right: 10rem !important;
  }
  .pe-sm-18 {
    padding-right: 12rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-12 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-14 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-16 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-17 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-18 {
    padding-bottom: 12rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-4 {
    padding-left: 1rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 1.75rem !important;
  }
  .ps-sm-8 {
    padding-left: 2rem !important;
  }
  .ps-sm-9 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-10 {
    padding-left: 3rem !important;
  }
  .ps-sm-11 {
    padding-left: 3.5rem !important;
  }
  .ps-sm-12 {
    padding-left: 4rem !important;
  }
  .ps-sm-13 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-14 {
    padding-left: 5rem !important;
  }
  .ps-sm-15 {
    padding-left: 6rem !important;
  }
  .ps-sm-16 {
    padding-left: 8rem !important;
  }
  .ps-sm-17 {
    padding-left: 10rem !important;
  }
  .ps-sm-18 {
    padding-left: 12rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 0.75rem !important;
  }
  .gap-sm-4 {
    gap: 1rem !important;
  }
  .gap-sm-5 {
    gap: 1.25rem !important;
  }
  .gap-sm-6 {
    gap: 1.5rem !important;
  }
  .gap-sm-7 {
    gap: 1.75rem !important;
  }
  .gap-sm-8 {
    gap: 2rem !important;
  }
  .gap-sm-9 {
    gap: 2.5rem !important;
  }
  .gap-sm-10 {
    gap: 3rem !important;
  }
  .gap-sm-11 {
    gap: 3.5rem !important;
  }
  .gap-sm-12 {
    gap: 4rem !important;
  }
  .gap-sm-13 {
    gap: 4.5rem !important;
  }
  .gap-sm-14 {
    gap: 5rem !important;
  }
  .gap-sm-15 {
    gap: 6rem !important;
  }
  .gap-sm-16 {
    gap: 8rem !important;
  }
  .gap-sm-17 {
    gap: 10rem !important;
  }
  .gap-sm-18 {
    gap: 12rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-sm-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-sm-8 {
    row-gap: 2rem !important;
  }
  .row-gap-sm-9 {
    row-gap: 2.5rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 3rem !important;
  }
  .row-gap-sm-11 {
    row-gap: 3.5rem !important;
  }
  .row-gap-sm-12 {
    row-gap: 4rem !important;
  }
  .row-gap-sm-13 {
    row-gap: 4.5rem !important;
  }
  .row-gap-sm-14 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-15 {
    row-gap: 6rem !important;
  }
  .row-gap-sm-16 {
    row-gap: 8rem !important;
  }
  .row-gap-sm-17 {
    row-gap: 10rem !important;
  }
  .row-gap-sm-18 {
    row-gap: 12rem !important;
  }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-sm-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-sm-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }
  .column-gap-sm-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }
  .column-gap-sm-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-sm-9 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }
  .column-gap-sm-10 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-sm-11 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }
  .column-gap-sm-12 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-sm-13 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }
  .column-gap-sm-14 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }
  .column-gap-sm-15 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-sm-16 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .column-gap-sm-17 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }
  .column-gap-sm-18 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .border-md {
    border: var(--fc-border-width) var(--fc-border-style) var(--fc-border-color) !important;
  }
  .border-md-0 {
    border: 0 !important;
  }
  .border-top-md {
    border-top: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-top-md-0 {
    border-top: 0 !important;
  }
  .border-end-md {
    border-right: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-end-md-0 {
    border-right: 0 !important;
  }
  .border-bottom-md {
    border-bottom: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }
  .border-start-md {
    border-left: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-start-md-0 {
    border-left: 0 !important;
  }
  .w-md-0 {
    width: 0 !important;
  }
  .w-md-5 {
    width: 5% !important;
  }
  .w-md-10 {
    width: 10% !important;
  }
  .w-md-15 {
    width: 15% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-45 {
    width: 45% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .m-md-5 {
    margin: 1.25rem !important;
  }
  .m-md-6 {
    margin: 1.5rem !important;
  }
  .m-md-7 {
    margin: 1.75rem !important;
  }
  .m-md-8 {
    margin: 2rem !important;
  }
  .m-md-9 {
    margin: 2.5rem !important;
  }
  .m-md-10 {
    margin: 3rem !important;
  }
  .m-md-11 {
    margin: 3.5rem !important;
  }
  .m-md-12 {
    margin: 4rem !important;
  }
  .m-md-13 {
    margin: 4.5rem !important;
  }
  .m-md-14 {
    margin: 5rem !important;
  }
  .m-md-15 {
    margin: 6rem !important;
  }
  .m-md-16 {
    margin: 8rem !important;
  }
  .m-md-17 {
    margin: 10rem !important;
  }
  .m-md-18 {
    margin: 12rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-md-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-md-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-md-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-md-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-md-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-md-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-md-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-md-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-md-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-md-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-md-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-md-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-md-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-md-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-md-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-md-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-md-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-md-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-md-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-md-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-md-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-md-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-md-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-md-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-md-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-md-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-md-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-md-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-md-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-md-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-md-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-md-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-md-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-md-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-md-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 0.75rem !important;
  }
  .mt-md-4 {
    margin-top: 1rem !important;
  }
  .mt-md-5 {
    margin-top: 1.25rem !important;
  }
  .mt-md-6 {
    margin-top: 1.5rem !important;
  }
  .mt-md-7 {
    margin-top: 1.75rem !important;
  }
  .mt-md-8 {
    margin-top: 2rem !important;
  }
  .mt-md-9 {
    margin-top: 2.5rem !important;
  }
  .mt-md-10 {
    margin-top: 3rem !important;
  }
  .mt-md-11 {
    margin-top: 3.5rem !important;
  }
  .mt-md-12 {
    margin-top: 4rem !important;
  }
  .mt-md-13 {
    margin-top: 4.5rem !important;
  }
  .mt-md-14 {
    margin-top: 5rem !important;
  }
  .mt-md-15 {
    margin-top: 6rem !important;
  }
  .mt-md-16 {
    margin-top: 8rem !important;
  }
  .mt-md-17 {
    margin-top: 10rem !important;
  }
  .mt-md-18 {
    margin-top: 12rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 0.75rem !important;
  }
  .me-md-4 {
    margin-right: 1rem !important;
  }
  .me-md-5 {
    margin-right: 1.25rem !important;
  }
  .me-md-6 {
    margin-right: 1.5rem !important;
  }
  .me-md-7 {
    margin-right: 1.75rem !important;
  }
  .me-md-8 {
    margin-right: 2rem !important;
  }
  .me-md-9 {
    margin-right: 2.5rem !important;
  }
  .me-md-10 {
    margin-right: 3rem !important;
  }
  .me-md-11 {
    margin-right: 3.5rem !important;
  }
  .me-md-12 {
    margin-right: 4rem !important;
  }
  .me-md-13 {
    margin-right: 4.5rem !important;
  }
  .me-md-14 {
    margin-right: 5rem !important;
  }
  .me-md-15 {
    margin-right: 6rem !important;
  }
  .me-md-16 {
    margin-right: 8rem !important;
  }
  .me-md-17 {
    margin-right: 10rem !important;
  }
  .me-md-18 {
    margin-right: 12rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-10 {
    margin-bottom: 3rem !important;
  }
  .mb-md-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-12 {
    margin-bottom: 4rem !important;
  }
  .mb-md-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-14 {
    margin-bottom: 5rem !important;
  }
  .mb-md-15 {
    margin-bottom: 6rem !important;
  }
  .mb-md-16 {
    margin-bottom: 8rem !important;
  }
  .mb-md-17 {
    margin-bottom: 10rem !important;
  }
  .mb-md-18 {
    margin-bottom: 12rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 0.75rem !important;
  }
  .ms-md-4 {
    margin-left: 1rem !important;
  }
  .ms-md-5 {
    margin-left: 1.25rem !important;
  }
  .ms-md-6 {
    margin-left: 1.5rem !important;
  }
  .ms-md-7 {
    margin-left: 1.75rem !important;
  }
  .ms-md-8 {
    margin-left: 2rem !important;
  }
  .ms-md-9 {
    margin-left: 2.5rem !important;
  }
  .ms-md-10 {
    margin-left: 3rem !important;
  }
  .ms-md-11 {
    margin-left: 3.5rem !important;
  }
  .ms-md-12 {
    margin-left: 4rem !important;
  }
  .ms-md-13 {
    margin-left: 4.5rem !important;
  }
  .ms-md-14 {
    margin-left: 5rem !important;
  }
  .ms-md-15 {
    margin-left: 6rem !important;
  }
  .ms-md-16 {
    margin-left: 8rem !important;
  }
  .ms-md-17 {
    margin-left: 10rem !important;
  }
  .ms-md-18 {
    margin-left: 12rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1rem !important;
  }
  .m-md-n5 {
    margin: -1.25rem !important;
  }
  .m-md-n6 {
    margin: -1.5rem !important;
  }
  .m-md-n7 {
    margin: -1.75rem !important;
  }
  .m-md-n8 {
    margin: -2rem !important;
  }
  .m-md-n9 {
    margin: -2.5rem !important;
  }
  .m-md-n10 {
    margin: -3rem !important;
  }
  .m-md-n11 {
    margin: -3.5rem !important;
  }
  .m-md-n12 {
    margin: -4rem !important;
  }
  .m-md-n13 {
    margin: -4.5rem !important;
  }
  .m-md-n14 {
    margin: -5rem !important;
  }
  .m-md-n15 {
    margin: -6rem !important;
  }
  .m-md-n16 {
    margin: -8rem !important;
  }
  .m-md-n17 {
    margin: -10rem !important;
  }
  .m-md-n18 {
    margin: -12rem !important;
  }
  .mx-md-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-md-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-md-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-md-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-md-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-md-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-md-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-md-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-md-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-md-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-md-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-md-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-md-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-md-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-md-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-md-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-md-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-md-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-md-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-md-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-md-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-md-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-md-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-md-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-md-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-md-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-md-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-md-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-md-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-md-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-md-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-md-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n4 {
    margin-top: -1rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-md-n8 {
    margin-top: -2rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n10 {
    margin-top: -3rem !important;
  }
  .mt-md-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-md-n12 {
    margin-top: -4rem !important;
  }
  .mt-md-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n14 {
    margin-top: -5rem !important;
  }
  .mt-md-n15 {
    margin-top: -6rem !important;
  }
  .mt-md-n16 {
    margin-top: -8rem !important;
  }
  .mt-md-n17 {
    margin-top: -10rem !important;
  }
  .mt-md-n18 {
    margin-top: -12rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -0.75rem !important;
  }
  .me-md-n4 {
    margin-right: -1rem !important;
  }
  .me-md-n5 {
    margin-right: -1.25rem !important;
  }
  .me-md-n6 {
    margin-right: -1.5rem !important;
  }
  .me-md-n7 {
    margin-right: -1.75rem !important;
  }
  .me-md-n8 {
    margin-right: -2rem !important;
  }
  .me-md-n9 {
    margin-right: -2.5rem !important;
  }
  .me-md-n10 {
    margin-right: -3rem !important;
  }
  .me-md-n11 {
    margin-right: -3.5rem !important;
  }
  .me-md-n12 {
    margin-right: -4rem !important;
  }
  .me-md-n13 {
    margin-right: -4.5rem !important;
  }
  .me-md-n14 {
    margin-right: -5rem !important;
  }
  .me-md-n15 {
    margin-right: -6rem !important;
  }
  .me-md-n16 {
    margin-right: -8rem !important;
  }
  .me-md-n17 {
    margin-right: -10rem !important;
  }
  .me-md-n18 {
    margin-right: -12rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-md-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-md-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-md-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-md-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n4 {
    margin-left: -1rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-md-n8 {
    margin-left: -2rem !important;
  }
  .ms-md-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n10 {
    margin-left: -3rem !important;
  }
  .ms-md-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-md-n12 {
    margin-left: -4rem !important;
  }
  .ms-md-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n14 {
    margin-left: -5rem !important;
  }
  .ms-md-n15 {
    margin-left: -6rem !important;
  }
  .ms-md-n16 {
    margin-left: -8rem !important;
  }
  .ms-md-n17 {
    margin-left: -10rem !important;
  }
  .ms-md-n18 {
    margin-left: -12rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .p-md-5 {
    padding: 1.25rem !important;
  }
  .p-md-6 {
    padding: 1.5rem !important;
  }
  .p-md-7 {
    padding: 1.75rem !important;
  }
  .p-md-8 {
    padding: 2rem !important;
  }
  .p-md-9 {
    padding: 2.5rem !important;
  }
  .p-md-10 {
    padding: 3rem !important;
  }
  .p-md-11 {
    padding: 3.5rem !important;
  }
  .p-md-12 {
    padding: 4rem !important;
  }
  .p-md-13 {
    padding: 4.5rem !important;
  }
  .p-md-14 {
    padding: 5rem !important;
  }
  .p-md-15 {
    padding: 6rem !important;
  }
  .p-md-16 {
    padding: 8rem !important;
  }
  .p-md-17 {
    padding: 10rem !important;
  }
  .p-md-18 {
    padding: 12rem !important;
  }
  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-md-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-md-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-md-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-md-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-md-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-md-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-md-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-md-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-md-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-md-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-md-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-md-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-md-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-md-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-md-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-md-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-md-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-md-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-md-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-md-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-md-3 {
    /* padding-bottom: 0.75rem !important; */
    padding-top: 0.75rem !important;
  }
  .py-md-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-md-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-md-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-md-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-md-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-md-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-md-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-md-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-md-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-md-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-md-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-md-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-md-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-md-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-md-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 0.75rem !important;
  }
  .pt-md-4 {
    padding-top: 1rem !important;
  }
  .pt-md-5 {
    padding-top: 1.25rem !important;
  }
  .pt-md-6 {
    padding-top: 1.5rem !important;
  }
  .pt-md-7 {
    padding-top: 1.75rem !important;
  }
  .pt-md-8 {
    padding-top: 2rem !important;
  }
  .pt-md-9 {
    padding-top: 2.5rem !important;
  }
  .pt-md-10 {
    padding-top: 3rem !important;
  }
  .pt-md-11 {
    padding-top: 3.5rem !important;
  }
  .pt-md-12 {
    padding-top: 4rem !important;
  }
  .pt-md-13 {
    padding-top: 4.5rem !important;
  }
  .pt-md-14 {
    padding-top: 5rem !important;
  }
  .pt-md-15 {
    padding-top: 6rem !important;
  }
  .pt-md-16 {
    padding-top: 8rem !important;
  }
  .pt-md-17 {
    padding-top: 10rem !important;
  }
  .pt-md-18 {
    padding-top: 12rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 0.75rem !important;
  }
  .pe-md-4 {
    padding-right: 1rem !important;
  }
  .pe-md-5 {
    padding-right: 1.25rem !important;
  }
  .pe-md-6 {
    padding-right: 1.5rem !important;
  }
  .pe-md-7 {
    padding-right: 1.75rem !important;
  }
  .pe-md-8 {
    padding-right: 2rem !important;
  }
  .pe-md-9 {
    padding-right: 2.5rem !important;
  }
  .pe-md-10 {
    padding-right: 3rem !important;
  }
  .pe-md-11 {
    padding-right: 3.5rem !important;
  }
  .pe-md-12 {
    padding-right: 4rem !important;
  }
  .pe-md-13 {
    padding-right: 4.5rem !important;
  }
  .pe-md-14 {
    padding-right: 5rem !important;
  }
  .pe-md-15 {
    padding-right: 6rem !important;
  }
  .pe-md-16 {
    padding-right: 8rem !important;
  }
  .pe-md-17 {
    padding-right: 10rem !important;
  }
  .pe-md-18 {
    padding-right: 12rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-10 {
    padding-bottom: 3rem !important;
  }
  .pb-md-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-12 {
    padding-bottom: 4rem !important;
  }
  .pb-md-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-14 {
    padding-bottom: 5rem !important;
  }
  .pb-md-15 {
    padding-bottom: 6rem !important;
  }
  .pb-md-16 {
    padding-bottom: 8rem !important;
  }
  .pb-md-17 {
    padding-bottom: 10rem !important;
  }
  .pb-md-18 {
    padding-bottom: 12rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 0.75rem !important;
  }
  .ps-md-4 {
    padding-left: 1rem !important;
  }
  .ps-md-5 {
    padding-left: 1.25rem !important;
  }
  .ps-md-6 {
    padding-left: 1.5rem !important;
  }
  .ps-md-7 {
    padding-left: 1.75rem !important;
  }
  .ps-md-8 {
    padding-left: 2rem !important;
  }
  .ps-md-9 {
    padding-left: 2.5rem !important;
  }
  .ps-md-10 {
    padding-left: 3rem !important;
  }
  .ps-md-11 {
    padding-left: 3.5rem !important;
  }
  .ps-md-12 {
    padding-left: 4rem !important;
  }
  .ps-md-13 {
    padding-left: 4.5rem !important;
  }
  .ps-md-14 {
    padding-left: 5rem !important;
  }
  .ps-md-15 {
    padding-left: 6rem !important;
  }
  .ps-md-16 {
    padding-left: 8rem !important;
  }
  .ps-md-17 {
    padding-left: 10rem !important;
  }
  .ps-md-18 {
    padding-left: 12rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 0.75rem !important;
  }
  .gap-md-4 {
    gap: 1rem !important;
  }
  .gap-md-5 {
    gap: 1.25rem !important;
  }
  .gap-md-6 {
    gap: 1.5rem !important;
  }
  .gap-md-7 {
    gap: 1.75rem !important;
  }
  .gap-md-8 {
    gap: 2rem !important;
  }
  .gap-md-9 {
    gap: 2.5rem !important;
  }
  .gap-md-10 {
    gap: 3rem !important;
  }
  .gap-md-11 {
    gap: 3.5rem !important;
  }
  .gap-md-12 {
    gap: 4rem !important;
  }
  .gap-md-13 {
    gap: 4.5rem !important;
  }
  .gap-md-14 {
    gap: 5rem !important;
  }
  .gap-md-15 {
    gap: 6rem !important;
  }
  .gap-md-16 {
    gap: 8rem !important;
  }
  .gap-md-17 {
    gap: 10rem !important;
  }
  .gap-md-18 {
    gap: 12rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1rem !important;
  }
  .row-gap-md-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-md-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-md-8 {
    row-gap: 2rem !important;
  }
  .row-gap-md-9 {
    row-gap: 2.5rem !important;
  }
  .row-gap-md-10 {
    row-gap: 3rem !important;
  }
  .row-gap-md-11 {
    row-gap: 3.5rem !important;
  }
  .row-gap-md-12 {
    row-gap: 4rem !important;
  }
  .row-gap-md-13 {
    row-gap: 4.5rem !important;
  }
  .row-gap-md-14 {
    row-gap: 5rem !important;
  }
  .row-gap-md-15 {
    row-gap: 6rem !important;
  }
  .row-gap-md-16 {
    row-gap: 8rem !important;
  }
  .row-gap-md-17 {
    row-gap: 10rem !important;
  }
  .row-gap-md-18 {
    row-gap: 12rem !important;
  }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-md-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-md-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }
  .column-gap-md-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-md-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }
  .column-gap-md-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-md-9 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }
  .column-gap-md-10 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-md-11 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }
  .column-gap-md-12 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-md-13 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }
  .column-gap-md-14 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }
  .column-gap-md-15 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-md-16 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .column-gap-md-17 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }
  .column-gap-md-18 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .border-lg {
    border: var(--fc-border-width) var(--fc-border-style) var(--fc-border-color) !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-top-lg {
    border-top: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-end-lg {
    border-right: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-end-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .border-start-lg {
    border-left: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-start-lg-0 {
    border-left: 0 !important;
  }
  .w-lg-0 {
    width: 0 !important;
  }
  .w-lg-5 {
    width: 5% !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-15 {
    width: 15% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-45 {
    width: 45% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.25rem !important;
  }
  .m-lg-6 {
    margin: 1.5rem !important;
  }
  .m-lg-7 {
    margin: 1.75rem !important;
  }
  .m-lg-8 {
    margin: 2rem !important;
  }
  .m-lg-9 {
    margin: 2.5rem !important;
  }
  .m-lg-10 {
    margin: 3rem !important;
  }
  .m-lg-11 {
    margin: 3.5rem !important;
  }
  .m-lg-12 {
    margin: 4rem !important;
  }
  .m-lg-13 {
    margin: 4.5rem !important;
  }
  .m-lg-14 {
    margin: 5rem !important;
  }
  .m-lg-15 {
    margin: 6rem !important;
  }
  .m-lg-16 {
    margin: 8rem !important;
  }
  .m-lg-17 {
    margin: 10rem !important;
  }
  .m-lg-18 {
    margin: 12rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-lg-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-lg-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-lg-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-lg-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-lg-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-lg-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-lg-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-lg-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-lg-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-lg-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-lg-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-lg-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-lg-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-lg-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-lg-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-lg-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-lg-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-lg-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-lg-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-lg-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-lg-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-lg-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-lg-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-lg-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-lg-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-lg-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-lg-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-lg-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-lg-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-lg-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-lg-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-4 {
    margin-top: 1rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 1.75rem !important;
  }
  .mt-lg-8 {
    margin-top: 2rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-10 {
    margin-top: 3rem !important;
  }
  .mt-lg-11 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-12 {
    margin-top: 4rem !important;
  }
  .mt-lg-13 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-14 {
    margin-top: 5rem !important;
  }
  .mt-lg-15 {
    margin-top: 6rem !important;
  }
  .mt-lg-16 {
    margin-top: 8rem !important;
  }
  .mt-lg-17 {
    margin-top: 10rem !important;
  }
  .mt-lg-18 {
    margin-top: 12rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 0.75rem !important;
  }
  .me-lg-4 {
    margin-right: 1rem !important;
  }
  .me-lg-5 {
    margin-right: 1.25rem !important;
  }
  .me-lg-6 {
    margin-right: 1.5rem !important;
  }
  .me-lg-7 {
    margin-right: 1.75rem !important;
  }
  .me-lg-8 {
    margin-right: 2rem !important;
  }
  .me-lg-9 {
    margin-right: 2.5rem !important;
  }
  .me-lg-10 {
    margin-right: 3rem !important;
  }
  .me-lg-11 {
    margin-right: 3.5rem !important;
  }
  .me-lg-12 {
    margin-right: 4rem !important;
  }
  .me-lg-13 {
    margin-right: 4.5rem !important;
  }
  .me-lg-14 {
    margin-right: 5rem !important;
  }
  .me-lg-15 {
    margin-right: 6rem !important;
  }
  .me-lg-16 {
    margin-right: 8rem !important;
  }
  .me-lg-17 {
    margin-right: 10rem !important;
  }
  .me-lg-18 {
    margin-right: 12rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-12 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-14 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-16 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-17 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-18 {
    margin-bottom: 12rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-4 {
    margin-left: 1rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 1.75rem !important;
  }
  .ms-lg-8 {
    margin-left: 2rem !important;
  }
  .ms-lg-9 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-10 {
    margin-left: 3rem !important;
  }
  .ms-lg-11 {
    margin-left: 3.5rem !important;
  }
  .ms-lg-12 {
    margin-left: 4rem !important;
  }
  .ms-lg-13 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-14 {
    margin-left: 5rem !important;
  }
  .ms-lg-15 {
    margin-left: 6rem !important;
  }
  .ms-lg-16 {
    margin-left: 8rem !important;
  }
  .ms-lg-17 {
    margin-left: 10rem !important;
  }
  .ms-lg-18 {
    margin-left: 12rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1rem !important;
  }
  .m-lg-n5 {
    margin: -1.25rem !important;
  }
  .m-lg-n6 {
    margin: -1.5rem !important;
  }
  .m-lg-n7 {
    margin: -1.75rem !important;
  }
  .m-lg-n8 {
    margin: -2rem !important;
  }
  .m-lg-n9 {
    margin: -2.5rem !important;
  }
  .m-lg-n10 {
    margin: -3rem !important;
  }
  .m-lg-n11 {
    margin: -3.5rem !important;
  }
  .m-lg-n12 {
    margin: -4rem !important;
  }
  .m-lg-n13 {
    margin: -4.5rem !important;
  }
  .m-lg-n14 {
    margin: -5rem !important;
  }
  .m-lg-n15 {
    margin: -6rem !important;
  }
  .m-lg-n16 {
    margin: -8rem !important;
  }
  .m-lg-n17 {
    margin: -10rem !important;
  }
  .m-lg-n18 {
    margin: -12rem !important;
  }
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-lg-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-lg-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-lg-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-lg-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-lg-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-lg-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-lg-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-lg-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-lg-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-lg-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-lg-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-lg-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-lg-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-lg-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-lg-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-lg-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-lg-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-lg-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-lg-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-lg-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-lg-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-lg-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-lg-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-lg-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-lg-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-lg-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-lg-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-lg-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-lg-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-lg-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n10 {
    margin-top: -3rem !important;
  }
  .mt-lg-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-lg-n12 {
    margin-top: -4rem !important;
  }
  .mt-lg-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n14 {
    margin-top: -5rem !important;
  }
  .mt-lg-n15 {
    margin-top: -6rem !important;
  }
  .mt-lg-n16 {
    margin-top: -8rem !important;
  }
  .mt-lg-n17 {
    margin-top: -10rem !important;
  }
  .mt-lg-n18 {
    margin-top: -12rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n4 {
    margin-right: -1rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n6 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -1.75rem !important;
  }
  .me-lg-n8 {
    margin-right: -2rem !important;
  }
  .me-lg-n9 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n10 {
    margin-right: -3rem !important;
  }
  .me-lg-n11 {
    margin-right: -3.5rem !important;
  }
  .me-lg-n12 {
    margin-right: -4rem !important;
  }
  .me-lg-n13 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n14 {
    margin-right: -5rem !important;
  }
  .me-lg-n15 {
    margin-right: -6rem !important;
  }
  .me-lg-n16 {
    margin-right: -8rem !important;
  }
  .me-lg-n17 {
    margin-right: -10rem !important;
  }
  .me-lg-n18 {
    margin-right: -12rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-lg-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-lg-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-lg-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-lg-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-lg-n8 {
    margin-left: -2rem !important;
  }
  .ms-lg-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n10 {
    margin-left: -3rem !important;
  }
  .ms-lg-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-lg-n12 {
    margin-left: -4rem !important;
  }
  .ms-lg-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n14 {
    margin-left: -5rem !important;
  }
  .ms-lg-n15 {
    margin-left: -6rem !important;
  }
  .ms-lg-n16 {
    margin-left: -8rem !important;
  }
  .ms-lg-n17 {
    margin-left: -10rem !important;
  }
  .ms-lg-n18 {
    margin-left: -12rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.25rem !important;
  }
  .p-lg-6 {
    padding: 1.5rem !important;
  }
  .p-lg-7 {
    padding: 1.75rem !important;
  }
  .p-lg-8 {
    padding: 2rem !important;
  }
  .p-lg-9 {
    padding: 2.5rem !important;
  }
  .p-lg-10 {
    padding: 3rem !important;
  }
  .p-lg-11 {
    padding: 3.5rem !important;
  }
  .p-lg-12 {
    padding: 4rem !important;
  }
  .p-lg-13 {
    padding: 4.5rem !important;
  }
  .p-lg-14 {
    padding: 5rem !important;
  }
  .p-lg-15 {
    padding: 6rem !important;
  }
  .p-lg-16 {
    padding: 8rem !important;
  }
  .p-lg-17 {
    padding: 10rem !important;
  }
  .p-lg-18 {
    padding: 12rem !important;
  }
  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-lg-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-lg-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-lg-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-lg-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-lg-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-lg-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-lg-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-lg-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-lg-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-lg-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-lg-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-lg-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-lg-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-lg-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-lg-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-lg-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-lg-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-lg-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-lg-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-lg-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-lg-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-lg-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-lg-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-lg-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-lg-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-lg-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-lg-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-lg-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-lg-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-lg-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-lg-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-lg-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-lg-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-4 {
    padding-top: 1rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 1.75rem !important;
  }
  .pt-lg-8 {
    padding-top: 2rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-10 {
    padding-top: 3rem !important;
  }
  .pt-lg-11 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-12 {
    padding-top: 4rem !important;
  }
  .pt-lg-13 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-14 {
    padding-top: 5rem !important;
  }
  .pt-lg-15 {
    padding-top: 6rem !important;
  }
  .pt-lg-16 {
    padding-top: 8rem !important;
  }
  .pt-lg-17 {
    padding-top: 10rem !important;
  }
  .pt-lg-18 {
    padding-top: 12rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-4 {
    padding-right: 1rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 1.75rem !important;
  }
  .pe-lg-8 {
    padding-right: 2rem !important;
  }
  .pe-lg-9 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-10 {
    padding-right: 3rem !important;
  }
  .pe-lg-11 {
    padding-right: 3.5rem !important;
  }
  .pe-lg-12 {
    padding-right: 4rem !important;
  }
  .pe-lg-13 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-14 {
    padding-right: 5rem !important;
  }
  .pe-lg-15 {
    padding-right: 6rem !important;
  }
  .pe-lg-16 {
    padding-right: 8rem !important;
  }
  .pe-lg-17 {
    padding-right: 10rem !important;
  }
  .pe-lg-18 {
    padding-right: 12rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-12 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-14 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-16 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-17 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-18 {
    padding-bottom: 12rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-4 {
    padding-left: 1rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 1.75rem !important;
  }
  .ps-lg-8 {
    padding-left: 2rem !important;
  }
  .ps-lg-9 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-10 {
    padding-left: 3rem !important;
  }
  .ps-lg-11 {
    padding-left: 3.5rem !important;
  }
  .ps-lg-12 {
    padding-left: 4rem !important;
  }
  .ps-lg-13 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-14 {
    padding-left: 5rem !important;
  }
  .ps-lg-15 {
    padding-left: 6rem !important;
  }
  .ps-lg-16 {
    padding-left: 8rem !important;
  }
  .ps-lg-17 {
    padding-left: 10rem !important;
  }
  .ps-lg-18 {
    padding-left: 12rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 0.75rem !important;
  }
  .gap-lg-4 {
    gap: 1rem !important;
  }
  .gap-lg-5 {
    gap: 1.25rem !important;
  }
  .gap-lg-6 {
    gap: 1.5rem !important;
  }
  .gap-lg-7 {
    gap: 1.75rem !important;
  }
  .gap-lg-8 {
    gap: 2rem !important;
  }
  .gap-lg-9 {
    gap: 2.5rem !important;
  }
  .gap-lg-10 {
    gap: 3rem !important;
  }
  .gap-lg-11 {
    gap: 3.5rem !important;
  }
  .gap-lg-12 {
    gap: 4rem !important;
  }
  .gap-lg-13 {
    gap: 4.5rem !important;
  }
  .gap-lg-14 {
    gap: 5rem !important;
  }
  .gap-lg-15 {
    gap: 6rem !important;
  }
  .gap-lg-16 {
    gap: 8rem !important;
  }
  .gap-lg-17 {
    gap: 10rem !important;
  }
  .gap-lg-18 {
    gap: 12rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-lg-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-lg-8 {
    row-gap: 2rem !important;
  }
  .row-gap-lg-9 {
    row-gap: 2.5rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 3rem !important;
  }
  .row-gap-lg-11 {
    row-gap: 3.5rem !important;
  }
  .row-gap-lg-12 {
    row-gap: 4rem !important;
  }
  .row-gap-lg-13 {
    row-gap: 4.5rem !important;
  }
  .row-gap-lg-14 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-15 {
    row-gap: 6rem !important;
  }
  .row-gap-lg-16 {
    row-gap: 8rem !important;
  }
  .row-gap-lg-17 {
    row-gap: 10rem !important;
  }
  .row-gap-lg-18 {
    row-gap: 12rem !important;
  }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-lg-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-lg-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }
  .column-gap-lg-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }
  .column-gap-lg-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-lg-9 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }
  .column-gap-lg-10 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-lg-11 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }
  .column-gap-lg-12 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-lg-13 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }
  .column-gap-lg-14 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }
  .column-gap-lg-15 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-lg-16 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .column-gap-lg-17 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }
  .column-gap-lg-18 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .border-xl {
    border: var(--fc-border-width) var(--fc-border-style) var(--fc-border-color) !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-top-xl {
    border-top: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-end-xl {
    border-right: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-end-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xl {
    border-left: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-start-xl-0 {
    border-left: 0 !important;
  }
  .w-xl-0 {
    width: 0 !important;
  }
  .w-xl-5 {
    width: 5% !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-15 {
    width: 15% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-45 {
    width: 45% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.25rem !important;
  }
  .m-xl-6 {
    margin: 1.5rem !important;
  }
  .m-xl-7 {
    margin: 1.75rem !important;
  }
  .m-xl-8 {
    margin: 2rem !important;
  }
  .m-xl-9 {
    margin: 2.5rem !important;
  }
  .m-xl-10 {
    margin: 3rem !important;
  }
  .m-xl-11 {
    margin: 3.5rem !important;
  }
  .m-xl-12 {
    margin: 4rem !important;
  }
  .m-xl-13 {
    margin: 4.5rem !important;
  }
  .m-xl-14 {
    margin: 5rem !important;
  }
  .m-xl-15 {
    margin: 6rem !important;
  }
  .m-xl-16 {
    margin: 8rem !important;
  }
  .m-xl-17 {
    margin: 10rem !important;
  }
  .m-xl-18 {
    margin: 12rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-xl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-xl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-xl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-xl-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-xl-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xl-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-xl-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-xl-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xl-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-xl-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-xl-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-xl-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-xl-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-xl-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xl-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-xl-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xl-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-xl-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-xl-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-xl-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xl-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-xl-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-xl-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xl-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-xl-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-xl-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-xl-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-xl-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-xl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-4 {
    margin-top: 1rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xl-8 {
    margin-top: 2rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-10 {
    margin-top: 3rem !important;
  }
  .mt-xl-11 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-12 {
    margin-top: 4rem !important;
  }
  .mt-xl-13 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-14 {
    margin-top: 5rem !important;
  }
  .mt-xl-15 {
    margin-top: 6rem !important;
  }
  .mt-xl-16 {
    margin-top: 8rem !important;
  }
  .mt-xl-17 {
    margin-top: 10rem !important;
  }
  .mt-xl-18 {
    margin-top: 12rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xl-4 {
    margin-right: 1rem !important;
  }
  .me-xl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xl-8 {
    margin-right: 2rem !important;
  }
  .me-xl-9 {
    margin-right: 2.5rem !important;
  }
  .me-xl-10 {
    margin-right: 3rem !important;
  }
  .me-xl-11 {
    margin-right: 3.5rem !important;
  }
  .me-xl-12 {
    margin-right: 4rem !important;
  }
  .me-xl-13 {
    margin-right: 4.5rem !important;
  }
  .me-xl-14 {
    margin-right: 5rem !important;
  }
  .me-xl-15 {
    margin-right: 6rem !important;
  }
  .me-xl-16 {
    margin-right: 8rem !important;
  }
  .me-xl-17 {
    margin-right: 10rem !important;
  }
  .me-xl-18 {
    margin-right: 12rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-12 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-14 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-15 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-16 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-17 {
    margin-bottom: 10rem !important;
  }
  .mb-xl-18 {
    margin-bottom: 12rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-4 {
    margin-left: 1rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xl-8 {
    margin-left: 2rem !important;
  }
  .ms-xl-9 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-10 {
    margin-left: 3rem !important;
  }
  .ms-xl-11 {
    margin-left: 3.5rem !important;
  }
  .ms-xl-12 {
    margin-left: 4rem !important;
  }
  .ms-xl-13 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-14 {
    margin-left: 5rem !important;
  }
  .ms-xl-15 {
    margin-left: 6rem !important;
  }
  .ms-xl-16 {
    margin-left: 8rem !important;
  }
  .ms-xl-17 {
    margin-left: 10rem !important;
  }
  .ms-xl-18 {
    margin-left: 12rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1rem !important;
  }
  .m-xl-n5 {
    margin: -1.25rem !important;
  }
  .m-xl-n6 {
    margin: -1.5rem !important;
  }
  .m-xl-n7 {
    margin: -1.75rem !important;
  }
  .m-xl-n8 {
    margin: -2rem !important;
  }
  .m-xl-n9 {
    margin: -2.5rem !important;
  }
  .m-xl-n10 {
    margin: -3rem !important;
  }
  .m-xl-n11 {
    margin: -3.5rem !important;
  }
  .m-xl-n12 {
    margin: -4rem !important;
  }
  .m-xl-n13 {
    margin: -4.5rem !important;
  }
  .m-xl-n14 {
    margin: -5rem !important;
  }
  .m-xl-n15 {
    margin: -6rem !important;
  }
  .m-xl-n16 {
    margin: -8rem !important;
  }
  .m-xl-n17 {
    margin: -10rem !important;
  }
  .m-xl-n18 {
    margin: -12rem !important;
  }
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-xl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-xl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-xl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-xl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-xl-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-xl-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-xl-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-xl-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-xl-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xl-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-xl-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-xl-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-xl-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-xl-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-xl-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-xl-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-xl-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xl-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-xl-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-xl-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-xl-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-xl-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-xl-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-xl-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xl-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-xl-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-xl-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-xl-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-xl-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n10 {
    margin-top: -3rem !important;
  }
  .mt-xl-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-xl-n12 {
    margin-top: -4rem !important;
  }
  .mt-xl-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n14 {
    margin-top: -5rem !important;
  }
  .mt-xl-n15 {
    margin-top: -6rem !important;
  }
  .mt-xl-n16 {
    margin-top: -8rem !important;
  }
  .mt-xl-n17 {
    margin-top: -10rem !important;
  }
  .mt-xl-n18 {
    margin-top: -12rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n4 {
    margin-right: -1rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n6 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -1.75rem !important;
  }
  .me-xl-n8 {
    margin-right: -2rem !important;
  }
  .me-xl-n9 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n10 {
    margin-right: -3rem !important;
  }
  .me-xl-n11 {
    margin-right: -3.5rem !important;
  }
  .me-xl-n12 {
    margin-right: -4rem !important;
  }
  .me-xl-n13 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n14 {
    margin-right: -5rem !important;
  }
  .me-xl-n15 {
    margin-right: -6rem !important;
  }
  .me-xl-n16 {
    margin-right: -8rem !important;
  }
  .me-xl-n17 {
    margin-right: -10rem !important;
  }
  .me-xl-n18 {
    margin-right: -12rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xl-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-xl-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-xl-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-xl-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-xl-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-xl-n8 {
    margin-left: -2rem !important;
  }
  .ms-xl-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n10 {
    margin-left: -3rem !important;
  }
  .ms-xl-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-xl-n12 {
    margin-left: -4rem !important;
  }
  .ms-xl-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n14 {
    margin-left: -5rem !important;
  }
  .ms-xl-n15 {
    margin-left: -6rem !important;
  }
  .ms-xl-n16 {
    margin-left: -8rem !important;
  }
  .ms-xl-n17 {
    margin-left: -10rem !important;
  }
  .ms-xl-n18 {
    margin-left: -12rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.25rem !important;
  }
  .p-xl-6 {
    padding: 1.5rem !important;
  }
  .p-xl-7 {
    padding: 1.75rem !important;
  }
  .p-xl-8 {
    padding: 2rem !important;
  }
  .p-xl-9 {
    padding: 2.5rem !important;
  }
  .p-xl-10 {
    padding: 3rem !important;
  }
  .p-xl-11 {
    padding: 3.5rem !important;
  }
  .p-xl-12 {
    padding: 4rem !important;
  }
  .p-xl-13 {
    padding: 4.5rem !important;
  }
  .p-xl-14 {
    padding: 5rem !important;
  }
  .p-xl-15 {
    padding: 6rem !important;
  }
  .p-xl-16 {
    padding: 8rem !important;
  }
  .p-xl-17 {
    padding: 10rem !important;
  }
  .p-xl-18 {
    padding: 12rem !important;
  }
  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-xl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-xl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-xl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-xl-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-xl-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xl-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-xl-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-xl-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xl-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-xl-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-xl-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-xl-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-xl-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-xl-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xl-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-xl-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xl-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-xl-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-xl-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-xl-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xl-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-xl-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-xl-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xl-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-xl-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-xl-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-xl-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-xl-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-4 {
    padding-top: 1rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xl-8 {
    padding-top: 2rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-10 {
    padding-top: 3rem !important;
  }
  .pt-xl-11 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-12 {
    padding-top: 4rem !important;
  }
  .pt-xl-13 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-14 {
    padding-top: 5rem !important;
  }
  .pt-xl-15 {
    padding-top: 6rem !important;
  }
  .pt-xl-16 {
    padding-top: 8rem !important;
  }
  .pt-xl-17 {
    padding-top: 10rem !important;
  }
  .pt-xl-18 {
    padding-top: 12rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-4 {
    padding-right: 1rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xl-8 {
    padding-right: 2rem !important;
  }
  .pe-xl-9 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-10 {
    padding-right: 3rem !important;
  }
  .pe-xl-11 {
    padding-right: 3.5rem !important;
  }
  .pe-xl-12 {
    padding-right: 4rem !important;
  }
  .pe-xl-13 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-14 {
    padding-right: 5rem !important;
  }
  .pe-xl-15 {
    padding-right: 6rem !important;
  }
  .pe-xl-16 {
    padding-right: 8rem !important;
  }
  .pe-xl-17 {
    padding-right: 10rem !important;
  }
  .pe-xl-18 {
    padding-right: 12rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-12 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-14 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-15 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-16 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-17 {
    padding-bottom: 10rem !important;
  }
  .pb-xl-18 {
    padding-bottom: 12rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-4 {
    padding-left: 1rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xl-8 {
    padding-left: 2rem !important;
  }
  .ps-xl-9 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-10 {
    padding-left: 3rem !important;
  }
  .ps-xl-11 {
    padding-left: 3.5rem !important;
  }
  .ps-xl-12 {
    padding-left: 4rem !important;
  }
  .ps-xl-13 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-14 {
    padding-left: 5rem !important;
  }
  .ps-xl-15 {
    padding-left: 6rem !important;
  }
  .ps-xl-16 {
    padding-left: 8rem !important;
  }
  .ps-xl-17 {
    padding-left: 10rem !important;
  }
  .ps-xl-18 {
    padding-left: 12rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 0.75rem !important;
  }
  .gap-xl-4 {
    gap: 1rem !important;
  }
  .gap-xl-5 {
    gap: 1.25rem !important;
  }
  .gap-xl-6 {
    gap: 1.5rem !important;
  }
  .gap-xl-7 {
    gap: 1.75rem !important;
  }
  .gap-xl-8 {
    gap: 2rem !important;
  }
  .gap-xl-9 {
    gap: 2.5rem !important;
  }
  .gap-xl-10 {
    gap: 3rem !important;
  }
  .gap-xl-11 {
    gap: 3.5rem !important;
  }
  .gap-xl-12 {
    gap: 4rem !important;
  }
  .gap-xl-13 {
    gap: 4.5rem !important;
  }
  .gap-xl-14 {
    gap: 5rem !important;
  }
  .gap-xl-15 {
    gap: 6rem !important;
  }
  .gap-xl-16 {
    gap: 8rem !important;
  }
  .gap-xl-17 {
    gap: 10rem !important;
  }
  .gap-xl-18 {
    gap: 12rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xl-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xl-8 {
    row-gap: 2rem !important;
  }
  .row-gap-xl-9 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xl-10 {
    row-gap: 3rem !important;
  }
  .row-gap-xl-11 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xl-12 {
    row-gap: 4rem !important;
  }
  .row-gap-xl-13 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xl-14 {
    row-gap: 5rem !important;
  }
  .row-gap-xl-15 {
    row-gap: 6rem !important;
  }
  .row-gap-xl-16 {
    row-gap: 8rem !important;
  }
  .row-gap-xl-17 {
    row-gap: 10rem !important;
  }
  .row-gap-xl-18 {
    row-gap: 12rem !important;
  }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-xl-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-xl-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }
  .column-gap-xl-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }
  .column-gap-xl-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-xl-9 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }
  .column-gap-xl-10 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-xl-11 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }
  .column-gap-xl-12 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-xl-13 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }
  .column-gap-xl-14 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }
  .column-gap-xl-15 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-xl-16 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .column-gap-xl-17 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }
  .column-gap-xl-18 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .border-xxl {
    border: var(--fc-border-width) var(--fc-border-style) var(--fc-border-color) !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-top-xxl {
    border-top: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-end-xxl {
    border-right: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .border-start-xxl {
    border-left: var(--fc-border-width) var(--fc-border-style)
      var(--fc-border-color) !important;
  }
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
  .w-xxl-0 {
    width: 0 !important;
  }
  .w-xxl-5 {
    width: 5% !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-15 {
    width: 15% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-45 {
    width: 45% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-65 {
    width: 65% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 0.75rem !important;
  }
  .m-xxl-4 {
    margin: 1rem !important;
  }
  .m-xxl-5 {
    margin: 1.25rem !important;
  }
  .m-xxl-6 {
    margin: 1.5rem !important;
  }
  .m-xxl-7 {
    margin: 1.75rem !important;
  }
  .m-xxl-8 {
    margin: 2rem !important;
  }
  .m-xxl-9 {
    margin: 2.5rem !important;
  }
  .m-xxl-10 {
    margin: 3rem !important;
  }
  .m-xxl-11 {
    margin: 3.5rem !important;
  }
  .m-xxl-12 {
    margin: 4rem !important;
  }
  .m-xxl-13 {
    margin: 4.5rem !important;
  }
  .m-xxl-14 {
    margin: 5rem !important;
  }
  .m-xxl-15 {
    margin: 6rem !important;
  }
  .m-xxl-16 {
    margin: 8rem !important;
  }
  .m-xxl-17 {
    margin: 10rem !important;
  }
  .m-xxl-18 {
    margin: 12rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }
  .mx-xxl-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xxl-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }
  .mx-xxl-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xxl-7 {
    margin-left: 1.75rem !important;
    margin-right: 1.75rem !important;
  }
  .mx-xxl-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-xxl-9 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-xxl-10 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xxl-11 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-xxl-12 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-xxl-13 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xxl-14 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .mx-xxl-15 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }
  .mx-xxl-16 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }
  .mx-xxl-17 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }
  .mx-xxl-18 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }
  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .my-xxl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
    margin-top: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-bottom: 0.75rem !important;
    margin-top: 0.75rem !important;
  }
  .my-xxl-4 {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .my-xxl-5 {
    margin-bottom: 1.25rem !important;
    margin-top: 1.25rem !important;
  }
  .my-xxl-6 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
  }
  .my-xxl-7 {
    margin-bottom: 1.75rem !important;
    margin-top: 1.75rem !important;
  }
  .my-xxl-8 {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }
  .my-xxl-9 {
    margin-bottom: 2.5rem !important;
    margin-top: 2.5rem !important;
  }
  .my-xxl-10 {
    margin-bottom: 3rem !important;
    margin-top: 3rem !important;
  }
  .my-xxl-11 {
    margin-bottom: 3.5rem !important;
    margin-top: 3.5rem !important;
  }
  .my-xxl-12 {
    margin-bottom: 4rem !important;
    margin-top: 4rem !important;
  }
  .my-xxl-13 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important;
  }
  .my-xxl-14 {
    margin-bottom: 5rem !important;
    margin-top: 5rem !important;
  }
  .my-xxl-15 {
    margin-bottom: 6rem !important;
    margin-top: 6rem !important;
  }
  .my-xxl-16 {
    margin-bottom: 8rem !important;
    margin-top: 8rem !important;
  }
  .my-xxl-17 {
    margin-bottom: 10rem !important;
    margin-top: 10rem !important;
  }
  .my-xxl-18 {
    margin-bottom: 12rem !important;
    margin-top: 12rem !important;
  }
  .my-xxl-auto {
    margin-bottom: auto !important;
    margin-top: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 1.75rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-10 {
    margin-top: 3rem !important;
  }
  .mt-xxl-11 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-12 {
    margin-top: 4rem !important;
  }
  .mt-xxl-13 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-14 {
    margin-top: 5rem !important;
  }
  .mt-xxl-15 {
    margin-top: 6rem !important;
  }
  .mt-xxl-16 {
    margin-top: 8rem !important;
  }
  .mt-xxl-17 {
    margin-top: 10rem !important;
  }
  .mt-xxl-18 {
    margin-top: 12rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-4 {
    margin-right: 1rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 1.75rem !important;
  }
  .me-xxl-8 {
    margin-right: 2rem !important;
  }
  .me-xxl-9 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-10 {
    margin-right: 3rem !important;
  }
  .me-xxl-11 {
    margin-right: 3.5rem !important;
  }
  .me-xxl-12 {
    margin-right: 4rem !important;
  }
  .me-xxl-13 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-14 {
    margin-right: 5rem !important;
  }
  .me-xxl-15 {
    margin-right: 6rem !important;
  }
  .me-xxl-16 {
    margin-right: 8rem !important;
  }
  .me-xxl-17 {
    margin-right: 10rem !important;
  }
  .me-xxl-18 {
    margin-right: 12rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 1.75rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-12 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-13 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-14 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-15 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-16 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-17 {
    margin-bottom: 10rem !important;
  }
  .mb-xxl-18 {
    margin-bottom: 12rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 1.75rem !important;
  }
  .ms-xxl-8 {
    margin-left: 2rem !important;
  }
  .ms-xxl-9 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-10 {
    margin-left: 3rem !important;
  }
  .ms-xxl-11 {
    margin-left: 3.5rem !important;
  }
  .ms-xxl-12 {
    margin-left: 4rem !important;
  }
  .ms-xxl-13 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-14 {
    margin-left: 5rem !important;
  }
  .ms-xxl-15 {
    margin-left: 6rem !important;
  }
  .ms-xxl-16 {
    margin-left: 8rem !important;
  }
  .ms-xxl-17 {
    margin-left: 10rem !important;
  }
  .ms-xxl-18 {
    margin-left: 12rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -0.75rem !important;
  }
  .m-xxl-n4 {
    margin: -1rem !important;
  }
  .m-xxl-n5 {
    margin: -1.25rem !important;
  }
  .m-xxl-n6 {
    margin: -1.5rem !important;
  }
  .m-xxl-n7 {
    margin: -1.75rem !important;
  }
  .m-xxl-n8 {
    margin: -2rem !important;
  }
  .m-xxl-n9 {
    margin: -2.5rem !important;
  }
  .m-xxl-n10 {
    margin: -3rem !important;
  }
  .m-xxl-n11 {
    margin: -3.5rem !important;
  }
  .m-xxl-n12 {
    margin: -4rem !important;
  }
  .m-xxl-n13 {
    margin: -4.5rem !important;
  }
  .m-xxl-n14 {
    margin: -5rem !important;
  }
  .m-xxl-n15 {
    margin: -6rem !important;
  }
  .m-xxl-n16 {
    margin: -8rem !important;
  }
  .m-xxl-n17 {
    margin: -10rem !important;
  }
  .m-xxl-n18 {
    margin: -12rem !important;
  }
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }
  .mx-xxl-n4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }
  .mx-xxl-n5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }
  .mx-xxl-n6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
  .mx-xxl-n7 {
    margin-left: -1.75rem !important;
    margin-right: -1.75rem !important;
  }
  .mx-xxl-n8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }
  .mx-xxl-n9 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }
  .mx-xxl-n10 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }
  .mx-xxl-n11 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }
  .mx-xxl-n12 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }
  .mx-xxl-n13 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }
  .mx-xxl-n14 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }
  .mx-xxl-n15 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }
  .mx-xxl-n16 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }
  .mx-xxl-n17 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }
  .mx-xxl-n18 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
    margin-top: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
    margin-top: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-bottom: -0.75rem !important;
    margin-top: -0.75rem !important;
  }
  .my-xxl-n4 {
    margin-bottom: -1rem !important;
    margin-top: -1rem !important;
  }
  .my-xxl-n5 {
    margin-bottom: -1.25rem !important;
    margin-top: -1.25rem !important;
  }
  .my-xxl-n6 {
    margin-bottom: -1.5rem !important;
    margin-top: -1.5rem !important;
  }
  .my-xxl-n7 {
    margin-bottom: -1.75rem !important;
    margin-top: -1.75rem !important;
  }
  .my-xxl-n8 {
    margin-bottom: -2rem !important;
    margin-top: -2rem !important;
  }
  .my-xxl-n9 {
    margin-bottom: -2.5rem !important;
    margin-top: -2.5rem !important;
  }
  .my-xxl-n10 {
    margin-bottom: -3rem !important;
    margin-top: -3rem !important;
  }
  .my-xxl-n11 {
    margin-bottom: -3.5rem !important;
    margin-top: -3.5rem !important;
  }
  .my-xxl-n12 {
    margin-bottom: -4rem !important;
    margin-top: -4rem !important;
  }
  .my-xxl-n13 {
    margin-bottom: -4.5rem !important;
    margin-top: -4.5rem !important;
  }
  .my-xxl-n14 {
    margin-bottom: -5rem !important;
    margin-top: -5rem !important;
  }
  .my-xxl-n15 {
    margin-bottom: -6rem !important;
    margin-top: -6rem !important;
  }
  .my-xxl-n16 {
    margin-bottom: -8rem !important;
    margin-top: -8rem !important;
  }
  .my-xxl-n17 {
    margin-bottom: -10rem !important;
    margin-top: -10rem !important;
  }
  .my-xxl-n18 {
    margin-bottom: -12rem !important;
    margin-top: -12rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -1.75rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -2rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -3.5rem !important;
  }
  .mt-xxl-n12 {
    margin-top: -4rem !important;
  }
  .mt-xxl-n13 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n14 {
    margin-top: -5rem !important;
  }
  .mt-xxl-n15 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n16 {
    margin-top: -8rem !important;
  }
  .mt-xxl-n17 {
    margin-top: -10rem !important;
  }
  .mt-xxl-n18 {
    margin-top: -12rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1rem !important;
  }
  .me-xxl-n5 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n6 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -1.75rem !important;
  }
  .me-xxl-n8 {
    margin-right: -2rem !important;
  }
  .me-xxl-n9 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n10 {
    margin-right: -3rem !important;
  }
  .me-xxl-n11 {
    margin-right: -3.5rem !important;
  }
  .me-xxl-n12 {
    margin-right: -4rem !important;
  }
  .me-xxl-n13 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n14 {
    margin-right: -5rem !important;
  }
  .me-xxl-n15 {
    margin-right: -6rem !important;
  }
  .me-xxl-n16 {
    margin-right: -8rem !important;
  }
  .me-xxl-n17 {
    margin-right: -10rem !important;
  }
  .me-xxl-n18 {
    margin-right: -12rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -1.75rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -2rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -3.5rem !important;
  }
  .mb-xxl-n12 {
    margin-bottom: -4rem !important;
  }
  .mb-xxl-n13 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n14 {
    margin-bottom: -5rem !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n16 {
    margin-bottom: -8rem !important;
  }
  .mb-xxl-n17 {
    margin-bottom: -10rem !important;
  }
  .mb-xxl-n18 {
    margin-bottom: -12rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -1.75rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -2rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -3.5rem !important;
  }
  .ms-xxl-n12 {
    margin-left: -4rem !important;
  }
  .ms-xxl-n13 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n14 {
    margin-left: -5rem !important;
  }
  .ms-xxl-n15 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n16 {
    margin-left: -8rem !important;
  }
  .ms-xxl-n17 {
    margin-left: -10rem !important;
  }
  .ms-xxl-n18 {
    margin-left: -12rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 0.75rem !important;
  }
  .p-xxl-4 {
    padding: 1rem !important;
  }
  .p-xxl-5 {
    padding: 1.25rem !important;
  }
  .p-xxl-6 {
    padding: 1.5rem !important;
  }
  .p-xxl-7 {
    padding: 1.75rem !important;
  }
  .p-xxl-8 {
    padding: 2rem !important;
  }
  .p-xxl-9 {
    padding: 2.5rem !important;
  }
  .p-xxl-10 {
    padding: 3rem !important;
  }
  .p-xxl-11 {
    padding: 3.5rem !important;
  }
  .p-xxl-12 {
    padding: 4rem !important;
  }
  .p-xxl-13 {
    padding: 4.5rem !important;
  }
  .p-xxl-14 {
    padding: 5rem !important;
  }
  .p-xxl-15 {
    padding: 6rem !important;
  }
  .p-xxl-16 {
    padding: 8rem !important;
  }
  .p-xxl-17 {
    padding: 10rem !important;
  }
  .p-xxl-18 {
    padding: 12rem !important;
  }
  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .px-xxl-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .px-xxl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xxl-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  .px-xxl-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xxl-7 {
    padding-left: 1.75rem !important;
    padding-right: 1.75rem !important;
  }
  .px-xxl-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-xxl-9 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-xxl-10 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xxl-11 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-xxl-12 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-xxl-13 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xxl-14 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .px-xxl-15 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
  .px-xxl-16 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }
  .px-xxl-17 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }
  .px-xxl-18 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }
  .py-xxl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
    padding-top: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-bottom: 0.75rem !important;
    padding-top: 0.75rem !important;
  }
  .py-xxl-4 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .py-xxl-5 {
    padding-bottom: 1.25rem !important;
    padding-top: 1.25rem !important;
  }
  .py-xxl-6 {
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
  }
  .py-xxl-7 {
    padding-bottom: 1.75rem !important;
    padding-top: 1.75rem !important;
  }
  .py-xxl-8 {
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
  }
  .py-xxl-9 {
    padding-bottom: 2.5rem !important;
    padding-top: 2.5rem !important;
  }
  .py-xxl-10 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
  }
  .py-xxl-11 {
    padding-bottom: 3.5rem !important;
    padding-top: 3.5rem !important;
  }
  .py-xxl-12 {
    padding-bottom: 4rem !important;
    padding-top: 4rem !important;
  }
  .py-xxl-13 {
    padding-bottom: 4.5rem !important;
    padding-top: 4.5rem !important;
  }
  .py-xxl-14 {
    padding-bottom: 5rem !important;
    padding-top: 5rem !important;
  }
  .py-xxl-15 {
    padding-bottom: 6rem !important;
    padding-top: 6rem !important;
  }
  .py-xxl-16 {
    padding-bottom: 8rem !important;
    padding-top: 8rem !important;
  }
  .py-xxl-17 {
    padding-bottom: 10rem !important;
    padding-top: 10rem !important;
  }
  .py-xxl-18 {
    padding-bottom: 12rem !important;
    padding-top: 12rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 1.75rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-10 {
    padding-top: 3rem !important;
  }
  .pt-xxl-11 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-12 {
    padding-top: 4rem !important;
  }
  .pt-xxl-13 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-14 {
    padding-top: 5rem !important;
  }
  .pt-xxl-15 {
    padding-top: 6rem !important;
  }
  .pt-xxl-16 {
    padding-top: 8rem !important;
  }
  .pt-xxl-17 {
    padding-top: 10rem !important;
  }
  .pt-xxl-18 {
    padding-top: 12rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 1.75rem !important;
  }
  .pe-xxl-8 {
    padding-right: 2rem !important;
  }
  .pe-xxl-9 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-10 {
    padding-right: 3rem !important;
  }
  .pe-xxl-11 {
    padding-right: 3.5rem !important;
  }
  .pe-xxl-12 {
    padding-right: 4rem !important;
  }
  .pe-xxl-13 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-14 {
    padding-right: 5rem !important;
  }
  .pe-xxl-15 {
    padding-right: 6rem !important;
  }
  .pe-xxl-16 {
    padding-right: 8rem !important;
  }
  .pe-xxl-17 {
    padding-right: 10rem !important;
  }
  .pe-xxl-18 {
    padding-right: 12rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 1.75rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-12 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-13 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-14 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-15 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-16 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-17 {
    padding-bottom: 10rem !important;
  }
  .pb-xxl-18 {
    padding-bottom: 12rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 1.75rem !important;
  }
  .ps-xxl-8 {
    padding-left: 2rem !important;
  }
  .ps-xxl-9 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-10 {
    padding-left: 3rem !important;
  }
  .ps-xxl-11 {
    padding-left: 3.5rem !important;
  }
  .ps-xxl-12 {
    padding-left: 4rem !important;
  }
  .ps-xxl-13 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-14 {
    padding-left: 5rem !important;
  }
  .ps-xxl-15 {
    padding-left: 6rem !important;
  }
  .ps-xxl-16 {
    padding-left: 8rem !important;
  }
  .ps-xxl-17 {
    padding-left: 10rem !important;
  }
  .ps-xxl-18 {
    padding-left: 12rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 0.75rem !important;
  }
  .gap-xxl-4 {
    gap: 1rem !important;
  }
  .gap-xxl-5 {
    gap: 1.25rem !important;
  }
  .gap-xxl-6 {
    gap: 1.5rem !important;
  }
  .gap-xxl-7 {
    gap: 1.75rem !important;
  }
  .gap-xxl-8 {
    gap: 2rem !important;
  }
  .gap-xxl-9 {
    gap: 2.5rem !important;
  }
  .gap-xxl-10 {
    gap: 3rem !important;
  }
  .gap-xxl-11 {
    gap: 3.5rem !important;
  }
  .gap-xxl-12 {
    gap: 4rem !important;
  }
  .gap-xxl-13 {
    gap: 4.5rem !important;
  }
  .gap-xxl-14 {
    gap: 5rem !important;
  }
  .gap-xxl-15 {
    gap: 6rem !important;
  }
  .gap-xxl-16 {
    gap: 8rem !important;
  }
  .gap-xxl-17 {
    gap: 10rem !important;
  }
  .gap-xxl-18 {
    gap: 12rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 0.75rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 1.25rem !important;
  }
  .row-gap-xxl-6 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-7 {
    row-gap: 1.75rem !important;
  }
  .row-gap-xxl-8 {
    row-gap: 2rem !important;
  }
  .row-gap-xxl-9 {
    row-gap: 2.5rem !important;
  }
  .row-gap-xxl-10 {
    row-gap: 3rem !important;
  }
  .row-gap-xxl-11 {
    row-gap: 3.5rem !important;
  }
  .row-gap-xxl-12 {
    row-gap: 4rem !important;
  }
  .row-gap-xxl-13 {
    row-gap: 4.5rem !important;
  }
  .row-gap-xxl-14 {
    row-gap: 5rem !important;
  }
  .row-gap-xxl-15 {
    row-gap: 6rem !important;
  }
  .row-gap-xxl-16 {
    row-gap: 8rem !important;
  }
  .row-gap-xxl-17 {
    row-gap: 10rem !important;
  }
  .row-gap-xxl-18 {
    row-gap: 12rem !important;
  }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    -moz-column-gap: 0.75rem !important;
    column-gap: 0.75rem !important;
  }
  .column-gap-xxl-4 {
    -moz-column-gap: 1rem !important;
    column-gap: 1rem !important;
  }
  .column-gap-xxl-5 {
    -moz-column-gap: 1.25rem !important;
    column-gap: 1.25rem !important;
  }
  .column-gap-xxl-6 {
    -moz-column-gap: 1.5rem !important;
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-7 {
    -moz-column-gap: 1.75rem !important;
    column-gap: 1.75rem !important;
  }
  .column-gap-xxl-8 {
    -moz-column-gap: 2rem !important;
    column-gap: 2rem !important;
  }
  .column-gap-xxl-9 {
    -moz-column-gap: 2.5rem !important;
    column-gap: 2.5rem !important;
  }
  .column-gap-xxl-10 {
    -moz-column-gap: 3rem !important;
    column-gap: 3rem !important;
  }
  .column-gap-xxl-11 {
    -moz-column-gap: 3.5rem !important;
    column-gap: 3.5rem !important;
  }
  .column-gap-xxl-12 {
    -moz-column-gap: 4rem !important;
    column-gap: 4rem !important;
  }
  .column-gap-xxl-13 {
    -moz-column-gap: 4.5rem !important;
    column-gap: 4.5rem !important;
  }
  .column-gap-xxl-14 {
    -moz-column-gap: 5rem !important;
    column-gap: 5rem !important;
  }
  .column-gap-xxl-15 {
    -moz-column-gap: 6rem !important;
    column-gap: 6rem !important;
  }
  .column-gap-xxl-16 {
    -moz-column-gap: 8rem !important;
    column-gap: 8rem !important;
  }
  .column-gap-xxl-17 {
    -moz-column-gap: 10rem !important;
    column-gap: 10rem !important;
  }
  .column-gap-xxl-18 {
    -moz-column-gap: 12rem !important;
    column-gap: 12rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.1875rem !important;
  }
  .fs-2 {
    font-size: 1.75rem !important;
  }
  .fs-3 {
    font-size: 1.53125rem !important;
  }
  .fs-4 {
    font-size: 1.3125rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.navbar {
  padding: 0.625rem 1rem;
}
.navbar .navbar-brand {
  padding-bottom: 0.2rem;
  padding-top: 0;
}
.navbar .navbar-toggler {
  border: none;
  margin-left: 0.5rem;
  padding: 0;
}
.navbar .navbar-toggler:focus {
  box-shadow: none;
  outline: 0;
}
.navbar .navbar-toggler .icon-bar {
  background-color: var(--fc-primary);
  display: block;
  height: 2px;
  margin-top: 0.25rem;
  transition: all 0.2s;
  width: 20px;
}
.navbar .navbar-toggler .top-bar {
  background-color: var(--fc-primary);
  transform: rotate(45deg);
  transform-origin: 16% 190%;
}
.navbar .navbar-toggler .middle-bar {
  opacity: 0;
}
.navbar .navbar-toggler .bottom-bar {
  background-color: var(--fc-primary);
  transform: rotate(-45deg);
  transform-origin: 10% -80%;
}
.navbar .navbar-collapse .navbar-toggler {
  position: absolute;
  right: 0.5rem;
  top: 0.6rem;
  z-index: 1;
}
.navbar .navbar-toggler.collapsed .top-bar {
  background-color: var(--fc-primary);
  transform: rotate(0);
}
.navbar .navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
.navbar .navbar-toggler.collapsed .bottom-bar {
  background-color: var(--fc-primary);
  transform: rotate(0);
}
.navbar .navbar-nav .nav-item .nav-link {
  font-size: 13px;
  line-height: 1.75;
  color: #000;
  font-family: Arial;
  font-weight: bold;
  font-style: normal;
  line-height: 185%;
  font-family: BrandonText-Light, sans-serif;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .navbar .navbar-nav .nav-item .nav-link {
    border-bottom: 0.5px solid #bdbebb;
    font-family: var(--fc-font-bold);
    text-transform: uppercase;
    color: #000 !important;
    padding: 18px 20px;
  }
  /* .mob-c-view .offcanvas.offcanvas-start {
    margin-top: 30%;
  } */
  .mob-c-view .offcanvas-backdrop.show {
    opacity: 0 !important;
  }
}
.mob-c-view {
  margin-left: 2%;
}
.navbar .dropdown-fullwidth {
  position: static;
}
.navbar .dropdown-fullwidth .dropdown-menu {
  left: 0;
  margin: 1.25rem auto;
  max-width: 1320px;
  padding: 1rem 0;
  right: 0;
  /* top: 32px; */
}
/* .navbar .dropdown .dropdown-toggle:after {
  background-repeat: no-repeat;
  background-size: 0.625rem 0.625rem;
  border: none;
  color: var(--fc-gray-600);
  content: "\f12f";
  float: right;
  font-family: feather-icons !important;
  line-height: 2;
  margin-left: 0.375rem;
} */
.navbar .dropdown-menu {
  border-radius: 0 !important;
  color: var(--fc-gray-600);
  line-height: 1.2rem;
  min-width: 12rem;
  top: 40px;
}
.navbar .dropdown-menu .dropdown-list-group-item {
  min-width: 100%;
}
.navbar .dropdown-menu .dropdown-submenu a:after {
  position: absolute;
  right: 1rem;
  transform: rotate(-90deg);
}
.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-menu {
  left: 100%;
  min-width: 12rem;
  right: 0;
  top: -18px;
}
.navbar .dropdown-menu .dropdown-submenuBig a:after {
  position: absolute;
  right: 1rem;
  transform: rotate(-90deg);
}
.navbar .dropdown-menu .dropdown-submenuBig.dropend .dropdown-menu {
  left: 105%;
  min-width: 12rem;
  right: 0;
  top: -35px;
}
.navbar .navbar-right-wrap .list-group-item {
  padding: 1rem 1.25rem;
}
.navbar .nav-badge {
  position: absolute;
  right: 3px;
  top: -9px;
}
@media (min-width: 1200px) {
  .navbar-default .dropdown-menu {
    display: block;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease-in;
    visibility: hidden;
  }
  .navbar-default .dropdown-menu-end {
    left: auto;
    right: 0;
  }
  .navbar-default .dropdown-menu-start {
    left: 0;
    right: auto;
  }
  .navbar-default .dropdown-submenu:hover > .dropdown-menu,
  .navbar-default .dropdown-submenuBig:hover > .dropdown-menu,
  .navbar-default .dropdown:hover > .dropdown-menu {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
  }
}
.navbar-nav-offcanvac .nav-item .nav-link {
  border-bottom: 1px solid var(--fc-gray-200);
}
@media (max-width: 990px) {
  .navbar-offcanvas-color .nav-item {
    width: 100%;
  }
  .navbar-offcanvas-color .nav-item .nav-link {
    border-bottom: 1px solid var(--fc-gray-200);
    color: #21313c;
  }
}
.navbar-dark .navbar-brand {
  /* filter: brightness(0) invert(1);  commented by akhil*/
  filter: unset;
}
.navbar-dark .navbar-toggler {
  border-color: var(--fc-white);
}
.navbar-dark .navbar-toggler .icon-bar {
  background-color: var(--fc-white);
}
.navbar-dark .navbar-toggler.collapsed .top-bar {
  background-color: var(--fc-white);
  transform: rotate(0);
}
.navbar-dark .navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
.navbar-dark .navbar-toggler.collapsed .bottom-bar {
  background-color: var(--fc-white);
  transform: rotate(0);
}
.navbar-dark .navbar-nav .dropdown .dropdown-toggle:after {
  color: var(--fc-gray-400);
}
.navbar-dark .navbar-nav .nav-link {
  border-bottom: transparent;
}
.navbar-toggler-icon {
  height: 1.2em;
}
.icon-hover {
  background-color: transparent;
  transition: background-color 0.5s ease-in-out;
}
.icon-hover:hover {
  background-color: var(--fc-light-primary);
}
body {
  -webkit-font-smoothing: antialiased;
}
a {
  color: #0aad0a;
}
a,
a:hover {
  text-decoration: none;
}
a:hover {
  color: #088a08;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  letter-spacing: -0.03rem;
}
.nav-scroll {
  border-bottom: 1px solid var(--fc-gray-300);
}
@media (max-width: 990px) {
  .nav-scroll {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .nav-scroll::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 990px) {
  .nav-scroll .nav-item .nav-link {
    white-space: nowrap;
  }
}
.nav-category .nav-item .nav-link {
  align-items: center;
  color: var(--fc-black);
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
}
/* Style the container with a fixed height and enable overflow */
.scroll-container {
  height: 450px;
  overflow: auto;
}
.unselect {
  color: var(--fc-black);
  font-size: 16px;
  text-transform: capitalize;
  font-family: var(--fc-font-regular);
  text-decoration: underline;
  cursor: pointer;
}
.unselect:hover {
  color: var(--fc-black);
  font-size: 16px;
  text-transform: capitalize;
  font-family: var(--fc-font-regular);

  text-decoration: underline;
}
/* Track - The scrollbar itself */
.scroll-container::-webkit-scrollbar {
  width: 10px;
}

/* Handle - The draggable part of the scrollbar */
.scroll-container::-webkit-scrollbar-thumb {
  border-radius: 37px;
  background: rgba(0, 0, 0, 0.2);
  width: 8px;
}

/* On hover, make the scrollbar handle darker */
.scroll-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.scroll-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.scroll-container::-moz-scrollbar-thumb {
  border-radius: 37px;
  background: rgba(0, 0, 0, 0.2);
  width: 8px;
}

.scroll-container::-moz-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
/* mini cart */
.scroll-container2 {
  max-height: 220px;
  overflow: auto;
}

/* Track - The scrollbar itself */
.scroll-container2::-webkit-scrollbar {
  width: 10px;
}

/* Handle - The draggable part of the scrollbar */
.scroll-container2::-webkit-scrollbar-thumb {
  border-radius: 37px;
  background: rgba(0, 0, 0, 0.2);
  width: 8px;
}

/* On hover, make the scrollbar handle darker */
.scroll-container2::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
.scroll-container2::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.scroll-container2::-moz-scrollbar-thumb {
  border-radius: 37px;
  background: rgba(0, 0, 0, 0.2);
  width: 8px;
}

.scroll-container2::-moz-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
/* .nav-category .nav-item .nav-link:hover {
  color: var(--fc-primary);
} */
.accordion-search {
  /* margin: 14px 0; */
  border: unset;
  background-color: #ebece9;
}
.accordion-search:focus {
  margin: 14px 0;
  border: unset;
  background-color: #ebece9;
}
.nav-lb-tab {
  /* border-bottom: 1px solid var(--fc-gray-300); */
}
@media (max-width: 576px) {
  .nav-lb-tab {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .nav-lb-tab::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .nav-lb-tab .nav-item {
    margin-right: 1rem !important;
  }
}

.nav-lb-tab .nav-item {
  margin-right: 10rem;
}
.nav-lb-tab .nav-item .nav-link {
  /* border-bottom: 2px solid transparent; */
  border-radius: 0;
  color: rgba(0, 0, 0, 0.25);
  margin-bottom: -1px;
  padding: 0;
  white-space: nowrap;
  text-transform: uppercase;
  font-size: 16px;
  font-family: var(--fc-font-bold);
}
body.checkout .nav-lb-tab .nav-item .nav-link {
  padding: 10px;
  border: solid 1px #bdbbbb;
  border-radius: 5px;
  width: 100%;
  color: #fff;
  background-color: #c5c4c4;
}
body.checkout .nav-lb-tab .nav-item .nav-link.active,
.nav-lb-tab .nav-item .nav-link:hover {
  color: #000;
  background-color: #fff;
}
ul.nav.nav-pills.nav-tabs.nav-lb-tab li.nav-item {
  width: 52% !important;
}
ul.nav.nav-pills.nav-tabs.nav-lb-tab li.nav-item.mobile-right {
  width: 48% !important;
}
body.checkout ul.nav.nav-pills.nav-tabs.nav-lb-tab li.nav-item {
  margin-right: 0px;
  width: 50%;
}
body.checkout ul.nav.nav-pills.nav-tabs.nav-lb-tab {
  width: 100%;
}
.nav-lb-tab .nav-item .nav-link.active,
.nav-lb-tab .nav-item .nav-link:hover {
  background-color: transparent;
  border-bottom: none;
  color: #000;
}
.nav-pills-dark .nav-item .nav-link {
  background-color: transparent;
  border-radius: 0;
  font-weight: 500;
  margin-bottom: 0.25rem;
  padding: 1.5rem 0.75rem;
  border-bottom: 1px solid #bdbebb;
  color: #000;
  font-size: 16px;
  line-height: normal;
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
}
.nav-pills-dark svg {
  float: right;
}
.nav-pills-dark img {
  padding-right: 10px;
}
/* .nav-pills-dark .nav-item .nav-link:hover {
  background-color: var(--fc-light);
  color: #001e2b;
} */
/* .nav-pills-dark .nav-item .nav-link.active {
  background-color: #001e2b;
  color: var(--fc-white);
} */
@media (max-width: 576px) {
  .nav-pills-light {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .nav-pills-light::-webkit-scrollbar {
    display: none;
  }
}
.nav-pills-light .nav-item {
  margin-right: 8px;
}
.nav-pills-light .nav-item .nav-link {
  background-color: var(--fc-gray-200);
  border-radius: 0.5rem;
  color: var(--fc-gray-800);
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  white-space: nowrap;
}
.nav-pills-light .nav-item .nav-link.active,
.nav-pills-light .nav-item .nav-link:hover {
  background-color: var(--fc-primary);
  color: var(--fc-white);
}
.nav-links .nav-item .nav-link {
  color: var(--fc-gray-800);
  padding: 0.25rem 0;
  white-space: nowrap;
}
.nav-links .nav-item .nav-link.active,
.nav-links .nav-item .nav-link:hover {
  color: var(--fc-primary);
}
.nav-line-bottom {
  border-bottom: 1px solid var(--fc-gray-200);
}
@media (max-width: 576px) {
  .nav-line-bottom {
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .nav-line-bottom::-webkit-scrollbar {
    display: none;
  }
}
.nav-line-bottom .nav-item .nav-link {
  border-radius: 0;
  color: var(--fc-gray-600);
  font-weight: 500;
  margin-bottom: -1px;
  padding: 1.2rem;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .nav-line-bottom .nav-item .nav-link {
    padding: 0.8rem;
  }
}
.nav-line-bottom .nav-item .nav-link.active {
  background-color: transparent;
  border-bottom: 1px solid var(--fc-primary);
  color: var(--fc-primary);
}
@media (max-width: 576px) {
  .nav-line-bottom .nav-item .nav-link.active {
    padding: 0.747rem;
  }
}
.nav-line-bottom .nav-item .nav-link:hover {
  background-color: transparent;
  border-bottom: 1px solid var(--fc-primary);
  color: var(--fc-primary);
}
.nav-line-bottom .nav-item .nav-link .badge {
  line-height: 1.4;
}
.dropdown-menu {
  box-shadow: var(--fc-dropdown-box-shadow);
  line-height: 1.375rem;
  z-index: 250;
}
.dropdown-header {
  color: var(--fc-gray-500);
  font-size: 0.6875rem;
  letter-spacing: 1px;
  margin-bottom: 0.2rem;
  text-transform: uppercase;
}
.dropdown-text {
  color: var(--fc-gray-500);
  font-size: 0.75rem;
  line-height: 1.4;
  padding: 0.25rem 1.2rem;
}
.dropdown-item {
  align-items: center;
  background-color: transparent;
  border-radius: 0.5rem !important;
  display: flex;
  font-weight: var(--fc-font-medium);
  line-height: 26px;
  padding: 0.125rem 0.75rem;
  color: var(--fc-black);
}
.my-account-drop-h3 {
  font-size: 16px;
  color: var(--fc-black);
  font-family: var(--fc-font-regular);
  text-transform: capitalize;
  padding: 10px 0 10px 10px;
}
.dropdown-item:hover {
  color: var(--fc-gray-800) !important;
}
.dropdown-item.active,
.dropdown-item:hover {
  background-color: var(--fc-gray-100);
  text-decoration: none;
}
.dropdown-item.active {
  color: var(--fc-gray-800);
}
.dropdown-item:last-child {
  border-bottom: 0;
}
.dropdown-item:focus {
  background-color: var(--fc-gray-100);
  color: var(--fc-gray-800);
  text-decoration: none;
}
.dropdown-item:focus .dropdown-toggle:after {
  color: var(--fc-primary);
}
.dropdown-item:focus:hover {
  color: var(--fc-primary);
}
.dropdown-item:focus:active,
.dropdown-item:focus:hover {
  background-color: var(--fc-gray-100);
  text-decoration: none;
}
.dropdown-item:focus:active {
  color: var(--fc-black);
}
.dropdown-item:hover.dropdown-toggle:after {
  color: var(--fc-primary);
}
.dropdown-menu-xs {
  min-width: 8rem !important;
}
.dropdown-menu-md {
  min-width: 17rem !important;
}
.dropdown-menu-lg {
  min-width: 22rem !important;
}
@media (max-width: 420px) {
  .dropdown-menu-lg {
    min-width: 17rem !important;
  }
}
.dropdown-menu-xl {
  min-width: 29rem !important;
}
/* .dropdown-toggle:after {
  border: 0;
  content: "\f12f";
  float: right;
  font-family: feather-icons !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
} */
.dropup .dropdown-toggle:after {
  content: "\f132";
}
.dropend .dropdown-toggle:after,
.dropup .dropdown-toggle:after {
  border: 0;
  display: inline-block;
  font-family: feather-icons !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
.dropend .dropdown-toggle:after {
  content: "\f131";
}
.dropstart .dropdown-toggle:before {
  border: 0;
  content: "\f130";
  display: inline-block;
  font-family: feather-icons !important;
  margin-right: 0.255em;
}
.dropdown-item-icon {
  color: var(--fc-gray-600);
  display: inline-block;
  font-size: 0.8125rem;
  opacity: 0.7;
  width: 1.5rem;
}
@media (min-width: 990px) {
  .dropstart-lg {
    position: relative;
  }
  .dropstart-lg .dropdown-menu {
    margin-right: 0.125rem;
    margin-top: 0;
    right: 100%;
    top: 0;
  }
  .dropstart-toggle-none.dropstart .dropdown-toggle:before {
    display: none;
  }
}
.dropdown-toggle-none .dropdown-toggle:before {
  display: none;
}
.dropdown-animation.dropdown-submenu .dropdown-menu {
  display: block;
  margin-right: 0.125rem;
  margin-top: 0;
  opacity: 0;
  right: 100%;
  top: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-in;
  visibility: hidden;
}
.dropdown-animation.dropdown-submenu:hover > .dropdown-menu {
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
}
.dropdown-animation .dropdown-menu {
  display: block;
  margin-right: 0.125rem;
  margin-top: 0;
  opacity: 0;
  right: 100%;
  top: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-in;
  visibility: hidden;
}
.dropdown-animation:hover > .dropdown-menu {
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
}
.dropdown-menu-list .dropdown-menu-list-submenu {
  grid-gap: 24px;
  background: var(--fc-white);
  border-radius: var(--fc-border-radius);
  box-shadow: var(--fc-box-shadow);
  display: grid;
  grid-template-columns: auto auto auto auto;
  left: 100%;
  margin-left: 12px;
  min-height: 100%;
  min-width: 260px;
  opacity: 0;
  overflow: auto;
  padding: var(--fc-dropdown-padding-y);
  position: absolute;
  top: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
}
@media (max-width: 990px) {
  .dropdown-menu-list .dropdown-menu-list-submenu {
    display: block;
    width: 100%;
  }
  .dropdown-menu-list
    .dropdown-menu-list-submenu
    .dropdown-menu-list-submenu-list {
    margin: 0.5rem 0;
  }
}
.dropdown-menu-list:hover .dropdown-menu-list-submenu {
  margin-left: 0;
  opacity: 1;
  visibility: visible;
}
.dropdown-menu-list:hover .dropdown-item svg {
  fill: var(--fc-gray-600);
}
.dropdown-flyout .dropdown-item {
  padding: 14px 18px;
}
.card-product {
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}
.card-product .card-hidden {
  background-color: #fff;
  border-bottom: 1px solid var(--fc-gray-300);
  border-left: 1px solid var(--fc-gray-300);
  border-radius: 0.4375rem;
  border-right: 1px solid var(--fc-gray-300);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  left: 0;
  margin-top: -1.25rem;
  opacity: 0;
  position: absolute;
  top: 100%;
  transition: all 0.15s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 1;
}
.card-product .product-action-btn {
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(10px);
  transition: opacity 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99),
    transform 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99);
}
.card-product .card-product-action {
  bottom: 15%;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9;
}
.card-product:hover {
  border: 1px solid var(--fc-primary);
  /* box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12); */
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}
.card-product:hover .card-hidden,
.card-product:hover .card-product-action {
  opacity: 1;
  visibility: visible;
}
.card-product:hover .product-action-btn {
  opacity: 1;
  transform: translateX(0);
}
.card-product .btn-action {
  align-items: center;
  background-color: var(--fc-white);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(145, 158, 171, 0.2),
    0 4px 5px 0 rgba(145, 158, 171, 0.14),
    0 1px 10px 0 rgba(145, 158, 171, 0.12);
  color: var(--fc-gray-800);
  display: inline-flex;
  font-size: 14px;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  text-align: center;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  width: 34px;
}
.card-product .btn-action:hover {
  background-color: var(--fc-primary);
  color: var(--fc-white);
}
.card-product-v2 {
  cursor: pointer;
  transition: z-index 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  z-index: 1;
}
.card-product-v2 .product-content-fade {
  background-color: var(--fc-white);
  bottom: 0;
  box-shadow: 5px 15px 30px rgba(62, 68, 90, 0.1);
  left: -1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -1px;
  top: -1px;
  transition: opacity 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  will-change: opacity;
  z-index: -1;
}
.card-product-v2 .product-fade-block {
  background-color: transparent;
  border-radius: 0.5rem;
  left: 0;
  opacity: 0;
  padding: 0 0.9375rem;
  position: absolute;
  right: 0;
  transform: translateY(5px);
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);
  visibility: hidden;
  will-change: opacity, transform;
}
.card-product-v2 .product-action-btn {
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(10px);
  transition: opacity 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99),
    transform 0.25s cubic-bezier(0.17, 0.62, 0.44, 0.99);
}
.card-product-v2 .btn-action {
  align-items: center;
  background-color: var(--fc-white);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(145, 158, 171, 0.2),
    0 4px 5px 0 rgba(145, 158, 171, 0.14),
    0 1px 10px 0 rgba(145, 158, 171, 0.12);
  color: var(--fc-gray-800);
  display: inline-flex;
  font-size: 14px;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  text-align: center;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  width: 34px;
}
.card-product-v2 .btn-action:hover {
  background-color: var(--fc-primary);
  color: var(--fc-white);
}
.card-product-v2:hover .product-fade-block {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.card-product-v2:hover .product-content-fade {
  opacity: 1;
}
.card-product-v2:hover .product-action-btn {
  opacity: 1;
  transform: translateX(0);
}
.card-product-v2 .product-content-fade.border-info {
  border: 1px solid #0aad0a !important;
  border-radius: 0.5rem;
}
.card-product-v2:hover.card {
  border: none;
  z-index: 2;
}
.card-image {
  transition: box-shadow 0.25s ease, transform 0.25s ease;
}

.card-image:hover {
  box-shadow: 0 2px 4px -1px rgba(145, 158, 171, 0.2),
    0 4px 5px 0 rgba(145, 158, 171, 0.14),
    0 1px 10px 0 rgba(145, 158, 171, 0.12) !important;
  transform: translate3d(0, -3px, 0);
}
.card-lift,
.card-lift:hover {
  transition: 0.2s;
  -moz-transition: 0.2s;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
}
.card-lift:hover {
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);
}
.card-lift:hover.card {
  background-color: var(--fc-transparent);
  color: #0aad0a;
}
.card-lift:hover svg {
  fill: #0aad0a;
}
.card-lg {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0 2px rgba(145, 158, 171, 0.2),
    0 12px 24px -4px rgba(145, 158, 171, 0.12);
}
.footer {
  background-color: var(--fc-black);
  padding: 1.15rem 0;
}
.footer-row {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #fff; */
}
.footer h6 {
  color: var(--fc-white);
  text-transform: uppercase;
  font-size: 12px;
}
.footer .btn-light {
  border-radius: 9px;
  background: #fff;
  font-size: 12px;
  font-weight: normal;
}
.footer .form-control {
  background-color: transparent;
  border-radius: 9px;
  border: 1px solid #fff;
  color: #fff !important;
  padding: 10px;
  font-size: 12px;
}
.footer-credits {
  color: var(--fc-white) !important;
}
.footer-credits a {
  color: var(--fc-white) !important;
  padding: 0 10px;
}
.footer-bottom {
  color: var(--fc-white);
}
.footer-bottom li {
  font-family: var(--fc-font-thin);
  line-height: 246%; /* 29.52px */
  letter-spacing: 1.08px;
  color: #fff;
}

.footer .form-control::placeholder {
  color: #fff;
  font-size: 12px;
  font-weight: normal;
}
.flag-span {
  color: #fff;
  font-size: 12px;
}
.footer .nav .nav-item .nav-link {
  color: var(--fc-white);
  padding: 0;
  font-size: 13px;
  /* text-transform: capitalize; */
  display: table;
  margin: 2px 0;
}
/* .footer .nav .nav-item .nav-link:hover {
  color: var(--fc-primary);
} */
.footer .social-links {
  color: var(--fc-gray-600);
}
.footer .social-links:hover {
  color: var(--fc-primary);
}
.btn-social {
  border: 1px solid var(--fc-gray-500);
  border-radius: 0.5rem;
  color: var(--fc-gray-500);
}
.btn-social:hover {
  border: 1px solid var(--fc-primary);
  color: var(--fc-primary);
}
.avatar {
  display: inline-block;
  height: 3rem;
  position: relative;
  width: 3rem;
}
.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}
.avatar-sm {
  height: 2rem;
  width: 2rem;
}
.avatar-md {
  height: 2.5rem;
  width: 2.5rem;
}
.avatar-lg {
  height: 3.5rem;
  width: 3.5rem;
}
.avatar-xl {
  height: 5rem;
  width: 5rem;
}
.avatar-xxl {
  height: 7.5rem;
  width: 7.5rem;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-switch {
  align-items: center;
  display: flex;
}
.form-switch .form-check-input {
  border: 0.5px solid #000;
  height: 1.6em;
  margin-right: 0.5rem;
  width: 3em;
}
.form-focus-none.form-control:focus,
.form-focus-none.form-select:focus {
  box-shadow: none;
  outline: none;
}
.input-spinner .button-minus,
.input-spinner .button-plus {
  background-color: transparent;
  border-color: var(--fc-gray-200);
  height: 2rem;
  width: 1.8rem;
}
.input-spinner .button-minus:hover,
.input-spinner .button-plus:hover {
  background-color: var(--fc-gray-200);
  border-color: var(--fc-gray-200);
}
.input-spinner .form-input {
  background: 0 0;
  border: 1px solid var(--fc-gray-200);
  height: 100%;
  min-height: 2rem;
  text-align: center;
  width: 2rem !important;
}
.file-upload {
  overflow: hidden;
  position: relative;
}
.file-upload input.file-input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 20px;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.input-phone {
  position: relative;
}
.input-phone input {
  box-sizing: border-box;
  font-size: 14px;
  padding-left: 72px;
  width: 100%;
}
.input-phone:before {
  content: "\f4e7";
  font-family: bootstrap-icons !important;
  font-size: 18px;
  font-style: normal;
  left: 12px;
}
.input-phone:after,
.input-phone:before {
  color: var(--fc-gray-600);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.input-phone:after {
  content: "+91 - ";
  font-size: 14px;
  left: 36px;
}
.docs-header .docs-header-btn {
  align-items: center;
  display: flex;
}
.docs-header .navbar {
  box-shadow: 0 -9px 49px rgba(22, 20, 37, 0.12),
    0 -2.71324px 14.7721px rgba(22, 20, 37, 0.14),
    0 -1.12694px 6.13556px rgba(22, 20, 37, 0.16),
    0 -0.407592px 2.21911px rgba(22, 20, 37, 0.18);
  position: fixed;
  width: 100%;
  z-index: 1022;
}
@media (max-width: 576px) {
  .docs-header .navbar {
    margin-left: 0;
  }
}
.docs-header .navbar .dropdown-menu {
  border: 1px solid #efeef1;
  margin: 0;
}
.docs-header .navbar .navbar-nav .nav-item .nav-link {
  color: var(--fc-gray-700);
  font-size: 1rem;
  line-height: 1.75rem;
  margin-bottom: 0;
  padding: 0.875rem;
}
.docs-header .navbar .navbar-nav .nav-item .nav-link:hover {
  color: var(--fc-black);
}
.docs-nav-sidebar {
  background-color: var(--fc-white);
  border-right: 1px solid var(--fc-gray-200);
  font-weight: 500;
  height: 100%;
  position: fixed;
  top: 0;
  width: 15.625rem;
  z-index: 22;
}
.docs-nav-sidebar .navbar-brand {
  margin-right: 0;
  padding: 14px 20px 10px;
}
.docs-nav-sidebar .docs-nav {
  height: 100% !important;
  overflow-y: auto;
  padding: 70px 0;
}
@media (max-width: 768px) {
  .docs-nav-sidebar .docs-nav {
    padding: 14px 0;
  }
}
.docs-nav-sidebar .navbar {
  padding: 0;
}
.docs-nav-sidebar .navbar-nav {
  min-width: 100%;
}
@media (max-width: 375px) {
  .docs-nav-sidebar .navbar-nav {
    padding-top: 25px;
  }
}
.docs-nav-sidebar .navbar-nav .navbar-header {
  font-size: 0.75rem;
  margin-bottom: 1rem;
  margin-top: 1.5625rem;
  padding: 0 1.25rem;
}
.docs-nav-sidebar .navbar-nav .navbar-header .heading {
  color: var(--fc-gray-800);
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: capitalize;
}
.docs-nav-sidebar .navbar-nav .navbar-border {
  border-bottom: 1px solid var(--fc-gray-200);
  margin: 1.25rem 0;
}
.docs-nav-sidebar .navbar-nav .nav-item .nav-link {
  border-bottom: none;
  border-radius: var(--fc-border-radius);
  color: var(--fc-gray-600) !important;
  font-family: var(--fc-font-family-sans-serif);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0.05rem 1.25rem;
  padding: 0.275rem 0.675rem;
}
.docs-nav-sidebar .navbar-nav .nav-item .nav-link:hover {
  background-color: var(--fc-gray-200);
  color: var(--fc-primary) !important;
}
.docs-nav-sidebar .navbar-nav .nav-item .nav-link.active {
  background-color: var(--fc-gray-200);
  color: #0aad0a !important;
}
.docs-nav-sidebar .nav-footer {
  background-color: var(--fc-white);
  border-top: 1px solid var(--fc-gray-200);
  bottom: 0;
  padding: 0.875rem 1.5rem;
  position: absolute;
  width: 100%;
}
.sidebar-nav-fixed {
  border-left: 1px solid var(--fc-gray-300);
  height: calc(100vh - 2rem);
  overflow-y: auto;
  padding-top: 4.0625rem;
  position: sticky;
  top: 4rem;
}
.sidebar-nav-fixed ul {
  list-style: none;
}
.sidebar-nav-fixed ul li a {
  background: transparent;
  border-left: 2px solid transparent;
  border-radius: 0;
  color: var(--fc-gray-600);
  display: block;
  font-size: 0.8125rem;
  line-height: 1.4375rem;
  padding: 0.125rem 1.5rem;
  text-decoration: none;
  transition: 0.5s ease;
}
.sidebar-nav-fixed ul li a:hover {
  color: var(--fc-primary);
}
.sidebar-nav-fixed ul li a.active {
  background: transparent !important;
  border-left-color: var(--fc-primary);
  color: var(--fc-primary);
  display: block;
  text-decoration: none;
  transition: 0.5s ease;
}
.sidebar-nav-fixed ul li ul {
  list-style: none;
  padding-left: 0;
}
.sidebar-nav-fixed ul li ul li a {
  padding: 0.125rem 2.5rem;
}
.docs-main-wrapper {
  min-height: 100%;
  position: relative;
}
.docs-wrapper {
  left: 0;
  margin-left: 15.625rem;
  position: relative;
  top: 35px;
}
.docs-content {
  margin: 6rem 4rem;
}
.docs-content-head {
  padding: 4.375rem 0;
}
.gd-example-modal.modal {
  bottom: auto;
  display: block;
  left: auto;
  position: relative;
  right: auto;
  top: auto;
  z-index: 1;
}
.scrollspy-example {
  height: 12.5rem;
  margin-top: 0.5rem;
  overflow: auto;
  position: relative;
}
.scrollspy-example-2 {
  height: 21.875rem;
  overflow: auto;
  position: relative;
}
.toast.hide {
  display: none;
}
.color-swatch {
  margin-bottom: 1.875rem;
}
.color-swatch-header {
  border-radius: 0.5rem;
  height: 4.5rem;
}
.color-swatch-body {
  line-height: 1;
  margin-top: 0.875rem;
}
.color-swatch-body-label {
  margin-bottom: 0.25rem;
}
.color-swatch-body-value {
  margin-bottom: 0;
}
.docs-example-border-utils [class^="border"] {
  background-color: var(--fc-gray-100);
  display: inline-block;
  height: 5rem;
  margin: 0.25rem;
  width: 5rem;
}
.docs-example-border-utils-0 [class^="border"] {
  border: 1px solid var(--fc-gray-300);
}
:focus {
  outline: 0;
}
@media only screen and (max-width: 1400px) {
  .docs-content {
    margin: 6rem 0;
  }
}
@media only screen and (max-width: 1200px) {
  .docs-content {
    margin: 5rem 3rem;
  }
}
@media only screen and (max-width: 768px) {
  .docs-content {
    margin: 5rem 3rem;
  }
}
@media only screen and (max-width: 480px) {
  .docs-nav-sidebar {
    position: relative;
    right: 0;
    width: 100%;
  }
  .docs-nav-sidebar .top-search-bar {
    border-bottom: 1px solid var(--fc-gray-300);
    margin-top: 2.875rem;
    padding: 1.25rem;
  }
  .docs-nav-sidebar .navbar-toggler {
    background-color: var(--fc-white);
  }
  .docs-wrapper {
    margin-left: 0;
  }
  .docs-main-wrapper .main-content {
    margin-left: 0;
    width: auto;
  }
  .docs-content {
    margin: 1rem;
  }
}
.ratio {
  background-color: var(--fc-gray-100);
  border: 1px solid var(--fc-gray-300);
  color: var(--fc-gray-600);
  display: inline-block;
  width: 10rem;
}
.docs-content .nav-line-bottom .nav-item .nav-link {
  padding: 0.75rem;
}
.btn-icon {
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 0.92969rem;
  font-weight: 400;
  height: 2.5rem;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 2.5rem;
}
.btn-icon.btn-xs {
  font-size: 0.75rem;
  height: 1.75rem;
  width: 1.75rem;
}
.btn-group-sm > .btn-icon.btn,
.btn-icon.btn-sm {
  font-size: 0.875rem;
  height: 2.1875rem;
  width: 2.1875rem;
}
.btn-group-lg > .btn-icon.btn,
.btn-icon.btn-lg {
  font-size: 1rem;
  height: 3.36875rem;
  width: 3.36875rem;
}
.btn-ghost-primary {
  background-color: transparent;
  color: #0aad0a;
}
.btn-ghost-primary.active,
.btn-ghost-primary:focus,
.btn-ghost-primary:hover {
  background-color: rgba(10, 173, 10, 0.125);
  color: #0aad0a;
}
.btn-ghost-secondary {
  background-color: transparent;
  color: #889397;
}
.btn-ghost-secondary.active,
.btn-ghost-secondary:focus,
.btn-ghost-secondary:hover {
  background-color: hsla(196, 7%, 56%, 0.125);
  color: #889397;
}
.btn-ghost-success {
  background-color: transparent;
  color: #198754;
}
.btn-ghost-success.active,
.btn-ghost-success:focus,
.btn-ghost-success:hover {
  background-color: rgba(25, 135, 84, 0.125);
  color: #198754;
}
.btn-ghost-info {
  background-color: transparent;
  color: #016bf8;
}
.btn-ghost-info.active,
.btn-ghost-info:focus,
.btn-ghost-info:hover {
  background-color: rgba(1, 107, 248, 0.125);
  color: #016bf8;
}
.btn-ghost-warning {
  background-color: transparent;
  color: #ffc107;
}
.btn-ghost-warning.active,
.btn-ghost-warning:focus,
.btn-ghost-warning:hover {
  background-color: rgba(255, 193, 7, 0.125);
  color: #ffc107;
}
.btn-ghost-danger {
  background-color: transparent;
  color: #db3030;
}
.btn-ghost-danger.active,
.btn-ghost-danger:focus,
.btn-ghost-danger:hover {
  background-color: rgba(219, 48, 48, 0.125);
  color: #db3030;
}
.btn-ghost-light {
  background-color: transparent;
  color: #f0f3f2;
}
.btn-ghost-light.active,
.btn-ghost-light:focus,
.btn-ghost-light:hover {
  background-color: rgba(240, 243, 242, 0.125);
  color: #f0f3f2;
}
.btn-ghost-dark {
  background-color: transparent;
  color: #001e2b;
}
.btn-ghost-dark.active,
.btn-ghost-dark:focus,
.btn-ghost-dark:hover {
  background-color: rgba(0, 30, 43, 0.125);
  color: #001e2b;
}
.btn-ghost-gray-400 {
  background-color: transparent;
  color: #c1c7c6;
}
.btn-ghost-gray-400.active,
.btn-ghost-gray-400:focus,
.btn-ghost-gray-400:hover {
  background-color: hsla(170, 5%, 77%, 0.125);
  color: #c1c7c6;
}
.btn-ghost-white {
  background-color: transparent;
  color: #fff;
}
.btn-ghost-white.active,
.btn-ghost-white:focus,
.btn-ghost-white:hover {
  background-color: hsla(0, 0%, 100%, 0.125);
  color: #fff;
}
.btn-ghost-light-primary {
  background-color: transparent;
  color: #ceefce;
}
.btn-ghost-light-primary.active,
.btn-ghost-light-primary:focus,
.btn-ghost-light-primary:hover {
  background-color: rgba(206, 239, 206, 0.125);
  color: #ceefce;
}
.btn-ghost-light-secondary {
  background-color: transparent;
  color: #889397;
}
.btn-ghost-light-secondary.active,
.btn-ghost-light-secondary:focus,
.btn-ghost-light-secondary:hover {
  background-color: hsla(196, 7%, 56%, 0.125);
  color: #889397;
}
.btn-ghost-light-danger {
  background-color: transparent;
  color: #f8d6d6;
}
.btn-ghost-light-danger.active,
.btn-ghost-light-danger:focus,
.btn-ghost-light-danger:hover {
  background-color: hsla(0, 71%, 91%, 0.125);
  color: #f8d6d6;
}
.btn-ghost-light-info {
  background-color: transparent;
  color: #cce1fe;
}
.btn-ghost-light-info.active,
.btn-ghost-light-info:focus,
.btn-ghost-light-info:hover {
  background-color: rgba(204, 225, 254, 0.125);
  color: #cce1fe;
}
.btn-ghost-light-warning {
  background-color: transparent;
  color: #fff3cd;
}
.btn-ghost-light-warning.active,
.btn-ghost-light-warning:focus,
.btn-ghost-light-warning:hover {
  background-color: rgba(255, 243, 205, 0.125);
  color: #fff3cd;
}
.btn-ghost-light-success {
  background-color: transparent;
  color: #d1e7dd;
}
.btn-ghost-light-success.active,
.btn-ghost-light-success:focus,
.btn-ghost-light-success:hover {
  background-color: rgba(209, 231, 221, 0.125);
  color: #d1e7dd;
}
.btn-ghost-dark-primary {
  background-color: transparent;
  color: #044504;
}
.btn-ghost-dark-primary.active,
.btn-ghost-dark-primary:focus,
.btn-ghost-dark-primary:hover {
  background-color: rgba(4, 69, 4, 0.125);
  color: #044504;
}
.btn-ghost-dark-warning {
  background-color: transparent;
  color: #664d03;
}
.btn-ghost-dark-warning.active,
.btn-ghost-dark-warning:focus,
.btn-ghost-dark-warning:hover {
  background-color: rgba(102, 77, 3, 0.125);
  color: #664d03;
}
.btn-ghost-dark-info {
  background-color: transparent;
  color: #002b63;
}
.btn-ghost-dark-info.active,
.btn-ghost-dark-info:focus,
.btn-ghost-dark-info:hover {
  background-color: rgba(0, 43, 99, 0.125);
  color: #002b63;
}
.btn-ghost-dark-danger {
  background-color: transparent;
  color: #581313;
}
.btn-ghost-dark-danger.active,
.btn-ghost-dark-danger:focus,
.btn-ghost-dark-danger:hover {
  background-color: rgba(88, 19, 19, 0.125);
  color: #581313;
}
.btn-ghost-dark-success {
  background-color: transparent;
  color: #0a3622;
}
.btn-ghost-dark-success.active,
.btn-ghost-dark-success:focus,
.btn-ghost-dark-success:hover {
  background-color: rgba(10, 54, 34, 0.125);
  color: #0a3622;
}
.btn-ghost-dark-secondary {
  background-color: transparent;
  color: #ecf0ef;
}
.btn-ghost-dark-secondary.active,
.btn-ghost-dark-secondary:focus,
.btn-ghost-dark-secondary:hover {
  background-color: rgba(236, 240, 239, 0.125);
  color: #ecf0ef;
}
.btn-ghost-indigo {
  background-color: transparent;
  color: #6610f2;
}
.btn-ghost-indigo.active,
.btn-ghost-indigo:focus,
.btn-ghost-indigo:hover {
  background-color: rgba(102, 16, 242, 0.125);
  color: #6610f2;
}
.btn-ghost-red {
  background-color: transparent;
  color: #db3030;
}
.btn-ghost-red.active,
.btn-ghost-red:focus,
.btn-ghost-red:hover {
  background-color: rgba(219, 48, 48, 0.125);
  color: #db3030;
}
.btn-ghost-pink {
  background-color: transparent;
  color: #d63384;
}
.btn-ghost-pink.active,
.btn-ghost-pink:focus,
.btn-ghost-pink:hover {
  background-color: rgba(214, 51, 132, 0.125);
  color: #d63384;
}
.btn-ghost-orange {
  background-color: transparent;
  color: #fd7e14;
}
.btn-ghost-orange.active,
.btn-ghost-orange:focus,
.btn-ghost-orange:hover {
  background-color: rgba(253, 126, 20, 0.125);
  color: #fd7e14;
}
.btn-ghost-teal {
  background-color: transparent;
  color: #20c997;
}
.btn-ghost-teal.active,
.btn-ghost-teal:focus,
.btn-ghost-teal:hover {
  background-color: rgba(32, 201, 151, 0.125);
  color: #20c997;
}
.btn-ghost-purple {
  background-color: transparent;
  color: #6f42c1;
}
.btn-ghost-purple.active,
.btn-ghost-purple:focus,
.btn-ghost-purple:hover {
  background-color: rgba(111, 66, 193, 0.125);
  color: #6f42c1;
}
.btn-soft-primary {
  background-color: rgba(10, 173, 10, 0.125);
  color: #0aad0a;
}
.btn-soft-primary.active,
.btn-soft-primary:focus,
.btn-soft-primary:hover {
  background-color: #0aad0a;
  color: #fff;
}
.btn-soft-secondary {
  background-color: hsla(196, 7%, 56%, 0.125);
  color: #889397;
}
.btn-soft-secondary.active,
.btn-soft-secondary:focus,
.btn-soft-secondary:hover {
  background-color: #889397;
  color: #fff;
}
.btn-soft-success {
  background-color: rgba(25, 135, 84, 0.125);
  color: #198754;
}
.btn-soft-success.active,
.btn-soft-success:focus,
.btn-soft-success:hover {
  background-color: #198754;
  color: #fff;
}
.btn-soft-info {
  background-color: rgba(1, 107, 248, 0.125);
  color: #016bf8;
}
.btn-soft-info.active,
.btn-soft-info:focus,
.btn-soft-info:hover {
  background-color: #016bf8;
  color: #fff;
}
.btn-soft-warning {
  background-color: rgba(255, 193, 7, 0.125);
  color: #ffc107;
}
.btn-soft-warning.active,
.btn-soft-warning:focus,
.btn-soft-warning:hover {
  background-color: #ffc107;
  color: #000;
}
.btn-soft-danger {
  background-color: rgba(219, 48, 48, 0.125);
  color: #db3030;
}
.btn-soft-danger.active,
.btn-soft-danger:focus,
.btn-soft-danger:hover {
  background-color: #db3030;
  color: #fff;
}
.btn-soft-light {
  background-color: rgba(240, 243, 242, 0.125);
  color: #f0f3f2;
}
.btn-soft-light.active,
.btn-soft-light:focus,
.btn-soft-light:hover {
  background-color: #f0f3f2;
  color: #000;
}
.btn-soft-dark {
  background-color: rgba(0, 30, 43, 0.125);
  color: #001e2b;
}
.btn-soft-dark.active,
.btn-soft-dark:focus,
.btn-soft-dark:hover {
  background-color: #001e2b;
  color: #fff;
}
.btn-soft-gray-400 {
  background-color: hsla(170, 5%, 77%, 0.125);
  color: #c1c7c6;
}
.btn-soft-gray-400.active,
.btn-soft-gray-400:focus,
.btn-soft-gray-400:hover {
  background-color: #c1c7c6;
  color: #000;
}
.btn-soft-white {
  background-color: hsla(0, 0%, 100%, 0.125);
  color: #fff;
}
.btn-soft-white.active,
.btn-soft-white:focus,
.btn-soft-white:hover {
  background-color: #fff;
  color: #000;
}
.btn-soft-light-primary {
  background-color: rgba(206, 239, 206, 0.125);
  color: #ceefce;
}
.btn-soft-light-primary.active,
.btn-soft-light-primary:focus,
.btn-soft-light-primary:hover {
  background-color: #ceefce;
  color: #000;
}
.btn-soft-light-secondary {
  background-color: hsla(196, 7%, 56%, 0.125);
  color: #889397;
}
.btn-soft-light-secondary.active,
.btn-soft-light-secondary:focus,
.btn-soft-light-secondary:hover {
  background-color: #889397;
  color: #fff;
}
.btn-soft-light-danger {
  background-color: hsla(0, 71%, 91%, 0.125);
  color: #f8d6d6;
}
.btn-soft-light-danger.active,
.btn-soft-light-danger:focus,
.btn-soft-light-danger:hover {
  background-color: #f8d6d6;
  color: #000;
}
.btn-soft-light-info {
  background-color: rgba(204, 225, 254, 0.125);
  color: #cce1fe;
}
.btn-soft-light-info.active,
.btn-soft-light-info:focus,
.btn-soft-light-info:hover {
  background-color: #cce1fe;
  color: #000;
}
.btn-soft-light-warning {
  background-color: rgba(255, 243, 205, 0.125);
  color: #fff3cd;
}
.btn-soft-light-warning.active,
.btn-soft-light-warning:focus,
.btn-soft-light-warning:hover {
  background-color: #fff3cd;
  color: #000;
}
.btn-soft-light-success {
  background-color: rgba(209, 231, 221, 0.125);
  color: #d1e7dd;
}
.btn-soft-light-success.active,
.btn-soft-light-success:focus,
.btn-soft-light-success:hover {
  background-color: #d1e7dd;
  color: #000;
}
.btn-soft-dark-primary {
  background-color: rgba(4, 69, 4, 0.125);
  color: #044504;
}
.btn-soft-dark-primary.active,
.btn-soft-dark-primary:focus,
.btn-soft-dark-primary:hover {
  background-color: #044504;
  color: #fff;
}
.btn-soft-dark-warning {
  background-color: rgba(102, 77, 3, 0.125);
  color: #664d03;
}
.btn-soft-dark-warning.active,
.btn-soft-dark-warning:focus,
.btn-soft-dark-warning:hover {
  background-color: #664d03;
  color: #fff;
}
.btn-soft-dark-info {
  background-color: rgba(0, 43, 99, 0.125);
  color: #002b63;
}
.btn-soft-dark-info.active,
.btn-soft-dark-info:focus,
.btn-soft-dark-info:hover {
  background-color: #002b63;
  color: #fff;
}
.btn-soft-dark-danger {
  background-color: rgba(88, 19, 19, 0.125);
  color: #581313;
}
.btn-soft-dark-danger.active,
.btn-soft-dark-danger:focus,
.btn-soft-dark-danger:hover {
  background-color: #581313;
  color: #fff;
}
.btn-soft-dark-success {
  background-color: rgba(10, 54, 34, 0.125);
  color: #0a3622;
}
.btn-soft-dark-success.active,
.btn-soft-dark-success:focus,
.btn-soft-dark-success:hover {
  background-color: #0a3622;
  color: #fff;
}
.btn-soft-dark-secondary {
  background-color: rgba(236, 240, 239, 0.125);
  color: #ecf0ef;
}
.btn-soft-dark-secondary.active,
.btn-soft-dark-secondary:focus,
.btn-soft-dark-secondary:hover {
  background-color: #ecf0ef;
  color: #000;
}
.btn-soft-indigo {
  background-color: rgba(102, 16, 242, 0.125);
  color: #6610f2;
}
.btn-soft-indigo.active,
.btn-soft-indigo:focus,
.btn-soft-indigo:hover {
  background-color: #6610f2;
  color: #fff;
}
.btn-soft-red {
  background-color: rgba(219, 48, 48, 0.125);
  color: #db3030;
}
.btn-soft-red.active,
.btn-soft-red:focus,
.btn-soft-red:hover {
  background-color: #db3030;
  color: #fff;
}
.btn-soft-pink {
  background-color: rgba(214, 51, 132, 0.125);
  color: #d63384;
}
.btn-soft-pink.active,
.btn-soft-pink:focus,
.btn-soft-pink:hover {
  background-color: #d63384;
  color: #fff;
}
.btn-soft-orange {
  background-color: rgba(253, 126, 20, 0.125);
  color: #fd7e14;
}
.btn-soft-orange.active,
.btn-soft-orange:focus,
.btn-soft-orange:hover {
  background-color: #fd7e14;
  color: #000;
}
.btn-soft-teal {
  background-color: rgba(32, 201, 151, 0.125);
  color: #20c997;
}
.btn-soft-teal.active,
.btn-soft-teal:focus,
.btn-soft-teal:hover {
  background-color: #20c997;
  color: #000;
}
.btn-soft-purple {
  background-color: rgba(111, 66, 193, 0.125);
  color: #6f42c1;
}
.btn-soft-purple.active,
.btn-soft-purple:focus,
.btn-soft-purple:hover {
  background-color: #6f42c1;
  color: #fff;
}
.btn-transition {
  transition: all 0.3s linear 0s;
}
.btn-transition i {
  font-size: 10px;
  margin-left: 5px;
  transition-duration: 0.3s;
}
.btn-transition:hover i {
  margin-left: 10px;
  transition-duration: 0.3s;
}
#passwordToggler {
  color: #001e2b;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.deals-countdown .countdown-section {
  border: 1px solid var(--fc-gray-300);
  border-radius: 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  margin-left: 3px;
  margin-right: 3px;
  padding: 14px 5px 26px;
  position: relative;
}
.deals-countdown .countdown-section .countdown-amount {
  color: var(--fc-dark);
  display: inline-block;
  font-weight: 500;
  height: 50px;
  line-height: 1;
  line-height: 50px;
  margin-bottom: 15px;
  max-width: 100%;
  width: 50px;
}
.deals-countdown .countdown-section .countdown-period {
  bottom: 10px;
  color: var(--fc-gray-500);
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}
.hero-slider {
  position: relative;
}
.hero-slider .slick-slide {
  margin-right: 0 !important;
}
.hero-slider .slick-dots {
  bottom: 24px;
  display: block;
  position: absolute;
}
.hero-slider .slick-dots li {
  margin: 0;
}
.hero-slider .slick-dots li button:before {
  color: var(--fc-gray-800);
  font-size: 0.5rem;
}
@media (min-width: 992px) {
  .offcanvas-collapse {
    background-color: transparent;
    bottom: auto !important;
    display: block;
    height: auto !important;
    left: auto !important;
    position: static;
    right: auto !important;
    top: auto !important;
    transform: none !important;
    visibility: visible;
    width: 100% !important;
  }
}
.navbar-vertical-nav {
  flex-shrink: 0;
  width: 280px;
}
.navbar-vertical-nav .navbar-vertical {
  background-color: var(--fc-white);
  border-right: 1px dashed var(--fc-gray-300);
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  left: 0;
  outline: 0;
  position: fixed;
  top: 0;
  width: 280px;
  z-index: 1200;
}
.navbar-vertical-nav .navbar-vertical .navbar-vertical-content {
  height: calc(100vh - 5.3125rem);
}
.navbar-vertical-nav .navbar-vertical .navbar-nav {
  padding: 0 16px;
}
.navbar-vertical-nav .navbar-vertical .navbar-nav .nav-item .nav-label {
  color: var(--fc-gray-500);
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  padding: 24px 16px 8px;
  text-transform: capitalize;
}
.navbar-vertical-nav .navbar-vertical .navbar-nav .nav-item .nav-link {
  align-items: center;
  border-radius: var(--fc-border-radius);
  color: var(--fc-gray-600);
  display: flex;
  height: 48px;
  justify-content: flex-start;
  margin: 0 0 4px;
  min-width: 0;
  padding: 8px 12px 8px 16px;
  position: relative;
  text-align: left;
  text-transform: capitalize;
  transition: 0.3s;
}
.navbar-vertical-nav .navbar-vertical .navbar-nav .nav-item .nav-link.disabled {
  color: var(--fc-gray-400);
}
.navbar-vertical-nav .navbar-vertical .navbar-nav .nav-item .nav-link:hover {
  background-color: var(--fc-gray-100);
  color: var(--fc-gray-800);
  text-decoration: none;
}
.navbar-vertical-nav .navbar-vertical .navbar-nav .nav-item .nav-link.active {
  background-color: var(--fc-light-primary);
  color: var(--fc-dark-primary);
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav-link
  .nav-link-icon {
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: center;
  margin-right: 16px;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav-link
  .nav-link-text {
  display: block;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav-link[data-bs-toggle="collapse"] {
  position: relative;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav-link[data-bs-toggle="collapse"]:after {
  content: "\f282";
  display: block;
  font-family: bootstrap-icons !important;
  margin-left: auto;
  position: absolute;
  right: 1.5rem;
  transition: 0.5s ease;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  [aria-expanded="true"].nav-link[data-bs-toggle="collapse"]:after {
  transform: rotate(180deg);
  transition: all 0.3s ease-in;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav-link {
  height: 40px;
  padding: 0.25rem 3rem;
  position: relative;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav-link:before {
  background-color: var(--fc-gray-600);
  border-radius: 10px;
  content: "";
  height: 4px;
  left: 22px;
  opacity: 0.5;
  position: absolute;
  transition: all 0.4s ease-in-out;
  width: 4px;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav-link.disabled {
  color: var(--fc-gray-400);
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav-link:hover {
  background-color: var(--fc-gray-100);
  color: var(--fc-gray-800);
  text-decoration: none;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav-link.active {
  background-color: var(--fc-light-primary);
  color: var(--fc-dark-primary);
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav-item
  .nav-link {
  height: 40px;
  padding: 0.25rem 4rem;
  position: relative;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav-item
  .nav-link:before {
  background-color: var(--fc-gray-600);
  border-radius: 10px;
  content: "";
  height: 4px;
  left: 32px;
  opacity: 0.5;
  position: absolute;
  transition: all 0.4s ease-in-out;
  width: 4px;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav-item
  .nav-link.disabled {
  color: var(--fc-gray-400);
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav-item
  .nav-link:hover {
  background-color: var(--fc-gray-100);
  color: var(--fc-gray-800);
  text-decoration: none;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav-item
  .nav-link.active {
  background-color: var(--fc-light-primary);
  color: var(--fc-dark-primary);
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav
  .nav-item
  .nav-link {
  height: 40px;
  padding: 0.25rem 4.3rem;
  position: relative;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav
  .nav-item
  .nav-link:before {
  background-color: var(--fc-gray-600);
  border-radius: 10px;
  content: "";
  height: 4px;
  left: 42px;
  opacity: 0.5;
  position: absolute;
  transition: all 0.4s ease-in-out;
  width: 4px;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav
  .nav-item
  .nav-link.disabled {
  color: var(--fc-gray-400);
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav
  .nav-item
  .nav-link:hover {
  background-color: var(--fc-gray-100);
  color: var(--fc-gray-800);
  text-decoration: none;
}
.navbar-vertical-nav
  .navbar-vertical
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  .nav
  .nav
  .nav-item
  .nav-link.active {
  background-color: var(--fc-light-primary);
  color: var(--fc-dark-primary);
}
.navbar-glass {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: hsla(0, 0%, 100%, 0.8);
  background-image: none;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--fc-gray-600);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 72px;
  left: auto;
  padding: 1rem;
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 280px);
  z-index: 1034;
}
@media (max-width: 1200px) {
  .navbar-glass {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .main-wrapper {
    display: flex;
    min-height: 100%;
  }
}
.main-content-wrapper {
  flex-grow: 1;
  padding: 100px 16px;
  width: calc(100% - 280px);
}
@media (max-width: 1200px) {
  .main-content-wrapper {
    width: 100%;
  }
}
.navbar-offcanvac {
  max-width: 280px;
}
table tr:last-child td {
  border-bottom-width: 0;
}
.table-centered td,
.table-centered th {
  vertical-align: middle !important;
}
.table-with-checkbox > tbody > tr > td:first-child,
.table-with-checkbox > thead > tr > th:first-child {
  padding-right: 0;
  width: 1px;
}
.page-item:first-child .page-link {
  margin-right: 0.25rem;
}
.page-item:not(:first-child) .page-link {
  margin-left: 0;
  margin-right: 0.25rem;
}
.page-item .page-link {
  border-radius: var(--fc-pagination-border-radius);
}
.slick-slider .slick-slide {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.slick-slider .slick-list {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.slick-slider img {
  display: inline-block;
}
.slick-slider .slick-next:before,
.slick-slider .slick-prev:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
}
.slick-slider .slick-next,
.slick-slider .slick-prev {
  align-items: center;
  background-color: var(--fc-gray-200);
  border: 1px solid var(--fc-gray-200);
  border-radius: 50px;
  color: var(--fc-gray-500);
  cursor: pointer;
  display: block;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 20px;
  font-size: 24px;
  height: 32px;
  justify-content: center;
  left: 97%;
  padding: 0;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  transition: 0.3s ease-in-out;
  width: 32px;
}
.slick-slider .slick-next:hover,
.slick-slider .slick-prev:hover {
  background-color: var(--fc-primary);
  border-color: var(--fc-primary);
  color: var(--fc-white);
}
@media (max-width: 1024px) {
  .slick-slider .slick-next,
  .slick-slider .slick-prev {
    left: 94%;
  }
}
@media (max-width: 390px) {
  .slick-slider .slick-next,
  .slick-slider .slick-prev {
    left: 87%;
  }
}
.slick-slider .slick-prev:hover {
  background: var(--fc-primary);
  color: var(--fc-white);
  outline: none;
}
.slick-slider .slick-prev:focus {
  display: none;
}
.slick-slider .slick-next {
  margin-top: -38px;
}
.slick-slider .slick-next:hover {
  background: var(--fc-primary);
  color: var(--fc-white);
  outline: none;
}
.slick-slider .slick-next:focus {
  display: none;
}
.slick-slider .slick-prev {
  margin-left: -40px;
  margin-top: -38px;
}
.team-slider .slick-next,
.team-slider .slick-prev {
  left: 76%;
  top: -34px;
}
@media (max-width: 1024px) {
  .team-slider .slick-next,
  .team-slider .slick-prev {
    left: 80%;
  }
}
@media (max-width: 390px) {
  .team-slider .slick-next,
  .team-slider .slick-prev {
    left: 87%;
  }
}
.slider-arrow {
  display: flex;
  justify-content: flex-end;
  max-width: 200px;
  position: relative;
  top: unset;
  transform: unset;
}
.slider-arrow .slick-prev {
  margin-right: 0.25rem;
}
.slider-arrow .slick-next {
  margin-left: 0.25rem;
}
.slider-arrow .slick-next,
.slider-arrow .slick-prev {
  align-items: center;
  background-color: var(--fc-gray-200);
  border: 1px solid var(--fc-gray-200);
  border-radius: 50px;
  color: var(--fc-gray-500);
  display: inline-flex;
  flex-shrink: 0;
  font-size: 1.25rem;
  height: 32px;
  justify-content: center;
  left: 0;
  position: relative;
  right: 0;
  top: 0;
  transform: none;
  transition: 0.3s ease-in-out;
  width: 32px;
}
.slider-arrow .slick-next:hover,
.slider-arrow .slick-prev:hover {
  background-color: var(--fc-primary);
  border-color: var(--fc-primary);
  color: var(--fc-white);
}
.slider-arrow .slick-next:before,
.slider-arrow .slick-prev:before {
  display: none !important;
}
.slider-nav .slick-prev {
  left: 40px;
  top: 84%;
  z-index: 1;
}
.slider-nav .slick-next {
  left: auto;
  right: -10px;
  top: 84%;
}
.slider-nav .slick-slide.slick-current img {
  border: 2px solid var(--fc-primary);
}
.noUi-target {
  background-color: var(--fc-gray-100);
  border: none;
  border-radius: 0.5rem;
}
.noUi-horizontal {
  height: 8px;
}
.noUi-handle {
  background: var(--fc-white);
  border: transparent;
  border-radius: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.18);
  cursor: default;
}
.noUi-handle:focus {
  outline: none;
}
.noUi-horizontal .noUi-handle {
  height: 18px;
  right: -13px;
  top: -6px;
  width: 18px;
}
.noUi-handle:after,
.noUi-handle:before {
  display: none;
}
.noUi-connects {
  border-radius: 6px;
}
.noUi-connect {
  background: var(--fc-primary);
}
.noUi-tooltip {
  background-color: transparent;
  background-color: var(--fc-primary);
  border: none;
  color: var(--fc-white);
  height: 30px;
  line-height: 30px;
  padding: 0;
  width: 30px;
}
pre[class*="language-"] {
  border-radius: 0.5rem !important;
  margin-bottom: 0;
  overflow: auto;
  padding: 1em;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: var(--fc-gray-900);
}
div.code-toolbar {
  position: relative;
}
div.code-toolbar > .toolbar {
  opacity: 0;
  position: absolute;
  right: 0.2em;
  top: 0.3em;
  transition: opacity 0.3s ease-in-out;
}
div.code-toolbar:focus-within > .toolbar,
div.code-toolbar:hover > .toolbar {
  opacity: 1;
}
div.code-toolbar > .toolbar > .toolbar-item {
  display: inline-block;
}
div.code-toolbar > .toolbar > .toolbar-item > a {
  cursor: pointer;
}
div.code-toolbar > .toolbar > .toolbar-item > button {
  background: none;
  border: 0;
  color: var(--fc-dark);
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
}
div.code-toolbar > .toolbar > .toolbar-item > a,
div.code-toolbar > .toolbar > .toolbar-item > button,
div.code-toolbar > .toolbar > .toolbar-item > span {
  background: var(--fc-white);
  border-radius: 0.25rem;
  color: var(--fc-dark);
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
}
div.code-toolbar > .toolbar > .toolbar-item > a:focus,
div.code-toolbar > .toolbar > .toolbar-item > a:hover,
div.code-toolbar > .toolbar > .toolbar-item > button:focus,
div.code-toolbar > .toolbar > .toolbar-item > button:hover {
  color: var(--fc-dark);
  text-decoration: none;
}
div.code-toolbar > .toolbar > .toolbar-item > span:focus,
div.code-toolbar > .toolbar > .toolbar-item > span:hover {
  color: inherit;
  text-decoration: none;
}
.dropzone {
  background: var(--fc-light) !important;
}
.profile-dropzone {
  border: 2px dashed var(--fc-gray-300);
  border-radius: 0.5rem;
  height: 3rem;
  min-height: 105px;
  padding: 0;
  position: relative;
  width: 105px;
}
.profile-dropzone .dz-message {
  display: none;
}
.profile-dropzone .dz-preview {
  margin: 0;
}
.profile-dropzone .dz-preview .dz-image {
  border-radius: 0.5rem;
  display: inline-block;
  height: 100px;
  left: 0;
  margin: 0;
  min-height: 60px;
  position: absolute;
  top: 0;
  vertical-align: top;
  width: 100px;
}
.profile-dropzone .dz-preview .dz-error-mark {
  margin-left: 23px;
  margin-top: -27px;
}
.product-tools {
  position: relative;
}
.product-tools .thumbnails {
  margin: 10px 0;
  text-align: center;
}
.product-tools img {
  border-radius: 6px;
  max-width: 100%;
}
.product .tns-item img {
  border-radius: 6px;
  width: 100%;
}
.thumbnails li img {
  height: auto;
}
.thumbnails .tns-nav-active .thumbnails-img {
  background: none;
  border: 2px solid var(--fc-primary);
  border-radius: 0.5rem;
}
@keyframes fpFadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.flatpickr-calendar {
  animation: none;
  background: transparent;
  background: var(--fc-white);
  border: 0;
  border-radius: 5px;
  box-shadow: 1px 0 0 #ecf0ef, -1px 0 0 #ecf0ef, 0 1px 0 #ecf0ef,
    0 -1px 0 #ecf0ef, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  direction: ltr;
  display: none;
  font-size: 14px;
  line-height: 24px;
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  touch-action: manipulation;
  visibility: hidden;
  width: 307.875px;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-left: 0;
}
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.flatpickr-calendar:before {
  border: 5px solid transparent;
  margin: 0 -5px;
}
.flatpickr-calendar:after,
.flatpickr-calendar:before {
  content: "";
  display: block;
  height: 0;
  left: 22px;
  pointer-events: none;
  position: absolute;
  width: 0;
}
.flatpickr-calendar:after {
  border: 4px solid transparent;
  margin: 0 -4px;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-calendar.open {
  display: inline-block;
  max-height: 640px;
  opacity: 1;
  visibility: visible;
  z-index: 99999;
}
.flatpickr-calendar.inline {
  display: block;
  max-height: 640px;
  opacity: 1;
  position: relative;
  top: 2px;
  visibility: visible;
}
.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  display: block;
  z-index: 999;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 1)
  .flatpickr-day.inRange:nth-child(7n + 7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth
  .flatpickr-days
  .dayContainer:nth-child(n + 2)
  .flatpickr-day.inRange:nth-child(7n + 1) {
  box-shadow: -2px 0 0 #ecf0ef, 5px 0 0 var(--fc-gray-200);
}
.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border-top: 1px solid var(--fc-gray-200);
  height: 40px;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--fc-gray-200);
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--fc-white);
  bottom: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--fc-gray-200);
  top: 100%;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--fc-white);
  top: 100%;
}
.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}
.flatpickr-months {
  display: flex;
}
.flatpickr-months .flatpickr-month {
  fill: rgba(0, 0, 0, 0.9);
  background: transparent;
  color: var(--fc-gray-100);
  flex: 1;
  height: 34px;
  line-height: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.flatpickr-months .flatpickr-prev-month {
  fill: rgba(0, 0, 0, 0.9);
  color: var(--fc-gray-100);
  cursor: pointer;
  height: 34px;
  padding: 10px;
  position: absolute;
  text-decoration: none;
  top: 0;
  z-index: 3;
}
.flatpickr-months .flatpickr-prev-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month:hover {
  color: var(--fc-gray-300);
}
.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: var(--fc-primary);
}
.flatpickr-months .flatpickr-prev-month svg {
  height: 14px;
  width: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path {
  fill: inherit;
  transition: fill 0.1s;
}
.flatpickr-months .flatpickr-next-month {
  fill: rgba(0, 0, 0, 0.9);
  color: var(--fc-gray-100);
  cursor: pointer;
  height: 34px;
  padding: 10px;
  position: absolute;
  text-decoration: none;
  top: 0;
  z-index: 3;
}
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-next-month:hover {
  color: var(--fc-gray-300);
}
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--fc-primary);
}
.flatpickr-months .flatpickr-next-month svg {
  height: 14px;
  width: 14px;
}
.flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill 0.1s;
}
.flatpickr-months .flatpickr-next-month.flatpickr-disabled,
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 0;
}
.flatpickr-months .flatpickr-next-month.flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
  right: 0;
}
.numInputWrapper {
  height: auto;
  position: relative;
}
.numInputWrapper input {
  display: inline-block;
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-inner-spin-button,
.numInputWrapper input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numInputWrapper span {
  border: 1px solid rgba(57, 57, 57, 0.15);
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  padding: 0 4px 0 2px;
  position: absolute;
  right: 0;
  width: 14px;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}
.numInputWrapper span svg {
  height: auto;
  width: inherit;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}
.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  color: var(--fc-gray-600);
  display: inline-block;
  font-size: 100%;
  font-weight: 300;
  height: 34px;
  left: 12.5%;
  line-height: inherit;
  line-height: 1;
  padding: 16px 0 0;
  position: absolute;
  text-align: center;
  transform: translateZ(0);
  width: 75%;
}
.flatpickr-current-month span.cur-month {
  color: var(--fc-gray-600);
  display: inline-block;
  font-family: var(--fc-font-sans-serif);
  font-weight: 700;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  display: inline-block;
  width: 6ch;
  width: 7ch\0;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--fc-gray-100);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: var(--fc-gray-100);
}
.flatpickr-current-month input.cur-year {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  color: var(--fc-gray-600);
  cursor: text;
  display: inline-block;
  font-family: var(--fc-font-sans-serif);
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: 0;
  padding: 0 0 0 0.5ch;
  vertical-align: initial;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  background: transparent;
  color: var(--fc-gray-400);
  font-size: 100%;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: var(--fc-gray-600);
  cursor: pointer;
  font-family: var(--fc-font-sans-serif);
  font-size: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:active,
.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  align-items: center;
  background: transparent;
  display: flex;
  overflow: hidden;
  padding: 18px 0;
  text-align: center;
  width: 100%;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}
span.flatpickr-weekday {
  background: transparent;
  color: var(--fc-gray-900);
  cursor: default;
  display: block;
  flex: 1;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  margin: 0;
  text-align: center;
}
.dayContainer {
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  max-width: 307.875px;
  min-width: 307.875px;
  opacity: 1;
  outline: 0;
  padding: 0;
  text-align: left;
  transform: translateZ(0);
  width: 307.875px;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 var(--fc-gray-200);
}
.flatpickr-weeks {
  padding: 1px 0 0;
}
.flatpickr-days {
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--fc-gray-800);
  cursor: pointer;
  display: inline-block;
  flex-basis: 14.2857143%;
  font-weight: 400;
  height: 39px;
  justify-content: center;
  line-height: 38px;
  margin: 0;
  max-width: 39px;
  position: relative;
  text-align: center;
  width: 14.2857143%;
}
.flatpickr-day.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
  background: var(--fc-gray-200);
  border-color: var(--fc-gray-200);
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #ecf0ef, 5px 0 0 var(--fc-gray-200);
}
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.today.inRange {
  background: var(--fc-gray-200);
  border-color: var(--fc-gray-200);
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.prevMonthDay {
  background: transparent;
  border-color: transparent;
  color: var(--fc-gray-600);
  cursor: default;
}
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover {
  background: var(--fc-gray-200);
  border-color: var(--fc-gray-200);
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.nextMonthDay {
  background: transparent;
  border-color: transparent;
  color: var(--fc-gray-300);
  cursor: default;
}
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover {
  background: var(--fc-gray-200);
  border-color: var(--fc-gray-200);
  cursor: pointer;
  outline: 0;
}
.flatpickr-day.today {
  background-color: var(--fc-primary);
  border-color: var(--fc-primary);
  color: var(--fc-white);
}
.flatpickr-day.today:focus,
.flatpickr-day.today:hover {
  background: var(--fc-gray-300);
  border-color: var(--fc-gray-300);
  color: var(--fc-white);
}
.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: var(--fc-primary);
  border-color: var(--fc-primary);
  box-shadow: none;
  color: var(--fc-white);
}
.flatpickr-day.selected.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 var(--fc-primary);
}
.flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 var(--fc-primary);
}
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 var(--fc-primary);
}
.flatpickr-day.endRange.endRange,
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.endRange.startRange.endRange,
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  background: transparent;
  border-color: transparent;
  color: var(--fc-gray-600);
  cursor: default;
  cursor: not-allowed;
}
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay {
  background: transparent;
  border-color: transparent;
  color: var(--fc-gray-600);
  cursor: default;
}
.flatpickr-day.notAllowed.nextMonthDay {
  background: transparent;
  border-color: transparent;
  color: var(--fc-gray-200);
  cursor: default;
}
.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #0aad0a, 5px 0 0 var(--fc-primary);
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: 1px 0 0 var(--fc-gray-200);
  padding: 0 12px;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  line-height: 28px;
  width: 100%;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  background: transparent;
  border: none;
  color: var(--fc-gray-600);
  cursor: default;
  display: block;
  max-width: none;
  width: 100%;
}
.flatpickr-innerContainer {
  box-sizing: border-box;
  display: block;
  display: flex;
  overflow: hidden;
}
.flatpickr-rContainer {
  box-sizing: border-box;
  display: inline-block;
  padding: 0;
}
.flatpickr-time {
  box-sizing: border-box;
  display: block;
  display: flex;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  outline: 0;
  overflow: hidden;
  text-align: center;
}
.flatpickr-time:after {
  clear: both;
  content: "";
  display: table;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  float: left;
  height: 40px;
  width: 40%;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--fc-gray-300);
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: var(--fc-gray-300);
}
.flatpickr-time input {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--fc-gray-300);
  font-size: 14px;
  height: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
}
.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}
.flatpickr-time input:focus,
.flatpickr-time input:hover {
  background: var(--fc-gray-200);
}
.flatpickr-time input.flatpickr-hour {
  font-weight: 700;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time .flatpickr-am-pm,
.flatpickr-time .flatpickr-time-separator {
  align-self: center;
  color: var(--fc-gray-300);
  float: left;
  font-weight: 700;
  height: inherit;
  line-height: inherit;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 2%;
}
.flatpickr-time .flatpickr-am-pm {
  cursor: pointer;
  font-weight: 400;
  outline: 0;
  text-align: center;
  width: 18%;
}
.flatpickr-time .flatpickr-am-pm:focus,
.flatpickr-time .flatpickr-am-pm:hover {
  background: var(--fc-gray-200);
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-input[readonly] {
  background-color: var(--fc-white);
  cursor: pointer;
}
.ql-container {
  box-sizing: border-box;
  font-family: var(--fc-font-sans-serif);
  font-size: 13px;
  margin: 0;
  position: relative;
}
.ql-container.ql-disabled .ql-tooltip {
  visibility: hidden;
}
.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
  pointer-events: none;
}
.ql-clipboard {
  height: 1px;
  left: -100000px;
  overflow-y: hidden;
  position: absolute;
  top: 50%;
}
.ql-clipboard p {
  margin: 0;
  padding: 0;
}
.ql-editor {
  word-wrap: break-word;
  box-sizing: border-box;
  height: 100%;
  line-height: 1.42;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  -o-tab-size: 4;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
}
.ql-editor > * {
  cursor: text;
}
.ql-editor p {
  padding: 0;
}
.ql-editor ol,
.ql-editor p {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
}
.ql-editor ol {
  padding: 0 0 0 1.5em;
}
.ql-editor ol > li {
  list-style-type: none;
}
.ql-editor ol li {
  counter-increment: list-0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.ql-editor ol li:before {
  content: counter(list-0, decimal) ". ";
}
.ql-editor ol li.ql-direction-rtl {
  padding-right: 1.5em;
}
.ql-editor ol li.ql-indent-1 {
  counter-increment: list-1;
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-2 {
  counter-increment: list-2;
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-3 {
  counter-increment: list-3;
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". ";
}
.ql-editor ol li.ql-indent-4 {
  counter-increment: list-4;
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-5 {
  counter-increment: list-5;
  counter-reset: list-6 list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-6 {
  counter-increment: list-6;
  counter-reset: list-7 list-8 list-9;
}
.ql-editor ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". ";
}
.ql-editor ol li.ql-indent-7 {
  counter-increment: list-7;
  counter-reset: list-8 list-9;
}
.ql-editor ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". ";
}
.ql-editor ol li.ql-indent-8 {
  counter-increment: list-8;
  counter-reset: list-9;
}
.ql-editor ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". ";
}
.ql-editor ol li.ql-indent-9 {
  counter-increment: list-9;
}
.ql-editor ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". ";
}
.ql-editor ul {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0 0 0 1.5em;
}
.ql-editor ul > li {
  list-style-type: none;
}
.ql-editor ul > li:before {
  content: "•";
}
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
.ql-editor ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
.ql-editor .h1,
.ql-editor .h2,
.ql-editor .h3,
.ql-editor .h4,
.ql-editor .h5,
.ql-editor .h6,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor pre {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  margin: 0;
  padding: 0;
}
.ql-editor ul[data-checked="true"] {
  pointer-events: none;
}
.ql-editor ul[data-checked="true"] > li * {
  pointer-events: all;
}
.ql-editor ul[data-checked="true"] > li:before {
  color: var(--fc-gray-600);
  content: "☑";
  cursor: pointer;
  pointer-events: all;
}
.ql-editor ul[data-checked="false"] {
  pointer-events: none;
}
.ql-editor ul[data-checked="false"] > li * {
  pointer-events: all;
}
.ql-editor ul[data-checked="false"] > li:before {
  color: var(--fc-gray-600);
  content: "☐";
  cursor: pointer;
  pointer-events: all;
}
.ql-editor li:before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.ql-editor li:not(.ql-direction-rtl):before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.ql-editor li.ql-direction-rtl:before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em;
}
.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em;
}
.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em;
}
.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em;
}
.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em;
}
.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em;
}
.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em;
}
.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em;
}
.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em;
}
.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em;
}
.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em;
}
.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em;
}
.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em;
}
.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em;
}
.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em;
}
.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em;
}
.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em;
}
.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em;
}
.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em;
}
.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em;
}
.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em;
}
.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em;
}
.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em;
}
.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em;
}
.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em;
}
.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em;
}
.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em;
}
.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em;
}
.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em;
}
.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em;
}
.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em;
}
.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em;
}
.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em;
}
.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em;
}
.ql-editor .ql-video {
  display: block;
  max-width: 100%;
}
.ql-editor .ql-video.ql-align-center {
  margin: 0 auto;
}
.ql-editor .ql-video.ql-align-right {
  margin: 0 0 0 auto;
}
.ql-editor .ql-bg-black {
  background-color: var(--fc-dark);
}
.ql-editor .ql-bg-orange,
.ql-editor .ql-bg-red,
.ql-editor .ql-bg-yellow {
  background-color: var(--fc-danger);
}
.ql-editor .ql-bg-green {
  background-color: var(--fc-success);
}
.ql-editor .ql-bg-blue {
  background-color: var(--fc-primary);
}
.ql-editor .ql-bg-purple {
  background-color: var(--fc-secondary);
}
.ql-editor .ql-color-white {
  color: var(--fc-white);
}
.ql-editor .ql-color-red {
  color: var(--fc-danger);
}
.ql-editor .ql-color-orange {
  color: #fd7e14;
}
.ql-editor .ql-color-yellow {
  color: var(--fc-warning);
}
.ql-editor .ql-color-green {
  color: var(--fc-success);
}
.ql-editor .ql-color-blue {
  color: var(--fc-primary);
}
.ql-editor .ql-color-purple {
  color: var(--fc-secondary);
}
.ql-editor .ql-font-monospace,
.ql-editor .ql-font-serif {
  font-family: var(--fc-font-sans-serif);
}
.ql-editor .ql-size-small {
  font-size: 0.75em;
}
.ql-editor .ql-size-large {
  font-size: 1.5em;
}
.ql-editor .ql-size-huge {
  font-size: 2.5em;
}
.ql-editor .ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-editor .ql-align-center {
  text-align: center;
}
.ql-editor .ql-align-justify {
  text-align: justify;
}
.ql-editor .ql-align-right {
  text-align: right;
}
.ql-editor.ql-blank:before {
  color: var(--fc-dark);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.ql-snow.ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}
.ql-snow.ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}
.ql-snow.ql-toolbar button svg {
  float: left;
  height: 100%;
}
.ql-snow.ql-toolbar button:active:hover {
  outline: none;
}
.ql-snow.ql-toolbar button:hover {
  color: var(--fc-primary);
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow.ql-toolbar button:focus {
  color: var(--fc-primary);
}
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow.ql-toolbar input.ql-image[type="file"] {
  display: none;
}
.ql-snow.ql-toolbar button.ql-active {
  color: var(--fc-primary);
}
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-label:hover {
  color: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-label.ql-active {
  color: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-item:hover {
  color: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow {
  box-sizing: border-box;
}
.ql-snow .ql-toolbar:after {
  clear: both;
  content: "";
  display: table;
}
.ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  padding: 3px 5px;
  width: 28px;
}
.ql-snow .ql-toolbar button svg {
  float: left;
  height: 100%;
}
.ql-snow .ql-toolbar button:active:hover {
  outline: none;
}
.ql-snow .ql-toolbar button:hover {
  color: var(--fc-primary);
}
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow .ql-toolbar button:focus {
  color: var(--fc-primary);
}
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow .ql-toolbar input.ql-image[type="file"] {
  display: none;
}
.ql-snow .ql-toolbar button.ql-active {
  color: var(--fc-primary);
}
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-label:hover {
  color: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-label.ql-active {
  color: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-item:hover {
  color: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--fc-primary);
}
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--fc-primary);
}
.ql-snow * {
  box-sizing: border-box;
}
.ql-snow .ql-hidden {
  display: none;
}
.ql-snow .ql-out-bottom,
.ql-snow .ql-out-top {
  visibility: hidden;
}
.ql-snow .ql-tooltip {
  background-color: var(--fc-white);
  border: 1px solid var(--fc-gray-400);
  box-shadow: 0 0 5px var(--fc-gray-300);
  color: var(--fc-gray-600);
  padding: 5px 12px;
  position: absolute;
  transform: translateY(10px);
  white-space: nowrap;
}
.ql-snow .ql-tooltip a {
  cursor: pointer;
  line-height: 26px;
  text-decoration: none;
}
.ql-snow .ql-tooltip:before {
  content: "Visit URL:";
  line-height: 26px;
  margin-right: 8px;
}
.ql-snow .ql-tooltip input[type="text"] {
  border: 1px solid var(--fc-gray-200);
  display: none;
  font-size: 13px;
  height: 26px;
  margin: 0;
  padding: 3px 5px;
  width: 170px;
}
.ql-snow .ql-tooltip a.ql-preview {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
.ql-snow .ql-tooltip a.ql-action:after {
  border-right: 1px solid var(--fc-gray-200);
  content: "Edit";
  margin-left: 16px;
  padding-right: 8px;
}
.ql-snow .ql-tooltip a.ql-remove:before {
  content: "Remove";
  margin-left: 8px;
}
.ql-snow .ql-tooltip.ql-flip {
  transform: translateY(-10px);
}
.ql-snow .ql-formats {
  display: inline-block;
  vertical-align: middle;
}
.ql-snow .ql-formats:after {
  clear: both;
  content: "";
  display: table;
}
.ql-snow .ql-stroke {
  fill: none;
  stroke: var(--fc-gray-700);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}
.ql-snow .ql-stroke-miter {
  fill: none;
  stroke: var(--fc-gray-700);
  stroke-miterlimit: 10;
  stroke-width: 2;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: var(--fc-gray-700);
}
.ql-snow .ql-empty {
  fill: none;
}
.ql-snow .ql-even {
  fill-rule: evenodd;
}
.ql-snow .ql-stroke.ql-thin,
.ql-snow .ql-thin {
  stroke-width: 1;
}
.ql-snow .ql-transparent {
  opacity: 0.4;
}
.ql-snow .ql-direction svg:last-child {
  display: none;
}
.ql-snow .ql-direction.ql-active svg:last-child {
  display: inline;
}
.ql-snow .ql-direction.ql-active svg:first-child {
  display: none;
}
.ql-snow .ql-editor .h1,
.ql-snow .ql-editor h1 {
  font-size: 2em;
}
.ql-snow .ql-editor .h2,
.ql-snow .ql-editor h2 {
  font-size: 1.5em;
}
.ql-snow .ql-editor .h3,
.ql-snow .ql-editor h3 {
  font-size: 1.17em;
}
.ql-snow .ql-editor .h4,
.ql-snow .ql-editor h4 {
  font-size: 1em;
}
.ql-snow .ql-editor .h5,
.ql-snow .ql-editor h5 {
  font-size: 0.83em;
}
.ql-snow .ql-editor .h6,
.ql-snow .ql-editor h6 {
  font-size: 0.67em;
}
.ql-snow .ql-editor a {
  text-decoration: underline;
}
.ql-snow .ql-editor blockquote {
  border-left: 4px solid var(--fc-gray-200);
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
.ql-snow .ql-editor code {
  font-size: 85%;
  padding: 2px 4px;
}
.ql-snow .ql-editor code,
.ql-snow .ql-editor pre {
  background-color: var(--fc-light);
  border-radius: 3px;
}
.ql-snow .ql-editor pre {
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
  white-space: pre-wrap;
}
.ql-snow .ql-editor pre.ql-syntax {
  background-color: var(--fc-dark);
  color: var(--fc-gray-300);
  overflow: visible;
}
.ql-snow .ql-editor img {
  max-width: 100%;
}
.ql-snow .ql-picker {
  color: var(--fc-gray-600);
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px;
  position: relative;
  vertical-align: middle;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  margin-top: -9px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 18px;
}
.ql-snow .ql-picker-label {
  cursor: pointer;
  display: inline-block;
  height: 100%;
  padding-left: 8px;
  padding-right: 2px;
  position: relative;
  width: 100%;
}
.ql-snow .ql-picker-label:before {
  display: inline-block;
  line-height: 22px;
}
.ql-snow .ql-picker-options {
  background-color: var(--fc-white);
  display: none;
  min-width: 100%;
  padding: 4px 8px;
  position: absolute;
  white-space: nowrap;
}
.ql-snow .ql-picker-options .ql-picker-item {
  cursor: pointer;
  display: block;
  padding-bottom: 5px;
  padding-top: 5px;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: var(--fc-gray-200);
  z-index: 2;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: var(--fc-gray-200);
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: var(--fc-gray-200);
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: 100%;
  z-index: 1;
}
.ql-snow .ql-color-picker {
  width: 28px;
}
.ql-snow .ql-color-picker .ql-picker-label {
  padding: 2px 4px;
}
.ql-snow .ql-color-picker .ql-picker-label svg {
  right: 4px;
}
.ql-snow .ql-color-picker .ql-picker-options {
  padding: 3px 5px;
  width: 152px;
}
.ql-snow .ql-color-picker .ql-picker-item {
  border: 1px solid transparent;
  float: left;
  height: 16px;
  margin: 2px;
  padding: 0;
  width: 16px;
}
.ql-snow .ql-icon-picker {
  width: 28px;
}
.ql-snow .ql-icon-picker .ql-picker-label {
  padding: 2px 4px;
}
.ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
}
.ql-snow .ql-icon-picker .ql-picker-options {
  padding: 4px 0;
}
.ql-snow .ql-icon-picker .ql-picker-item {
  height: 24px;
  padding: 2px 4px;
  width: 24px;
}
.ql-snow .ql-picker.ql-header {
  width: 98px;
}
.ql-snow
  .ql-picker.ql-header
  .ql-picker-item[data-label]:not([data-label=""]):before,
.ql-snow
  .ql-picker.ql-header
  .ql-picker-label[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}
.ql-snow .ql-picker.ql-header .ql-picker-item:before,
.ql-snow .ql-picker.ql-header .ql-picker-label:before {
  content: "Normal";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]:before {
  content: "Heading 1";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]:before {
  content: "Heading 1";
  font-size: 2em;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]:before {
  content: "Heading 2";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]:before {
  content: "Heading 2";
  font-size: 1.5em;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]:before {
  content: "Heading 3";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]:before {
  content: "Heading 3";
  font-size: 1.17em;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]:before {
  content: "Heading 4";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]:before {
  content: "Heading 4";
  font-size: 1em;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]:before {
  content: "Heading 5";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]:before {
  content: "Heading 5";
  font-size: 0.83em;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]:before {
  content: "Heading 6";
}
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]:before {
  content: "Heading 6";
  font-size: 0.67em;
}
.ql-snow .ql-picker.ql-font {
  width: 108px;
}
.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-label]:not([data-label=""]):before,
.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}
.ql-snow .ql-picker.ql-font .ql-picker-item:before,
.ql-snow .ql-picker.ql-font .ql-picker-label:before {
  content: "Sans Serif";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]:before {
  content: "Serif";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]:before {
  content: "Serif";
  font-family: var(--fc-font-sans-serif);
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]:before {
  content: "Monospace";
}
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]:before {
  content: "Monospace";
  font-family: var(--fc-font-sans-serif);
}
.ql-snow .ql-picker.ql-size {
  width: 98px;
}
.ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-label]:not([data-label=""]):before,
.ql-snow
  .ql-picker.ql-size
  .ql-picker-label[data-label]:not([data-label=""]):before {
  content: attr(data-label);
}
.ql-snow .ql-picker.ql-size .ql-picker-item:before,
.ql-snow .ql-picker.ql-size .ql-picker-label:before {
  content: "Normal";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]:before {
  content: "Small";
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]:before {
  content: "Small";
  font-size: 10px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]:before {
  content: "Large";
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]:before {
  content: "Large";
  font-size: 18px;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]:before {
  content: "Huge";
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]:before {
  content: "Huge";
  font-size: 32px;
}
.ql-snow .ql-color-picker.ql-background .ql-picker-item {
  background-color: var(--fc-white);
}
.ql-snow .ql-color-picker.ql-color .ql-picker-item {
  background-color: var(--fc-dark);
}
.ql-snow .ql-tooltip.ql-editing a.ql-preview,
.ql-snow .ql-tooltip.ql-editing a.ql-remove {
  display: none;
}
.ql-snow .ql-tooltip.ql-editing input[type="text"] {
  display: inline-block;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action:after {
  border-right: 0;
  content: "Save";
  padding-right: 0;
}
.ql-snow .ql-tooltip[data-mode="link"]:before {
  content: "Enter link:";
}
.ql-snow .ql-tooltip[data-mode="formula"]:before {
  content: "Enter formula:";
}
.ql-snow .ql-tooltip[data-mode="video"]:before {
  content: "Enter video:";
}
.ql-snow a {
  color: var(--fc-primary);
}
.ql-toolbar.ql-snow {
  background-color: var(--fc-white);
  border: 1px solid var(--fc-border-color);
  border-radius: 0.25rem 0.25rem 0 0;
  box-sizing: border-box;
  font-family: var(--fc-font-sans-serif);
  padding: 8px;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}
.ql-toolbar.ql-snow .ql-picker-label {
  border: 1px solid transparent;
}
.ql-toolbar.ql-snow .ql-picker-options {
  border: 1px solid transparent;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: var(--fc-gray-200);
}
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
.ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
  border-color: var(--fc-dark);
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 0;
}
.ql-container.ql-snow {
  border: 1px solid var(--fc-border-color);
  border-radius: 0 0 0.5rem 0.5rem;
}
@media (pointer: coarse) {
  .ql-snow.ql-toolbar button:hover:not(.ql-active) {
    color: var(--fc-gray-600);
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: var(--fc-gray-700);
  }
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: var(--fc-gray-700);
  }
  .ql-snow .ql-toolbar button:hover:not(.ql-active) {
    color: var(--fc-gray-600);
  }
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
    fill: var(--fc-gray-700);
  }
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
  .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
    stroke: var(--fc-gray-700);
  }
}
.apexcharts-tooltip {
  border-radius: 0.5rem !important;
  box-shadow: var(--fc-box-shadow-sm) !important;
}
.apexcharts-tooltip .apexcharts-tooltip-series-group {
  align-items: center;
  background: var(--fc-white) !important;
  color: var(--fc-dark);
  display: none;
  justify-content: left;
  line-height: 1 !important;
  padding: 3px 16px 6px !important;
  text-align: left;
}
.apexcharts-tooltip-marker {
  border-radius: 50%;
  height: 8px !important;
  margin-right: 6px !important;
  position: relative;
  top: 0;
  width: 8px !important;
}
.apexcharts-tooltip.apexcharts-theme-light {
  background-color: var(--fc-gray-100) !important;
  background: var(--fc-white) !important;
  border: 1px solid var(--fc-gray-200) !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--fc-white) !important;
  border-bottom: 1px solid var(--fc-gray-200) !important;
}
.apexcharts-tooltip-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 4px;
  padding: 8px 16px !important;
}
.apexcharts-xaxis-label {
  fill: var(--fc-gray-500);
}

.marquee-text {
  /* color: #89b577; */
  font-family: brandonTextLight;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  letter-spacing: 2.24px;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0;
}
.navbar-dark {
  background-color: #ffffff;
  color: var(--fc-white) !important;
  font-family: var(--fc-font-regular);
}
span.dropdown-text.store-color {
  color: #80ba51 !important;
  font-family: var(--fc-font-bold);
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}
.custom-dropdown .dropdown-text {
  display: block;
  color: var(--fc-black) !important;
}
.custom-dropdown svg {
  display: flex;
  margin: 0 auto;
}
.btn-custom {
  width: 100%;
  border-radius: 9px;
  background-color: var(--fc-black);
  color: var(--fc-white);
  font-family: brandonTextLight !important;
  font-size: 12px !important;
}
.dropdown-menu-custom {
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px !important;
  width: 100%;
  min-width: 220px;
  /* top: 80px; */
}
.dropdown-menu-custom .btn {
  margin-bottom: 17px;
}
.dropdown-menu-custom span {
  margin-right: 12px;
}
.menu-drop-link {
  margin-bottom: 17px;
  font-size: 12px;
}
.deals-card {
  border-radius: 9px;
  background: linear-gradient(90deg, #01c3de 0%, #00bba6 100%);
  border: none;
}
.deals-banner {
  width: 100%;
}
.deals-card-body {
  padding: 40px 120px;
}
.deals-card-body .btn-light {
  color: #000;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 52px;
  background: #fff;
  padding: 16px 56px;
  max-width: 220px;
}
.deals-card-footer {
  background: rgba(0, 0, 0, 0.19);
  color: var(--fc-white);
  border: none;
  font-family: var(--fc-heading-font);
  font-style: italic;
  font-weight: 500;
  line-height: 145%; /* 17.4px */
  letter-spacing: 1.08px;
  text-transform: uppercase;
}
.deals-card-footer span {
  padding: 0 12px;
}
.sticker {
  position: relative;
  top: -20%;
}
.deals-card h1 {
  font-family: var(--fc-heading-font);
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: 117.5%; /* 64.625px */
  text-transform: uppercase;
  color: var(--fc-white);
}
.deals-card h1 span {
  color: var(--fc-black);
}
.top-categories {
  border-radius: 9px;
  background: linear-gradient(140deg, #eb84a4 0%, #9ee8ec 0.01%, #fbc7ff 100%);
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 33px;
}
.top-categories h1 {
  line-height: normal;
  /* text-transform: uppercase; */
  color: var(--fc-black);
  text-align: center;
  font-family: var(--fc-font-bold);
  font-size: 25px;
}
.top-categories h3 {
  text-align: center;
  font-family: var(--fc-font-bold);
  line-height: normal;
  text-transform: uppercase;
  color: var(--fc-black) !important;
  margin-top: 20px;
  font-size: 20px;
}
.top-categories img {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
}
.bg-middle {
  background: #f9f4f4;
}
.shop-more {
  border-radius: 9px;
  background: #fff !important;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 43px 69px;
}
.shop-more img {
  border-radius: 50%;
}

@media only screen and (max-width: 1200px) {
  .shop-p-head {
    font-size: 18px;
  }

  .react-multi-carousel-list h3 {
    font-size: 18px;
  }
  .slick-slider h3 {
    font-size: 13px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 769px) {
  .shop-more {
    padding: 13px 0px;
    box-shadow: none;
    border-radius: unset;
    border: none;
  }
  .top-categories h1 {
    line-height: normal;
    text-transform: capitalize;
    color: var(--fc-black);
    text-align: center;
    font-family: var(--fc-font-medium);
    font-size: 14px;
  }
  .top-categories h3 {
    font-size: 12px !important;
  }
  .shop-more h3 {
    font-size: 12px;
    text-align: center;
    font-family: var(--fc-font-regular);
  }
  .top-categories {
    border-radius: unset;
  }
  .top-categories img {
    width: 105.5px;
    height: 124px !important;
    /*object-fit: cover !important;*/
  }
  .store-details-slider .react-multiple-carousel__arrow {
    background-color: #ebece9 !important;
    display: none;
  }
  .shop-more h1 {
    line-height: normal;
    text-transform: capitalize;
    color: var(--fc-black);
    text-align: center;
    font-family: var(--fc-font-medium) !important;
    font-size: 14px !important;
  }
}
.shop-more h1 {
  line-height: normal;
  /* text-transform: uppercase; */
  color: var(--fc-black);
  text-align: center;
  font-family: var(--fc-font-bold);
  font-size: 25px;
}
.top-categories h3 {
  text-align: center;
  font-family: var(--fc-font-bold);
  line-height: normal;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 20px;
}
.top-categories img {
  /*box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);*/
  height: auto;
  max-width: 100%;
  object-fit: contain;
}
.thumbnails {
  margin: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s;
}

.thumbnails img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 20%;
  max-width: 208px;
  max-height: 208px;
}

.shop-preference-img {
  border-radius: 15px !important;
}

/* .black {
  text-align: center;
  background: #000;
  background-blend-mode: multiply;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: .7;
} */

.title {
  color: #fff !important;
  font-size: 2em;
  align-self: center;
  position: absolute;
  z-index: 10;
  text-transform: uppercase;
}

.black,
.title {
  transition: opacity 0.3s;
}
.thumbnails:hover {
  cursor: pointer;
  transform: scale(1.05);
  z-index: 20;
}

.thumbnails:hover .black,
.thumbnails:hover .title {
  opacity: 0;
}
.ads-block img {
  flex-shrink: 0;
  border-radius: 8px;
  width: 100%;
  /* margin-bottom: 30px; */
  filter: drop-shadow(0px 4px 17px rgba(0, 0, 0, 0.24));
}
.shop-preferences {
  border-radius: 9px;
  background: #f5e9db;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 43px 69px;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .shop-preferences {
    padding: 40px 0px;
    border-radius: unset;
    box-shadow: none;
    border: none;
  }
  .top-categories h3 {
    font-size: 12px !important;
  }
  .shop-preferences h3 {
    font-size: 12px !important;
    font-family: var(--fc-font-regular) !important;
  }
  .shop-preferences h1 {
    line-height: normal;
    text-transform: uppercase;
    color: var(--fc-black);
    text-align: center;
    font-family: var(--fc-font-medium) !important;
    font-size: 14px !important;
  }
}
.shop-preferences h1 {
  line-height: normal;
  text-transform: uppercase;
  color: var(--fc-black);
  text-align: center;
  font-family: var(--fc-font-bold);
  font-size: 25px;
}
.shop-preferences .thumbnails img {
  width: 100%;
  object-fit: cover;
  object-position: 50% 20%;
  border-radius: 50%;
  max-width: 188px;
  max-height: 188px;
}
.shop-preferences .row {
  display: flex;
  justify-content: space-evenly;
}
.login-card {
  border: none;
  margin-top: 20%;
}
.social-head {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-evenly;
}
.social-haed svg {
  width: 85%;
}
.login-card p {
  line-height: normal;
  text-transform: uppercase;
  color: var(--fc-black);
  font-family: var(--fc-font-bold);
  margin-bottom: 42px;
  text-align: center;
}
.login-card .btn-dark {
  border-radius: 9px;
  background-color: var(--fc-black);
}
.login-card .form-control {
  border-radius: 9px;
  border: 1px solid #000;
  padding: 15px;
}
.separator {
  display: flex;
  align-items: center;
  margin-bottom: 42px;
}

.separator .line {
  height: 1.2px;
  flex: 1;
  background-color: #000;
}

.separator h2 {
  padding: 0 2rem;
  font-size: 16px;
  font-family: var(--fc-font-regular);
  font-weight: 390;
  color: var(--fc-black);
}
.home-banner .react-multiple-carousel__arrow {
  /* top: 15%; */
  background-color: var(--fc-white);
}
.home-banner .react-multiple-carousel__arrow::before {
  color: var(--fc-black);
}
.home-banner .react-multiple-carousel__arrow:hover {
  background: rgba(255, 255, 255, 0.7);
}
.flash-sale {
  text-align: center;
  font-family: var(--fc-font-bold);
  /* text-transform: uppercase; */
}
.flash-sale span {
  padding: 0 12px;
}
.product-box {
  border-radius: 9px;
  background-color: rgba(235, 236, 233, 1);
  padding: 7px 10px;
  /* margin: 0 24px; */
  /* border: none !important; */
  outline: none !important;
  background-color: #fff;
  border: solid 1px #a2a2a242 !important;
}
.product-box:hover {
  border: none;
}
.product-image {
  width: 100%;
  max-height: 280px;
  object-fit: contain;
}
.product-image img {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.product-box .btn-dark {
  text-transform: uppercase;
  position: relative;
  z-index: 10;
}
.product-img {
  height: 100%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-img img {
  object-fit: contain;
  width: 100%;
  max-height: 270px;
}
.flash-sale-container {
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 43px 80px;
}
.flash-sale-container h1 {
  line-height: normal;
  /* text-transform: uppercase; */
  color: var(--fc-black);
  text-align: center;
  font-family: var(--fc-font-bold);
  font-size: 25px;
}
.rec-products-container {
  border-radius: 9px;
  background: #fff;
  box-shadow: none !important;
  border: unset !important;
  padding: 2px 80px;
}
.rec-products-container h1 {
  line-height: normal;
  text-transform: uppercase;
  color: var(--fc-black);
  text-align: center;
  font-family: var(--fc-font-bold);
  font-size: 25px;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .flash-sale-container {
    padding: 13px 0px;
    box-shadow: none;
    border: none;
    padding-top: 0px;
  }
  .rec-products-container {
    padding: 13px 12px;
    box-shadow: none;
    border: none;
  }
  .flash-sale-container h1 {
    line-height: normal;
    text-transform: uppercase;
    color: var(--fc-black);
    text-align: center;
    font-family: var(--fc-font-medium);
    font-size: 14px !important;
    text-transform: capitalize;
  }
  .carousel-product-margin {
    margin: 0 5.5px !important;
  }
  .product-img {
    height: 140px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-img img {
    height: 90px !important;
    height: 120px !important;
    object-fit: contain;
    margin-top: 25px;
  }
  .btn-dark {
    font-size: 0.5rem !important;
    font-size: 10px !important;
  }
  .product-grid h4 {
    color: #000;
    font-family: Arial;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-transform: capitalize;
    text-align: center !important;
    padding-top: 1px !important;
    /* white-space: normal !important;
    overflow: visible !important;
    text-overflow: unset !important; */
  }
  .product-grid .selling-price,
  .discounted-price,
  .discount-percentage {
    font-size: 14px !important;
    padding-left: 0px;
    /* text-align: left; */
    font-family: var(--fc-font-regular);
  }
  body.products .product-grid {
    padding-bottom: 10px;
  }
  .col-lg-3.col-md-4.product-filter-sidebar aside.mb-6.mb-md-0 {
    margin-bottom: 0px !important;
}
  .discount-percentage {
    display: flex;
    justify-content: space-between;
    margin-left: 0;
    margin-top: 5px;
  }
}
.add-to-bag-btn svg {
  margin-left: 26px;
  margin-top: -3px;
}
.custom-badge {
  border-radius: 9px;
  background: #fdd801;
  padding: 10px;
  position: absolute;
  left: 0;
  color: #000;
  font-size: 16px;
  font-family: var(--fc-font-regular);
}
.custom-badge svg {
  transform: scale(0.85);
}
.wishlist-button {
  position: absolute;
  right: 8%;
  top: 20px;
  z-index: 999;
  cursor: pointer;
}
.wishlist-button svg:hover {
  fill: rgba(128, 186, 39, 1);
}
.wishlist-active svg {
  fill: rgba(128, 186, 39, 1);
}
.product-grid h4 {
  color: #000;
  font-family: "BrandonText-Light", sans-serif;
  font-weight: 400;
  font-size: 1.09375rem;
  font-style: normal;
  line-height: 185%; /* 29.6px */
  text-transform: capitalize;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-grid .card {
  border: none !important;
}
.product-grid .card-footer {
  border: none !important;
  padding: unset 0 !important;
  margin: 0 9.5px;
}
.selling-price {
  color: #000;
  font-size: 16px;
  font-family: var(--fc-font-bold);
  text-transform: uppercase;
}
.discounted-price {
  color: #fa6338;
  font-size: 16px;
  font-family: var(--fc-font-regular);
  text-decoration: line-through;
  text-transform: uppercase;
}
.discount-percentage {
  color: #80ba27;
  font-size: 16px;
  font-family: var(--fc-font-bold);
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .flash-sale-container .col-md-4 {
    flex: 0 0 auto;
    width: 32%;
  }
  .rec-products-container .col-md-4 {
    flex: 0 0 auto;
    width: 25%;
  }
}
.custom-row1 {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.cc-margin {
  margin: 3.5rem 0;
}
.cd-margin {
  margin-bottom: 3.5rem;
}
.wallet-banner {
  border-radius: 9px;
  background: #a37073;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 65px 54px;
  border: none !important;
}
.wallet-banner h1 {
  color: #000;
  font-family: Poppins;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.58px;
  text-transform: uppercase;
  text-align: start !important;
}
.wallet-banner h5 {
  color: #fff;
  font-family: Poppins;
  font-size: 20.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 131%; /* 26.855px */
  letter-spacing: 1.025px;
  text-transform: uppercase;
}
.wallet-card {
  border-radius: 26px;
  /* border: 5px solid #fff; */

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* padding: 25px; */
  overflow: hidden;
  margin: 0 20px;
  max-height: 180px;
  max-width: 180px;
}
.wallet-card > div {
  background: #fff;
  /* box-shadow: 7px 10px 18px 0px rgba(22, 21, 22, 0.56),
    -12px -9px 35px 0px rgba(255, 255, 255, 0.39),
    -5px 10px 16px 0px rgba(55, 10, 53, 0.27) inset; */
  width: 100%;
  padding: 25px;
}
.wallet-card h3 {
  font-size: 18px;
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
}
.wallet-card h2 {
  color: #000;
  text-align: center;
  font-family: Arial Black;
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.brand-img {
  margin: 0 15px;
}
.brand-img img {
  border-radius: 9px;
}
.brands-card {
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 43px 31px;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .brands-card {
    padding: 13px 0px;
    box-shadow: none;
    border-radius: unset;
    border: none;
  }
  .top-categories h3 {
    font-size: 12px !important;
  }
  .cc-margin {
    margin: 2rem 0;
  }
  .cd-margin {
    margin-bottom: 2rem;
  }
  .brands-card h1 {
    line-height: normal;
    text-transform: capitalize;
    color: var(--fc-black);
    text-align: center;
    font-family: var(--fc-font-medium) !important;
    font-size: 14px !important;
  }
}
.brands-card h1 {
  line-height: normal;
  /* text-transform: uppercase; */
  color: var(--fc-black);
  text-align: center;
  font-family: var(--fc-font-bold);
  font-size: 25px;
}
.home-banner {
  background: url("../img/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.category-top-img {
  margin: 0 auto;
  text-align: center;
}
.category-top-img h3 {
  text-align: center;
  font-family: var(--fc-font-regular);
  font-size: 16px;
}
.category-top-img img {
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(
      from 180deg at 50% 50%,
      #00bb61 0deg,
      #97d25c 174.38deg,
      #f6e059 360deg
    ),
    linear-gradient(0deg, #d9d9d9, #d9d9d9);
    max-width: 400px;
    max-height: 400px;
    width: 85%;
}
.category-top {
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 43px 0px;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .category-top {
    padding-top: 13px !important;
    box-shadow: none;
    border: none;
    padding-bottom: 5px;
  }
  .category-top h1 {
    font-size: 14px !important;
    font-family: var(--fc-font-medium) !important;
  }
  .top-categories h3 {
    font-size: 12px !important;
    font-family: var(--fc-font-regular);
  }
  .category-top-img img {
    border-radius: 50%;
  padding: 1px;
  background: conic-gradient(
      from 180deg at 50% 50%,
      #00bb61 0deg,
      #97d25c 174.38deg,
      #f6e059 360deg
    ),
    linear-gradient(0deg, #d9d9d9, #d9d9d9);
    max-width: 400px;
    max-height: 400px;
    width: 85%;

  }
  .category-top-img h3 {
    font-size: 14px;
  }
  .custom-badge {
    border-radius: 9px;
    background: #fdd801;
    padding: 10px;
    position: absolute;
    left: 0;
    transform: scale(0.55);
    top: 0;
  }
  .wishlist-button {
    position: absolute;
    right: 5%;
    z-index: 80;
    cursor: pointer;
    top: 3%;
    transform: scale(0.65);
  }
  .product-main-banner {
    max-height: 80px !important;
    border-radius: 14px;
    object-fit: contain !important;
  }
  .sortHeader h6 {
    color: #bdbebb;
    font-family: var(--fc-font-light);
    font-size: 12px !important;
    line-height: normal;
    text-align: center;
    margin-top: 12px;
  }
  .sortHeader .sort-dropdown {
    color: #000;
    font-family: var(--fc-font-light);
    font-size: 12px !important;
    line-height: normal;
    border-radius: 3px;
    border: 0.7px solid #000;
    padding: 2px;
  }
  .sortHeader .sort-dropdown svg {
    transform: scale(0.7);
  }
  .btn-filter {
    color: #000;
    font-family: var(--fc-font-light) !important;
    font-size: 12px !important;
    line-height: normal;
    border-radius: 3px;
    border: 0.7px solid #000;
    padding: 5px;
    text-align: start;
    font-weight: 400;
  }
  .btn-filter span {
    float: right !important;
  }
  .btn-filter:hover {
    background-color: #fff !important;
    border-color: #000 !important;
    color: #000 !important;
  }
  .btn-filter:hover {
    background-color: #fff !important;
    border-color: #000 !important;
    color: #000 !important;
    line-height: 20px;
    padding: 2px 5px;
  }
  .px-5 {
    padding: 1rem !important;
  }
}
.category-top h1 {
  line-height: normal;
  /* text-transform: uppercase; */
  color: var(--fc-black);
  text-align: center;
  font-family: var(--fc-font-bold);
  font-size: 25px;
}
.banner-flashsale {
  background-repeat: none;
  background-repeat: no-repeat;
  background-size: 100% 100%, cover;
  background-position: center center;
  padding: 8% 0;
  min-height: max-content;
}
.timer-card {
  border-radius: 9px;
  background: #fefaec;
  box-shadow: -4px 4px 3px 0px #000;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px;
  justify-content: center;
  width: 90px;
  height: 90px;
}
.timer-card h1 {
  color: #000;
  text-align: center;
  font-family: NexaRegular;
  font-size: 46px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 20px;
}
.time-seperator {
  position: relative;
  font-size: 45px;
  color: #fff;
}
.cc-col-1 {
  width: 3% !important;
}
.blink-hard {
  animation: blinker 1s step-end infinite;
}
.blink-soft {
  animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.banner-flashsale h2 {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 43px;
  font-style: normal;
  font-weight: 800;
  line-height: 103.5%; /* 44.505px */
  text-transform: uppercase;
}
.banner-flashsale p {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 103.5%; /* 15.525px */
  letter-spacing: 1.05px;
  text-transform: uppercase;
}
.banner-flashsale h5 {
  color: #fff;
  font-family: var(--fc-font-regular);
  font-size: 12px;
  font-style: normal;
  font-weight: 390;
  line-height: normal;
}
.btn-outline-light {
  border-radius: 52px;
  border: 1px solid #fff;
}
.d-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.ends-in-lg p{
  margin-left: 46.5rem;
  margin-bottom: 20px;
  font-size: larger;
}
.ads-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 18% 0;
  min-height: 480px;

  height: 100%;
  margin: 0 40px;
  border-radius: 9px !important;
}
.ads-banner h1 {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 98px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.5%; /* 106.33px */
  text-transform: uppercase;
}
.ads-banner h4 {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  line-height: 108.5%; /* 33.635px */
  text-transform: uppercase;
}
.ads-banner .btn-light {
  border-radius: 9px;
  background: #fff;
  padding: 16px 25px;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  /* .ads-banner {
    background-image: url("../img/ad-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 60px;
  
    height: 100%;
  }   */
  body::-webkit-scrollbar {
    position: absolute;
  }
  .container-lg-fluid {
    --fc-gutter-x: 4rem;
    --fc-gutter-y: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
  }
  .ads-banner {
    background-image: url("../img/ad-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 70px 0;
    min-height: 100%;
    margin: 0 20px;
    height: 100%;
  }
  .icon-section svg {
    transform: scale(0.75);
  }
  .product-desc-section .btn-checkout {
    font-size: 17px !important;
    padding: 12px 20px;
    color: black;
    padding-left: 36px;
  }
  .timer-box {
    border-radius: 9px;
    background: #c8ddd9;
    padding: 12px;
    font-size: 16px;
    color: #000;
    font-family: var(--fc-font-bold);
    text-align: center;
    width: 55px;
    height: 49px;
  }
  .timer-field span {
    color: #fff;
    font-family: var(--fc-font-light);
    font-size: 10px !important;
    text-transform: capitalize;
    text-align: center;
  }
}
.container-lg-fluid {
  --fc-gutter-x: 4rem;
  --fc-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--fc-gutter-x) * 0.5);
  padding-right: calc(var(--fc-gutter-x) * 0.5);
  width: 100%;
}
.custom-mega-menu h6 {
  color: #000 !important;
  font-size: 16px;
  line-height: normal;
  text-transform: uppercase;
  font-family: var(--fc-font-regular);
}
.custom-mega-menu .dropdown-item {
  color: #000;
  font-family: var(--fc-font-light);
  font-size: 12px;
  line-height: normal;
  letter-spacing: 1.68px;
  text-transform: capitalize;
  margin: 13px 0;
  cursor: pointer;
}
.custom-mega-menu .dropdown-item:hover {
  cursor: pointer;
}
.custom-mega-menu .thumbnails {
  width: 121px;
  height: 121px;
  border-radius: 50%;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.86);
}
.custom-mega-menu .thumbnails img {
  object-fit: contain;
}
.megamenu-ads {
  border-radius: 9px;
  margin-bottom: 20px;
  height: 110px;
  width: 100%;
}
.custom-a {
  color: var(--fc-black) !important;
}
.navbar .dropdown-fullwidth .dropdown-menu {
  max-width: 100vw !important;
  overflow: hidden;
}
.digital-text {
  color: #000;
  text-align: center;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.48);
  font-family: "DS-Digital Bold";
  font-size: 35px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 9px;
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0.23);
  padding: 10px;
  width: max-content;
}
.timer-text {
  color: #000;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-family: var(--fc-font-bold);
  font-weight: 450;
  line-height: normal;
  text-transform: capitalize;
}
.pr-0 {
  padding-right: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.desktop-thumbs .topArrow {
  transform: rotate(90deg);
}
.desktop-thumbs .bottomArrow {
  transform: rotate(270deg);
}
.desktop-thumbs .slick-slider .slick-next:hover,
.slick-slider .slick-prev:hover {
  background: rgba(235, 236, 233, 1);
  color: var(--fc-white);
  border: none !important;
  outline: none;
}
.desktop-thumbs .slick-slider .slick-next {
  position: absolute;
  left: 45%;
  top: calc(5% + -20px);
}
.desktop-thumbs .slick-slider .slick-prev {
  position: absolute;
  left: 65%;
  top: calc(100% + 25px);
  z-index: 999;
}

.px-5 {
  padding: 5rem;
}
.thumbnail-slide {
  max-width: 136px;
  height: 136px;
  background: rgba(235, 236, 233, 1);
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 27px;
  background: #fff;
  border: solid 1px #a2a2a2 !important;
}
.thumbnail-slide img {
  width: 100px !important;
  height: 100px;
  object-fit: contain;
}
.product-showcase {
  background: rgba(235, 236, 233, 1);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: auto;
  padding: 30px 30px;
  background: #fff;
  border: solid 1px #a2a2a2 !important;
}
.slick-slide div {
  display: flex;
  justify-content: center;
}
.mob-thumbs {
  display: none;
}
.desktop-thumbs {
  display: block;
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .mob-thumbs {
    display: block;
  }
  .desktop-thumbs {
    display: none;
  }
  .nav-pills-dark .nav-item .nav-link {
    font-size: 12px;
    padding: 0.95rem 0.5rem;
  }
  .my-dash-head {
    font-size: 14px;
    color: #000;
    font-family: var(--fc-font-regular);
  }
  .app-credits {
    text-transform: uppercase;
    color: #584f4f;
    font-size: 12px;
    font-family: var(--fc-font-regular);
    margin-top: 24px;
  }
  .nav-pills-dark svg {
    float: right;
    transform: scale(0.65);
  }
  .nav-pills-dark img {
    transform: scale(0.8);
  }
  .mob-arrow {
    float: right;
    padding-top: 7.5px;
  }
  .product-showcase-mobile {
    background: rgba(235, 236, 233, 1);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 480px;
    min-height: auto;
    padding: 30px 30px;
    margin-bottom: 20px;
    background-color: #fff;
    border: solid 1px #a2a2a2 !important;
  }
  .product-showcase-mobile .slick-dots {
    position: absolute;
    bottom: 5%;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    right: 2%;
  }
  .product-showcase-mobile .slick-dots li {
    width: 5% !important;
  }
  .product-showcase-mobile img {
    display: flex !important;
    justify-content: center;
    height: 400px;
    object-fit: contain;
  }
  .product-showcase-mobile .slick-dots li.slick-active button::before {
    opacity: 1 !important;
    color: #000 !important;
    background-color: #000 !important;
    border: none !important;
  }
  .product-showcase-mobile .slick-dots li button::before {
    font-family: "slick";
    font-size: 10px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    background-color: #fff !important;
    color: #fff !important;
    border: 0.5px solid #000 !important;
    border-radius: 50%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .product-showcase-mobile .slick-dots li button {
    background-color: #fff !important;
    color: #fff !important;
    border: 0.5px solid #000 !important;
    border-radius: 50% !important;
    transform: scale(0.7);
  }
  .slick-initialized .slick-slide {
    margin: 0 auto !important;
  }
  .product-desc-section h1 {
    font-size: 18px !important;
  }
  .product-desc-section h2 {
    font-size: 12px !important;
  }
  .product-single-price,
  .product-single-discounted-price,
  .product-single-discounted {
    font-size: 14px !important;
  }
}
.product-desc-section h1 {
  color: #000;
  font-size: 24px;
  /* text-transform: lowercase; */
  font-weight: 600;
}
.product-desc-section h1::first-letter{
  text-transform: capitalize;
}
.product-desc-section h2 {
  color: #000;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: var(--fc-font-thin);
}
.langRow {
  position: relative;
  top: -20px;
}
.langName {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 390;
  line-height: normal;
  text-transform: uppercase;
}
.lang-dropdown-menu {
  border-radius: 9px !important;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) !important;
}
.lang-dropdown-menu .dropdown-item:hover {
  background-color: none !important;
}
.lang-dropdown-menu .dropdown-item {
  margin-bottom: 12px;
}
.lang-dropdown-menu span {
  padding-left: 12px;
  text-transform: uppercase;
  font-size: 12px;
  font-style: normal;
  font-weight: 390;
  line-height: normal;
  text-transform: uppercase;
}
.ModalBtn {
  position: fixed;
  top: 30%;
  transform: scale(0.65);
  right: -12px;
  z-index: 99;
}
.discount-modal {
  border-radius: 9px !important;
  background: #ffe08b !important;
}
.order-now {
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
}
.discount-modal h4 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 40px;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff; */
}
.discount-modal h1 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 104px;
  line-height: 100px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -10px;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff; */
}
.discount-modal h1 span {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: normal;
}
.discount-modal .btn-dark {
  border-radius: 9px;
  background: #000;
  padding: 16px 65px;
  text-transform: uppercase;
  font-weight: var(--fc-font-thin);
}
.discount-modal .t-c,
.discount-modal .t-c a {
  color: #000 !important;
  text-align: center;
  font-size: 20px;
  font-weight: var(--fc-font-thin);
  line-height: normal;
  text-decoration-line: underline;
  text-transform: uppercase;
}
.discount-modal .btn-close {
  color: var(--fc-black) !important;
  transform: scale(0.75);
}
.product-custom-dropdown .dropdown-menu {
  border-radius: 9px;
  background: #fff;
  box-shadow: 2px 6px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 0;
  border: none;
}
.product-custom-dropdown .dropdown-item {
  margin-bottom: 5px;
  padding: 2px 5px !important;
}
.product-custom-dropdown .dropdown-item:nth-last-child(1) {
  margin-bottom: 0;
}
.product-custom-dropdown .dropdown-item-custom:focus,
.dropdown-item-custom:hover:nth-child(1) {
  border-radius: 9px 9px 0px 0px !important;
  background: #80ba27;
  color: #fff !important;
}
.product-custom-dropdown .dropdown-item-custom:focus,
.dropdown-item-custom:hover {
  border-radius: 0 !important;
  background: #80ba27 !important;
  color: #fff !important;
}
.product-custom-dropdown .dropdown-item-custom:focus,
.dropdown-item-custom:hover:nth-child(4) {
  border-radius: 0px 0px 9px 9px !important;
  background: #80ba27 !important;
  color: #fff !important ;
}
.d-space-around {
  display: flex;
  justify-content: space-around;
}
.sortHeader h5 {
  color: #000;
  font-size: 18px;
  line-height: normal;
  text-align: start;
}
.sortHeader .sort-dropdown {
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 18px;
  line-height: normal;
  padding: 3px;
}
.sortHeader h6 {
  color: #bdbebb;
  font-family: var(--fc-font-light);
  font-size: 18px;
  line-height: normal;
}
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-main-banner {
  /* height: 141px; */
  border-radius: 14px;
  width: 100%;
  object-fit: fill;
}
.sort-icon {
  cursor: pointer;
}
.carousel-product-margin {
  margin: 10px;
}
.product-author {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 390;
  line-height: 185%; /* 29.6px */
  text-decoration-line: underline;
  text-transform: capitalize;
}
.product-single-price {
  color: #000;
  font-size: 23px;
  font-family: var(--fc-font-bold);
  line-height: 250%; /* 33.3px */
  text-transform: uppercase;
}
.product-desc-section .row.full-views {
  align-items: baseline;
}
.vat-included {
  color: #000;
  font-family: var(--fc-font-light);
  font-size: 10px;
  font-style: normal;
  line-height: 185%; /* 22.2px */
  text-transform: capitalize;
}
.product-single-discounted-price {
  color: #fa6338;
  font-family: var(--fc-font-regular);
  font-size: 23px;
  line-height: 250%; /* 33.3px */
  text-decoration: line-through;
  text-transform: uppercase;
}
.product-single-discounted {
  color: #80ba27;
  font-family: var(--fc-font-bold);
  font-size: 23px;
  line-height: 270%; /* 33.3px */
  text-transform: uppercase;
}
.btn-checkout {
  padding: 8px 20px;
  border-radius: 9px;
  background: #76bc21;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-width: 0;
  color: black;
}
.btn-checkout svg {
  margin-left: 20px;
}
.button-minus {
  display: flex;
  width: 29px !important;
  height: 29px !important;
  padding: 6px 12px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px !important;
  margin-right: 20px;
  border: 1px solid #000 !important;
}
.button-plus {
  display: flex;
  width: 29px !important;
  height: 29px !important;
  padding: 6px 12px !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px !important;
  margin-left: 20px;
  border: 1px solid #000 !important;
}
.quantity-field {
  border-radius: 52px !important;
  border: 1.6px solid #000 !important;
  padding: 6px 39px;
}
.btn-variant {
  border-radius: 9px;
  border: 1px solid #000;
  color: #000;
  margin: 0 4px;
}
.btn-variant:hover {
  background-color: #000;
  border: 1px solid #000;
}
/* .variant-box{
  width: 300px;
  overflow-x: scroll;
} */
.language-drop {
  border-radius: 9px;
  border: 1px solid #000;
  color: #000;
}
.select-size {
  font-family: var(--fc-font-light);
  color: #000;
  text-transform: capitalize;
}
.product-color-box {
  width: 70px;
  height: 70px;
  background-color: rgba(235, 236, 233, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.product-color-box img {
  object-fit: contain;
  width: 50px;
  height: 50px;
}
.custom-badge2 {
  background: #80ba27;
  padding: 3px 12px;
  left: 0;
  position: absolute;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 1.08px;
  text-transform: capitalize;
  border-radius: 0;
  z-index: 2;
}
.alert-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 5%;
}

.success-alert {
  color: #1a8754;
  text-align: center;
  font-size: 16px;
  font-family: var(--fc-font-regular);
  line-height: normal;
  text-transform: uppercase;
}
.error-alert {
  color: #e90f1f;
  text-align: center;
  font-size: 16px;
  font-family: var(--fc-font-regular);
  line-height: normal;
  text-transform: uppercase;
}
.alert-container h6 {
  color: #000;
  text-align: center;
  font-size: 14px;
  line-height: normal;
  font-family: var(--fc-font-thin);
  text-transform: uppercase;
}
/* .alert-block{
box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) !important;
padding: 24px 39px !important; 
border-radius: none !important;
} */
.Toastify__toast {
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) !important;
  padding: 24px 39px !important;
  border-radius: none !important;
}
.Size-card {
  border-radius: 9px;
  border: 1px solid #000;
  padding: 8px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
}
.Discount-Prize {
  color: #a7a9a2;
  font-family: var(--fc-font-light);
  text-decoration: line-through;
  text-transform: uppercase;
}
.Size-card:hover {
  border-radius: 9px;
  border: 1px solid #000;
  background: #000;
  cursor: pointer;
  color: #fff;
}
.Size-card:hover > .Discount-Prize {
  color: #fff;
}
.info-box {
  background: #ebece9;
  padding: 7px 15px;
}
.info-box span {
  padding-right: 17px;
}
.info-box h2 {
  color: #000;
  font-size: 16px;
  font-family: var(--fc-font-regular);
  line-height: normal;
  text-transform: capitalize;
}
.payment-tabby {
  color: #000;
  font-size: 14px;
  text-transform: capitalize;
  font-family: var(--fc-font-light);
}
.delivery-update {
  text-decoration: underline;
  cursor: pointer;
}
.flash-sale-box {
  border-radius: 9px;
  background: #000;
  padding: 9px 19px;
}
.flash-sale-box h2 {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
}
.flash-sale-box p {
  color: #fff;
  font-family: var(--fc-font-light);
  font-size: 9px;
  text-transform: capitalize;
}
.ends-in {
  color: #fff;
  font-family: var(--fc-font-bold) !important;
  font-size: 9px;
  text-transform: capitalize;
}
.timer-box {
  border-radius: 9px;
  background: #c8ddd9;
  padding: 12px;
  font-size: 16px;
  color: #000;
  font-family: var(--fc-font-bold);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
}
.timer-field span {
  color: #fff;
  font-family: var(--fc-font-light);
  font-size: 12px;
  text-transform: capitalize;
  text-align: center;
  /* padding-left: 10px; */
}
.category-top-div {
  top: -1650px;
  position: relative;
}
.category-top-div2 {
  top: -1600px;
  position: relative;
}
.category-top-div3 {
  top: -1550px;
  position: relative;
}
.category-top-div4 {
  top: -1600px;
  margin-bottom: -1600px;
  position: relative;
}
.main-carousel .react-multiple-carousel__arrow--left {
  top: 12.5%;
  background-color: #fff;
}
.main-carousel .react-multiple-carousel__arrow--right {
  top: 12.5%;
  background-color: #fff;
}
.main-carousel .react-multiple-carousel__arrow--left::before {
  color: #000;
}
.main-carousel .react-multiple-carousel__arrow--right::before {
  color: #000;
}
.pos-r {
  position: relative;
}
.p-d {
  color: #000;
  font-size: 16px;
  line-height: normal;
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
  padding-bottom: 20px;
  font-weight: 500;
}
.p-ul li {
  color: #000;
  font-family: var(--fc-font-light);
  margin: 0 12px;
  font-size: 16px;
}
.p-d-c {
  color: #000;
  text-align: justify;
  font-family: var(--fc-font-light);
  line-height: 2;
  font-size: 16px;
}
.variant-active {
  background-color: #000;
  border: 1px solid #000;
  color: var(--fc-btn-hover-color);
}
.my-profile-heading {
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  font-family: var(--fc-font-bold);
}
.change-btn {
  color: #000;
  cursor: pointer;
}
.change-btn:hover {
  color: #000;
}
.mb-30 {
  margin-bottom: 30px;
}
.change-modal {
  border-radius: 9px !important;
  background: #fff !important;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) !important;
}
.btn-custom-close {
  position: absolute;
  right: 3%;
  top: 3%;
  color: #000 !important;
  transform: scale(0.85);
}
.address-card {
  border-radius: 9px !important;
  background: #fff !important;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) !important;
  border: none;
  padding: 16px;
  color: #000;
  font-size: 14px;
  font-family: var(--fc-font-light);
  margin-bottom: 30px;
}
.btn-link {
  color: #000;
  font-size: 16px;
  font-family: var(--fc-font-light);
  text-decoration: none;
  cursor: pointer;
}
.btn-link:hover {
  font-family: var(--fc-font-regular);
  color: #000;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.dashboard-row h6 {
  text-transform: uppercase;
  padding: 20px 0 5px 0;
  font-family: var(--fc-font-bold);
  color: #000;
}
.dashboard-row p {
  text-transform: capitalize;
  color: #000;
  padding-top: 5px;
}
.dashboard-heading {
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
  text-align: center;
  padding-bottom: 2rem;
}
.input-group-custom {
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%;
  height: 28px;
}
.mini-cart-img {
  background-color: #ebece9;
  padding: 5px;
  justify-content: center;
  width: 120px;
  height: 120px;
  align-items: center;
  background: #fff;
  border: solid 1px #a2a2a2 !important;
}
.price-minicart {
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
  color: #000;
  font-size: 12px;
}
.price-minicart-discount {
  text-transform: uppercase;
  text-decoration: line-through;
  font-family: var(--fc-font-light);
  font-size: 12px;
}
.input-spinner .button-minus1,
.input-spinner .button-plus1 {
  background-color: transparent;
  border-color: #000 !important;
  height: 25px;
  width: 25px;
  border-radius: 200px;
  margin: 0 10px;
}
.input-spinner .button-minus1:hover,
.input-spinner .button-plus1:hover {
  background-color: var(--fc-gray-200);
  border-color: #000 !important;
}
.quantity-field1 {
  border-radius: 52px !important;
  border: 1.6px solid #000 !important;
  padding: 6px 39px;
  height: 25px;
}
.input-spinner .form-input1 {
  height: 15px !important;
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  font-weight: 700;
  color: #000 !important;
}
.cart-footer {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  background-color: #fff !important;
  text-align: center;
  margin: 10px;
}
.offcanvas-body {
  max-height: 90vh;
  padding-top: 0;
}
.mini-cart-final-price {
  font-size: 16px;
  color: #000;
  font-family: var(--fc-font-bold);
  text-transform: uppercase;
  text-align: end;
}
.footer-expand img {
  transform: scale(2);
  cursor: pointer;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .flex-column {
    flex-direction: row !important;
    justify-content: unset;
  }
  .incriment-button .d-end {
    display: flex !important;
    justify-content: flex-start !important;
  }
  .payment-tabby {
    color: #000;
    font-size: 12px;
    text-transform: capitalize;
    font-family: var(--fc-font-light);
  }
  .mob-sort-icon {
    padding-left: 5px;
    visibility: visible !important;
  }
  .regular-sort-icon {
    visibility: hidden !important;
    display: none;
  }

  .mob-payment-icons img {
    display: flex;
    justify-content: flex-start;
    text-align: start;
    width: 100%;
    height: auto;
  }
  .footer-expand-section {
    margin-bottom: 60px;
  }
  .scroll-to-top {
    background-color: white;
    right: 60px !important;
    bottom: 80px !important;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
  }
  .footer-link-seperator {
    visibility: visible !important;
    display: inline-block !important;
  }
  .my-bag .container-fluid {
    --fc-gutter-x: 2.5rem;
  }
  .my-bag-img img {
    width: 60px !important;
    height: 60px !important;
    object-fit: contain;
  }
  .my-bag-img {
    width: 80px !important;
  }
  .my-bag-card {
    border-radius: 9px !important;
    background: #fff;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
    /* margin: 25px 0 25px 0; */
    padding: 10px !important;
  }
  .my-bag-text h6 {
    color: #000;
    font-family: var(--fc-font-regular);
    font-size: 11px !important;
    text-transform: capitalize;
    padding-bottom: 4px !important;
    padding-left: 15px;
  }
  #price-my-bag .selling-price {
    color: #000;
    font-size: 12px;
    font-family: var(--fc-font-bold);
    text-transform: uppercase;
  }
  #price-my-bag .discount-percentage {
    display: flex;
    justify-content: space-between;
    margin-left: -4px;
  }
  #price-my-bag {
    margin-bottom: 5px !important;
  }
  .top-categories .row > * {
    flex-shrink: 0;
    margin-top: var(--fc-gutter-y);
    max-width: 100%;
    padding-left: calc(var(--fc-gutter-x) * 0.15);
    padding-right: calc(var(--fc-gutter-x) * 0.15);
  }
  .footer-credits a {
    color: var(--fc-white) !important;
    padding: 0 5px;
    font-size: 10px;
    text-align: start !important;
  }
  .company-name {
    color: var(--fc-white) !important;
    padding: 0 0px;
    font-size: 10px;
    text-align: start !important;
  }
  .flash-sale-container .react-multiple-carousel__arrow {
    display: none !important;
  }
  .rec-products-container .react-multiple-carousel__arrow {
    display: none !important;
  }
  .wallet-banner .react-multiple-carousel__arrow {
    display: none !important;
  }
  .wallet-banner {
    border-radius: unset;
    background: #a37073;
    box-shadow: none;
    padding: 15px 0px;
  }

  .wallet-banner h1 {
    color: #000;
    font-family: var(--fc-font-medium);
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.58px;
    text-transform: uppercase;
    text-align: center !important;
    margin-bottom: 18px;
  }
  .wallet-banner h5 {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 131%; /* 26.855px */
    letter-spacing: 1.025px;
    text-transform: uppercase;
  }
  .wallet-card {
    background: #fff;
    border-radius: 16px;
    border: 2.5px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* padding: 25px; */
    overflow: hidden;
    /* margin: 0 18px; */
    height: 100px;
    width: 100px;
  }
  .wallet-card > div {
    background: #fff;
    box-shadow: 7px 10px 18px 0px rgba(22, 21, 22, 0.56),
      -12px -9px 35px 0px rgba(255, 255, 255, 0.39),
      -5px 10px 16px 0px rgba(55, 10, 53, 0.27) inset;
    width: 100%;
  }
  .wallet-card h3 {
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
  .wallet-card h2 {
    color: #000;
    text-align: center;
    font-family: Arial Black;
    font-size: 23px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
  .category-top-div4 {
    top: unset;
    margin-bottom: unset;
  }
  .category-top-div3 {
    top: unset;
  }
  .category-top-div2 {
    top: unset;
  }
  .category-top-div {
    top: unset;
  }
  .main-carousel .react-multiple-carousel__arrow--left {
    top: unset;
    background-color: #fff;
  }
  .main-carousel .react-multiple-carousel__arrow--right {
    top: unset;
    background-color: #fff;
  }
  .ModalBtn {
    position: fixed;
    top: 7%;
    transform: scale(0.4);
    right: -18px;
    z-index: 99;
  }
  .banner-flashsale {
    background-repeat: none;
    /* background-size: cover; */
    padding: 22px 25px;
    min-height: 200px;
  }
  .timer-card {
    border-radius: 9px;
    background: #fefaec;
    box-shadow: -4px 4px 3px 0px #000;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px;
    justify-content: center;
    height: 65px;
    width: 65px;
  }
  .timer-card h1 {
    color: #000;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  .banner-flashsale h2 {
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 43px;
    font-style: normal;
    font-weight: 800;
    line-height: 103.5%; /* 44.505px */
    text-transform: uppercase;
  }
  .banner-flashsale p {
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 103.5%; /* 15.525px */
    letter-spacing: 1.05px;
    text-transform: uppercase;
  }
  .banner-flashsale h5 {
    color: #fff;
    font-family: var(--fc-font-regular);
    font-size: 12px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
  }
  .digital-text {
    color: #000;
    text-align: center;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.48);
    font-family: "DS-Digital Bold";
    font-size: 12px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 9px;
    border: 1px solid #000;
    background: rgba(255, 255, 255, 0.23);
    padding: 5px;
    transform: scale(0.85);
  }
  .timer-text {
    font-size: 9px;
  }
  .flash-sale {
    font-size: 14px;
  }
  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show {
    color: var(--fc-black);
  }
  .custom-badge2 {
    transform: scale(0.65);
    left: -12%;
    top: 5%;
  }
  .navbar .dropdown-menu {
    box-shadow: none;
  }
  .dropdown-item {
    border-bottom: 0.5px solid #bdbebb;
    border-radius: 0px !important;
    padding: 18px 20px;
    font-size: 14px !important;
    font-family: var(--fc-font-light) !important;
  }
  .inline-thumb {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #bdbebb;
    padding: 0 0 20px 0;
  }
  .custom-mega-menu .thumbnails {
    width: 80px;
    height: 80px;
  }
  .custom-mega-menu .dropdown-item a span {
    display: flex;
  }
  .card-product .card-body {
    padding: 10px 7.5px;
  }
  /* .nav-item {
    width: 100%;
  } */
  .product-custom-dropdown .dropdown-item {
    padding: none !important;
  }
  .card2 {
    border-radius: 0px !important;
    height: 180px !important;
    background-color: #000;
    color: #fff;
    margin-bottom: 30px !important;
  }
  .card2 img {
    border-radius: 0px !important;
    height: 180px !important;
    object-fit: cover;
  }
  .fixed-top-margin {
    margin-top: 150px;
  }
  .flex-custom-column {
    flex-direction: column !important;
  }
  .product-showcase {
    background: rgba(235, 236, 233, 1);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 30px 30px;
    min-height: 400px;
  }
  .product-showcase img {
    max-height: 340px;
    object-fit: contain;
  }
  .react-multi-carousel-dot-list {
    position: absolute;
    bottom: 4% !important;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .my-bag-remove-btn {
    top: -5% !important;
    cursor: pointer;
    position: absolute;
    right: 2%;
  }
  .delivery-info {
    font-size: 12px;
  }
  .alert-padding {
    padding: 3rem 0 !important;
  }
  .address-card {
    border-radius: 9px !important;
    background: #fff !important;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) !important;
    border: none;
    padding: 8px;
    color: #000;
    font-size: 14px;
    font-family: var(--fc-font-light);
    margin-bottom: 15px;
  }
  .container-md-fluid {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    width: 100%;
  }
}
.container-md-fluid {
  --fc-gutter-x: 4rem;
  --fc-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--fc-gutter-x) * 0.5);
  padding-right: calc(var(--fc-gutter-x) * 0.5);
  width: 100%;
}
.my-bag-card h2 {
  text-transform: uppercase;
  font-size: 16px;
  font-family: var(--fc-font-medium);
  padding-bottom: 0;
  line-height: 0.8;
  letter-spacing: 1.44px;
  margin-bottom: 8px;
  color: var(--fc-black);
}
.guest-modal .btn-dark {
  border-radius: 7px !important;
  padding: 16px 32px !important;
  font-size: 0.85rem !important;
}

.my-bag-card .btn-dark {
  border-radius: 7px !important;
  padding: 9px 20px !important;
  font-size: 0.85rem !important;
}
.my-bag-card .form-label {
  color: #000;
  font-size: 12px;
  font-family: var(--fc-font-bold);
  line-height: 185%; /* 22.2px */
  letter-spacing: 1.08px;
}
.my-bag-total {
  font-family: var(--fc-font-bold);
  font-size: 16px;
}
.my-bag-total span {
  font-family: var(--fc-font-light);
  font-size: 12px;
}
/* mobile view common styles ends here */
.footer-link-seperator {
  visibility: hidden;
}
.bottom-nav-item:active {
  background-color: #fff !important;
}
.bottom-nav {
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  border-radius: 9px 9px 0px 0px;
  background: #fff;
  box-shadow: 0px -11px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 1008 !important;
}
.social-mdedia-icons img {
  width: 25px;
  height: 25px;
  cursor: pointer;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1);
  transition: all 200ms ease-in;
  transform: scale(1);
}
.social-mdedia-icons img:hover {
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.1);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.1);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.1);
  transition: all 200ms ease-in;
  transform: scale(1.1);
}
.mob-view .form-control {
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 98px !important;
  border: 0.8px solid #000 !important;
  color: #000 !important;
  font-size: 12px !important;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 7px 20px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  /* z-index: 999 !important; */
}
.input-group .btn {
  position: relative;
  z-index: 2;
  padding: 18px;
  border: 1px solid #000 !important;
  border-left: none !important;
}
.offcanvas-header .btn-close {
  color: var(--fc-white) !important;
}
.flash-icon {
  display: inline-block;
  padding-right: 10px;
}
.store-image-frame img {
  height: 250px;
}
.card2 {
  border-radius: 0px !important;
  height: 320px;
  background-color: #000;
  color: #fff;
  border: none !important;
  margin-bottom: 30px !important;
}
.card2 img {
  border-radius: 0px !important;
  height: 320px;
  object-fit: cover;
}
.card2 .btn {
  font-family: var(--fc-font-light) !important;
  text-transform: uppercase;
  font-size: 12px;
}
.stores-heading {
  font-family: var(--fc-font-bold);
  text-transform: uppercase;
  font-size: 21px;
  margin-top: 2.5rem;
}
.fixed-top-margin {
  margin-top: 100px;
}
.my-bag-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 21px;
  font-family: var(--fc-font-bold);
}
.my-bag-card {
  border-radius: 9px !important;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  margin: 25px 0 25px 0;
  padding: 33px;
}
.my-bag-img {
  background: #fff;
  border: solid 1px #a2a2a2 !important;
  /* background-color: rgba(235, 236, 233, 1); */
  width: 124px;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #a2a2a2 !important;
}
.my-bag-img img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.my-bag-remove-btn {
  top: 0;
  right: 3%;
  cursor: pointer;
  position: absolute;
}
.my-bag-text h6 {
  color: #000;
  font-family: var(--fc-font-bold);
  font-size: 14px;
  text-transform: capitalize;
  padding-bottom: 10px;
  padding-left: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  font-weight: 600;
}
.my-bag-text p {
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 12px;
  text-transform: capitalize;
  padding-left: 15px;
  padding-bottom: 1.5%;
  color: #76bc21;
  cursor: pointer;
}
.price-area-bag {
  transform: scale(0.25);
}
#price-my-bag li {
  display: inline-block;
  padding: 0 10px;
}
#price-my-bag {
  padding-left: 0px !important;
  text-align: left;
  position: relative;
  left: 0;
  margin-bottom: 15px;
}

#price-my-bag h5 {
  font-size: 16px;
}
.my-bag-spinner {
  transform: scale(0.7);
  position: relative;
  left: -12%;
}
.my-bag-spinner .input-spinner.form-input1 {
  width: 0% !important;
}
.delivery-info {
  text-align: center;
  color: #000;
  padding-top: 20px;
}
.delivery-info span {
  padding-right: 8px;
}
.my-bag-card .form-control {
  padding: 9.5px !important;
}
.promo-code-label {
  color: #000;
  font-family: var(--fc-font-bold);
  cursor: pointer;
}
.amount-dispaly {
  color: var(--fc-black);
  font-family: var(--fc-font-bold);
  font-size: 16px;
}
.my-bag-card .list-group:last-child {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-width: 0;
}
.my-bag-summary-border {
  border-bottom: 1px solid #bdbebb !important;
  padding: 10px 0 !important;
}
/* @media screen and (min-width: 1400px) {
  .container-lg-fluid {
    width: 1370px;
  }
}
@media screen and (min-width: 1600px) {
  .container-lg-fluid {
    width: 1570px;
  }
} */
@media screen and (min-width: 1900px) {
  .container-lg-fluid {
    width: 95%;
  }
  body.home .container-lg-fluid {
    width: 100%;
  }
  .container-new {
    width: 100%;
    max-width: 95%;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1570px) {
  .product-img img {
    object-fit: contain;
    width: 100%;
    transform: scale(0.75);
  }
  .category-top-div {
    top: -2050px;
    position: relative;
  }
  .category-top-div2 {
    top: -2000px;
    position: relative;
  }
  .category-top-div3 {
    top: -1950px;
    position: relative;
  }
  .category-top-div4 {
    top: -2000px;
    position: relative;
    margin-bottom: -2050px;
  }
}
@media only screen and (max-width: 3200px) and (min-width: 1921px) {
  .product-img img {
    object-fit: contain;
    width: 100%;
    transform: scale(0.75);
  }
  .category-top-div {
    top: -2850px;
    position: relative;
  }
  .category-top-div2 {
    top: -2800px;
    position: relative;
  }
  .category-top-div3 {
    top: -2750px;
    position: relative;
  }
  .category-top-div4 {
    top: -2800px;
    position: relative;
    margin-bottom: -2850px;
  }
}
/* Macbook Pro */
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .category-top-div {
    top: -1400px;
    position: relative;
  }
  .category-top-div2 {
    top: -1380px;
    position: relative;
  }
  .category-top-div3 {
    top: -1350px;
    position: relative;
  }
  .category-top-div4 {
    top: -1400px;
    position: relative;
    margin-bottom: -1450px;
  }
  .me-5 {
    margin-right: 0.15rem !important;
  }
  .wallet-card h2 {
    color: #000;
    text-align: center;
    font-family: Arial Black;
    font-size: 35px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .wallet-card h3 {
    font-size: 14px;
  }
  .wallet-card > div {
    padding: 18px;
  }
  .my-bag-img {
    background-color: rgba(235, 236, 233, 1);
    width: auto !important;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fixed-top-margin {
    margin-top: 10%;
  }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .category-top-div {
    top: 0;
    position: relative;
  }
  .category-top-div2 {
    top: 0;
    position: relative;
  }
  .category-top-div3 {
    top: 0;
    position: relative;
  }
  .category-top-div4 {
    top: 0px;
    position: relative;
    margin-bottom: 0px;
  }
  .wallet-banner h1 {
    color: #000;
    font-family: Poppins;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.58px;
    text-transform: uppercase;
    text-align: start !important;
  }
  .category-top-div {
    top: 0;
    position: relative;
  }
  .category-top-div2 {
    top: 0;
    position: relative;
  }
  .category-top-div3 {
    top: 0;
    position: relative;
  }
  .category-top-div4 {
    top: 0px;
    position: relative;
    margin-bottom: 0px;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .category-top-div {
    top: 0;
    position: relative;
  }
  .category-top-div2 {
    top: 0;
    position: relative;
  }
  .category-top-div3 {
    top: 0;
    position: relative;
  }
  .category-top-div4 {
    top: 0px;
    position: relative;
    margin-bottom: 0px;
  }
  .wallet-banner h1 {
    color: #000;
    font-family: Poppins;
    font-size: 38px !important;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.58px;
    text-transform: uppercase;
    text-align: start !important;
  }
}
@media only screen and (min-device-width: 1366px) and (max-device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .category-top-div {
    top: 0;
    position: relative;
  }
  .category-top-div2 {
    top: 0;
    position: relative;
  }
  .category-top-div3 {
    top: 0;
    position: relative;
  }
  .category-top-div4 {
    top: 0px;
    position: relative;
    margin-bottom: 0px;
  }
  .wallet-banner h1 {
    color: #000;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.58px;
    text-transform: uppercase;
    text-align: start !important;
  }
}

/* Portrait*/
@media only screen and (min-device-width: 1024px) and (max-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .category-top-div {
    top: 0;
    position: relative;
  }
  .category-top-div2 {
    top: 0;
    position: relative;
  }
  .category-top-div3 {
    top: 0;
    position: relative;
  }
  .category-top-div4 {
    top: 0px;
    position: relative;
    margin-bottom: 0px;
  }
  .wallet-banner h1 {
    color: #000;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.58px;
    text-transform: uppercase;
    text-align: start !important;
  }
}
@media only screen and (min-width: 1440px) and (orientation: landscape) {
  .me-5 {
    margin-right: 0.2rem !important;
  }
}
.icon-input {
  position: relative;
  max-width: 100%;
}
.icon-input__text-field {
  width: 100%;
  padding: 10px 20px;
  border-radius: 76px;
  border: 1px solid #000;
}
.icon-input__icon {
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
.icon-input__text-field:focus + .icon-input__icon {
  color: #ff8400;
}
.flash-sale-container .react-multi-carousel-list {
  position: unset !important;
}
.flash-sale-container .slick-slider {
  position: unset !important;
}

.flash-sale-container .react-multiple-carousel__arrow {
  background-color: #ebece9 !important;
}
.flash-sale-container .react-multiple-carousel__arrow::before {
  color: #000 !important;
}
.flash-sale-container .react-multiple-carousel__arrow--left {
  left: calc(3% + -10px);
}
.flash-sale-container .react-multiple-carousel__arrow--right {
  right: calc(3% + -10px);
}
.rec-products-container .react-multi-carousel-list {
  position: unset !important;
}

.rec-products-container .slick-slider {
  position: unset !important;
}

.rec-products-container .react-multiple-carousel__arrow {
  background-color: #ebece9 !important;
}
.rec-products-container .react-multiple-carousel__arrow::before {
  color: #000 !important;
}
.rec-products-container .react-multiple-carousel__arrow--left {
  left: calc(3% + -10px);
}
.rec-products-container .react-multiple-carousel__arrow--right {
  right: calc(3% + -10px);
}
.wallet-banner .react-multi-carousel-list {
  position: unset !important;
}
.wallet-banner .slick-slider {
  position: unset !important;
}

.wallet-banner .react-multiple-carousel__arrow--left {
  left: calc(23% + 10px);
  display: none;
}
.wallet-banner .react-multiple-carousel__arrow--right {
  right: calc(2% + -10px);
}
.wallet-banner .react-multiple-carousel__arrow {
  background-color: #ebece9 !important;
}
.wallet-banner,
.react-multiple-carousel__arrow::before {
  color: #000 !important;
}

.dropdown-menu.hide {
  display: none !important;
}
.dropdown-menu.show {
  display: block !important;
}
.account_show {
  display: none !important;
}
.mob-specs .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.mob-specs .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  color: #000;
}
.mob-specs .accordion-button:not(.collapsed)::after {
  background-image: var(--fc-accordion-btn-icon);
  transform: var(--fc-accordion-btn-icon-transform);
}
.mob-spec .accordion {
  border-radius: 0px !important;
}

.accordion-item {
  border-left: 1px #fff !important;
  border-right: none !important;
}
.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: unset !important;
  outline: 0;
  z-index: 3;
}
.tamara-summary-widget__container {
  color: #000 !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  font-family: var(--fc-font-light) !important;
  line-height: normal !important;
}
.single-product-row {
  max-height: 850px;
}
.slick-track {
  height: auto !important;
}
.return-policy-div h1 {
  color: #000;
  font-size: 16px;
  line-height: normal;
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
  font-weight: 500;
}
.return-policy-div p {
  color: #000;
  font-size: 14px;
  line-height: normal;
  font-family: var(--fc-font-light);
  margin-bottom: 4px;
}
.return-policy-div h6 {
  color: #000;
  font-size: 12px;
  line-height: normal;
  text-transform: capitalize;
  font-family: var(--fc-font-regular);
}
.return-policy-div a {
  color: #000;
  font-size: 12px;
  line-height: normal;
  text-transform: capitalize;
  font-family: var(--fc-font-light);
  text-decoration: underline;
  padding-bottom: 8px;
}
.cursor {
  cursor: pointer;
}
.alert-padding {
  padding: 8rem 0;
}
.alert-box-success p {
  font-size: 12px;
  color: #000;
  font-family: var(--fc-font-light);
  padding: 8px 0;
}
.alert-box-success h5 {
  font-size: 14px;
  background: linear-gradient(174deg, #f00 0%, #e439bd 49.48%, #ffe08b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
  margin-left: 5px;
}
.alert-box-success h5 span {
  margin-right: 7px;
}
.alert-box-success h1 {
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
  font-family: var(--fc-font-regular);
  margin-left: 5px;
  padding: 8px 0;
}
.alert-box-success h1 span {
  margin-right: 7px;
}
.alert-box-failed h5 {
  font-size: 14px;
  background: linear-gradient(168deg, #72f7c8 0%, #0057ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-family: var(--fc-font-bold);
  margin-left: 5px;
}
.alert-box-failed h5 span {
  margin-right: 7px;
}
.alert-box-failed h1 {
  font-size: 16px;
  color: #000;
  text-transform: capitalize;
  font-family: var(--fc-font-regular);
  margin-left: 5px;
  padding: 8px 0;
}
.alert-box-failed h1 span {
  margin-right: 7px;
}

.alert-box-bag-empty h5 {
  font-size: 18px;
  background: linear-gradient(107deg, #e439bd 0%, #f00 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  font-family: var(--fc-font-bold);
  margin-left: 5px;
}
.alert-box-bag-empty h5 span {
  margin-right: 7px;
}
.alert-box-bag-empty h1 {
  font-size: 25px;
  color: #ce1124;
  text-transform: capitalize;
  font-family: var(--fc-font-bold);
  padding: 8px 0;
}
.alert-box-bag-empty p {
  color: #000;
  font-size: 12px;
  line-height: 23px;
}
.alert-box-wishlist-empty h5 {
  font-size: 18px;
  background: linear-gradient(107deg, #e439bd 0%, #f00 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  font-family: var(--fc-font-bold);
  margin-left: 5px;
}
.alert-box-wishlist-empty h5 span {
  margin-right: 7px;
}
.alert-box-wishlist-empty h1 {
  font-size: 25px;
  color: #01e986 !important;
  text-transform: capitalize;
  font-family: var(--fc-font-bold);
  padding: 8px 0;
}
.alert-box-wishlist-empty p {
  color: #000;
  font-size: 12px;
  line-height: 23px;
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .desktop-thumbs {
    display: block;
  }
}
@media only screen and (min-width: 669px) and (max-width: 1366px) {
  .mob-thumbs {
    display: block;
  }
  .thumbnail-slide {
    margin-bottom: 20px;
    width: 60% !important;
    height: 60%;
  }
}
/* ipad pro 9.7 inch */
@media only screen and (min-width: 567px) and (max-width: 1024px) {
  .desktop-thumbs {
    display: block !important;
  }
  .thumbnail-slide {
    transform: scale(0.65);
    margin-bottom: 2px;
  }
}
/*  */
@media (min-device-width: 769px) and (max-device-width: 1195px) {
  .category-top-div {
    top: -200px;
    position: relative;
  }
  .category-top-div2 {
    top: -380px;
    position: relative;
  }
  .category-top-div3 {
    top: -350px;
    position: relative;
  }
  .category-top-div4 {
    top: -400px;
    position: relative;
    margin-bottom: -1450px;
  }
  .me-5 {
    margin-right: 0.15rem !important;
  }
}
.checkout-accordion {
  border-radius: 9px !important;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.24);
  margin-bottom: 20px;
  padding: 18px !important;
}
.accordion-arrow svg {
  transform: rotate(180deg);
}
.checkout-accordion h4 {
  color: var(--fc-black);
  font-family: var(--fc-font-regular);
  font-size: 16px;
}

.sub-heading {
  font-size: 21px;
  color: #000;
  font-family: var(--fc-font-bold);
}
.checkout-left-card {
  border-radius: 9px !important;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 5%;
}
/* .transformable {
  transform: rotate(180deg);
  transition: transform 0.3s ease; 
}

.transformed {
  transform: rotate(0deg); 
} */
.orders-card {
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  padding: 33px;
  margin-bottom: 50px;
}
.order-top-row {
  color: #000;
  font-size: 12px;
  font-family: var(--fc-font-regular);
  text-transform: capitalize;
}
/* .order-top-row  .col-md-2 {
  flex: 0 0 auto;
  width: 14.66666667%;
} */
.float-end {
  float: right;
}
.order-top-row svg {
  margin-right: 8px !important;
  vertical-align: middle;
}
.order-middle-row {
  background: #b5f7de;
  padding: 10px;
  margin: 26px 0;
}
.order-middle-row h3 {
  font-size: 16px;
  color: #000;
  font-family: var(--fc-font-regular);
  padding-bottom: 10px;
}
.order-middle-row h4 {
  font-size: 12px;
  color: #000;
  font-family: var(--fc-font-regular);
  text-transform: capitalize;
  text-decoration: underline;
}
.order-middle-row p {
  font-size: 12px;
  color: #000;
  font-family: var(--fc-font-light);
  text-transform: capitalize;
  font-weight: 600;
}
.order-img {
  width: 159px;
  height: 143px;
  background: rgba(235, 236, 233, 1);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #a2a2a2 !important;
}
.order-img img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.order-bottom-row h4 {
  font-size: 14px;
  color: #000;
  font-family: var(--fc-font-regular);
}
.order-bottom-row h2 {
  font-size: 16px;
  color: #000;
  font-family: var(--fc-font-regular);
  margin-top: 24px;
}
.order-bottom-row h5 {
  font-size: 12px;
  color: #000;
  font-family: var(--fc-font-regular);
  margin-top: 24px;
  text-transform: capitalize;
}
.order-bottom-row h3 {
  font-size: 12px;
  color: #000;
  font-family: var(--fc-font-regular);
  margin-top: 24px;
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
}
.order-bottom-row .react-multi-carousel-list {
  position: unset !important;
}
.order-bottom-row .slick-slider {
  position: unset !important;
}
.order-bottom-row .react-multiple-carousel__arrow--left {
  left: calc(28% + 18px);
  transform: scale(0.55) !important;
}
.order-bottom-row .react-multiple-carousel__arrow--right {
  right: calc(28% + -10px);
  transform: scale(0.55) !important;
}
.order-bottom-row .react-multiple-carousel__arrow {
  background-color: #ebece9 !important;
}
.order-bottom-row,
.react-multiple-carousel__arrow::before {
  color: #000 !important;
}

.modal {
  background: rgba(0, 0, 0, 0.15) !important;
}
.order-details h3,
.returned-haed h3 {
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 16px !important;
}
.order-details p {
  color: #000;
  font-family: var(--fc-font-light);
  font-size: 12px;
}
.order-details .tax-invoice {
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.order-details hr {
  border-top: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.return-reason {
  color: #000;
  font-family: var(--fc-font-light);
  font-size: 12px;
}
.return-reason span {
  color: #000;
  font-family: var(--fc-font-bold);
  font-size: 12px;
}
.promo-modal .modal-content {
  padding: 20px 50px;
}
.promo-modal .modal-dialog {
  max-width: 650px !important;
}
.promo-modal h1 {
  text-align: center;
  text-transform: uppercase;
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 21px;
  margin-bottom: 26px;
}
.promo-modal .btn-close {
  position: absolute;
  right: 10%;
}
.promo-modal h2 {
  text-transform: uppercase;
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 16px;
}
.promo-modal .btn-dark {
  border-radius: 14px !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
}
.promo-modal p {
  color: #000;
  font-family: var(--fc-font-light);
  font-size: 12px;
}
.promo-modal a {
  color: #000;
  font-family: var(--fc-font-light);
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}
.promo-card {
  border-radius: 9px;
  background: linear-gradient(
    143deg,
    #ffe08b 0%,
    #ffa7a7 30.73%,
    #e43977 99.48%
  );
  background-position: center center;
  box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.25) inset;
  border: unset !important;
  margin-bottom: 26px;
  padding: 15px;
}
@media only screen and (min-width: 321px) and (max-width: 769px) {
  .promo-modal .modal-content {
    padding: 18px !important;
  }
  .promo-modal .modal-dialog {
    max-width: 650px !important;
  }
  .promo-modal h1 {
    text-align: center;
    text-transform: uppercase;
    color: #000;
    font-family: var(--fc-font-regular);
    font-size: 21px;
    margin-bottom: 26px;
  }
  .promo-modal .btn-close {
    position: absolute;
    right: 10%;
  }
  .promo-modal h2 {
    text-transform: uppercase;
    color: #000;
    font-family: var(--fc-font-regular);
    font-size: 16px;
  }
  .promo-modal .btn-dark {
    border-radius: 14px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
  }
  .promo-modal p {
    color: #000;
    font-family: var(--fc-font-light);
    font-size: 12px;
  }
  .promo-modal a {
    color: #000;
    font-family: var(--fc-font-light);
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
  }
  .promo-card {
    border-radius: 9px;
    background: linear-gradient(
      143deg,
      #ffe08b 0%,
      #ffa7a7 30.73%,
      #e43977 99.48%
    );
    background-position: center center;
    box-shadow: 0px 4px 27px 0px rgba(0, 0, 0, 0.25) inset;
    border: unset !important;
    margin-bottom: 26px;
    padding: 15px;
  }
}
.faq-accordion .accordion-button:not(.collapsed) {
  background-color: #fff !important;
  box-shadow: unset !important;
  color: #000 !important;
}
.faq-accordion .accordion-body {
  border-top: 1px solid var(--fc-accordion-border-color) !important;
}
.faq-accordion .accordion {
  border-color: rgba(189, 190, 187, 1) !important;
}

.faq-accordion .accordion {
  --fc-accordion-color: var(--fc-gray-600);
  --fc-accordion-bg: #fff;
  --fc-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --fc-accordion-border-color: var(--fc-border-color);
  --fc-accordion-border-width: 1px;
  --fc-accordion-border-radius: 0rem;
  --fc-accordion-inner-border-radius: calc(0.5rem - 1px);
  --fc-accordion-btn-padding-x: 1.25rem;
  --fc-accordion-btn-padding-y: 30px;
  --fc-accordion-btn-color: var(--fc-gray-600);
  --fc-accordion-btn-bg: var(--fc-accordion-bg);
  --fc-accordion-btn-icon: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18 13H13V18H11V13H6V11H11V6H13V11H18V13Z' fill='%23BDBEBB'/%3E%3C/svg%3E");
  --fc-accordion-btn-icon-width: 1.25rem;
  --fc-accordion-btn-icon-transform: rotate(-180deg);
  --fc-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --fc-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1_5)'%3E%3Cpath d='M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM18 13H6V11H18V13Z' fill='%23BDBEBB'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1_5'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  --fc-accordion-btn-focus-border-color: #85d685;
  --fc-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(10, 173, 10, 0.25);
  --fc-accordion-body-padding-x: 1.25rem;
  --fc-accordion-body-padding-y: 1rem;
  --fc-accordion-active-color: #099c09;
  --fc-accordion-active-bg: #e7f7e7;
}
.cart-summary-label {
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 12px;
  text-align: left;
}
.summary-sub-total {
  color: #000;
  font-family: var(--fc-font-bold);
  font-size: 16px;
}
.summary-shipping {
  color: #000;
  font-family: var(--fc-font-bold);
  font-size: 12px;
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
}
.otp-modal {
  padding: 33px 77px 56px 77px !important;
}
.otp-modal h5 {
  color: #000;
  font-family: var(--fc-font-bold);
  font-size: 16px;
  text-transform: uppercase;
}
.otp-modal .btn-close {
  position: absolute;
  right: 10%;
}
.otp-modal h6 {
  font-size: 12px;
  font-family: var(--fc-font-regular);
  line-height: 17.5px;
}
.otp-modal span {
  font-family: var(--fc-font-bold);
}
.text-underline {
  text-decoration: underline;
}
.otp-modal .modal-footer {
  font-size: 12px;
  font-weight: var(--fc-font-regular);
  color: #000;
}
.otp-modal .modal-footer span {
  font-weight: var(--fc-font-bold);
}
.otp-modal .modal-footer a {
  font-weight: var(--fc-font-regular);
  color: #f92f00;
  text-decoration: underline;
}
.delivery-type h5 {
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 16px;
  text-transform: capitalize;
}
.delivery-type h6 {
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 12px;
  text-transform: capitalize;
}
.payment-type h5 {
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 12px;
  text-transform: capitalize;
}
.payment-type h5 span {
  color: #000;
  font-family: var(--fc-font-thin);
  font-size: 12px;
  text-transform: capitalize;
}
.checkout-left-card h5 {
  color: #000;
  font-family: var(--fc-font-regular);
  font-size: 16px;
  text-transform: uppercase;
  padding-left: 5px;
}
.cart-details-img {
  width: 80px;
  max-width: 90px;
  height: 80px;
  background-color: rgba(235, 236, 233, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: solid 1px #a2a2a2 !important;
}
.cart-details-img img {
  width: 90px;
  height: 60px;
  object-fit: contain;
}
.checkout-left-card h4 {
  color: #000;
  font-family: var(--fc-font-medium);
  font-size: 12px;
  text-transform: capitalize;
  line-height: 185%;
}
.checkout-left-card h6 {
  color: #000;
  font-family: var(--fc-font-medium);
  font-size: 12px;
  text-transform: capitalize;
}
.checkout-left-card h6 span {
  color: #000;
  font-family: var(--fc-font-light);
  font-size: 12px;
  text-transform: capitalize;
  text-decoration: line-through;
  padding-left: 12px;
}
.left-sec-text {
  font-size: 12px;
  text-transform: capitalize;
  font-family: var(--fc-font-light);
}
.footer-link-row {
  --fc-gutter-x: 0 !important;
}
body {
  overflow-x: hidden; /* Hide horizontal scrollbar by default */

  /* Add other styles */
}

/* Media query for mobile view */
@media (max-width: 768px) {
  body {
    overflow-x: hidden; /* Hide horizontal scrollbar in mobile view */
  }
  .product-box .btn-dark {
    padding: 4px !important;
  }
  .add-to-bag-btn svg {
    margin-left: 10px;
    transform: scale(0.75);
  }
  .flash-sale-gif {
    transform: scale(0.75);
  }
  .mob-menu-container {
    margin-top: 55px;
  }
  .cart-details-img {
    width: 80px;
    max-width: 80px;
    height: 65px;
    background-color: rgba(235, 236, 233, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  .cart-details-img img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
}

.order_status_disable {
  color: #a7a9a2;
}
.shop-p-head {
  margin: 0 15px;
  text-align: center;
}
.flex-custom-column {
  flex-direction: column;
}
.form-group {
  position: relative;
}
.form-control-feedback {
  position: absolute;
  right: 5%;
  top: 20%;
  transform: scale(0.85);
}
.flash-gif-menu {
  width: 17px;
  height: 15px;
  object-fit: contain;
}
/* .navbar-nav li:first-child a {
  color: #80BA27 !important;
} */
.mob-count .digital-text {
  color: black !important;
  text-align: center;
  font-family: "DS-Digital Bold";
  font-style: normal;
  /* font-size: 35px !important; */
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 9px;
  border: unset;
  padding: 10px;
  width: max-content;
  background: transparent !important;
}
.mob-count .count {
  background: transparent !important;
  color: black!important;
  font-size: 35px !important;
}
.mob-count .mob-time-break {
  color: var(--fc-white) !important;
  font-size: 12px;
  text-transform: capitalize;
}
/* .mob-count .timer-row {
  position: absolute;
  top: 0;
  right: 15%;
} */
.mob-sort-icon {
  padding-left: 5px;
  visibility: hidden;
}
.regular-sort-icon {
  visibility: visible;
}

@media (max-width: 768px) {
  /* CSS rules for mobile devices */
  body {
    overflow-x: hidden;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .search-container {
    position: relative !important;
    width: 90vw !important;
    background-color: var(--fc-white);
    /* width: max-content; */
    border-radius: unset !important;
    /* box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25); */
    box-shadow: unset !important;
    /* padding: 10px; */
    left: 0;
    max-width: fit-content !important;
    margin-top: 2%;
    /* height: 72vh; */
    overflow-y: scroll;
  }
  .icon-input__icon {
    display: none;
  }
  .menu-thumbnails {
    box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.86);
    width: 65px !important;
    height: 65px !important;
    border-radius: 50%;
  }
  .img-nav-link {
    border-bottom: 0.5px solid #bdbebb;
    font-family: var(--fc-font-bold);
    color: #000 !important;
    padding: 18px 20px;
    text-align: left !important;
  }
  .menu-thumbnails-link {
    text-align: left !important;
  }
  .mob-img-arrow {
    float: right;
  }
  .filter-side .offcanvas-body {
    margin-top: 10%;
  }
  .fixed-top {
    left: unset;
    position: relative;
    right: unset;
    z-index: 999;
    /* z-index: 1030; */
  }
  .fixed-top-margin {
    margin-top: unset;
  }
}
.main-layout {
  overflow-x: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.list-inline-item {
  transform: scale(0.95);
}
.custom-dropdown {
  white-space: nowrap;
}
.navbar {
  z-index: -1 !important;
}
.product-rating .btn-outline-dark {
  border-radius: 9px !important;
  border: 1px solid #000 !important;
  font-size: 12px !important;
  font-size: var(--fc-font-light) !important;
  font-weight: 400 !important;
  padding: 8px 36px !important;
  letter-spacing: 1.08px;
}
.brand-rating .btn-outline-dark {
  border-radius: 9px !important;
  border: 1px solid #000 !important;
  font-size: 12px !important;
  font-size: var(--fc-font-light) !important;
  font-weight: 400 !important;
  padding: 8px 36px !important;
  letter-spacing: 1.08px;
}
.product-rating h1 {
  color: #000;
  font-family: var(--fc-font-bold);
  font-size: 40px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
}
.product-rating span {
  color: #000;
  font-family: var(--fc-font-bold);
  font-size: 12px;
  font-style: normal;
  display: inline-block;
  padding: 15px 0px;
}
.product-rating p {
  color: #000;
  font-family: var(--fc-font-bold);
  font-size: 12px;
  font-style: normal;
  line-height: 17px;
  margin-bottom: 0;
}
.star-rating {
  color: var(--fc-black);
  font-size: 14px;
}
.rating-card {
  border-radius: 9px;
  background: #fff;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
}
.btn-plus {
  margin-right: 10px;
  width: 35px;
}
.btn-minus {
  margin-left: 10px;
  width: 35px;
}
.cart-icon-plus {
  margin-left: 10px;
}
.cart-icon-minus {
  margin-right: 10px;
}
.cart-drawer .input-group-custom {
  transform: scale(0.85);
}
.remove-icon {
  transform: scale(1.3);
  cursor: pointer;
}
.mob-account-sidebar .nav-item {
  width: 100%;
}
.menu-thumbnails {
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.86);
  width: 128px;
  height: 128px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 12px;
}
.menu-thumbnails img {
  border-radius: 50%;
}
.menu-thumbnails-link {
  color: var(--fc-black) !important;
  font-family: var(--fc-font-regular) !important;
  text-align: center;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.inline-thumb {
  display: flex;
  justify-content: center;
}
.cart-header-title {
  font-size: 16px;
  text-transform: uppercase;
  font-family: var(--fc-font-regular);
}
#guestLoginModal .modal-body {
  padding: 0 10% 10% 10% !important;
}

#guestLoginModal .modal-header {
  justify-content: center !important;
}
#guestLoginModal .modal-header h5 {
  color: var(--fc-black);
  font-size: 16px;
  font-family: var(--fc-font-regular);
}
#guestLoginModal .btn-close {
  position: absolute;
  right: 5%;
  top: 5%;
  transform: scale(0.85);
}
#guestLoginModal .form-control {
  padding: 15px !important;
}
#guestLoginModal .btn-seperator {
  font-size: 12px !important;
  font-family: var(--fc-font-regular) !important;
  padding: 20px 0;
  margin-bottom: 0;
  color: var(--fc-black);
}
.payment-card-img {
  width: 80px;
  object-fit: contain;
  height: 40px;
  border-radius: 4px;
}
.search-container {
  position: fixed;
  background-color: var(--fc-white);
  max-width: 750px;
  border-radius: 9px;
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25);
}
.search-tag {
  border-radius: 36px;
  border: 1px solid #000;
  margin: 10px;
  font-size: 12px;
  color: var(--fc-black);
  font-family: var(--fc-font-regular);
  text-transform: capitalize;
  padding: 4.5px 13px;
}
.search-tag svg {
  transform: scale(1.35);
  padding-bottom: 2px;
}
.search-container h5 {
  font-family: var(--fc-font-regular);
  color: var(--fc-black);
  margin-bottom: 20px;
}
.search-container h6 {
  color: #a7a9a2;
  text-align: right;
  font-size: 12px !important;
  line-height: normal;
  text-transform: capitalize;
  font-family: var(--fc-font-regular);
  margin-bottom: 0px;
}
.search-container .row.py-2 {
  align-items: center;
}
.search-container h4 {
  color: #a7a9a2;
  text-align: left;
  font-size: 12px !important;
  line-height: normal;
  text-transform: capitalize;
  /*font-family: var(--fc-font-medium);*/
}
.search-container .mini-cart-img {
  background-color: #ebece9;
  padding: 5px;
  justify-content: center;
  width: 92px;
  height: 70px;
  align-items: center;
  cursor: pointer;
  background-color: #ffffff;
}
.green-text {
  color: #80ba27 !important;
}
@media only screen and (min-width: 768px) {
  .home-wrapper {
    position: relative;
    margin-top: -106.5%;
  }
}
.brand-author {
  color: var(--fc-black);
  font-size: 14px;
  font-family: var(--fc-font-medium);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.react-simple-star-rating svg {
  margin: 0 8px;
}
.brand-rating-date {
  color: var(--fc-black);
  font-size: 14px;
  font-family: var(--fc-font-regular);
  text-transform: capitalize;
}
.brand-rating-message {
  color: var(--fc-black);
  font-size: 12px;
  font-family: var(--fc-font-regular);
  text-transform: capitalize;
  text-align: justify;
}
@media only screen and (min-width: 768px) and (max-width: 1380px) {
  .wallet-banner h1 {
    font-size: 38px !important;
  }
  .dropdown-text {
    padding: 0.25rem 0.75rem;
  }
}
.price-footer h5 {
  font-size: 13px;
  color: var(--fc-black);
  font-family: var(--fc-font-regular);
  margin-bottom: 0;
}
.price-footer span {
  font-size: 11px;
  font-weight: var(--fc-font-light);
  color: var(--fc-black);
}
.scrollable-area {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
  margin-top: 10px;
}
.scrollable-area .btn-variant {
  margin-bottom: 10px;
}
.text-green {
  color: #80ba27 !important;
}
/* Tab view */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 10px !important;
    padding-right: 10px !important;
    color: #fff !important;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    border-bottom: none;
    font-family: var(--fc-font-regular);
    color: #000 !important;
    padding: 10px;
    text-transform: unset !important;
    font-size: 12px !important;
  }
  .navbar .dropdown-fullwidth .dropdown-menu {
    margin: 0 !important;
  }
}
.offer-button {
  display: inline-block;
  transform: rotate(-90deg);
  border-radius: 12px 0px 0px 12px;
  background: #000;
  color: #fff;
  font-family: Brandon Text;
  font-size: 16px;
  font-style: normal;
  margin-top: 100px;
  text-transform: uppercase;
}

.highlighted {
  color: #000;
  font-weight: bolder;
  text-transform: capitalize;
}

@media only screen and (max-width: 600px) {
  #hide-mob-brand-name {
    display: none;
  }
}
.mob-cart-count {
  border-style: none;
  border-radius: 50%;
  background: #76bc21;
  padding: 2px 7px;
  color: #fff;
  font-size: 9px;
  position: absolute;
  left: 5px;
  top: -6px;
  border-style: none;
}

.desktop-cart-count {
  border-style: none;
  border-radius: 50%;
  background: #76bc21;
  padding: 2px 7px;
  color: #fff;
  font-size: 9px;
  position: absolute;
  left: 52%;
  top: -4px;
  border-style: none;
}
.mob-footer-cart-count {
  border-style: none;
  border-radius: 50%;
  background: #76bc21;
  padding: 2px 7px;
  color: #fff;
  font-size: 9px;
  position: absolute;
  left: 50%;
  top: 3px;
  border-style: none;
}

.order-btn-active {
  background: black;
  color: white;
  font-weight: bold;
  border: none;
}
.order-btn-inactive {
  background: white;
  color: black;
  font-weight: bold;
  border: 1px solid black;
}
.carousel {
  transform: translate3d(0, 0, 0);
}
.react-datepicker-wrapper {
  width: 100%;
}
.col-md-4.text-end.col-4.company-name a {
  padding: 0;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  #desktop-menu {
    display: none;
  }
}

@media (max-width: 768px) {
  #mobile-menu {
    display: block;
  }
}

/* Hide the menu on screens with a width greater than 768px */
@media (min-width: 769px) {
  #mobile-menu {
    display: none;
  }
}

img {
  user-drag: none;
  -webkit-user-drag: none; /* For older versions of Webkit-based browsers (e.g., Safari) */
  -moz-user-drag: none; /* For older versions of Firefox */
  -ms-user-drag: none; /* For older versions of Microsoft Edge */
  -o-user-drag: none; /* For older versions of Opera */
}
.form-validation-message {
  color: red;
}
body.checkout .css-13cymwt-control {
  border-radius: 9px;
  border-color: rgb(0 0 0);
}
body.checkout .css-hlgwow {
  font-size: 12px !important;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.6;
}
.css-qbdosj-Input {
  margin: 4px;
}
.d-grid.mb-1.mt-4.btn-bg-fix button {
  background-color: #76bc21;
  border-color: #76bc21;
  color: #000;
  font-family: var(--fc-font-bold);
}
body.product-details button.btn.btn-dark.btn-checkout.w-100 {
  background-color: #76bc21;
  border-color: #76bc21;
  color: #000;
  font-family: var(--fc-font-bold);
}
body.product-details .slick-arrow {
  display: block !important;
}
.birthday {
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translateY(-50%);
  font-size: 12px;
  color: "#292828"
}
.birthday-div {
  position: relative;
}
.popupOverlay {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popupContent {
  position: relative;
  width: 80%;
  max-width: 380px;
  height: 55%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 150px;
}

.backgroundImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 103%;
  height: 96%;
  z-index: 3;
}

.yellowSection {
  background-color: #ffd700;
  height: 65%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteSection {
  background-color: #fff;
  height: 35%;
  padding: 20px;
  text-align: center;
  position: relative;
}

.whiteSection h4 {
  color: #ffd700;
  margin-bottom: 3px;
}

.whiteSection_message  {
  font-size: 10px;
  font-weight: bold;
}

.whiteSectionTC  {
  font-size: 7px;
  margin-top: 7px;
}

.centerImage {
  max-width: 80%;
  max-height: 80%;
  margin-top: 30px;
  margin-right: 25px;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  z-index: 5;
}
.product-img-giveaway {
  height: 180px;
}
.giveaway-close-btn {
  position: absolute;
  top: 30px;
  right: 25px;
  border: none;
  background: none;
  font-size: 32px;
}