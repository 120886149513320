.wallet-card {
  /* box-shadow: 1px 2px 2px 0px rgba(22, 21, 22, 0.56),
    -1px 4px 14px 2px rgba(255, 255, 255, 0.39),
    -5px 12px 6px 0px rgba(55, 10, 53, 0.27) inset; */
  max-width: 161px;
}

.wallet-banner .react-multi-carousel-list {
  padding: 25px 0px;
}
.wallet-banner .slick-slider {
  padding: 25px 0px;
}

.wallet-card h2 {
  line-height: 36px;
}

.custom-dropdown svg {
  max-height: 23px;
}

.marquee-text {
  font-size: 12px;
}

.search-container.active {
  padding: 20px;
  width: 100%;
}

.wallet-card h2 {
  margin-bottom: 5px;
}

.search-top {
  justify-content: space-between;
}

.flash-sale span img {
  width: 16px;
  height: auto;
  margin-top: -4px;
}

.search-container h4 {
  color: #000;
}

.navbar .dropdown-fullwidth .dropdown-menu {
  max-width: 100vw !important;
  overflow: hidden;
  overflow-y: auto;
  max-height: 69vh;
  margin-left: 50px;
  margin-right: 50px;
}

.ModalBtn.offer-button {
  right: -182px !important;
  top: 280px !important;
}
.custom-badge {
  display: flex;
  align-items: center;
  border-radius: 7px;
  background: #fdd801;
  padding: 7px 10px;
  z-index: 1;
}

.custom-badge svg {
  width: 23px;
  height: 20px;
  margin-right: 5px;
}

.root-react-component-countdown-timer .split {
  padding: 0px 10px;
}

.top-categories h3 {
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 16px;
}

.dashboard-icon {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-content-center.dashboard-row {
  align-items: baseline;
}

.dashboard-icon img {
  max-height: 90px;
  max-width: 70px;
}

.nav.nav-category {
  padding-bottom: 160px;
}

body.products .selling-price {
  font-size: 15px;
}

body.products .discounted-price {
  font-size: 15px;
}

body.products .discount-percentage {
  font-size: 15px;
}

.search-result-close {
  align-items: center;
  background: #ff3d3d;
  border-radius: 100%;
  color: #fff;
  display: flex;
  font-size: 15px;
  height: 31px;
  justify-content: center;
  line-height: 33px;
  margin-left: auto;
  margin-right: 12px;
  margin-top: 12px;
  text-align: center;
  width: 30px;
}

.search-result-close:hover {
  background: #000;
  text-align: center;
  color: #ffff;
}

@media only screen and (min-width: 1200px) and (max-width: 1920px) {
  .filter-side {
    width: 22%;
    position: fixed;
    margin-top: -5%;
  }

  .footer {
    position: relative;
  }
}

@media only screen and (min-width: 321px) and (max-width: 769px) {
  .wallet-card h2 {
    line-height: 22px;
  }

  .wallet-banner .react-multi-carousel-list {
    padding: 20px 0px;
  }

  .wallet-banner .slick-slider {
    padding: 0;
  }

  .wallet-banner .slick-slide div {
    padding: 25px 0;
  }

  .wallet-banner h1 {
    margin-bottom: 0px;
  }

  .button.ModalBtn.offer-button {
    font-style: normal;
    font-weight: 443;
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 1.6px;
    font-family: var(--fc-font-bold);
    border-radius: 10px 10px 0px 0px !important;
    padding: 4px 15px;
    right: -90px !important;
    top: 50% !important;
  }

  .search-top {
    justify-content: left;
  }

  .search-tag {
    margin: 6px;
  }

  .breadcrumb {
    display: none;
  }

  .offcanvas.show:not(.hiding),
  .offcanvas.showing {
    padding-top: 150px;
    padding-bottom: 90px;
    width: 100%;
  }

  .nav-open .fixed-top {
    position: fixed;
    top: 0;
  }

  .container-fluid.full-width {
    padding-left: 0;
    padding-right: 0;
  }

  .container-fluid.full-width .side-padding a {
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .container-fluid.full-width .ads-block img {
    border-radius: 0px;
  }

  .mobile-search {
    display: none;
  }

  .fixed-top {
    padding-bottom: 20px;
  }
}

/* CSS for desktop (default) view */
.mob_tab_slider {
  display: none;
}

/* CSS for mobile and tablet view (max-width: 768px) */
@media (max-width: 768px) {
  .mob_tab_slider {
    display: block;
  }

  .desktop_slider {
    display: none;
  }
}
.slick-arrow {
  display: none !important;
}

.slick-slide .slick-slider-alignment div {
  display: block !important;
}
.slider .variable-width {
  width: 128px;
}

.mob-slick-slider-padding {
  padding-left: 9px;
  padding-right: 9px;
}
.wallet-friendly-mob-slider-padding {
  padding-left: 20px !important;
}
.prod-carosal-mob-padding {
  padding-left: 7px !important;
}
/* By Akhil */
#offcanvasAccount {
  padding-top: 0px !important;
}
#mobile_category_list {
  padding-top: 0px !important;
}
#mobile-menu {
  z-index: unset !important;
}
#mobile-sub-menu {
  z-index: 999 !important;
}
#offcanvasSubMenus {
  padding-top: 0px !important;
}
.product-active-image-wrapper {
  position: relative;
}
.out-of-stock-badge {
  background: #f22f2f;
  padding: 3px 12px;
  left: 0;
  position: absolute;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 1.08px;
  text-transform: capitalize;
  border-radius: 0;
  z-index: 1;
}
.prod-notify-me {
  color: #f22f2f;
}

.notify-icon {
  margin-left: 11px;
}
.footer-logo-size {
  width: 40px;
}
.career-vac-text-color {
  color: #000;
}
@media screen and (min-width: 1190px) and (max-width: 1280px) {
  .filter-side {
    margin-top: -85px;
  }
}
/* 404 page styles */
.not-found-slide {
  margin-right: 15px;
}
.error-carousel .react-multiple-carousel__arrow {
  background-color: #ebece9 !important;
}
.error-carousel .react-multiple-carousel__arrow--left {
  left: calc(25% + 10px);
}
.error-carousel .react-multiple-carousel__arrow--right {
  right: calc(25% + 10px);
}
.error-carousel .react-multi-carousel-list {
  position: unset !important;
}

/*End 404 page styles */

.store-details-slider .react-multiple-carousel__arrow {
  background-color: #ebece9 !important;
}
.store-details-slider .react-multiple-carousel__arrow--left {
  left: calc(3% + 10px);
}
.store-details-slider .react-multiple-carousel__arrow--right {
  right: calc(3% + 10px);
}
.store-details-slider .react-multi-carousel-list {
  position: unset !important;
}
/* End */
.desktop_logo_section {
  display: none !important;
}
@media (min-width: 992px) {
  .desktop_logo_section {
    display: block !important;
  }
}
.store-single-img-width {
  width: 100% !important;
  /*height: 488px;*/
}
.products-filter-active {
  border-radius: 0 !important;
  background: #80ba27 !important;
  color: #fff !important;
}

.fbt-slide-wrapper {
  position: relative;
}

.plus-button {
  position: absolute;
  top: 36%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: #000;
  font-family: Brandon Text;
  font-size: 40px;
  font-style: normal;
  font-weight: 390;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
}

.timer-text-color {
  color: #000 !important;
}
body.personal-info div#changePhone button.btn.btn-dark.w-100.my-5{
  margin-left: 0px;
  }