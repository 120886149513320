.row-40 {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    align-items: center;
}
.strt-btns a {
border-radius: 9px;
background: #0C0C0C;
color: #fff;
display: inline-flex;
text-align: center;
padding: 10px 80px;
}
.strt-btns {
margin: 40px 0px;
}
.error-carousel {
width: 60%;
margin: 0 auto;
}
.error-carousel img {
width: 100%;
height:auto !important;
cursor: pointer;
}
.row-40 .error-carousel .react-multiple-carousel__arrow--right {
    right: calc(17% + 10px);
}
.row-40 .error-carousel .react-multiple-carousel__arrow--left {
    left: calc(17% + 10px);
}
.error-text h2 {
color: #000;
text-align: center;
font-family: Brandon Text;
font-size: 25px;
font-style: normal;
font-weight: 450;
line-height: normal;
text-transform: uppercase;
font-family: brandonTextBold,sans-serif;
}
.error-text {
padding: 40px 0px;
}
.error-carousel.owl-carousel.owl-theme.owl-loaded.owl-drag p {
text-align: center;
color: #000;
text-align: center;
font-family: Brandon Text;
font-size: 16px;
font-style: normal;
font-weight: 450;
line-height: normal;
text-transform: uppercase;
padding-top: 30px;
}
.row-40 h1 {
font-family: brandonTextBold,sans-serif;
font-family: var(--fc-font-bold);
font-size: 25px;
padding: 20px 0;
text-transform: capitalize;
}
.row-40 p {
font-size: 18px;
font-weight: 600;
line-height: 27px;
margin: 0 auto;
padding-top: 10px;
width: 20%;
margin: 0 auto;
text-align: center;
}
.error-carousel.owl-carousel.owl-theme.owl-loaded.owl-drag button.owl-prev {
position: absolute;
left: -90px;
top: 95px;
}
.error-carousel.owl-carousel.owl-theme.owl-loaded.owl-drag button.owl-next {
position: absolute;
right: -80px;
top: 95px;
}
.error-carousel.owl-carousel.owl-theme p {
    color: #000;
    text-align: center;
    font-family: brandonTextBold,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    text-transform: uppercase;
    padding-top: 20px;
    width: 100%;
}
@media screen and (max-width: 767px)
{
.row-40 p {
font-size: 18px;
width: 50%;
margin: 0 auto;
text-align: center;
}
.row-40 .error-carousel .react-multiple-carousel__arrow--right {
    right: calc(9% + 10px);
}
.row-40 .error-carousel .react-multiple-carousel__arrow--left {
    left: calc(6% + 10px);
}
.row-40 img {
    width: 60%;
}
.row-40 .error-carousel img {
    width: 100% !important;}
}