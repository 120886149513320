@media only screen and (min-width: 2600px) and (min-height: 1650px) {
  .product-single-price {
    font-size: 24px;
  }
}
@media (min-width: 1600px) {
  footer.footer .container {
    max-width: 90% !important;
  }
  .footer .nav .nav-item .nav-link {
    font-size: 16px;
  }
  .product-desc-section h1 {
    font-size: 27px;
  }
  .footer h6 {
    font-size: 14px;
  }
  .vat-included {
    font-size: 12px;
  }
  .return-policy-div a {
    font-size: 14px;
  }
  .ads-banner {
    background-repeat: no-repeat;
    background-size: 100% 100%, cover;
    background-position: center center;
    padding: 10% 0;
    min-height: 519px;
    height: 100%;
    margin: 0 30px;
    border-radius: 9px !important;
}
  .dashboard-row h6 {
    font-size: 21px;
}
.dashboard-row p {
  font-size: 16px;
}
.my-bag-text h6 {
  font-size: 18px;
}
.p-d-c.footer-text p, a {
  font-size: 15px;
}
  .navbar .navbar-nav .nav-item .nav-link {
    font-size: 17px;
  }
  .desktop_logo_section img {
    width: 130px;
  }
  .imgs-header {
    width: 26px;
  }
  .dropdown-text {
    font-size: 0.88rem;
  }
  .custom-mega-menu .dropdown-item {
    font-size: 13px;
  }
  .category-top-img h3 {
    font-size: 19px;
  }
  .order-top-row {
    font-size: 16px;
  }
  .order-bottom-row h4 {
    font-size: 18px;
  }
  .order-bottom-row h5 {
    font-size: 15px;
  }
  .product-img img {
    max-height: 300px;
  }
  .product-grid h4 {
    font-size: 19px;
  }
  .checkout-left-card h4 {
    font-size: 21px;
  }
  .left-sec-text {
    font-size: 15px;
  }
  .return-reason span {
    font-size: 15px;
  }
  .cart-summary-label {
    font-size: 15px;
  }
  .alert-box-success h1 {
    font-size: 20px;
  }
  .order-details h3, .returned-haed h3 {
    font-size: 17px !important;
}
body.home .wallet-card {
 max-width: 185px;
}
body.home .wallet-card h3 {
  line-height: 40px;
}
  .alert-box-success p {
    font-size: 14px;}
  body.blog .container-new {
    width: 100%;
    max-width: 90%;
  }
  .social-mdedia-icons img {
    width: 35px;
    height: 35px;
  }
  .menu-drop-link {
    font-size: 14px;
  }
  .p-ul li {
    font-size: 17px;
  }
  .p-d-c {
    font-size: 17px;
  }
  .return-policy-div p {
    font-size: 16px;
  }
  .return-policy-div h6 {
    font-size: 14px;
  }
  .product-img {
    height: 100%;
    height: 320px;
  }
  .nav-category .nav-item .nav-link {
    font-size: 21px;
  }
  .category-top h1 {
    font-size: 28px;
}
}
@media (max-width: 1440px) {
  .container-new {
    width: 100%;
    max-width: 1150px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 17px;
    padding-right: 17px;
}
  .ModalBtn.offer-button {
    right: -150px !important;
    top: 200px !important;
}
.dropdown-menu.show.pb-0 .row.g-5.ads-block .menu-thumbnails {
  width: 100px;
   height: 100px;
 }
button.ModalBtn.offer-button {
  padding: 2px 17px;
  border: none;
  font-size: 12px;
}
.ft-icon-fixed a {
  right: 2px;
  bottom: 25px;
  width: 45px;
  height: 45px;
}
  .store-single-img-width {
    height: 470px;
  }
  .scroll-container2 {
    max-height: 170px;
  }
  body.product-details .card-body.fbt-product-box .product-img {
    height: 120px;
  }
  .navbar .navbar-nav .nav-item .nav-link {
    font-size: 12px;}
  .order-bottom-row .react-multiple-carousel__arrow--left {
    left: calc(29% + 18px);
  }
  .navbar .dropdown-fullwidth .dropdown-menu {
    max-height: 75vh;
  }
  .image-list-one img {
    height: 500px;
  }
  .deatil-blog-content h1 {
    font-size: 29px;
  }
  .owl-carousel.owl-theme.owl-slider button.owl-prev {
    left: -50px;
  }
  .owl-carousel.owl-theme.owl-slider button.owl-next {
    right: -50px;
  }
  .react-multi-carousel-list.container-with-dots.fbt-product-plus .react-multi-carousel-item::after {
    content: '+';
    position: absolute;
    top: 75px;
    right: -3px;
    font-size: 26px;
    color: black;
    font-weight: 800;
}
  .blog-sub-text h2 {
    font-size: 29px;
  }
  .blog-content h2 {
    font-size: 34px;
  }
  .blog-content-sub h2 {
    color: #0000;
    font-family: "BrandonText-Bold", sans-serif;
    font-size: 19px;
    padding-bottom: 15px;
  }
  .blog-content-sub p {
    font-size: 13px;
  }
  .blog-content-sub a {
    margin-top: 40px;
    font-size: 13px;
  }
  span.blog-date {
    font-size: 13px;
    display: block;
    padding-top: 35px;
  }
  .storage-care-img img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
  }
  .storage-rows p {
    color: #000;
    text-align: justify;
    font-family: "BrandonText-Regular";
    font-size: 13px;
    font-style: normal;
    font-weight: 390;
    line-height: 187.99%;
    letter-spacing: 1.1px;
    text-transform: capitalize;
  }
  .blog-text-personality p {
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 30px;
    letter-spacing: 1.08px;
  }
  .blog-text-personality h3 {
    font-size: 19px;
  }
  .blog-text-personality a {
    line-height: 143%;
    margin-top: 60px;
    display: inline-block;
    font-size: 13px;
  }
  .left-gallery-one img {
    height: 525px;
  }
  .right-gallery-one img {
    height: 257px;
  }
  .gallery-flex ul li img {
    width: 100%;
    height: 459px;
  }
  .storage-rows a {
    font-size: 13px;
  }
  .blog-content-sub p {
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 30px;
    letter-spacing: 1.08px;
}
  .blog-col-1 img {
    height: 380px;
  }
  ol.breadcrumb {
    margin-bottom: 2rem;
    margin-top: 50px;
  }

  .brand-rating-date {
    font-size: 11px;
    line-height: 24px;
  }
  .col-md-5.col-4.icon-end {
    text-align: center;
  }

  .input-group-custom.input-spinner {
    justify-content: center;
    margin-left: 29px;
  }
  .imgs-header {
    width: 17px;
  }
  .ftr-tamara img {
    width: 95px;
  }
  .logo-m img.footer-logo-size {
    width: 60px;
    padding-top: 0px;
  }
  .footer-logo-size {
    width: 47px;
    padding-top: 5px;
  }
  h5.fbt-selling-price {
    color: #000;
    font-size: 14px;
  }

  .react-multi-carousel-list.container-with-dots.fbt-product-plus
    .react-multiple-carousel__arrow--left {
    left: calc(-3% + 1px);
    top: 103px;
  }
  .react-multi-carousel-list.container-with-dots.fbt-product-plus
    .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
    top: 103px;
  }
  h4.fbt-ellipsis-text {
    max-height: 45px;
    line-height: 23px;
}

  body.checkout .remove-cd button {
    font-size: 12px;
    padding: 10px 8px;
}
}
@media (max-width: 1366px) {
  .container-new {
    width: 90%;
    margin: 0 auto;
  }
  .product-single-price{
    font-size: 20px;
  }
  .product-single-discounted-price{
    font-size: 20px;
  }
  .product-single-discounted{
    font-size: 18px;
  }
  .choose-countries {
    margin-left: 172px;
  }
}
@media only screen and (max-width: 1200px) {
  .container-new {
    width: 90%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1200px) {

  .discounted-price {
    font-size: 12px !important;
  }
  .selling-price {
    font-size: 12px !important;
  }
  .discount-percentage {
    font-size: 12px !important;
  }
  button.btn.btn-dark.w-100.mt-2.add-to-bag-btn {
    font-size: 12px !important;
  }
  .product-single-price{
    font-size: 15px;
    line-height: 280%;
  }
  .product-single-discounted-price {
    font-size: 15px;
    line-height: 280%;
  }
  .product-single-discounted {
    font-size: 15px;
    line-height: 300%;
  }
  .choose-countries {
    margin-left: 119px;
    margin-right: 18px;
  }
  .row.full-views {
    align-items: baseline;
}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-new {
    width: 90%;
    margin: 0 auto;
  }
  body.product .flash-sale-box h2 {
    font-size: 12px;
  }
  .product-grid h4 {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 35px;
    text-align: left !important;
  }
  .product-single-discounted-price {
    font-size: 10px !important;
    line-height: 290%;
  }
  .product-single-discounted {
    font-size: 10px !important;
    line-height: 300%;
  }
  .product-single-price {
    color: #000;
    font-size: 10px !important;
    line-height: 290%;
  }
  .right-gallery-one img:nth-child(1) {
    padding-bottom: 1px;
  }
  .right-gallery-one img:nth-child(2) {
    padding-top: 1px;
  }
  .visit-text a {
    margin-top: 25px;
    display: flex;
    width: 130px;
    height: 40px;
    padding: 16px 20px;
  }
  .owl-carousel.owl-theme.owl-slider button.owl-next {
    right: -39px;
  }
  .owl-carousel.owl-theme.owl-slider button.owl-prev {
    left: -40px;
  }
  .wi-50 {
    width: 100%;
    text-align: center;
    padding: 30px;
  }
  .career-review-text h3 {
    font-size: 17px;
  }
  .career-review-text p {
    font-size: 13px;
  }
  .apply-form form {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
  }
  button.btn.btn-dark.w-100.mt-2.add-to-bag-btn {
    font-size: 11px;
}
  .selling-price {
    font-size: 13px;
  }
  .discounted-price {
    font-size: 13px;
  }
  .discount-percentage {
    font-size: 13px;
  }
  .storage-care-img img {
    width: 120px;
    height: 120px;
  }
  .blog-content-sub p {
    font-size: 11px;
    line-height: 18px;
  }
  .blog-text-personality p {
    font-size: 11px;
    line-height: 18px;
  }
  .storage-rows p {
    font-size: 12px;
    font-weight: 390;
    line-height: 170.99%;
    letter-spacing: normal;
  }
  .storage-rows a {
    font-size: 13px;
  }
  .blog-sub-text h2 {
    font-size: 22px;
    font-weight: 450;
    line-height: 144.49%;
  }
  .ftr-tamara img {
    width: 42px;
  }
  .ftr-visa img {
    width: 37px;
  }
  .ftr-mcard img {
    width: 40px;
  }
  .desktop_logo_section {
    display: block !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 5px !important;}
 .custom-mega-menu h6 {
      font-size: 11px;
    }

  nav.fixed-top.bg-white {
    padding-bottom: 0px;
  }
  .add-to-bag-btn svg {
    margin-left: 5px;
}
.discounted-price {
  font-size: 12px !important;
}
.selling-price {
  font-size: 12px !important;
}
.discount-percentage {
  font-size: 12px !important;
}
button.btn.btn-dark.w-100.mt-2.add-to-bag-btn {
  font-size: 12px !important;
}
  .wallet-banner h1 {
    font-size: 30px !important;
  }
  .wallet-card h2 {
    font-size: 17px;
    line-height: 25px;
  }
  .nav-pills-dark .nav-item .nav-link {
    font-size: 11px;
  }
  ul.nav.flex-column.nav-pills.nav-pills-dark a.nav-link img {
    width: 35px;
  }
  .footer .nav .nav-item .nav-link {
    font-size: 10px;
  }
  .visit-text h2 {
    color: #fff;
    font-family: "BrandonText-Bold", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 19px;
    text-transform: capitalize;
}
.visit-pg {
  min-height: 70px;
  max-height: 70px;
  overflow: auto;
}
.choose-countries {
  margin-left: 72px;
  margin-right: 26px;
}
a.dropdown-toggle.text-dark.btn-filter {
  font-size: 13px;
}
.popupOverlay {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popupContent {
  position: relative;
  width: 80%;
  max-width: 380px;
  height: 55%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 150px;
}

.backgroundImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 103%;
  height: 96%;
  z-index: 3;
}

.yellowSection {
  background-color: #ffd700;
  height: 65%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.whiteSection {
  background-color: #fff;
  height: 35%;
  padding: 20px;
  text-align: center;
  position: relative;
}

.whiteSection h4 {
  color: #ffd700;
  margin-bottom: 3px;
}

.whiteSection_message  {
  font-size: 10px;
  font-weight: bold;
}

.whiteSectionTC  {
  font-size: 7px;
  margin-top: 7px;
}

.centerImage {
  max-width: 80%;
  max-height: 80%;
  margin-top: 30px;
  margin-right: 25px;
}
}
@media screen and (max-width: 767px) {
  .container-new {
    width: 90%;
  }
  .image-list-one img {
    height: 246px;
}
.ft-icon-fixed a {
  right: 2px;
  bottom: 113px;
  width: 45px;
  height: 45px;
}

  body.order-details .order-img img {
    height: 85px;
  }
  .side-fixed {
    margin-top: 0px;
    position: static;
    width: auto;
  }
  .request-ret h3 {
    padding-right: 10px;
  }
  body.checkout .row.g-4.m-2.mts {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .row.ads-block .menu-thumbnails img {
    border-radius: 50%;
    padding-right: 0px;
    transform: none;
  }
  .nav-pills-dark img.megamenu-ads {
    padding-right: 0px;
    margin-bottom: 0px;
}
  body.checkout div#flush-collapseOne input.form-control {
    padding: 7px 10px !important;
  }
  .right-gallery-one img:nth-child(2) {
    padding-top: 0px;
  }
  .right-gallery-one img:nth-child(1) {
    padding-bottom: 0px;
  }
  .btn-fix-btm {
    position: fixed;
    bottom: 64px;
    padding-top: 15px;
    padding-bottom: 18px;
    z-index: 888;
    background-color: #fff;
  }
  .sub-abt h2 {
    font-size: 16px;
    line-height: 40%;
  }
  section.abut-section {
    padding-bottom: 30px;
  }
  label.option-lb.ps-5 {
    padding-right: 20px;
}
.react-multi-carousel-list.container-with-dots.fbt-product-plus .react-multi-carousel-item::after {
  content: '+';
  position: absolute;
  top: 92px;
  right: -6px;
  font-size: 19px;
  color: black;
  font-weight: 800;
}
  .start-explor {
    padding: 30px 0px;
  }
  .viisit-img {
    width: 50%;
  }
  .visit-text {
    width:50%;
    background-color: #000;
    min-height: 235px;
    max-height: 235px;
    display:flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 7px;
    box-sizing: border-box;
  }
  .visit-pg {
    min-height: 80px;
    max-height: 120px;
    overflow: auto;
}
  .viisit-img img {
    width: 100%;
    height: 235px;
    object-fit: cover;
  }
  .visit-text a {
    margin-top: 15px;
    width: 110px;
    height: 24px;
    padding: 16px 16px;
    gap: 38px;
    flex-shrink: 0;
    font-size: 11px;
  }
  .visit-row {
    margin-top: 30px;
    flex-wrap: wrap;
  }
  .t-gap {
    margin-top: 30px;
  }
  .visit-tittle h1 {
    text-align: center;
  }
  .visit-tittle {
    width: 100%;
    padding-bottom: 10px;
  }
  .choose-emirates {
    width: 50%;
    text-align: right;
  }
  body.coral-perfumes-showrooms .choose-row {
    justify-content: end;
}
body.coral-perfumes-showrooms .choose-row div button {
    margin-top: 15px;
}
  .choose-countries {
    width: 50%;
  }
  .visit-text h2 {
    font-size: 16px;
    line-height: 23px;
  }
  .store-address {
    width: 100%;
  }
  .store-hours {
    width: 100%;
  }
  .store-contact {
    width: 100%;
  }
  .row-details {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px;
    gap: 0px 0px;
  }
  .store-details-slider {
    padding-bottom: 20px;
}

  .row-details h2 {
    font-size: 17px;
    line-height: 42px;
  }
  .row-details h5 {
    font-size: 17px;
  }
  .row-details p {
    font-size: 14px;
    line-height: 30px;
  }
  .direction-btn a {
    display: flex;
    width: 300px;
  }
  .lg-none {
    display: block;
  }
  .sm-none {
    display: none;
  }
  .slid-mobview img {
    width: 100%;
  }
  .slid-mobview {
    padding-bottom: 25px;
  }
  body.checkout .dropdown-item,
  .inline-thumb {
    border-bottom: 0.5px solid #bdbebb;
    padding: 7px 20px;
  }
  .carrer-listing {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .sales-list {
    width: 85%;
    margin: 0 auto;
  }
  .marketing-list {
    width: 85%;
    margin: 0 auto;
    padding-top: 30px;
  }
  .finance-list {
    width: 85%;
    margin: 0 auto;
    padding-top: 30px;
  }
  .corporate-list {
    padding-top: 30px;
    width: 85%;
    margin: 0 auto;
  }
  .career-review-img {
    width: 50%;
    display: none;
  }
  .career-review-text {
    width: 100%;
  }
  .wi-50 {
    width: 100%;
    text-align: center;
    padding: 30px;
  }
  .career-review-text h3 {
    font-size: 15px;
    line-height: 30px;
  }
  .career-review-text p {
    font-size: 13px;
  }
  .carrer-listing ul li {
    font-size: 13px;
    line-height: 27px;
  }
  .carrer-listing ul {
    padding-top: 10px;
  }
  .carrer-listing h3 {
    font-size: 17px;
  }
  .carrer-listing {
    width: 100%;
  }
  .career-review-text span {
    display: inline;
  }
  .apply-form form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .apply-form .form-group {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .apply-form input[type="text"],
  input[type="file"] {
    width: 100%;
  }
  .faq-w {
    width: 100%;
  }
  .faq-accordion li a {
    color: #666;
    padding: 0;
  }
  .blog-sub-text h2 {
    font-size: 17px;
  }
  .blog-rows {
    display: flex;
    flex-direction: column;
  }
  .blog-col-1 {
    width: 100%;
    order: 0;
  }
  .blog-col-2 {
    width: 100%;
    order: 1;
  }
  span.blog-date {
    padding-top: 15px;
  }
  .blog-content-sub a {
    margin-top: 20px;
  }
  .blog-text-personality .faq p a {
    margin-top: 0 !important;
  }
  .blog-text-personality a {
    margin-top: 10px;
  }
  span.blog-date {
    padding-bottom: 0px;
  }
  .blog-content-sub {
    padding: 20px 0px;
  }
  .storage-rows {
    display: flex;
    flex-direction: column;
  }
  .storage-care {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 0px;
  }
  .shelf-life {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 0px;
    padding-top: 35px;
  }
  .navbar-dark {
    border-bottom: none;
  }
  .storage-care {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 0px;
  }
  .storage-care-img img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .storage-rows p {
    font-size: 11px;
    font-weight: 390;
    line-height: 163.99%;
    letter-spacing: 1.1px;
    max-height: max-content;
    overflow: visible;
  }
  .storage-rows a {
    font-size: 11px;
    font-weight: 390;
    line-height: 35px;
  }
  .blog-text-personality h3 {
    font-size: 17px;
  }
  .blog-img-personality {
    padding-left: 0px;
  }
  section.perfume-row-3 {
    padding: 0px;
  }
  .blog-content-sub h2 {
    padding: 15px 0px;
  }
  .order-1 {
    order: 0;
  }
  .order-2 {
    order: 1;
  }
  .blog-text-personality {
    padding: 20px 0px;
  }

  .bg-blog-banner {
    background-image: url(../images/blog-banner.webp);
    background-position: center;
    width: 100%;
    min-height: 340px;
  }
  .blog-content {
    padding: 25px;
  }
  .blog-content h2 {
    font-size: 24px;
  }
  .blog-content a {
    display: flex;
    width: 144px;
    height: 35px;
    padding: 16px 36px;
    justify-content: center;
    align-items: center;
    gap: 38px;
    font-size: 10px;
    margin-top: 19px;
  }
  .title-page {
    padding: 20px 0px;
  }

  .visit-tittle {
    width: 100%;
  }
  .blog-col-1 img {
    height: 350px;
    width: 100%;
  }
  .right-gallery-one img {
    height: 122px;
  }
  .left-gallery-one img {
    height: 253px;
  }
  .details-full-img {
    width: 100%;
  }
  .deatil-blog-content h1 {
    font-size: 20px;
  }
  .deatil-blog-content h2 {
    font-size: 18px;
  }
  .deatil-blog-contents h3 {
    font-size: 18px;
  }
  .blog-detail-page {
    padding-top: 40px;
  }
  .deatil-blog-content p {
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 30px;
    letter-spacing: 1.08px;
    text-align: -webkit-auto;
  }
  .deatil-blog-contents p {
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: 30px;
    letter-spacing: 1.08px;
    text-align: -webkit-auto;
  }
  .date-text p {
    font-size: 11px;
  }
  .storage-rows h3 {
    font-size: 16px;
    line-height: 50px;
  }
  .product-grid .card-footer {
    margin: 0;
  }
  .card-footer.border-0 h4.ellipsis-text {
    font-size: 17.5px !important;
    padding-left: 4px;
    font-family: "BrandonText-Light", sans-serif;
  }
  .sortHeader h5 {
    text-align: center;
    padding-bottom: 15px;
  }
  a.dropdown-toggle.text-dark.btn-filter {
    padding: 5px;
    display: block;
  }
  body.stores a.dropdown-toggle.text-dark.btn-filter {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    min-width: 87px;
}
body.stores .product-custom-dropdown .dropdown-menu {
 
  right: -13px;
}
  nav.fixed-top .icon-input input.icon-input__text-field::placeholder {
    font-weight: 900;
  }
  input.form-control.rounded.mob-search-box {
    font-weight: 900;
  }
  body.products .product-main-banner {
    display: none;
  }
  body.checkout .nav-lb-tab .nav-item .nav-link {
    text-transform: uppercase;
    font-size: 11px;
  }
  .flash-sale-box span img,
  svg {
    vertical-align: middle;
  }
.loc-svg .add-heading {
    padding-left: 10px;
}
.col-md-4.d-flex.loc-svg-1 svg {
  width: 30px;
}
  .d-block.d-lg-none.my-2 {
    margin-bottom: 0px !important;
  }
  .col-lg-9.col-md-12 .mt-4 {
    margin-top: 5px !important;
  }
  .visit-row ul li {
    width: 100%;
  }

  .offcanvas {
    z-index: 999 !important;
  }
 .btn-bg-fix {
    display: block !important;
    position: fixed;
    z-index: 888;
    bottom: -8px;
    width: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 20px 15px;
    margin: 0px;
}
body.cart .my-bag-card .list-group:last-child {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-width: 0;
  position: fixed;
  bottom: 56px;
  left: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  padding: 0px 20px;
  z-index: 889;
}
body.checkout footer.footer {
  display: none;
}
body.checkout .mt-2.secure-checkout-top .d-grid {
  display: block !important;
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 20px;
}
body.checkout .mt-2.secure-checkout-top .d-grid button.btn.btn-dark.mb-1 {
  width: 100%;
  background-color: #88b48d;
  color: #000;
  border-color:  #88b48d;
}
body.checkout .list-group-flush > li.list-group-item.ps-0.d-flex.justify-content-between.align-items-start.border-tp:last-child {
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  z-index: 999;
  padding: 15px 30px 1px 15px !important;
}
body.cart ul.list-group.list-group-flush hr {
  display: none;
}
body.cart footer.footer {
  display: none;
}
  .product-grid h4 {
    white-space: normal;
    line-height: 20px;
    height: 45px;
    text-align: left !important;
  }
  button.ModalBtn.offer-button {
    display: none;
  }
  .container.d-block.d-sm-none.mob-count {
    position: absolute;
    top: -14px;
  }
  .banner-flashsale {
    position: relative;
  }
  .flash-sale-container h1 {
    text-align: left;
  }
  .col-lg-9.col-md-12.brands-sub {
    padding-left: 0px;
    padding-right: 0px;
  }
  .container {
    width: 95% !important;
    margin: 0 auto;
  }
  body.prvt-label .enquirys-btn a {
    padding: 17px 0px;
}
  .col-auto.search-tag {
    margin-right: 20px;
  }
  .hd-left {
    padding-left: 35px;
  }
  h1.mb-5.flash-sale {
    text-align: center;
  }
  .custom-badge {
    position: absolute;
    left: -17px;
    transform: scale(0.55);
    top: 4px;
  }
  .my-bag-spinner {
    transform: scale(0.65);
    position: relative;
    left: -26%;
  }
  .conatiner.pd-detail .row.mb-5 {
    padding: 0 15px;
  }
  .conatiner.pd-detail .mob-specs {
    padding: 0 15px;
  }
  span.color-txt {
    color: #a7a9a2;
  }
  div#myTabContent button.btn.btn-dark.validate {
    margin-left: 5px !important;
    padding-left: 40px;
    padding-right: 40px;
    width: 94%;
  }
  h6.ps-15.h6 {
    padding-left: 4rem !important;
  }
  li.list-group-item.ps-0.d-flex.justify-content-between.align-items-start.border-tp {
    border-top: solid 1px #e8dede;
  }
  .col.best-scroll {
    max-height: none;
    overflow-y: auto;
  }
  footer.footer .container {
    width: 100% !important;
  }
  .d-flex.justify-content-between.align-items-center.d-md-none.py-4
    h3.fs-5.mb-0 {
    display: none;
  }
  .card.checkout-left-card hr {
    width: 90%;
    margin: 0 auto;
  }
  .checkout-left-card h4 {
    color: #000;
    font-family: var(--fc-font-medium);
    font-size: 12px;
    text-transform: capitalize;
    line-height: 180%;
    max-height: 45px;
    overflow: hidden;
  }
  ul.list-group.list-group-flush.mob-flush li:first-child {
    margin-right: 0 !important;
    padding-right: 13px;
  }
  .alert-box-bag-empty p {
    width: 70%;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 40px;
  }
  #price-my-bag .selling-price {
    padding-left: 5px;
  }
  #price-my-bag .discount-percentage {
    margin-left: 0px;
  }
  .my-bag-card {
    margin: 20px 0 10px 0;
  }
  .input-group-custom.input-spinner {
    justify-content: center;
    margin-left: 30px;
  }
  .mob-change {
    padding: 0px 20px;
  }
  .col-md-6.col-12.text-end.mob-change a {
    display: inline-block;
    padding-right: 20px;
    padding-top: 20px;
  }
  .icon-input__icon {
    display: block;
  }
  .icon-input__icon {
    top: 17px !important;
    right: 1rem;
    transform: translateY(-50%);
    width: 14px;
  }
  .mob-view .input-group .form-control {
    padding: 5px 20px !important;
  }
  input.form-control.rounded.mob-search-box {
    padding: 9px 20px !important;
    border-radius: 9px !important;
}
  .row.search-container.row.search-top {
    padding: 0px 25px;
  }
  body.product-details .row.mob-view .input-group {
    display: block;
  }
  body.products .row.mob-view .input-group {
    display: block;
  }
  
  .row.mob-view .input-group {
    display: none;
  }
  /*body.home .row.mob-view .input-group {
    display: block !important;
  }*/
  .fixed-top-margin {
    margin-top: 10px;
  }
  .product-single-price,
  .product-single-discounted-price,
  .product-single-discounted {
    font-size: 14px !important;
    font-weight: 900;
    font-family: var(--fc-font-medium);
  }
  .product-desc-section h2.product-author a {
    font-size: 17px;
    text-transform: uppercase;
}
  .row.full-views .col-4 {
    margin-right: 0px;
    padding-right: 0px;
    width: 30%;
  }
  .row.full-views .row.icon-section {
    justify-content: end;
    padding-right: 20px;
  }
  .row.full-views .col-md-8.col-8 {
    padding-right: 0px;
  }
  body.wishlist .fixed-top-margin.main-layout {
    margin-top: 5px !important;
  }
  .fixed-top {
    padding-bottom: 5px;
    border: none;
  }
  .fixed-top-margin {
    margin-top: 5px;
  }
  body.products .fixed-top-margin {
    margin-top: 0px;
}
  body.cart .fixed-top-margin {
    margin-top: 5px;
  }
  body.wishlist .fixed-top-margin.main-layout {
    margin-top: 5px;
  }

  .d-flex.justify-content-between.align-items-center.d-md-none.py-4 {
    display: none !important;
  }
  .row.p-ul ul li {
    list-style-type: disc;
  }
  .search-top {
    padding: 0px 25px;
  }
  .rec-products-container {
    padding-right: 0px;
  }
  .card-footer.border-0 .row.custom-row1.mb-5 {
    padding: 0px !important;
    margin-bottom: 0px !important;
  }
  .rec-products-container .col-6 {
    width: 50%;
  }
  .rec-products-container .col-md-4 {
    width: 50%;
  }
  .row.brand-rating {
    width: 100%;
    margin: 0px !important;
  }
  .row.brand-rating .ml-10 {
    padding-bottom: 40px;
  }
  .row.product-rating {
    margin: 0;
  }
  /*.offcanvas.show:not(.hiding),
  .offcanvas.showing {
    padding-top: 0px;
  }*/
  .offcanvas-title {
    color: #000;
    text-transform: uppercase;
  }
  .visit-text p {
    font-size: 11px;
  }
  .col-md-4.text-end.col-4.company-name a {
    text-transform: uppercase;
  }
  .mob_tab_slider .slick-list {
    overflow: visible;
  }
  .input-icon-close {
    display: block;
  }
  img.input-icon-close {
    position: absolute;
    right: 15px;
    top: 12px;
    width: 7px;
    z-index: 9999;
  }
  body.home .row.mob-view .input-group {
    display: block;
  }
  body.product .row.mob-view .input-group {
    display: block;
  }
  button.btn-ongoing {
    /*margin-top: 20px;*/
    margin-top: 20px;
    margin-right: -20px;
    width: 65%;
  }
  button.btn-complete {
    margin-top: 20px;
  }
  .border-top.py-4.footer-bottom p {
    font-size: 12px;
    font-weight: 600;
  }
  .p-d-c.footer-text ul li {
    font-size: 14px;
    font-weight: var(--fc-nav-link-font-weight);
  }
  .offcanvas-title {
    color: #000;
  }
  .offcanvas-header {
    background-color: #f3f3f3;
  }
  div#offcanvasCategory .offcanvas-header.d-lg-none svg {
    display: none;
  }
  /*.filter-tp .offcanvas.show:not(.hiding), .offcanvas.showing {
  position: absolute;
  top:0;
  z-index: 2000 !important;
}*/
  .filter-tp {
    padding-top: 0px !important;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 2000 !important;
  }
  .rc-slider-handle {
    border: solid 2px #000000 !important;
  }
  .line-break {
    width: 65px;
  }
  .mob-info-sidebar {
    padding-top: 95px !important;
  }
  .card.flash-sale-container h1.flash-sale {
    font-size: 12px;
  }
  .collect-banner {
    padding: 0px 0 !important;
  }
  body.home .cc-margin {
    margin: 1rem 0 !important;
    margin-bottom: 0px !important;
  }
  .flash-sale span {
    padding: 0 5px;
  }
  .product-filter-sidebar .offcanvas.show:not(.hiding),
  .offcanvas.showing {
    padding-top: 0px;
  }

  .custom-badge2 {
    left: -8%;
  }
  .text-size {
    font-size: 1.75rem;
    font-weight: 600;
    font-family: "BrandonText-Bold", sans-serif;
  }
  .return-policy-div h1,
  h6 {
    padding-bottom: 5px;
  }
  .form-group.has-search .product-search-icon-close {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .out-of-stock-badge {
    position: absolute;
    text-transform: capitalize;
    z-index: 10;
    left: 15px;
    top: 140px;
  }
  body.product-details .out-of-stock-badge {
    position: absolute;
    text-transform: capitalize;
    z-index: 10;
    left: 15px;
    top: 146px;
}
body.product-details .accordion-collapse.collapse.show .accordion-body {
  padding-top: 0px;
}
body.product-details .accordion-button {
  padding-bottom: 20px;
}
.offcanvas-body.mob-account-sidebar ul.nav.flex-column.nav-pills.nav-pills-dark {
  padding-top: 3px;
}
.offcanvas-body.mob-account-sidebar  ul.nav.flex-column.nav-pills.nav-pills-dark .dropdown-item {
  padding: 10px 20px;
}
.offcanvas-body.mob-account-sidebar  .img-nav-link {
  padding: 8px 20px;
}
  .badge.out-of-stock-badge.left-badge {
    position: absolute;
    text-transform: capitalize;
    z-index: 10;
    left: 0px;
    top: 14px;
    font-size: 8px;
  }
  .d-block.d-sm-none.footer-expand-section {
    margin-bottom: 10px;
  }
  .border-top.py-4.footer-bottom p.mb-5.text-size {
    font-weight: 600;
    line-height: normal;
    font-family: "BrandonText-Bold", sans-serif;
    font-size: calc(1.3rem + 0.6vw);
    margin-bottom: 0px !important;
  }
  .text-mid {
    text-align: left;
  }
  a.btn.btn-filter.w-100:focus {
    border: 1px solid #000;
  }
  a.btn.btn-filter.w-100 {
    border: 1px solid #000;
  }
  .icon-end .lang-dropdown-menu {
    right: -35px;
    top: 30px;
  }
  .ftr-mcard {
    text-align: start;
  }
  .ftr-tamara img {
    width: 60px !important;
  }
  .mob-set {
    padding-right: 10px;
  }
  .row.mob-payment-icons.align-items-center img {
    width: 50px;
  }
  .footer-logo-size {
    width: 39px;
    padding-top: 5px;
  }
  .logo-m img.footer-logo-size {
    width: 50px;
    padding-top: 0px;
  }
  .flash-sale-container {
    padding-bottom: 0px;
  }

  img.notify-icon {
    display: inline-block;
  }
  .career-review-text.lg-none {
    display: block;
  }
  .gallery-flex ul li img {
    width: 100%;
    height: auto;
  }
  span.style-module_emptyIcons__Bg-FZ.empty-icons svg {
    width: 23px;
  }
  body.product-details svg.star-svg {
    width: 23px;
  }
  body.product-details .form-outline textarea.form-control {
    min-height: calc(1.6em + 1.1rem + 2px);
    height: 45px;
  }
  .btn-100 {
    width: 100%;
  }
  .mb-center {
    text-align: center;
  }
  .btn-complete {
    /*position: absolute;
  width: 140px;
  padding-left: 20px;*/
    position: static;
    width: 160px;
    padding-left: 20px;
  }
  body.product-details .otp-modal {
    padding: 20px 10px 45px 10px !important;
  }
  body.product-details div#ratingModal .otp-modal .btn-close {
    position: absolute;
    right: 3%;
    font-size: 10px;
  }
  body.checkout .cart-details-img {
    height: 90px;
  }
  body.checkout .checkout-left-card h6 {
    display: block;
    padding-right: 0px;
  }
  .react-multi-carousel-list.container-with-dots.fbt-product-plus
    .react-multiple-carousel__arrow--left {
    display: none;
  }
  .react-multi-carousel-list.container-with-dots.fbt-product-plus
    .react-multiple-carousel__arrow--right {
    display: none;
  }
  .react-multi-carousel-list.container-with-dots.fbt-product-plus
    .product-grid.slick-slider-alignment {
    width: 100%;
    margin: 0 auto;
  }
  .card-body.fbt-product-box {
    width: 100%;
  }
  button.btn.btn-dark.mt-2.fbt-add-to-cart {
    width: 95% !important;
    padding: 12px 0px;
    font-size: 13px !important;
    font-style: normal;
  }
  h4.fbt-ellipsis-text {
    font-size: 14px !important;
  }
  .card-body.fbt-product-box .product-img {
    min-height: 170px !important;
  }
  .conatiner.pd-detail .col-md-5 .p-d {
    padding-bottom: 20px;
    padding-top: 10px;
  }
  body.orders .orders-card {
    padding: 20px 10px;
  }
  body.orders .orders-card label {
    font-size: 11px;
  }
  .order-flex {
    padding-right: 10px;
  }
  body.orders .react-multi-carousel-item .pds-0 h4 {
    padding-right: 10px;
  }
  body.orders .react-multi-carousel-item .order-img {
    width: 95px;
    height: auto;
  }
  body.order-details .order-img {
    width: 95px;
    height: 100px;
  }
  body.order-details .order-bottom-row h4 {
    font-size: 10px;
    height: 27px;
    overflow: hidden;
    padding-right: 5px;
  }
  body.order-details .order-bottom-row h2 {
    font-size: 11px;
    margin-top: 10px;
  }
  body.order-details .order-bottom-row h5 {
    font-size: 10px;
    text-transform: capitalize;
  }
  body.orders .react-multi-carousel-item h4 {
    font-size: 9px;
    height: 33px;
    overflow: hidden;
    line-height: 16px;
  }
  body.orders .react-multi-carousel-item h2 {
    font-size: 13px;
    margin-top: 10px;
  }
  body.orders .order-bottom-row h5 {
    margin-top: 15px;
  }

  body.orders .react-multi-carousel-item h3 {
    font-size: 10px;
  }

  .order-bottom-row .react-multiple-carousel__arrow--left {
    left: calc(29% + 18px);
    display: none;
  }
  button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    display: none;
  }
  body.orders .order-middle-row h3 {
    font-size: 10px;
  }
  body.orders .orders-card a {
    color: #000;
    font-size: 10px;
  }
  body.orders .order-middle-row p {
    font-size: 9px;
  }

  .order-bottom-row h3 {
    font-size: 9px !important;
  }
  body.orders .order-details p {
    font-size: 9px;
  }
  body.order-details .orders-card {
    padding: 15px;
  }
  body.order-details .order_status_disable {
    font-size: 9px;
  }
  body.order-details .text-lg-end {
    text-align: end;
  }
  .orders-card.order-details h3 {
    font-size: 11px !important;
  }
  .prd-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  body.orders .order-top-row svg {
    margin-right: 3px !important;
    vertical-align: middle;
    width: 11px;
  }
  body.orders .order-middle-row {
    margin-top: 10px;
  }
  body.order-details .order-top-row svg {
    margin-right: 3px !important;
    vertical-align: middle;
    width: 7px;
  }
  body.order-details .order-middle-row {
    margin-top: 10px;
  }
  body.order-details .row.order-top-row label {
    font-size: 11px;
  }
  body.order-details .order-middle-row p {
    padding-top: 6px;
    font-size: 9px;
  }
  body.order-details .order-middle-row.align-items-center h3 {
    font-size: 9px !important;
  }
  body.home .footer {
    margin-top: 30px;
  }

  body.orders .float-end {
    margin-top: 2px;
  }
  .pds-0 {
    padding-right: 0;
  }
  body.orders .order-bottom-row h3 {
    margin-top: 0px;
  }
  body.returns .orders-card {
    padding: 20px 15px;
  }
  .wallet-card h3 {
    font-family: brandonTextBold, sans-serif;
    font-size: 15px;
  }
  body.blog-details .deatil-blog-content h1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  body.blog-details .blog-detail-page {
    padding-top: 0px;
  }
  body.cart .promo-modal .modal-content {
    max-height: 470px;
    overflow-y: auto;
  }
  body.checkout .promo-modal .modal-content {
    max-height: 470px;
    overflow-y: auto;
  }
  .add-heading a {
    padding-bottom: 20px;

}
.add-heading p {
  width: 100%;
}
.add-heading h2 {
  font-size: 15px;
}
body.checkout ul.nav.nav-pills.nav-tabs.nav-lb-tab li.nav-item {
  margin-right: 0px;
  width: 50%;
  margin-right: 0px !important;
}
body.address-book div#AddAddress .modal-body.p-5 {
  max-height: 300px;
  overflow-y: auto;
}
body.product-details div#collapseThree .accordion-body {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 20px;
}
body.product-details div#collapseTwo .accordion-body {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 20px;
}
body.address-book .fixed-top-margin.main-layout main br {
  display: none;
}
body.cart section.my-bag {
  padding-bottom: 110px !important;
}
.store-single-img-width {
  height: auto;
}
.view-all {
  margin-left: 7px;
  margin-top: -4px;
}
body.product .flash-sale-box h2 {
  font-size: 10px !important;
}
section.banner-flashsale.container-lg-fluid .row.align-items-center.d-end.px-5.timer-row {
  justify-content: center !important;
}
section.collect-banner .mob-inspired {
  padding: 35px 20px 15px 20px;
}
section.banner-flashsale.container-lg-fluid .mob-count .mob-time-break {
  color: #070707 !important;
}
  .popupOverlay {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popupContent {
  position: relative;
  width: 80%;
  max-width: 380px;
  height: 45%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 0;
}

.backgroundImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 103%;
  height: 96%;
  z-index: 3;
}

.yellowSection {
  background-color: #ffd700;
  height: 65%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.whiteSection {
  background-color: #fff;
  height: 35%;
  padding: 20px;
  text-align: center;
  position: relative;
}

.whiteSection h4 {
  color: #ffd700;
  margin-bottom: 3px;
}

.whiteSection_message  {
  font-size: 10px;
  font-weight: bold;
}

.whiteSectionTC  {
  font-size: 7px;
  margin-top: 7px;
}

.centerImage {
  max-width: 80%;
  max-height: 80%;
  margin-top: 30px;
  margin-right: 25px;
}
.popupOverlay {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popupContent {
  position: relative;
  width: 80%;
  max-width: 380px;
  height: 45%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 0;
}

.backgroundImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 103%;
  height: 96%;
  z-index: 3;
}

.yellowSection {
  background-color: #ffd700;
  height: 65%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.whiteSection {
  background-color: #fff;
  height: 35%;
  padding: 20px;
  text-align: center;
  position: relative;
}

.whiteSection h4 {
  color: #ffd700;
  margin-bottom: 3px;
}

.whiteSection_message  {
  font-size: 10px;
  font-weight: bold;
}

.whiteSectionTC  {
  font-size: 7px;
  margin-top: 7px;
}

.centerImage {
  max-width: 80%;
  max-height: 80%;
  margin-top: 30px;
  margin-right: 25px;
}
}

@media only screen and (min-width: 321px) and (max-width: 769px) {
  body.home .cd-margin {
    margin-bottom: 0rem;
  }
  .border-top.py-4.footer-bottom .d-sm-block {
    padding-bottom: 50px;
  }
  body.checkout div#rating-tab-pane svg.regular-sort-icon {
    display: block;
    visibility: visible !important;
  }
  .container-fluid.cc-margin.w-deals {
    padding: 0 15px;
  }

.container-new .visit-row {
  margin-top: 0px;
}
.mt-4.crumb-mob {
  display: none;
}
.page-new .container-new .title-page {
  padding-top: 0px;
}
}
@media (max-width: 320px) {
  .category-top-img img {
    padding: 4px;
    max-width: 70px;
    max-height: 121px;
  }
  .category-top h1 {
    font-size: 16px;
  }
  .wallet-banner h1 {
    font-size: 20px;
  }
  .wallet-banner {
    border-radius: 9px;
    padding: 30px 20px;
  }
  .top-categories {
    padding: 6px;
  }
  .container-lg-fluid {
    --fc-gutter-x: 4rem;
    --fc-gutter-y: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 100%;
  }
  .top-categories h3 {
    font-size: 10px;
  }
  .top-categories h1 {
    font-size: 15px;
  }
  .shop-preferences {
    padding: 25px 10px;
  }
  .slick-slider h3 {
    font-size: 10px;
  }
  .shop-preferences h1 {
    font-size: 15px;
  }
  .thumbnails {
    margin: 3px;
  }
  .brands-card h1 {
    font-size: 15px;
  }
  .brands-card h1 {
    font-size: 15px;
  }
  .ads-banner {
    padding: 10% 0;
    min-height: 145px;
    margin: 0 12px;
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  }
  body.product-details .thumbnail-slide {
    display: none !important;
  }
  .incriment-button .d-end {
    display: flex !important;
    justify-content: flex-start !important;
  }
  .conatiner.pd-detail .mob-specs {
    padding: 0 4px;
  }
  .rec-products-container {
    padding: 8px 0px;
  }
  .product-desc-section h2 {
    color: #000;
    font-size: 11px;
  }
  .shop-more h1 {
    font-size: 15px;
  }
  .sub-abt h2 {
    font-size: 14px;
    line-height: 40%;
  }
  .start-explor a {
    width: 243px;
    height: 54px;
  }
  ol.breadcrumb {
    margin-bottom: 1rem;
    margin-top: 8px;
  }
  .product-desc-section h1 {
    font-size: 17px;
    padding-top: 10px;
  }
  body.products .product-box .btn-dark {
    padding: 4px !important;
    font-size: 8px;
  }
  body.products .discounted-price {
    font-size: 12px;
  }
  body.products .selling-price {
    font-size: 12px;
  }
  a.dropdown-toggle.text-dark.btn-filter {
    font-size: 12px;
  }
  .btn-filter {
    color: #000;
    font-family: var(--fc-font-light) !important;
    font-size: 12px !important;
    line-height: normal;
    border-radius: 3px;
    border: 0.7px solid #000;
    padding: 5px;
    text-align: start;
    font-weight: 400;
  }
  a.dropdown-toggle.text-dark.btn-filter {
    padding: 1px;
    display: block;
  }
  .sortHeader h6 {
    font-size: 12px;
  }
  svg.regular-sort-icon {
    vertical-align: middle;
    width: 15px;
  }
  .product-grid h4 {
    height: 44px;
  }
  .container-fluid .col-12.px-10 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .btn-returned {
    width: 134px;
    left: 123px;
    padding: 6px 0px 5px 0px;
    height: 35px;
  }
  .img-nav-link {
    padding: 15px 0px;
    text-align: center !important;
  }
  .img-nav-link {
    padding: 15px 0px;
  }
  .dropdown-item {
    border-bottom: 0.5px solid #bdbebb;
    border-radius: 0px !important;
  }
  
}
.menu-zindex {
  z-index: -1 !important;
}
.bottom-nav {
  z-index: 9999 !important;
}

@media (max-width: 1400px) {
  .visit-pg {
    min-height: 258px;
  } 
}